import React from 'react';
import { Col, Row } from 'antd';

import * as Styled from './ContentStyles';

interface IProps {
  position: string;
  children: JSX.Element;
  bg?: string;
}

export default function Content({ position, children, bg }: IProps) {
  if (position === 'stretch') {
    return (
      <Styled.Container bg={bg}>
        <Col span={24}>{children}</Col>
      </Styled.Container>
    );
  }
  if (position === 'center') {
    return (
      <Styled.Container justify="center" gutter={[10, 10]} bg={bg}>
        <Col md={{ span: 14 }}>{children}</Col>
      </Styled.Container>
    );
  }

  if (position === 'grid') {
    return (
      <Styled.Container gutter={[24, 24]} bg={bg}>
        <Col span={16}>
          <Row gutter={[0, 24]}>{children.props.children[0]}</Row>
        </Col>
        <Col span={8}>
          <Row gutter={[0, 24]}>{children.props.children[1]}</Row>
        </Col>
      </Styled.Container>
    );
  }

  if (position === 'flexcol') {
    return (
      <Styled.Container bg={bg}>
        <Col span={24}>
          <Row gutter={[0, 24]}>{children}</Row>
        </Col>
      </Styled.Container>
    );
  }

  return <>{children}</>;
}
