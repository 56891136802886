/* eslint-disable react/no-children-prop */
import React, { useState } from 'react';
import { Avatar, Col, Row, Tag } from 'antd';
import { useProjectsData } from 'ui-v2/hooks/useProjectsData';
import * as Styled from 'ui-v2/components/WorkExperience/WorkExperienceStyles';
import CardItem from 'ui-v2/components/Card';
import { useTranslation } from 'react-i18next';
import {
  IApprover,
  IApproverUpdateDTO,
  TimeOff,
  TimeOffStatus,
} from 'types/TimeOff';
import moment from 'moment';
import { FormItemStyled, StyledButton } from 'components/NewForms/FormStyled';
import { ViewFeedbackIcon } from 'Icons/ViewFeedbackIcon';
import GenericModal from 'ui-v2/components/GenericModal';
import { useDispatch, useSelector } from 'react-redux';
import { updateVoteTimeOff } from 'api/timeOffService';
import { fetchSingleProject } from 'redux/projects/actions';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';

export const getStatusColor = (status: TimeOffStatus) => {
  switch (status) {
    case TimeOffStatus.APPROVED:
      return 'success';
    case TimeOffStatus.REJECTED:
      return 'error';
    case TimeOffStatus.PENDING:
      return 'warning';
    default:
      return 'default';
  }
};

export const getStatusStyles = (status: TimeOffStatus) => {
  switch (status) {
    case 'APPROVED':
      return {
        backgroundColor: '#f6ffed',
        color: '#52c41a',
        borderColor: '#b7eb8f',
      };
    case 'PENDING':
      return {
        backgroundColor: '#fffbe6',
        color: '#faad14',
        borderColor: '#ffe58f',
      };
    case 'REJECTED':
      return {
        backgroundColor: '#fff2f0',
        color: '#ff4d4f',
        borderColor: '#ffccc7',
      };
    default:
      return {};
  }
};

export default function ProjectTimeOffs() {
  const { t } = useTranslation();
  const {
    project: { data },
  } = useProjectsData();
  const dispatch = useDispatch();
  const authUserState = useSelector((state: any) => state.authUser);
  const [searchParams] = useSearchParams();
  const [selectedTimeOff, setSelectedTimeOff] = useState<TimeOff | null>(null);
  const [loading, setLoading] = useState(false);

  const approverEmployeeId = authUserState?.authUser?.employee?.id;

  const approvers = data?.employeesWithTimeOff?.find(
    (a: any) => a.id === selectedTimeOff?.id
  )?.approver;

  const approverId = approvers?.find(
    (a: IApprover) => a?.employee?.id === approverEmployeeId
  )?.id;

  const formatDateRange = (dates: string) => {
    if (!dates) return 'N/A';
    try {
      const parsedDates = JSON.parse(dates);
      if (!parsedDates || !parsedDates.length) return 'N/A';

      const startDate = moment(parsedDates[0].date);
      const endDate = moment(parsedDates[parsedDates.length - 1].date);
      return `${startDate.format('MMM D')} - ${endDate.format('MMM D, YYYY')}`;
    } catch (error) {
      return 'N/A';
    }
  };

  if (!data?.id) return null;

  const onSelectTimeOff = (timeOff: TimeOff | null) => {
    setSelectedTimeOff(timeOff);
  };

  const closeModal = () => {
    setSelectedTimeOff(null);
  };

  const updateVote = (vote: TimeOffStatus, reject = false) => {
    if (!approverEmployeeId || !approverId) return;
    setLoading(true);
    updateVoteTimeOff({
      employeeId: approverEmployeeId,
      timeOffId: selectedTimeOff?.id,
      action: vote,
      approverId,
    } as IApproverUpdateDTO)
      .then((res) => {
        setLoading(false);
        if (res.status === 200) {
          if (reject) {
            closeModal();
            toast.success(t('succesfullyRejected'));
          } else {
            closeModal();
            toast.success(t('succesfullyApproved!'));
          }

          dispatch(fetchSingleProject(searchParams.get('id') as string));
        }
      })
      .catch(() => {
        setLoading(false);
        toast.error(t('failedToApprove!'));
      });
  };

  const onCancelRequest = () => {
    updateVote(TimeOffStatus.REJECTED, true);
  };

  const onApproveRequest = () => {
    updateVote(TimeOffStatus.APPROVED);
  };

  return (
    <>
      <CardItem title={t('timeOffSchedule')}>
        <>
          <Row gutter={[0, 24]}>
            {!data?.employeesWithTimeOff?.length && (
              <>
                <Styled.RowItem justify="center" last>
                  <Col>
                    <Styled.NodataLabel>
                      {t('timeOffNotFound')}
                    </Styled.NodataLabel>
                  </Col>
                </Styled.RowItem>
              </>
            )}
            {data?.employeesWithTimeOff?.map((timeOff: TimeOff) => (
              <Styled.RowItem
                key={timeOff.employeeId}
                onClick={() => onSelectTimeOff(timeOff)}
                style={{ cursor: 'pointer' }}
                last
                gutter={[0, 10]}
                align="middle"
              >
                <Col span={6}>
                  <span>{timeOff?.employee?.firstName}</span>
                  <span style={{ marginLeft: '4px' }}>
                    {timeOff?.employee?.lastName}
                  </span>
                </Col>
                <Col span={10} style={{ textAlign: 'center' }}>
                  {formatDateRange(timeOff.dates)}
                </Col>
                <Col span={8} style={{ textAlign: 'right' }}>
                  <Tag
                    color={getStatusColor(timeOff.aprovalStatus)}
                    style={{ borderRadius: '8px' }}
                  >
                    {timeOff.aprovalStatus}
                  </Tag>
                </Col>
              </Styled.RowItem>
            ))}
          </Row>
        </>
      </CardItem>
      <GenericModal
        title="View Selected Time-Off request"
        open={!!selectedTimeOff}
        closeModal={closeModal}
        icon={<ViewFeedbackIcon />}
      >
        <>
          <Row gutter={[0, 20]}>
            <Col span={24}>
              <Row gutter={[0, 4]} align="middle">
                <Col span={4}>
                  <Avatar size={50}>
                    {selectedTimeOff?.employee?.firstName?.trim().charAt(0)}
                    {selectedTimeOff?.employee?.lastName?.trim().charAt(0)}
                  </Avatar>
                </Col>
                <Col span={8}>
                  <Row gutter={[5, 0]}>
                    <Col>
                      <Col>{selectedTimeOff?.employee?.firstName ?? ''}</Col>
                    </Col>
                    <Col>
                      <Col>{selectedTimeOff?.employee?.lastName ?? ''}</Col>
                    </Col>
                  </Row>
                </Col>
                <Col span={4} offset={8}>
                  <Tag
                    color={
                      selectedTimeOff
                        ? getStatusColor(selectedTimeOff?.aprovalStatus)
                        : ''
                    }
                    style={{ borderRadius: '8px' }}
                  >
                    {selectedTimeOff?.aprovalStatus}
                  </Tag>
                </Col>
                <Col span={10} offset={4}>
                  {formatDateRange(selectedTimeOff?.dates)}
                </Col>
              </Row>
            </Col>
          </Row>

          {selectedTimeOff?.aprovalStatus === TimeOffStatus.PENDING && (
            <FormItemStyled style={{ marginTop: 30, marginBottom: 0 }}>
              <Row>
                <Col span={11}>
                  <StyledButton
                    onClick={() => {
                      onCancelRequest();
                      closeModal();
                    }}
                    htmlType="reset"
                    danger
                  >
                    Reject
                  </StyledButton>
                </Col>
                <Col span={11} offset={2}>
                  <StyledButton
                    type="primary"
                    htmlType="submit"
                    loading={loading}
                    onClick={onApproveRequest}
                  >
                    Approve
                  </StyledButton>
                </Col>
              </Row>
            </FormItemStyled>
          )}
        </>
      </GenericModal>
    </>
  );
}
