import { Action } from 'redux';
import { Reducer } from 'react';

import { Trackings, TrackingsState } from './types';

const initialTrackingsState: TrackingsState = {
  trackedHoursDetails: {
    data: [],
    meta: undefined,
    loading: false,
    loaded: false,
    errored: false,
    error: undefined,
  },
  singleTrackedHour: {
    data: undefined,
    loading: false,
    loaded: false,
    errored: false,
    error: undefined,
  },
  trackedHoursBarChart: {
    data: [],
    meta: undefined,
    loading: false,
    loaded: false,
    errored: false,
    error: undefined,
  },
  trackedHoursPieChart: {
    data: [],
    meta: undefined,
    loading: false,
    loaded: false,
    errored: false,
    error: undefined,
  },
  trackedHoursSummary: {
    data: undefined,
    loading: false,
    loaded: false,
    errored: false,
    error: undefined,
  },
  trackedHoursToApprove: {
    data: [],
    meta: undefined,
    loading: false,
    loaded: false,
    errored: false,
    error: undefined,
  },
  project: '',
};

export interface TrackingsReducedAction extends TrackingsState, Action {
  payload?: any;
}

const TrackedHoursReducer: Reducer<TrackingsState, TrackingsReducedAction> = (
  state = initialTrackingsState,
  action: TrackingsReducedAction
) => {
  switch (action.type) {
    /**
     * @DETAILED
     */
    case Trackings.FETCH_TRACKED_HOURS_DETAILS_START:
      state = {
        ...state,
        trackedHoursDetails: {
          ...initialTrackingsState.trackedHoursDetails,
          loading: true,
        },
      };
      break;
    case Trackings.FETCH_TRACKED_HOURS_DETAILS_SUCCESS:
      state = {
        ...state,
        trackedHoursDetails: {
          ...initialTrackingsState.trackedHoursDetails,
          data: action.trackedHoursDetails.data,
          meta: action.trackedHoursDetails.meta,
          overallTotal: action.trackedHoursDetails.overallTotal,
          sectionTotal: action.trackedHoursDetails.sectionTotal,
          loaded: true,
        },
      };
      break;
    case Trackings.FETCH_TRACKED_HOURS_DETAILS_ERRORED:
      state = {
        ...state,
        trackedHoursDetails: {
          ...initialTrackingsState.trackedHoursDetails,
          error: action.trackedHoursDetails.error,
          errored: true,
        },
      };
      break;
    /**
     * @SUMMARY
     */
    case Trackings.FETCH_TRACKED_HOURS_SUMMARY_START:
      state = {
        ...state,
        trackedHoursSummary: {
          ...initialTrackingsState.trackedHoursSummary,
          loading: true,
        },
      };
      break;
    case Trackings.FETCH_TRACKED_HOURS_SUMMARY_SUCCESS:
      state = {
        ...state,
        trackedHoursSummary: {
          ...initialTrackingsState.trackedHoursSummary,
          data: action.trackedHoursSummary.data,
          loaded: true,
          loading: false,
        },
      };
      console.log({ summary: action.trackedHoursSummary.data });
      break;
    case Trackings.FETCH_TRACKED_HOURS_SUMMARY_ERRORED:
      state = {
        ...state,
        trackedHoursSummary: {
          ...initialTrackingsState.trackedHoursSummary,
          error: action.trackedHoursSummary.error,
          errored: true,
        },
      };
      break;
    /**
     * @PIE_CHART
     */
    case Trackings.FETCH_TRACKED_HOURS_PIE_CHART_START:
      state = {
        ...state,
        trackedHoursPieChart: {
          ...initialTrackingsState.trackedHoursPieChart,
          loading: true,
        },
      };
      break;
    case Trackings.FETCH_TRACKED_HOURS_PIE_CHART_SUCCESS:
      state = {
        ...state,
        trackedHoursPieChart: {
          ...initialTrackingsState.trackedHoursPieChart,
          data: action.trackedHoursPieChart.data,
          meta: action.trackedHoursPieChart.meta,
          loaded: true,
        },
      };
      break;
    case Trackings.FETCH_TRACKED_HOURS_PIE_CHART_ERRORED:
      state = {
        ...state,
        trackedHoursPieChart: {
          ...initialTrackingsState.trackedHoursPieChart,
          error: action.trackedHoursPieChart.error,
          errored: true,
        },
      };
      break;
    /**
     * @BAR_CHART
     */
    case Trackings.FETCH_TRACKED_HOURS_BAR_CHART_START:
      state = {
        ...state,
        trackedHoursBarChart: {
          ...initialTrackingsState.trackedHoursBarChart,
          loading: true,
        },
      };
      break;
    case Trackings.FETCH_TRACKED_HOURS_BAR_CHART_SUCCESS:
      state = {
        ...state,
        trackedHoursBarChart: {
          ...initialTrackingsState.trackedHoursBarChart,
          data: action.trackedHoursBarChart.data,
          meta: action.trackedHoursBarChart.meta,
          loaded: true,
        },
      };
      break;
    case Trackings.FETCH_TRACKED_HOURS_BAR_CHART_ERRORED:
      state = {
        ...state,
        trackedHoursBarChart: {
          ...initialTrackingsState.trackedHoursBarChart,
          error: action.trackedHoursBarChart.error,
          errored: true,
        },
      };
      break;
    /**
     * @TO_APPROVE
     */
    case Trackings.FETCH_TRACKED_HOURS_TO_APPROVE_START:
      state = {
        ...state,
        trackedHoursToApprove: {
          ...initialTrackingsState.trackedHoursToApprove,
          loading: true,
        },
      };
      break;
    case Trackings.FETCH_TRACKED_HOURS_TO_APPROVE_SUCCESS:
      state = {
        ...state,
        trackedHoursToApprove: {
          ...initialTrackingsState.trackedHoursToApprove,
          data: action.trackedHoursToApprove.data,
          meta: action.trackedHoursToApprove.meta,
          overallTotal: action.trackedHoursToApprove.overallTotal,
          sectionTotal: action.trackedHoursToApprove.sectionTotal,
          loaded: true,
        },
      };
      break;
    case Trackings.FETCH_TRACKED_HOURS_TO_APPROVE_ERRORED:
      state = {
        ...state,
        trackedHoursToApprove: {
          ...initialTrackingsState.trackedHoursToApprove,
          error: action.trackedHoursToApprove.error,
          errored: true,
        },
      };
      break;
    case Trackings.FETCH_TRACKED_PROJECT_NAME:
      return {
        ...state,
        project: action.payload,
      };
    default:
      state = { ...state };
      break;
  }
  return state;
};

export { TrackedHoursReducer };
