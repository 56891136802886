import { Popconfirm, Switch, SwitchProps } from 'antd';
import React, { useEffect, useState } from 'react';
import { RulesType } from 'types/FormTypes';
import { t } from 'i18next';
import { FormItemStyled } from '../FormStyled';

interface Props {
  placeholder: string;
  name: string;
  defaultChecked?: boolean | undefined;
  popconfirmMessage?: string;
  switchProps?: SwitchProps;
  rules?: RulesType[];
  disabled?: boolean;
  direction?: 'vertical' | 'horizontal';
  onChecked?: (checked: boolean) => void;
  hint?: React.ReactNode | string;
  setFieldValue?: any;
}

const PopconfirmSwitchComponent: React.FC<Props> = ({
  placeholder,
  name,
  switchProps,
  defaultChecked,
  popconfirmMessage,
  rules,
  disabled,
  onChecked,
  direction,
  hint,
  setFieldValue,
}) => {
  const [checked, setChecked] = useState(defaultChecked ?? false);

  const handleConfirm = () => {
    const newValue = !checked;
    setChecked(newValue);
    setFieldValue(newValue);
    if (onChecked) {
      onChecked(newValue);
    }
  };

  useEffect(() => {
    setChecked(defaultChecked ?? false);
  }, [defaultChecked]);

  return (
    <FormItemStyled
      name={name}
      valuePropName="checked"
      label={placeholder}
      direction={direction}
      rules={rules}
      initialValue={defaultChecked}
      {...(hint && { tooltip: hint })}
    >
      <Popconfirm
        title={`${popconfirmMessage}`}
        okText={t('yes')}
        cancelText={t('no')}
        onConfirm={handleConfirm}
      >
        <Switch disabled={disabled} checked={checked} {...switchProps} />
      </Popconfirm>
    </FormItemStyled>
  );
};

PopconfirmSwitchComponent.defaultProps = {
  defaultChecked: false,
  direction: 'horizontal',
  rules: [],
};

export default PopconfirmSwitchComponent;
