import React from 'react';
import FlowComponent from './FlowComponent';

export default function TenantHierarchy() {
  return (
    <>
      <FlowComponent />
    </>
  );
}
