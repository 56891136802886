import React, { useState } from 'react';
import {
  Badge,
  Col,
  PaginationProps,
  Popconfirm,
  Popover,
  Row,
  Space,
} from 'antd';
import { useSelector } from 'react-redux';
import { CloseOutlined, SearchOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import useHeader from 'ui-v2/hooks/useHeader';
import { DataGridProps } from 'types/GridTable';
import { DataGridState } from 'redux/UI/Datagrid/reducer';
import { ArrowLeftIcon } from 'Icons/ArrowLeftIcon';
import { ArrowRightIcon } from 'Icons/ArrowRightIcon';
import { TblColumnType } from 'ui-v2/components/TableConfigurePopover/ConfigreComponent/helpers';
import { useScreenSize } from 'ui-v2/hooks/useScreenSize';
import * as Styled from './DataGridStyles';
import ExportDataGrid from './ExportDataGrid';
import BulkUpload from './BulkUpload';
import i18next from '../../../i18n';
import DownloadTemplate from './DownloadTemplate';

export const itemRender: PaginationProps['itemRender'] = (
  _,
  type,
  originalElement
) => {
  if (type === 'prev') {
    return (
      <Styled.TableBtn
        type="default"
        className="ant-btn-first"
        icon={<ArrowLeftIcon />}
      >
        {i18next.t('previous')}
      </Styled.TableBtn>
    );
  }
  if (type === 'next') {
    return (
      <Styled.TableBtn
        type="default"
        className="ant-btn-last"
        icon={<ArrowRightIcon />}
      >
        {i18next.t('next')}
      </Styled.TableBtn>
    );
  }
  return originalElement;
};

const Dynamic: React.FC<DataGridProps> = ({
  columns,
  data,
  loading,
  paginationName,
  onPageChange,
  totalItems,
  currentPage,
  showSearch,
  onSelectedDelete,
  onSearch,
  multipleSelect = true,
  expandedRowRender,
  entityProps,
  rowKey,
  manualTake,
}) => {
  const dataGridStore: DataGridState = useSelector(
    (state: any) => state.dataGrid
  );
  const [searchTerm, setSearchTerm] = useState('');
  const [inputClear, setInputClear] = useState<boolean>(false);
  const [selectedRowIds, setSelectedRowIds] = useState<React.Key[]>([]);
  const { entity, take } = useHeader();
  const { isMobile } = useScreenSize();
  const { t } = useTranslation();

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[]) => {
      setSelectedRowIds(selectedRowKeys);
    },
    getCheckboxProps: (record: any) => ({
      disabled: record.name === 'Disabled User', // Column configuration not to be checked
      name: record.name,
    }),
  };

  const onInputChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  const onSearchClicked = () => {
    if (searchTerm === '') return;
    if (onSearch) {
      onSearch(searchTerm);
    }
  };

  const onClear = () => {
    if (searchTerm.length > 0 || (searchTerm === '' && inputClear)) {
      setSearchTerm('');
      setInputClear(false);
      if (onSearch && data.length >= 0) {
        onSearch('');
      }
    }
  };

  const onDelete = () => {
    if (!onSelectedDelete) {
      return;
    }
    onSelectedDelete(selectedRowIds);
    setSelectedRowIds([]);
  };

  const onKeyUp = (
    e:
      | React.KeyboardEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLInputElement>
  ) => {
    if (
      ((e as React.KeyboardEvent<HTMLInputElement>).key === 'Delete' ||
        (e as React.KeyboardEvent<HTMLInputElement>).key === 'Backspace') &&
      !(e.target as HTMLInputElement).value &&
      onSearch
    ) {
      onSearch('');
    }
  };

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      {showSearch && (
        <Row justify="end" gutter={[10, 0]}>
          <Col>
            {!isMobile && (
              <Popover placement="top" content={t('Import data from CSV')}>
                <span>
                  <BulkUpload entity={entity} />
                </span>
              </Popover>
            )}
          </Col>
          {entity === 'assets' && !isMobile && (
            <Col>
              <Popover placement="top" content={t('downloadTemplate')}>
                <span>
                  <DownloadTemplate entity={entityProps || entity} />
                </span>
              </Popover>
            </Col>
          )}
          {!isMobile ? (
            <>
              <Col sm={2} md={2} xl={1}>
                <Popover placement="top" content={t('Export data to CSV')}>
                  <span>
                    <ExportDataGrid
                      entity={entityProps || entity}
                      data={data}
                    />
                  </span>
                </Popover>
              </Col>
              <Col sm={10} md={8} xl={7}>
                <Styled.Search
                  placeholder={
                    entity === 'payroll-report'
                      ? t('searchByCount')
                      : t('searchallColumns')
                  }
                  allowClear={{
                    clearIcon: (
                      <CloseOutlined onClick={() => setInputClear(true)} />
                    ),
                  }}
                  onChange={onInputChanged}
                  onKeyUp={onKeyUp}
                  onPressEnter={onSearchClicked}
                  value={searchTerm}
                />
              </Col>
              <Col sm={4} md={3} xl={2}>
                <Styled.TableBtn
                  icon={<SearchOutlined />}
                  onClick={onSearchClicked}
                />
              </Col>
              <Col sm={4} md={3} xl={2}>
                <Styled.TableBtn onClick={onClear}>
                  {t('clear')}
                </Styled.TableBtn>
              </Col>
            </>
          ) : (
            <Row gutter={[10, 10]}>
              <Row style={{ width: '100%' }}>
                <Col xs={24} sm={10} md={8} xl={7}>
                  <Styled.Search
                    placeholder={
                      entity === 'payroll-report'
                        ? t('searchByCount')
                        : t('searchallColumns')
                    }
                    allowClear={{
                      clearIcon: (
                        <CloseOutlined onClick={() => setInputClear(true)} />
                      ),
                    }}
                    onChange={onInputChanged}
                    onKeyUp={onKeyUp}
                    onPressEnter={onSearchClicked}
                    value={searchTerm}
                  />
                </Col>
              </Row>
              <Row
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'space-around',
                }}
              >
                <Col xs={7}>
                  <Popover placement="top" content={t('Export data to CSV')}>
                    <span>
                      <ExportDataGrid
                        entity={entityProps || entity}
                        data={data}
                      />
                    </span>
                  </Popover>
                </Col>
                <Col xs={7} offset={1}>
                  <Styled.TableBtn
                    icon={<SearchOutlined />}
                    onClick={onSearchClicked}
                  />
                </Col>
                <Col xs={7} offset={1}>
                  <Styled.TableBtn onClick={onClear}>
                    {t('clear')}
                  </Styled.TableBtn>
                </Col>
              </Row>
            </Row>
          )}
          {selectedRowIds?.length > 0 && (
            <Col>
              <Badge count={selectedRowIds?.length}>
                <Popconfirm
                  placement="topLeft"
                  title={t('areYouSureYouWantToDeleteTheSelectedRecords?')}
                  onConfirm={onDelete}
                  okText={t('yes')}
                  cancelText={t('no')}
                >
                  <Styled.TableBtn danger>{t('delete')}</Styled.TableBtn>
                </Popconfirm>
              </Badge>
            </Col>
          )}
        </Row>
      )}

      <Styled.DataGrid
        className="gx-table-responsive"
        expandable={expandedRowRender}
        rowKey={rowKey}
        rowSelection={
          !isMobile && multipleSelect
            ? {
                type: 'checkbox',
                ...rowSelection,
              }
            : undefined
        }
        scroll={{ x: 400 }}
        size={dataGridStore.size}
        loading={loading}
        columns={columns.sort(
          (a: TblColumnType, b: TblColumnType) => a.order - b.order
        )}
        dataSource={data?.map((item: any) => ({
          ...item,
          key: entity === 'departments' ? item.department_id : item.id,
        }))}
        pagination={{
          position: ['bottomCenter'],
          showSizeChanger: false,
          locale: { items_per_page: paginationName },
          itemRender,
          onChange: onPageChange,
          total: totalItems,
          pageSize: manualTake ?? take ?? 10,
          current: currentPage,
        }}
      />
    </Space>
  );
};

export default Dynamic;
