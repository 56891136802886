import type { ColumnsType } from 'antd/es/table';
import {
  AssetColumnsMobile,
  AssetColumns as AssetsColumns,
} from 'table-configuration/AssetConfig';
import {
  CandidatesColums,
  CandidatesColumsMobile,
} from 'table-configuration/CandidatesConfig';
import {
  CertificationColumns,
  CertificationColumnsMobile,
} from 'table-configuration/CertificationsConfig';
import {
  DepartmentsColumns,
  DepartmentsColumnsMobile,
} from 'table-configuration/DepartmentsConfig';
import {
  EmployeesColums,
  EmployeesColumsMobile,
} from 'table-configuration/EmployeesConfig';
import { EvaluationPeriodsConfig } from 'table-configuration/EvaluationPeriodsConfig';
import {
  InterviewsColumns,
  InterviewsColumnsMobile,
} from 'table-configuration/InterviewsConfig';
import { IpWhitelistingColumns } from 'table-configuration/IpWhitelistingConfig';
import {
  OnboardingColumns,
  OnboardingColumnsMobile,
} from 'table-configuration/OnboardingConfig';
import {
  OpenPositionsColumns,
  OpenPositionsColumnsMobile,
} from 'table-configuration/OpenPositionsConfig';
import {
  ProjectsColumns,
  ProjectsColumnsMobile,
} from 'table-configuration/ProjectsConfig';
import { PublicSuperAdminColumns } from 'table-configuration/PublicSuperAdminConfig';
import {
  SkillsColumns,
  SkillsColumnsMobile,
} from 'table-configuration/SkillConfig';
import { TenantAccountColumns } from 'table-configuration/TenantAccountConfig';
import { TenantColumns } from 'table-configuration/tenantConfigs/TenantConfig';
import {
  TrainingsColumns,
  TrainingsColumnsMobile,
} from 'table-configuration/TrainingsConfig';
import {
  UsersColumns,
  UsersColumnsMobile,
} from 'table-configuration/UsersTableConfig';
import {
  WorkPositionsColumns,
  WorkPositionsColumnsMobile,
} from 'table-configuration/WorkPositionsConfig';
import { TrackingHoursColumns } from 'table-configuration/trackingHoursConfigs/TrackingHoursConfig';
import {
  PayrollRuleColumns,
  PayrollRuleColumnsMobile,
} from 'table-configuration/PayrollRuleConfig';
import {
  BenefitsColums,
  BenefitsColumsMobile,
} from 'table-configuration/BenefitsConfig';
import {
  PayrollDateColumns,
  PayrollDateColumnsMobile,
} from 'table-configuration/PayrollDateConfig';
import {
  QuestionnairesColumns,
  QuestionnairesColumnsMobile,
} from 'table-configuration/QuestionnairesConfig';
import {
  AssetCategoriesColumns,
  AssetCategoriesColumnsMobile,
} from 'table-configuration/AssetCategoriesConfig';
import {
  PurchaseRequestColumns,
  PurchaseRequestColumnsMobile,
} from 'table-configuration/PurchaseRequestConfig';
import {
  VendorsColumns,
  VendorsColumnsMobile,
} from 'table-configuration/VendorsConfig';
import {
  VendorCategoriesColumns,
  VendorCategoriesColumnsMobile,
} from 'table-configuration/VendorCategoriesConfig';
import {
  PurchaseOrderColumnsMobile,
  PurchaseOrdersColumns,
} from 'table-configuration/PurchaseOrdersConfig';
import {
  VendorQuestionnaireColumns,
  VendorQuestionnaireColumnsMobile,
} from 'table-configuration/VendorQuestionnaireConfig';
import {
  InvoicingColumns,
  InvoicingColumnsMobile,
} from 'table-configuration/InvoicingConfig';
import {
  IdeaboxColumns,
  IdeaboxColumnsMobile,
} from 'table-configuration/IdeaboxConfig';
import { GoalColumns, GoalColumnsMobile } from 'table-configuration/GoalConfig';
import { SkillsetColumns } from 'table-configuration/SkillsetMatchingConfig';
import {
  ExpenseReportColumns,
  ExpenseReportColumnsMobile,
} from 'table-configuration/ExpenseReportConfig';
import { FeatureColumns } from 'table-configuration/FeatureFlagsConfig';
import { PHONE_SCREEN_BREAKPOINT } from 'utils/constants';
import { CheckinColumns } from 'table-configuration/CheckinConfig';

export const getTblConfig: any = (entity: string) => {
  const isMobile = window.innerWidth < PHONE_SCREEN_BREAKPOINT;

  switch (entity) {
    case 'vendor-questionnaire':
      return isMobile
        ? VendorQuestionnaireColumnsMobile
        : VendorQuestionnaireColumns;
    case 'candidates':
      return isMobile ? CandidatesColumsMobile : CandidatesColums;
    case 'tenant-accounts':
      return TenantAccountColumns;
    case 'users':
      return isMobile ? UsersColumnsMobile : UsersColumns;
    case 'skills':
      return isMobile ? SkillsColumnsMobile : SkillsColumns;
    case 'employees':
      return isMobile ? EmployeesColumsMobile : EmployeesColums;
    case 'interviews':
      return isMobile ? InterviewsColumnsMobile : InterviewsColumns;
    case 'open-positions':
      return isMobile ? OpenPositionsColumnsMobile : OpenPositionsColumns;
    case 'certifications':
      return isMobile ? CertificationColumnsMobile : CertificationColumns;
    case 'trainings':
      return isMobile ? TrainingsColumnsMobile : TrainingsColumns;
    case 'departments':
      return isMobile ? DepartmentsColumnsMobile : DepartmentsColumns;
    case 'projects':
      return isMobile ? ProjectsColumnsMobile : ProjectsColumns;
    case 'questionnaires':
      return isMobile ? QuestionnairesColumnsMobile : QuestionnairesColumns;
    case 'ideabox':
      return isMobile ? IdeaboxColumnsMobile : IdeaboxColumns;
    case 'work-positions':
      return isMobile ? WorkPositionsColumnsMobile : WorkPositionsColumns;
    case 'assets':
      return isMobile ? AssetColumnsMobile : AssetsColumns;
    case 'vendors':
      return isMobile ? VendorsColumnsMobile : VendorsColumns;
    case 'evaluations':
      return EvaluationPeriodsConfig;
    case 'onboardings':
      return isMobile ? OnboardingColumnsMobile : OnboardingColumns;
    case 'tenants':
      return TenantColumns;
    case 'ip-whitelisting':
      return IpWhitelistingColumns;
    case 'tracking':
      return TrackingHoursColumns;
    case 'employee-details':
      return TrackingHoursColumns;
    case 'public-super-admins':
      return PublicSuperAdminColumns;
    case 'payroll-rules':
      return isMobile ? PayrollRuleColumnsMobile : PayrollRuleColumns;
    case 'goals':
      return isMobile ? GoalColumnsMobile : GoalColumns;
    case 'payroll-report':
      return isMobile ? PayrollDateColumnsMobile : PayrollDateColumns;
    case 'skillset-matching':
      return SkillsetColumns;
    case 'benefits':
      return isMobile ? BenefitsColumsMobile : BenefitsColums;
    case 'asset-categories':
      return isMobile ? AssetCategoriesColumnsMobile : AssetCategoriesColumns;
    case 'purchase-requests':
      return isMobile ? PurchaseRequestColumnsMobile : PurchaseRequestColumns;
    case 'vendor-categories':
      return isMobile ? VendorCategoriesColumnsMobile : VendorCategoriesColumns;
    case 'purchase-orders':
      return isMobile ? PurchaseOrderColumnsMobile : PurchaseOrdersColumns;
    case 'invoicing':
      return isMobile ? InvoicingColumnsMobile : InvoicingColumns;
    case 'feature-flags':
      return FeatureColumns;
    case 'expenses':
      return isMobile ? ExpenseReportColumnsMobile : ExpenseReportColumns;
    case 'checkin':
      return CheckinColumns;
    default:
      return [];
  }
};

export const routesWithFilters = [
  'candidates',
  'interviews',
  'onboardings',
  'open-positions',
  'employees',
  'tenants',
  'tenant-accounts',
  'tracking',
  'evaluations',
  'work-positions',
  'trainings',
  'certifications',
  'users',
  'projects',
  'vendors',
  'assets',
  'skills',
  'payroll-rules',
  'payroll-report',
  'invoicing',
  'expenses',
  'checkin',
];

export interface TblColumnType extends ColumnsType {
  default: boolean;
  order: number;
}
