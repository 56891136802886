import React from 'react';
import { Col, Row, Spin } from 'antd';
import { Item } from 'ui-v2/components/PersonalInfo';
import { StatusTag } from 'ui-v2/components/DataGridComponents';
import CardItem from 'ui-v2/components/Card';
import * as Styled from 'ui-v2/components/PersonalInfo/PersonalInfoStyles';
import { useTranslation } from 'react-i18next';
import useIdeaboxData from 'ui-v2/hooks/useIdeaboxData';
import { EmployeeType } from 'types/Employee';
import { EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';

interface IProps {
  loading: boolean;
}

export default function IdeaboxInformation({ loading }: IProps) {
  const { singleIdeabox } = useIdeaboxData();

  const { t } = useTranslation();

  if (!singleIdeabox?.id) return null;

  return (
    <CardItem title={t('ideaboxInformation')}>
      <Spin spinning={loading}>
        <Row gutter={[0, 16]}>
          <Styled.RowItem gutter={[0, 10]}>
            <Col md={{ span: 12 }} sm={{ span: 24 }}>
              <Item label={t('ideaText')} value={singleIdeabox?.ideaText} />
            </Col>
            <Col md={{ span: 12 }} sm={{ span: 24 }}>
              <Row>
                <Col span={24}>
                  <Styled.ItemLabel>{t('visibility')}</Styled.ItemLabel>
                </Col>
                <StatusTag
                  status={singleIdeabox?.private ? t('private') : t('public')}
                >
                  <Row align="middle" gutter={[8, 0]}>
                    <Col>
                      {singleIdeabox?.private ? (
                        <EyeInvisibleOutlined />
                      ) : (
                        <EyeOutlined />
                      )}
                    </Col>
                    <Col>
                      {singleIdeabox?.private ? t('private') : t('public')}
                    </Col>
                  </Row>
                </StatusTag>
              </Row>
            </Col>
          </Styled.RowItem>
          <Styled.RowItem gutter={[0, 10]}>
            <Col md={{ span: 12 }} sm={{ span: 24 }}>
              <Item
                label={t('tags')}
                value={
                  singleIdeabox?.tags
                    ?.map(
                      (emp: EmployeeType) =>
                        `${emp?.firstName} ${emp?.lastName}`
                    )
                    .join(', ') || 'noTags'
                }
              />
            </Col>
            <Col md={{ span: 12 }} sm={{ span: 24 }}>
              <Item
                label={t('creator')}
                value={
                  `${singleIdeabox?.employee?.firstName} ${singleIdeabox?.employee?.lastName}` ||
                  ''
                }
              />
            </Col>
          </Styled.RowItem>
        </Row>
      </Spin>
    </CardItem>
  );
}
