import agent from 'api/agent';
import { UpdateFeatureFlagsTenantDTO } from 'types/FeatureFlagsTenant';
import { FeatureFlagType } from 'types/FeatureFlag';
import { apiURLs } from './constants';

export const getFeatureFlags = () => agent.post(`${apiURLs.featureFlags}/get`);

export const createFeatureFlags = (payload: FeatureFlagType) =>
  agent.post(apiURLs.featureFlags, payload);

export const deleteFeatureFlags = (tenantId: string, featureId: string) =>
  agent.delete(`${apiURLs.featureFlagsTenant}/${tenantId}/${featureId}`);

export const updateFeatureFlagsTenants = (
  payload: UpdateFeatureFlagsTenantDTO
) => agent.patch(`${apiURLs.featureFlagsTenant}`, payload);
