import { Col, Popconfirm, Row, Tooltip } from 'antd';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { Id } from 'types/Id';
import { ViewProfileIcon } from 'Icons/ViewProfileIcon';
import { ArchieveTableIcon } from 'Icons/ArchieveTableIcon';
import {
  getCandidatesResumeFiles,
  removeCandidate,
} from 'api/candidateService';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { fetchCandidates } from 'redux/candidates/actions';
import { IResume } from 'types/IResume';
import { useTranslation } from 'react-i18next';
import { FileResume } from 'Icons/FileResume';
import { useCandidateData } from 'ui-v2/hooks/useCandidateData';

const IconButton = styled.span`
  &:hover {
    cursor: pointer;
  }
`;

const CandidateActions: React.FC<Id> = ({ id }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [, setResume] = useState<IResume>();
  const { t } = useTranslation();
  const { pagination } = useCandidateData();

  const onViewClick = () => {
    navigate(`profile?id=${id}`);
  };

  const onArchieveClick = async () => {
    const response = await removeCandidate(id);

    if (response.status === 200) {
      toast.success(t('archivedCandidate'));
      dispatch(fetchCandidates({ page: pagination?.page || 1, take: 10 }));
      return;
    }

    toast.warning(t('Could not archieve this candidate!'));
  };

  const onViewResumeClick = async () => {
    try {
      const response = await getCandidatesResumeFiles({ candidateIds: [id] });

      if (response.status === 200 && response.data.length > 0) {
        setResume(response.data[0]);
        if (response.data[0].content !== null) {
          window.open(response.data[0].content);
        } else {
          toast.warning(t('No resume available for this candidate.'));
        }
      }
    } catch (err) {
      toast.error(t("Couldn't retrieve the candidate's resume."));
    }
  };

  return (
    <Row>
      <Col span={24}>
        <Row justify="space-around">
          <Col>
            <Tooltip title={t('candidateDetails')}>
              <IconButton onClick={onViewClick}>
                <ViewProfileIcon />
              </IconButton>
            </Tooltip>
          </Col>
          <Col>
            <Tooltip title={t('viewResume')}>
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  onViewResumeClick();
                }}
              >
                <FileResume />
              </IconButton>
            </Tooltip>
          </Col>
          <Col style={{ marginTop: '2px' }}>
            <Popconfirm
              title={t('Do you want to archive this candidate?')}
              onConfirm={onArchieveClick}
              okText={t('yes')}
              cancelText={t('no')}
              placement="left"
            >
              <Tooltip title={t('archiveCandidate')}>
                <IconButton>
                  <ArchieveTableIcon />
                </IconButton>
              </Tooltip>
            </Popconfirm>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default CandidateActions;
