import React from 'react';
import { Moment } from 'moment';
import { Avatar } from 'antd';

import { ITimeOffRequestDTO } from 'types/TimeOff';
import { EmployeeType } from 'types/Employee';
import { Center, StyledAvatar } from './CellRenderStyles';

interface IProps {
  cellDate: Moment;
  requests: ITimeOffRequestDTO;
  employees: EmployeeType[];
  onSelect: (requests: ITimeOffRequestDTO) => void;
  isHoliday: boolean;
  holidayName: string | undefined;
}

const CellRender = ({
  cellDate,
  requests,
  employees,
  onSelect,
  isHoliday,
  holidayName,
}: IProps) => {
  const employee = employees.find((employee) =>
    requests?.timeOffs?.some((request) => request?.employeeId === employee?.id)
  );

  return (
    <Center
      key={cellDate.toString()}
      onClick={() => onSelect(requests)}
      style={isHoliday ? { backgroundColor: '#add8e6' } : {}}
    >
      {employee && (
        <StyledAvatar key={employee?.id} size={50}>
          <Avatar key={employee?.id}>
            {`${employee?.firstName?.charAt(0)} ${employee?.lastName?.charAt(
              0
            )}`}
          </Avatar>
        </StyledAvatar>
      )}

      {isHoliday && <div>{holidayName}</div>}
    </Center>
  );
};

export default React.memo(CellRender);
