import agent from 'api/agent';
import {
  CreateEmployeeRequest,
  EmployeeRequestByEntityName,
  RespondToRequest,
  updateRequest,
} from 'types/Request';
import { apiURLs } from './constants';

export const getAllRequests = () => agent.get(`${apiURLs.request}`);

export const updateRequestStatus = (payload: updateRequest) =>
  agent.patch(`${apiURLs.request}`, payload);

export const createEmployeeRequest = (payload: CreateEmployeeRequest) =>
  agent.post(`${apiURLs.employeeRequest}`, payload);

export const getEmployeeRequestsByEntityName = (
  payload: EmployeeRequestByEntityName
) =>
  agent.post(`${apiURLs.employeeRequest}/${payload.employeeId}`, {
    entityName: payload.entityName,
  });

export const respondToRequest = (payload: RespondToRequest) =>
  agent.patch(`${apiURLs.employeeRequest}/${payload.requestId}`, {
    approved: payload.approved,
  });
