import React from 'react';
import * as Styled from 'ui-v2/components/DataGridComponents';
import i18next from 'i18next';
import moment from 'moment';

import { VendorQuestionnaireCategory } from 'types/VendorQuestionnaire';
import VendorQuestionnaireActions from 'components/Actions/VendorQuestionnaireActions';

export const VendorQuestionnaireColumnsMobile = [
  {
    dataIndex: 'vendorQuestionnaires',
    key: 'vendorQuestionnaires',
    render: (_: string, item: VendorQuestionnaireCategory) => (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '1rem',
        }}
      >
        <div>
          <strong>{`${i18next.t('name')}: `}</strong>
          {item?.name}
        </div>

        <div>
          <strong>{`${i18next.t('numberOfQuestionnaires')}: `}</strong>
          {item?.vendorQuestionnaireCount || 0}
        </div>

        <div>
          <VendorQuestionnaireActions id={item.id} />
        </div>
      </div>
    ),
    default: true,
    order: 0,
    width: 200,
  },
];

export const VendorQuestionnaireColumns = [
  {
    title: i18next.t('name'),
    dataIndex: 'name',
    key: 'name',
    ellipsis: true,
    sorter: (a: VendorQuestionnaireCategory, b: VendorQuestionnaireCategory) =>
      moment(a?.createdAt).diff(moment(b?.createdAt)),
    render: (_: string, item: VendorQuestionnaireCategory) => (
      <Styled.TextBold>{item?.name}</Styled.TextBold>
    ),
    default: true,
    order: 0,
  },
  {
    title: i18next.t('numberOfQuestionnaires'),
    dataIndex: 'vendorQuestionnaires',
    key: 'vendorQuestionnaires',
    ellipsis: true,
    sorter: (a: VendorQuestionnaireCategory, b: VendorQuestionnaireCategory) =>
      (a?.vendorQuestionnaireCount || 0) - (b?.vendorQuestionnaireCount || 0),
    render: (_: string, item: VendorQuestionnaireCategory) => (
      <Styled.TextBold>{item?.vendorQuestionnaireCount || 0}</Styled.TextBold>
    ),
    order: 1,
  },
  {
    title: i18next.t('action'),
    dataIndex: 'action',
    key: 'action',
    width: 120,
    render: (_: string, row: VendorQuestionnaireCategory) => (
      <VendorQuestionnaireActions id={row.id} />
    ),
    default: false,
    order: 3,
  },
];
