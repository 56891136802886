import { PurchaseRequestDataType } from 'types/PurchaseRequest';
import moment from 'moment';
import PurchaseRequestActions from 'components/Actions/PurchaseRequestActions';
import React from 'react';
import i18next from '../i18n';

export const PurchaseRequestColumnsMobile = [
  {
    dataIndex: 'purchaseRequest',
    key: 'purchaseRequest',
    render: (_: string, item: PurchaseRequestDataType) => (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '1rem',
        }}
      >
        <div>
          <strong>{`${i18next.t('Date')}: `}</strong>
          {moment(item.date).format('MMMM DD, YYYY')}
        </div>

        <div>
          <strong>{`${i18next.t('time')}: `}</strong>
          {moment(item.time).format('HH:mm')}
        </div>

        <div>
          <strong>{`${i18next.t('Estimated Cost')}: `}</strong>
          {item.estimatedCost}
        </div>

        <div>
          <strong>{`${i18next.t('Identifier')}: `}</strong>
          {item.indentifier}
        </div>

        <div>
          <strong>{`${i18next.t('Item Description')}: `}</strong>
          {item.itemDescription}
        </div>

        <div>
          <strong>{`${i18next.t('Requesting Person')}: `}</strong>
          {item.requestingPerson}
        </div>

        <div>
          <PurchaseRequestActions id={item.id} />
        </div>
      </div>
    ),
    default: true,
    order: 0,
    width: 200,
  },
];

export const PurchaseRequestColumns = [
  {
    title: i18next.t('Date'),
    dataIndex: 'date',
    key: 'date',
    sorter: (a: PurchaseRequestDataType, b: PurchaseRequestDataType) =>
      moment(a.date).valueOf() - moment(b.date).valueOf(),
    render: (date: string) => moment(date).format('MMMM DD, YYYY'),
    default: true,
    order: 0,
  },
  {
    title: i18next.t('Estimated Cost'),
    dataIndex: 'estimatedCost',
    key: 'estimatedCost',
    sorter: (a: PurchaseRequestDataType, b: PurchaseRequestDataType) =>
      parseFloat(a.estimatedCost) - parseFloat(b.estimatedCost),
    default: true,
    order: 1,
  },
  {
    title: i18next.t('Identifier'),
    dataIndex: 'indentifier',
    key: 'indentifier',
    default: true,
    order: 2,
  },
  {
    title: i18next.t('Item Description'),
    dataIndex: 'itemDescription',
    key: 'itemDescription',
    default: true,
    order: 3,
  },
  {
    title: i18next.t('Requesting Person'),
    dataIndex: 'requestingPerson',
    key: 'requestingPerson',
    default: true,
    order: 4,
  },
  {
    title: i18next.t('time'),
    dataIndex: 'time',
    key: 'time',
    sorter: (a: PurchaseRequestDataType, b: PurchaseRequestDataType) =>
      moment(a.time, 'HH:mm:ss').valueOf() -
      moment(b.time, 'HH:mm:ss').valueOf(),
    render: (time: string) => moment(time).format('HH:mm'),
    default: true,
    order: 5,
  },
  {
    title: i18next.t('action'),
    dataIndex: 'action',
    key: 'action',
    width: 120,
    render: (_: string, row: PurchaseRequestDataType) => (
      <PurchaseRequestActions id={row.id} />
    ),
    default: false,
    order: 6,
  },
];
