import React from 'react';
import { Row, Col } from 'antd';
import CardItem from 'ui-v2/components/Card';
import { t } from 'i18next';
import styled from 'styled-components';

enum AccountStatus {
  Activated = 'activated',
  NonActivated = 'nonactivated',
}
interface EmployeeAccountStatusProps {
  status: AccountStatus;
}

const EmployeeStatus = styled.div<{
  status: string;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 800;
  height: 40px;
  font-size: 12px;
  border-radius: 6px;
  width: 100%;

  ${({ status }) =>
    status === AccountStatus.Activated &&
    'background-color:#cff7e0; color:#027A48'};
  ${({ status }) =>
    status === AccountStatus.NonActivated &&
    'background-color:#D8D8D8; color:#7A7A7A'};

  p {
    margin-bottom: 0;
    margin-left: 1px;
  }
`;

export default function EmployeeAccountStatus({
  status,
}: EmployeeAccountStatusProps) {
  return (
    <CardItem title={t('accountStatus')}>
      <Row>
        <Col span={24}>
          {status ? (
            <EmployeeStatus status="activated">
              <p>{t('activated')}</p>
            </EmployeeStatus>
          ) : (
            <EmployeeStatus status="nonactivated">
              <p>{t('nonactivated')}</p>
            </EmployeeStatus>
          )}
        </Col>
      </Row>
    </CardItem>
  );
}
