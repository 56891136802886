/* eslint-disable react/no-children-prop */
import React from 'react';
import { Col, Row } from 'antd';

import { CheckIcon } from 'Icons/CheckIcon';
import { CloseIcon } from 'Icons/CloseIcon';
import { Item } from 'ui-v2/components/PersonalInfo';
import { useProjectsData } from 'ui-v2/hooks/useProjectsData';
import { StatusTag } from 'ui-v2/components/DataGridComponents';
import { convertUTCtoFormatedLocalTime } from 'utils/utilFunctions';

import CardItem from 'ui-v2/components/Card';
import * as Styled from 'ui-v2/components/PersonalInfo/PersonalInfoStyles';
import { useTranslation } from 'react-i18next';

export default function ProjectInformation() {
  const {
    project: { data },
  } = useProjectsData();

  const { t } = useTranslation();

  if (!data?.id) return null;

  return (
    <CardItem title={t('projectInformation')}>
      <Row gutter={[0, 16]}>
        <Styled.RowItem gutter={[0, 10]}>
          <Col md={{ span: 12 }} sm={{ span: 24 }}>
            <Item label={t('name')} value={data?.name} children={undefined} />
          </Col>
          <Col md={{ span: 12 }} sm={{ span: 24 }}>
            <Row>
              <Col span={24}>
                <Styled.ItemLabel>{t('deadline')}</Styled.ItemLabel>
              </Col>
              <StatusTag status={!data?.endDate ? 'rejected' : 'verify'}>
                {!data?.endDate ? <CloseIcon /> : <CheckIcon />}
                <p>{!data?.endDate ? t('no') : t('yes')}</p>
              </StatusTag>
            </Row>
          </Col>
        </Styled.RowItem>
        <Styled.RowItem gutter={[0, 10]}>
          <Col md={{ span: 12 }} sm={{ span: 24 }}>
            <Item
              label={t('startDate')}
              value={
                data?.startDate &&
                convertUTCtoFormatedLocalTime(data?.startDate)
              }
              children={undefined}
            />
          </Col>
          {!data?.noEndPeriod && (
            <Col md={{ span: 12 }} sm={{ span: 24 }}>
              <Item
                label={t('endDate')}
                value={
                  data?.endDate
                    ? convertUTCtoFormatedLocalTime(data?.endDate)
                    : '⎯'
                }
                children={undefined}
              />
            </Col>
          )}
        </Styled.RowItem>
        <Styled.RowItem gutter={[0, 10]} last>
          <Col md={{ span: 12 }} sm={{ span: 24 }}>
            <Item
              label={t('description')}
              value={data?.description ?? ''}
              children={undefined}
            />
          </Col>
        </Styled.RowItem>
      </Row>
    </CardItem>
  );
}
