/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-one-expression-per-line */
import { PlusCircleOutlined } from '@ant-design/icons';
import { Button, Col, Row, Spin, Typography, Skeleton } from 'antd';
import { useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import CardItem from 'ui-v2/components/Card';
import { BUTTONS } from 'ui-v2/components/HeaderActions/config';
import RoleBasedButton from 'ui-v2/components/RoleBasedButton';
import {
  editBenefitSuccessMessage,
  toastErrorMessages,
} from 'utils/utilFunctions';
import {
  rejectBenefitRequest,
  findRequestBenefitByEmployeeId,
  getEmployeeBenefit,
  upgradeEmployeeBenefitPlan,
} from 'api/benefitTemplates';
import { AuthUserState } from 'redux/authUser/types';
import { RootState } from 'redux/store';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { EdititingIcon } from 'Icons/EditIcon';
import { toast } from 'react-toastify';
import { AUTH_ROLES } from 'types/Auth';
import BenefitModal from './BenefitModal';
import * as Styled from './BenefitsStyles';
import * as Styled2 from '../../WorkExperience/WorkExperienceStyles';
import { IconButton } from '../Renumaration/RenumarationStyles';

interface IProps {
  employeeId: string;
  visibility: boolean;
}

export default function EmployeeBenefits({ employeeId, visibility }: IProps) {
  const [loading, setLoading] = useState(true);
  const [modalVisible, setModalVisible] = useState(false);
  const [employeeBenefits, setEmployeeBenefits] = useState<any>();
  const [employeeRequestBenefits, setEmployeeRequestBenefits] = useState<any>();
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedBenefit, setSelectedBenefit] = useState<any>({});

  const authUserState = useSelector((state: any) => state.authUser);

  const { authUserRole }: AuthUserState = useSelector(
    (state: RootState) => state.authUser
  );
  const { t } = useTranslation();

  const getEmployeeBenefits = async (employeeId: string) => {
    try {
      const response: any = await getEmployeeBenefit(employeeId);
      setEmployeeBenefits(response.data);
      setLoading(false);
    } catch (error) {
      toastErrorMessages(error);
    }
  };

  const getEmployeeRequestBenefit = async (employeeId: string) => {
    try {
      if (authUserRole === AUTH_ROLES.AMDIN || authUserRole === AUTH_ROLES.HR) {
        const response: any = await findRequestBenefitByEmployeeId(employeeId);
        setEmployeeRequestBenefits(response?.data);
      }
    } catch (error) {
      toastErrorMessages(error);
    }
  };

  const onApprove = async (item: any) => {
    const payload: {
      specifications: string;
      plan: string;
      fromEmployee: string;
      benefitPackageId: string;
      planId: string;
    } = {
      specifications:
        item?.benefitTemplatePlan?.benefitTemplate?.specifications,
      plan: item?.benefitTemplatePlan?.name,
      fromEmployee: employeeId,
      benefitPackageId: item?.benefit?.id,
      planId: item?.benefitTemplatePlan?.id,
    };

    const response = await upgradeEmployeeBenefitPlan(payload);
    if (response.status === 200) {
      editBenefitSuccessMessage(selectedBenefit?.benefits_id, authUserState);
      searchParams.append('added-benefit', 'true');
      setSearchParams(searchParams);
    }
  };

  const onRejectRequest = async (id: string) => {
    try {
      const response = await rejectBenefitRequest(id);
      if (response?.status === 200) {
        toast.success('requestBenefitWasRejectedSuccessfully');
        searchParams.append('rejected-benefit', 'true');
        setSearchParams(searchParams);
      }
    } catch (err) {
      toastErrorMessages(err);
    }
  };

  useEffect(() => {
    getEmployeeRequestBenefit(employeeId);
  }, []);

  useEffect(() => {
    if (searchParams.get('added-benefit') === 'true' || employeeId) {
      getEmployeeBenefits(employeeId);
      getEmployeeRequestBenefit(employeeId);
      searchParams.delete('added-benefit');
      setSearchParams(searchParams);
    }
    if (searchParams.get('rejected-benefit') === 'true' || employeeId) {
      getEmployeeRequestBenefit(employeeId);
      searchParams.delete('rejected-benefit');
      setSearchParams(searchParams);
    }
  }, [searchParams]);

  const onShowModal = () => {
    setModalVisible(true);
    setSelectedBenefit(null);
  };

  const onCloseModal = () => {
    setModalVisible(false);
  };

  const onEditBenefit = (benefit: any) => {
    setSelectedBenefit(benefit);
    setModalVisible(true);
  };

  return (
    <Row gutter={[16, 16]}>
      <>
        {loading ? (
          <Skeleton loading={loading} paragraph={{ rows: 1 }} active />
        ) : (
          !employeeBenefits?.length &&
          !visibility && (
            <CardItem title={t('employeeBenefits')}>
              <Styled2.RowItem justify="center" last>
                <Col>
                  <Styled2.NodataLabel>
                    {authUserRole === 'employee'
                      ? t('There are no benefits assigned to you')
                      : ' There are no benefits related to this employee'}
                  </Styled2.NodataLabel>
                </Col>
              </Styled2.RowItem>
            </CardItem>
          )
        )}
      </>
      {visibility && (
        <Col xs={24} sm={12} md={8}>
          <CardItem minHeight={100}>
            <RoleBasedButton btn={BUTTONS.ADD_BUTTON}>
              <Row justify="center" gutter={[16, 24]}>
                <Col>
                  <Styled.StyleText>
                    {t('You can add a benefit here')}
                  </Styled.StyleText>
                </Col>
                <Col span={24}>
                  <Button
                    icon={<PlusCircleOutlined />}
                    size="large"
                    style={{
                      borderRadius: '8px',
                      width: '100%',
                      fontWeight: '600',
                      fontSize: '16px',
                      color: '344054',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                    onClick={onShowModal}
                  >
                    {t('addBenefit')}
                  </Button>
                </Col>
              </Row>
            </RoleBasedButton>
          </CardItem>
        </Col>
      )}
      {employeeRequestBenefits?.map((item: any) => (
        <Col xs={24} sm={12} md={8}>
          <CardItem
            minHeight={70}
            title={
              <Row style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Styled.Status status={item?.status}>
                  {item?.status === 'PENDING'
                    ? t('PENDING')
                    : item?.status === 'REJECTED'
                    ? t('rejected')
                    : t('pending')}
                </Styled.Status>
                <Col span={35}>
                  <Styled.TypeTag>
                    {`${item?.benefitTemplatePlan?.benefitTemplate?.title?.toUpperCase()} REQUEST`}
                  </Styled.TypeTag>
                </Col>
              </Row>
            }
          >
            <>
              <Spin spinning={loading}>
                <Row gutter={[0, 20]}>
                  <Col span={24}>
                    <Typography.Text style={{ fontSize: '15px' }}>
                      <Row gutter={[16, 0]}>
                        <Col span={11} style={{ fontWeight: 'lighter' }}>
                          Plan Name:
                        </Col>
                        <Styled.PlanTag>
                          <Col span={13}>
                            {item?.benefitTemplatePlan?.plan_name?.toUpperCase()}
                          </Col>
                        </Styled.PlanTag>
                      </Row>
                      <Row gutter={[16, 0]}>
                        <Col span={11} style={{ fontWeight: 'lighter' }}>
                          Plan Value:
                        </Col>
                        <Col
                          span={13}
                          style={{ color: '#1890ff', fontSize: '14px' }}
                        >
                          {item?.benefitTemplatePlan?.plan_value}
                        </Col>
                      </Row>
                    </Typography.Text>
                  </Col>
                </Row>
                <Row
                  gutter={[48, 20]}
                  style={{
                    marginTop: '1rem',
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <Col>
                    <Styled.StyledButton
                      danger
                      onClick={() => onRejectRequest(item?.id)}
                    >
                      {t('reject')}
                    </Styled.StyledButton>
                  </Col>
                  <Col>
                    <Styled.StyledButton
                      type="link"
                      htmlType="submit"
                      onClick={() => onApprove(item)}
                    >
                      {t('Approve')}
                    </Styled.StyledButton>
                  </Col>
                </Row>
              </Spin>
            </>
          </CardItem>
        </Col>
      ))}
      {employeeBenefits?.map((item: any) => (
        <Col xxl={6} xl={8} md={12} sm={24} xs={24}>
          <CardItem
            minHeight={70}
            title={
              <Row>
                <Col xs={18} sm={20} md={20} lg={20} xl={20}>
                  <Styled.TypeTag>{item?.bt_type.toUpperCase()}</Styled.TypeTag>
                </Col>
                <IconButton
                  style={{ paddingLeft: '30px' }}
                  onClick={() => onEditBenefit(item)}
                >
                  <EdititingIcon />
                </IconButton>
              </Row>
            }
          >
            <>
              <Spin spinning={loading}>
                <Row gutter={[0, 20]}>
                  <Col span={24}>
                    <Typography.Text style={{ fontSize: '15px' }}>
                      <Row gutter={[16, 0]}>
                        <Col span={11} style={{ fontWeight: 'lighter' }}>
                          Plan Name:
                        </Col>
                        <Styled.PlanTag>
                          <Col span={13}>
                            {item?.btp_plan_name.toUpperCase()}
                          </Col>
                        </Styled.PlanTag>
                      </Row>
                      <Row gutter={[16, 0]}>
                        <Col span={11} style={{ fontWeight: 'lighter' }}>
                          Plan Value:
                        </Col>
                        <Col
                          span={13}
                          style={{ color: '#1890ff', fontSize: '14px' }}
                        >
                          {item?.btp_plan_value}
                        </Col>
                      </Row>
                    </Typography.Text>
                  </Col>
                </Row>
              </Spin>
            </>
          </CardItem>
        </Col>
      ))}
      {modalVisible && (
        <BenefitModal
          employeeId={employeeId}
          open={modalVisible}
          closeModal={onCloseModal}
          selectedBenefit={selectedBenefit}
        />
      )}
    </Row>
  );
}
