/* eslint-disable react/no-array-index-key */
import React from 'react';

import { UserDataType } from 'redux/authUser/types';
import { CreateUserDto } from 'redux/users/types';

import UsersActions from 'components/Actions/UsersActions';
import * as Styled from 'ui-v2/components/DataGridComponents';
import { Row, Tooltip } from 'antd';
import { UserEmployeeDataType } from 'types/User';
import i18next from '../i18n';

export const UsersColumnsMobile = [
  {
    dataIndex: 'users',
    key: 'users',
    render: (_: string, item: UserDataType) => (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '1rem',
        }}
      >
        <div>
          <strong>{`${i18next.t('firstName')}: `}</strong>
          {item?.firstName}
        </div>
        <div>
          <strong>{`${i18next.t('lastName')}: `}</strong>
          {item?.lastName}
        </div>
        <div>
          <strong>{`${i18next.t('email')}: `}</strong>
          {item?.email}
        </div>
        <div>
          <strong>{`${i18next.t('phoneNumber')}: `}</strong>
          {item?.phoneNumber}
        </div>
        <div>
          <Row>
            <strong>{`${i18next.t('role')}: `}</strong>
            <Styled.RoleTag
              roles={item?.roles[0]?.name || ''}
              style={{ textTransform: 'capitalize' }}
            >
              {item?.roles[0]?.name.split('_').join(' ')}
            </Styled.RoleTag>
          </Row>
        </div>
        <div>
          <UsersActions id={item.id} email={item.email} />
        </div>
      </div>
    ),
    default: true,
    order: 0,
    width: 200,
  },
];

export const UsersColumns = [
  {
    title: i18next.t('firstName'),
    dataIndex: 'firstName',
    key: 'firstName',
    sorter: (a: UserDataType, b: UserDataType) =>
      a.firstName.localeCompare(b.firstName),
    default: true,
    order: 2,
  },
  {
    title: i18next.t('lastName'),
    dataIndex: 'lastName',
    key: 'lastName',
    sorter: (a: UserDataType, b: UserDataType) =>
      a.lastName.localeCompare(b.lastName),
    default: true,
    order: 3,
  },
  {
    title: i18next.t('email'),
    dataIndex: 'email',
    key: 'email',
    ellipsis: true,
    sorter: (a: UserDataType, b: UserDataType) =>
      a.email.localeCompare(b.email),
    default: true,
    render: (email: string) => (
      <Tooltip title={email}>
        <span>{email}</span>
      </Tooltip>
    ),
    order: 4,
  },
  {
    title: i18next.t('phoneNumber'),
    dataIndex: 'phoneNumber',
    key: 'phoneNumber',
    render: (_record: string, row: UserEmployeeDataType) =>
      `${row?.phoneNumberPrefix || ''} ${row?.phoneNumber}`,
    default: false,
    order: 5,
  },
  {
    title: i18next.t('role'),
    dataIndex: 'roles',
    key: 'roles',
    ellipsis: true,
    render: (_: string, row: UserDataType) => (
      <Row justify="start">
        <Styled.RoleTag
          roles={row?.roles[0]?.name || ''}
          style={{ textTransform: 'capitalize' }}
        >
          {row?.roles[0]?.name.split('_').join(' ')}
        </Styled.RoleTag>
      </Row>
    ),
    default: false,
    order: 7,
  },
  {
    title: i18next.t('action'),
    dataIndex: 'action',
    key: 'action',
    width: 150,
    render: (record: string, row: CreateUserDto) => (
      <UsersActions id={row.id} email={row.email} />
    ),
    default: false,
    order: 8,
    fixed: 'right',
  },
];
