import { Menu } from 'antd';
import styled from 'styled-components';

export const HeaderContainer = styled.header`
  position: sticky;
  top: 0;
  z-index: 1000;
  background-color: #101828;
  color: white;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 55px;
`;

export const SiteName = styled.div`
  font-size: 1rem;
  font-weight: bold;
`;

export const UserSection = styled.div`
  display: flex;
  align-items: center;
  gap: 0.75rem;
`;

export const UserName = styled.span`
  font-size: 0.875rem;
`;

export const MenuButton = styled.button`
  background: none;
  border: none;
  font-size: 20px;
  padding: 0;
`;

export const Drawer = styled.div<{ isOpen: boolean }>`
  position: fixed;
  top: 55px;
  left: 0;
  right: 0;
  height: calc(100vh - 100px);
  background-color: #101828;
  transform: translateY(${(props) => (props.isOpen ? '0' : '-110%')});
  opacity: ${(props) => (props.isOpen ? '1' : '0')};
  transition: transform 0.3s cubic-bezier(0.25, 0.8, 0.25, 1), opacity 0.4s ease;
  z-index: 999;
  font-size: 0.875rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: auto;
`;

export const DrawerContent = styled.div`
  overflow-y: auto;
  flex-grow: 1;
`;

export const StyledDrawerItem = styled.div<{ active: boolean }>`
  display: flex;
  align-items: start;
  gap: 13px;
  padding: 1.5rem;
  background-color: ${(props) => (props.active ? '#1a1f2e' : 'transparent')};
  color: #ffff;
  text-decoration: none;
  border-bottom: 1px solid rgba(213, 212, 212, 0.1);

  &:last-child {
    border-bottom: none;
  }
`;

export const DrawerFooter = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  color: #ffff;
  text-decoration: none;
  border-top: 1px solid rgba(213, 212, 212, 0.1);
`;

export const StyledMenu = styled(Menu)`
  &&& {
    background-color: #101828;
    padding: 1.5rem;
    .ant-menu-submenu-title {
      color: #ffffff !important;
    }

    .ant-menu-item {
      background-color: #101828;
      color: #ffffff;
    }

    .ant-menu-sub {
      background-color: #101828 !important;
    }
  }
`;

export const IconWrapper = styled.span`
  stroke: white;
  transform: translateY(-10px);
  display: inline-block;

  svg {
    height: 24px;
    width: 24;
  }
`;
