import React from 'react';
import * as Styled from 'ui-v2/components/DataGridComponents';
import i18next from 'i18next';
import AssetCategoryAction from 'components/Actions/AssetCategoryAction';

export const AssetCategoriesColumnsMobile = [
  {
    dataIndex: 'assetCategories',
    key: 'assetCategories',
    render: (_: string, item: any) => (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '1rem',
        }}
      >
        <div>
          <strong>{`${i18next.t('categoryName')}: `}</strong>
          {item?.categoryName}
        </div>
        <div>
          <AssetCategoryAction id={item.id} name={item.categoryName} />
        </div>
      </div>
    ),
    default: true,
    order: 0,
    width: 200,
  },
];

export const AssetCategoriesColumns = [
  {
    title: i18next.t('categoryName'),
    dataIndex: 'categoryName',
    key: 'categoryName',
    ellipsis: true,
    sorter: (a: any, b: any) => a.categoryName.localeCompare(b.categoryName),
    render: (_: string, item: any) => (
      <Styled.TextBold>{item?.categoryName}</Styled.TextBold>
    ),
    default: true,
    order: 0,
  },
  {
    title: i18next.t('action'),
    dataIndex: 'action',
    key: 'action',
    width: 120,
    render: (_: string, row: any) => (
      <AssetCategoryAction id={row.id} name={row.categoryName} />
    ),
    default: false,
    order: 2,
  },
];
