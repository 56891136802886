import styled from 'styled-components';

export const CircularButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: #004eeb;
  color: #eff4ff;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    background-color: #e4ebfa;
    color: #004eeb;
  }
`;
