import React from 'react';
import { Handle, Position } from '@xyflow/react';
import { EmployeesSkim } from 'types/Employee';
import { t } from 'i18next';

export default function GroupNode({ data }: any) {
  return (
    <>
      <Handle
        type="target"
        position={Position.Top}
        style={{
          width: '10px',
          height: '10px',
          background: '#004EEB',
        }}
      />
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          backgroundColor: '#EFF4FF',
          border: '1px solid #004EEB',
          maxHeight: '250px',
          minWidth: '150px',
          padding: '10px',
          borderRadius: '7px',
        }}
      >
        <div
          style={{
            fontWeight: 'bold',
            marginBottom: '10px',
            textAlign: 'center',
          }}
        >
          {data.label}
        </div>

        <div
          className="nowheel"
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '5px',
            width: '100%',
            overflowY: 'auto',
            maxHeight: '180px',
          }}
        >
          {data.employees.length > 0 ? (
            data.employees.map((employee: EmployeesSkim) => (
              <div
                key={employee.id}
                style={{
                  padding: '5px 10px',
                  backgroundColor: '#FFFFFF',
                  border: '1px solid #D1D1D1',
                  borderRadius: '5px',
                  textAlign: 'center',
                }}
              >
                {`${employee.firstName} ${employee.lastName}`}
              </div>
            ))
          ) : (
            <div
              style={{
                padding: '5px 10px',
                color: '#004EEB',
                borderRadius: '5px',
                textAlign: 'center',
              }}
            >
              {t('noData')}
            </div>
          )}
        </div>
      </div>
      <Handle
        type="source"
        position={Position.Bottom}
        style={{
          width: '10px',
          height: '10px',
          background: 'blue',
        }}
      />
    </>
  );
}
