import { CheckOutlined, CloseOutlined, PlusOutlined } from '@ant-design/icons';
import { createNewHierarchy } from 'api/hierarchyService';
import { StyledButton } from 'components/NewForms/FormStyled';
import { t } from 'i18next';
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { fetchHierarchy } from 'redux/hierarchy/action';
import { searchParamsAppend } from 'ui-v2/components/HeaderActions/config';

interface IProps {
  onRestore: () => void;
  data: any;
}

export default function HierarchyButtons({ onRestore, data }: IProps) {
  const [, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();

  const handleSave = useCallback((val) => {
    createNewHierarchy(val)
      .then((res) => {
        if (res.status === 200) {
          dispatch(fetchHierarchy());
        }
        toast.success(t('hierarchySavedSuccessfully'));
      })
      .catch(() => {
        toast.error(t('hierarchyCanNotBeSaved'));
      });
  }, []);

  return (
    <div style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
      <StyledButton
        onClick={() =>
          setSearchParams((searchParams: any) =>
            searchParamsAppend({
              searchParams,
              key: 'new-node',
              value: 'true',
            })
          )
        }
        icon={<PlusOutlined />}
      >
        {t('newNode')}
      </StyledButton>
      <StyledButton onClick={onRestore} icon={<CloseOutlined />}>
        {t('restore')}
      </StyledButton>
      <StyledButton onClick={() => handleSave(data)} icon={<CheckOutlined />}>
        {t('save')}
      </StyledButton>
    </div>
  );
}
