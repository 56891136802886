import React, { useEffect } from 'react';
import Content from 'ui-v2/components/Content';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import DataGrid from 'components/common/DataGrid/DataGrid';
import useHeader from 'ui-v2/hooks/useHeader';
import { SkillsetColumns } from 'table-configuration/SkillsetMatchingConfig';
import { fetchSkillsetMatchingByOpenPositionId } from 'redux/skillsetMatching/action';
import { useSkillsetMatchingData } from 'ui-v2/hooks/useSkillsetMatchingData';
import { StyledButton } from 'components/NewForms/FormStyled';
import { FilterByIcon } from 'Icons/FilterByIcon';
import { t } from 'i18next';
import { Popover } from 'antd';
import SkillsetMatchingFilter from 'ui-v2/components/TableFilterPopover/Filters/SkillsetMatchingFilter';
import {
  PopoverOverlayInnerStyles,
  PopoverOverlayStyles,
} from '../../components/TableFilterPopover/TableFilterPopoverStyles';

export default function SkillsetMatching() {
  const { list, pagination, loading } = useSkillsetMatchingData();
  const [searchParams] = useSearchParams();
  const { take } = useHeader();
  const dispatch = useDispatch();

  useEffect(() => {
    const id: string = searchParams.get('id') as string;
    const yearsOfExperienceFilter = !!searchParams.get(
      'yearsOfExperienceFilter'
    );
    const params = {
      pageOptions: {
        page: 1,
        take,
        yearsOfExperienceFilter,
      },
    };
    dispatch(fetchSkillsetMatchingByOpenPositionId(id, params));
  }, [searchParams]);

  const onPageChange = (page: number) => {
    const id: string = searchParams.get('id') as string;
    const params = {
      pageOptions: {
        page,
        take,
      },
    };
    dispatch(fetchSkillsetMatchingByOpenPositionId(id, params));
  };

  return (
    <>
      <div style={{ position: 'relative', width: '100%', paddingTop: '40px' }}>
        <div
          style={{
            position: 'absolute',
            top: 0,
            right: 10,
          }}
        >
          <Popover
            overlayClassName="popover-content"
            overlayStyle={PopoverOverlayStyles}
            overlayInnerStyle={PopoverOverlayInnerStyles}
            content={<SkillsetMatchingFilter />}
            trigger="click"
          >
            <StyledButton id="filter-popover" icon={<FilterByIcon />}>
              {t('filterBy')}
            </StyledButton>
          </Popover>
        </div>
        <Content position="stretch">
          <DataGrid
            loading={loading}
            columns={SkillsetColumns}
            data={list}
            paginationName="Skillset"
            rowSelectionType="checkbox"
            totalItems={pagination.itemCount}
            currentPage={pagination.page}
            totalPages={pagination.pageCount}
            onPageChange={onPageChange}
            multipleSelect={false}
          />
        </Content>
      </div>
    </>
  );
}
