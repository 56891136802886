import {
  ChangePassword,
  EmployeeAssetsDTO,
  EmployeeResumeType,
  EmployeeResumesDTO,
  EmployeeTrainingsDTO,
  IEmployeeDocumentDTO,
  IUpdateEmployeeSkillsDTO,
  RemoveEmployeeAsset,
} from 'types/Employee';
import agent from 'api/agent';
import { searchFields } from 'ui-v2/table-search/utils-search-functions';
import { EmployeesQueryParamsType, QueryParamsDTO } from 'types/QueryParams';
import { apiURLs } from './constants';

export const getEmployeeDocuments = (id: string) =>
  agent.get(`${apiURLs.employees}/documents/${id}`);

export const getAllEmployees = (queryParams?: EmployeesQueryParamsType) =>
  agent.post(
    `${apiURLs.employees}/get`,
    searchFields(queryParams, apiURLs.employees)
  );

export const getAllEmployeesSkim = () => agent.get(`${apiURLs.employees}/skim`);

export const getEmployeeResumeFiles = (payload: EmployeeResumesDTO) =>
  agent.post(`${apiURLs.employees}/resumesPerPage`, payload);

export const getEmployeeLight = (id: string) =>
  agent.get(`${apiURLs.employees}/${id}`);

export const getEmployee = (id: string) =>
  agent.get(`${apiURLs.employees}/${id}`);

export const getEmployeeAssignments = (id: string) =>
  agent.get(`${apiURLs.employees}/assignments/${id}`);

export const searchEmployees = (searchText?: string | null) => {
  const params = {
    page: 1,
    ...(searchText && { filterText: searchText }),
  };
  return agent.post(
    `${apiURLs.employees}/get`,
    searchFields(params, apiURLs.employees)
  );
};
export const registerEmployeeFromScratch = (employeeData: any) =>
  agent.post(apiURLs.employees, employeeData);

export const registerEmployee = (employeeData: any) =>
  agent.post(
    `${apiURLs.candidates}/newEmployee/${employeeData.id}`,
    employeeData
  );

export const updateEmployee = (id: string, employeeData: any) =>
  agent.patch(`${apiURLs.employees}/${id}`, employeeData);

export const updateEmployeeSkills = (payload: IUpdateEmployeeSkillsDTO) =>
  agent.post(`${apiURLs.employees}/skills`, payload);

export const createEmployeeAssets = (employeeAssets: EmployeeAssetsDTO) =>
  agent.post(apiURLs.employeeAsset, employeeAssets);

export const updateEmployeeAssets = (
  id: string,
  employeeAssets: EmployeeAssetsDTO
) => agent.put(`${apiURLs.employeeAsset}/${id}`, employeeAssets);

export const removeEmployeeAssets = (payload: RemoveEmployeeAsset) =>
  agent.post(`${apiURLs.employeeAsset}/remove`, payload);

export const createEmployeeTrainings = (
  employeeTraining: EmployeeTrainingsDTO
) => agent.post(apiURLs.employeeTraining, employeeTraining);

export const updateEmployeeTrainings = (
  id: string,
  employeeTraining: EmployeeTrainingsDTO
) => agent.put(`${apiURLs.employeeTraining}/${id}`, employeeTraining);

export const getAllEmployeeTrainings = (id: string) =>
  agent.get(`${apiURLs.employeeTraining}/employee/${id}`);

export const deleteEmployeeTraining = (trainingId: string) =>
  agent.delete(`${apiURLs.employeeTraining}/${trainingId}`);

export const createEmployeeDocument = (employeeDoc: IEmployeeDocumentDTO) =>
  agent.post(apiURLs.employeeDocument, employeeDoc);

export const deleteEmployeeDocument = (id: string) =>
  agent.delete(`${apiURLs.employeeDocument}/${id}`);

export const deleteEmployeeNote = (id: string) =>
  agent.delete(`${apiURLs.employeeNote}/${id}`);

export const changeEmployeePassword = (payload: ChangePassword) => {
  agent.post(`${apiURLs.employees}/changePassword`, { payload });
};

export const updateEmployeeOnboardingStatus = (employeeId: string) =>
  agent.patch(`${apiURLs.employees}/completedOnBoarding/${employeeId}`);

export const getEmployeeWelcomeScreen = (employeeId: string) =>
  agent.get(`${apiURLs.users}/${employeeId}/welcome`);

export const getEmployeeOnboardingMission = (employeeId: string) =>
  agent.get(`${apiURLs.users}/${employeeId}/welcome`);

export const removeEmployee = (id: string) =>
  agent.delete(`${apiURLs.employees}/${id}`);

export const getEmployeeResume = (
  resume: string | undefined
): Promise<EmployeeResumeType> =>
  agent.get(`${apiURLs.employees}/resume?name=${resume}`);

export const getEmployeeJobContract = (employeeId: string) =>
  agent.get(`${apiURLs.onBoarding}/jobContract/${employeeId}`);

export const getEmployeeNDA = (employeeId: string) =>
  agent.get(`${apiURLs.onBoarding}/NDA/${employeeId}`);

export const signEmployeeJobContract = (employeeId: string) =>
  agent.get(`${apiURLs.onBoarding}/jobContract/${employeeId}/sign`);

export const signEmployeeNDA = (employeeId: string) =>
  agent.get(`${apiURLs.onBoarding}/NDA/${employeeId}/sign`);

export const bulkUploadEmployees = (payload: any) =>
  agent.post(`${apiURLs.employeesBulkUpload}`, payload);

export const getEmployeesBulkUploadDetails = (payload: any) =>
  agent.get(`${apiURLs.getEmployeesBulkUploadDetails}`, payload);

export const getEmployeeSelectOptions = (queryParams?: QueryParamsDTO) =>
  agent.post(`${apiURLs.employees}/selectOptions`, {
    pageOptionsDto: queryParams?.pageOptions,
    filterOptions: queryParams?.filterOptions,
  });

export const getEmployeeReviewQuestions = (employeeIds: string[]) =>
  agent.post(`${apiURLs.employees}/review-questions`, {
    employeeIds,
  });

export const getApprovers = (queryParams?: QueryParamsDTO) =>
  agent.post(`${apiURLs.timeOff}/selectOptions`, {
    pageOptionsDto: queryParams?.pageOptions,
    filterOptions: queryParams?.filterOptions,
  });

export const getEmployeeCount = () => agent.get(`${apiURLs.employees}/count`);
