import { useSelector } from 'react-redux';
import { RootState } from 'redux/store/reducers';
import { EmployeeRequest } from 'types/Request';

export function useEmployeeRequestData() {
  const { data, loading, loaded, errored, error } = useSelector(
    (state: RootState) => state.employeeRequestReducer
  );

  return {
    employeeRequests: data as EmployeeRequest[],
    loading,
    loaded,
    errored,
    error,
  };
}
