import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useSidebarItems } from 'ui-v2/hooks/useSidebarItems';
import { LogOutIcon } from 'Icons/LogOutIcon';
import { clearAuthState } from 'redux/authUser/actions';
import { useDispatch, useSelector } from 'react-redux';
import { AUTH_ROLES } from 'types/Auth';
import useTenantStore from 'redux/persisted-state/tenant-store';
import { AuthUserState } from 'redux/authUser/types';
import { RootState } from 'redux/store';
import { IconButton } from 'components/Actions/AssetAction';
import { TENANT_ID } from 'utils/constants';
import { useTenantFeatureData } from 'ui-v2/hooks/useTenantFeatureData';
import { FeatureFlagsTenantType } from 'types/FeatureFlagsTenant';
import { useTranslation } from 'react-i18next';
import { InputRef } from 'antd';
import DrawerItem from './DrawerItem';
import {
  Drawer,
  DrawerContent,
  DrawerFooter,
  StyledMenu,
} from './SideBarMobileStyles';
import { SidebarItemGroup } from '../AppSidebar/utils';
import SidebarSearch from '../AppSidebar/SidebarSearch';

interface DrawerProps {
  isDrawerOpen: boolean;
  email: string;
  toggleDrawer: () => void;
}

export default function MobileDrawer({
  isDrawerOpen,
  email,
  toggleDrawer,
}: DrawerProps) {
  const tenantId = localStorage.getItem(TENANT_ID);
  const [isFeatureAllowed, setIsFeatureAllowed] = useState<any>();
  const { data, getTenantFeatureList } = useTenantFeatureData();
  const [filteredItems, setFilteredItems] = useState<SidebarItemGroup[]>();
  const [filteredCopy, setFilteredCopy] = useState<SidebarItemGroup[]>([]);
  const { items, groupedItems, isActivePath, onSearch } = useSidebarItems();
  const dispatch = useDispatch();
  const { clearTenant } = useTenantStore();
  const { t } = useTranslation();
  const { authUserRole }: AuthUserState = useSelector(
    (state: RootState) => state.authUser
  );
  const inputRef = useRef<InputRef>(null);

  const onItemSearch = (searchedValue: string) => {
    onSearch({
      searchedValue,
      t,
      setFilteredItems,
      filteredCopy,
    });
  };

  useEffect(() => {
    if (tenantId) {
      getTenantFeatureList(tenantId);
    }
  }, []);

  useEffect(() => {
    if (data.length !== 0) {
      const featureMap = data?.reduce(
        (dict: any, item: FeatureFlagsTenantType) => {
          dict.set(item.featureFlag.featureName, item.status);
          return dict;
        },
        new Map()
      );
      setIsFeatureAllowed(featureMap);
      const filterForMap = groupedItems;
      filterForMap.forEach((item) => {
        const filteredEach = item.items.filter(
          (item) =>
            isFeatureAllowed.get(item.path.slice(1)) ||
            isFeatureAllowed.get(item.path.slice(1)) == null
        );
        item.items = filteredEach;
      });
      setFilteredItems(filterForMap);
      setFilteredCopy(filterForMap);
    } else {
      setFilteredItems(groupedItems);
      setFilteredCopy(groupedItems);
    }
  }, [data]);

  const onLogoutClicked = useCallback(() => {
    dispatch(clearAuthState(authUserRole === AUTH_ROLES.SUPER_ADMIN));
    clearTenant();
  }, [authUserRole]);
  return (
    <Drawer isOpen={isDrawerOpen}>
      <SidebarSearch onSearch={onItemSearch} elementRef={inputRef} />
      <DrawerContent>
        {items.map((item) => (
          <DrawerItem
            key={item.path}
            item={item}
            active={isActivePath({ items: [item] })}
            toggleDrawer={toggleDrawer}
          />
        ))}
        {filteredItems?.map((groupedItem) => (
          <StyledMenu key={groupedItem.title} mode="inline">
            <StyledMenu.SubMenu
              key={groupedItem.title}
              title={t(groupedItem.title)}
            >
              {groupedItem.items.map((item) => (
                <DrawerItem
                  key={item.path}
                  item={item}
                  active={isActivePath({ items: [item], grouped: true })}
                  toggleDrawer={toggleDrawer}
                />
              ))}
            </StyledMenu.SubMenu>
          </StyledMenu>
        ))}
        <DrawerFooter>
          <span>{email}</span>
          <IconButton onClick={onLogoutClicked}>
            <LogOutIcon />
          </IconButton>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
}
