import React from 'react';
import { Row, Col, Empty, Space, Tooltip } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useScreenSize } from 'ui-v2/hooks/useScreenSize';
import {
  StyledTrackingCard,
  StyledTrackingCardContent,
  StyledTrackingCardNoData,
  StyledTrackingCardPanel,
  StyledTrackingCardSkeleton,
} from './StyledTrackingCard';
import { formatTotalMinutes } from '../utils';
import { StyledTrackingButton, StyledTrackingText } from '../StyledTracking';
import TrackingExport from './TrackingExport';
import i18next from '../../../../i18n';

interface TrackingCardProps {
  children: React.ReactNode;
  loading?: boolean;
  isEmpty?: boolean;
  overallTitle?: string;
  perPageTitle?: string;
  overallTotal?: number;
  pageTotal?: number;
  canAddHour?: boolean;
  onAddHourClick?: () => void;
}

const TrackingCard: React.FC<TrackingCardProps> = ({
  children,
  loading,
  isEmpty = true,
  overallTitle,
  perPageTitle,
  overallTotal,
  pageTotal,
  canAddHour,
  onAddHourClick,
}) => {
  const { isMobile } = useScreenSize();
  return (
    <StyledTrackingCard>
      <StyledTrackingCardPanel span={24}>
        <Row gutter={[100, 0]}>
          <Col>
            <Row gutter={[10, 0]} align="middle">
              {!isMobile && overallTitle && (
                <Col>
                  <StyledTrackingText light>
                    {`${i18next.t('overallApprovedHours')}:`}
                  </StyledTrackingText>
                </Col>
              )}
              {!isMobile && (overallTotal || overallTotal === 0) && (
                <Col>
                  <StyledTrackingText bold>
                    {formatTotalMinutes(overallTotal)}
                  </StyledTrackingText>
                </Col>
              )}
            </Row>
          </Col>
          <Row gutter={[10, 0]} align="middle">
            {!isMobile && perPageTitle && (
              <Col>
                <StyledTrackingText light>{perPageTitle}</StyledTrackingText>
              </Col>
            )}
            {!isMobile && (pageTotal || pageTotal === 0) && (
              <Col>
                <StyledTrackingText bold>
                  {formatTotalMinutes(pageTotal)}
                </StyledTrackingText>
              </Col>
            )}
          </Row>
          <Col />
        </Row>
        <Row align="middle" gutter={[10, 0]}>
          <Col>
            <Tooltip title={i18next.t('exportDataToCSV')}>
              <div>
                <TrackingExport />
              </div>
            </Tooltip>
          </Col>
          {canAddHour && (
            <Col>
              <StyledTrackingButton onClick={onAddHourClick}>
                <Space align="center">
                  <PlusOutlined />
                  {i18next.t('logHour')}
                </Space>
              </StyledTrackingButton>
            </Col>
          )}
        </Row>
      </StyledTrackingCardPanel>
      {loading && (
        <Col span={24}>
          <StyledTrackingCardSkeleton shape="square" active />
        </Col>
      )}
      {!loading && isEmpty && (
        <Col span={24}>
          <StyledTrackingCardNoData>
            <Empty
              description={
                <StyledTrackingText light>
                  {`${i18next.t(
                    'thereAreNoHoursTrackedFromtheSelectedInterval'
                  )}!`}
                </StyledTrackingText>
              }
            />
          </StyledTrackingCardNoData>
        </Col>
      )}
      {!loading && !isEmpty && (
        <StyledTrackingCardContent>{children}</StyledTrackingCardContent>
      )}
    </StyledTrackingCard>
  );
};

export default TrackingCard;
