import React, { useEffect, useState } from 'react';

import { Button, Col } from 'antd';
import SelectComponent from 'components/NewForms/Select';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { getFeatureFlags } from 'api/featureFlagService';
import { createFeatureFlagsTenant } from 'api/featureFlagsTenant';
import { useDispatch } from 'react-redux';
import { fetchFeatureFlagsTenants } from 'redux/featureFlagsTenant/action';
import { useTenantFeatureData } from 'ui-v2/hooks/useTenantFeatureData';
import * as Styles from './TenantFeatureComponentStyles';

export default function TenantFeatureComponent() {
  const { data } = useTenantFeatureData();
  const [searchParams] = useSearchParams();
  const [features, setFeatures] = useState<any>();
  const [selectedFeature, setSelectedFeature] = useState<any>();
  const dispatch = useDispatch();

  useEffect(() => {
    getFeatureFlags().then((response) => {
      const filteredFeatures = response.data.data.filter(
        (feature: any) =>
          !data.some(
            (tenantFeature: any) => tenantFeature.featureFlag.id === feature.id
          )
      );
      setFeatures(filteredFeatures);
    });
  }, [data]);

  const onSelect = (value: string) => {
    setSelectedFeature(value);
  };

  const onSubmit = () => {
    createFeatureFlagsTenant({
      featureId: selectedFeature,
      tenantId: searchParams.get('id') || '',
      status: true,
    });
    dispatch(fetchFeatureFlagsTenants(searchParams.get('id') || ''));
  };

  const { t } = useTranslation();
  return (
    <Styles.Container gutter={[0, 10]}>
      <Styles.Container align="middle">
        <Col span={24}>
          <SelectComponent
            placeholder={t('records')}
            name="records"
            showSearch
            defaultValue="Features"
            onSelect={onSelect}
            selectOptions={
              features
                ? features.map((feature: any) => ({
                    label: feature.featureName,
                    value: feature.id,
                    id: feature.id,
                  }))
                : []
            }
          />
          <Button onClick={onSubmit}>Add</Button>
        </Col>
      </Styles.Container>
    </Styles.Container>
  );
}
