/* eslint-disable react/jsx-one-expression-per-line */

import React from 'react';
import { Popover } from 'antd';
import VendorsAction from 'components/Actions/VendorsAction';
import i18next from 'i18n';
import { VendorType } from 'types/Vendor';

export const VendorsColumnsMobile = [
  {
    dataIndex: 'vendor',
    key: 'vendor',
    render: (_: string, item: VendorType) => (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '1rem',
        }}
      >
        <div>
          <strong>{`${i18next.t('taxId')}: `}</strong>
          {item?.taxId}
        </div>

        <div>
          <strong>{`${i18next.t('companyName')}: `}</strong>
          {item?.companyName}
        </div>
        <div>
          <strong>{`${i18next.t('country')}: `}</strong>
          {item?.country}
        </div>
        <div>
          <strong>{`${i18next.t('city')}: `}</strong>
          {item?.city}
        </div>
        <div>
          <strong>{`${i18next.t('street')}: `}</strong>
          {item?.street}
        </div>
        <div>
          <strong>{`${i18next.t('zipCode')}: `}</strong>
          {item?.zipCode}
        </div>
        <div>
          <strong>{`${i18next.t('state')}: `}</strong>
          {item?.state}
        </div>
        <div>
          <strong>{`${i18next.t('contactPerson')}: `}</strong>
          {`${item?.contactPersonPrefix || ''} ${item?.contactPerson}`}
        </div>
        <div>
          <strong>{`${i18next.t('email')}: `}</strong>
          {item?.email}
        </div>
        <div>
          <strong>{`${i18next.t('phoneNumber')}: `}</strong>
          {`${item?.phoneNumberPrefix || ''} ${item?.phoneNumber}`}
        </div>
        <div>
          <VendorsAction id={item?.id} />
        </div>
      </div>
    ),
    default: true,
    order: 0,
    width: 200,
  },
];

export const VendorsColumns = [
  {
    title: i18next.t('taxId'),
    dataIndex: 'taxId',
    key: 'taxId',
    default: true,
    ellipsis: true,
    order: 1,
  },
  {
    title: i18next.t('companyName'),
    dataIndex: 'companyName',
    key: 'companyName',
    default: true,
    ellipsis: true,
    order: 2,
  },
  {
    title: i18next.t('country'),
    dataIndex: 'country',
    key: 'country',
    default: true,
    ellipsis: true,
    order: 3,
  },
  {
    title: i18next.t('city'),
    dataIndex: 'city',
    key: 'city',
    default: true,
    ellipsis: true,
    order: 4,
  },
  {
    title: i18next.t('street'),
    dataIndex: 'street',
    key: 'street',
    default: true,
    order: 5,
    ellipsis: true,
    render: (text: string) => {
      if (text.length > 15) {
        const abbreviatedAddress = text.slice(0, 15);
        return (
          <Popover content={text} placement="topLeft">
            {abbreviatedAddress}...
          </Popover>
        );
      }
      return (
        <Popover content={text} placement="topLeft">
          {text}
        </Popover>
      );
    },
  },
  {
    title: i18next.t('zipCode'),
    dataIndex: 'zipCode',
    key: 'zipCode',
    default: true,
    ellipsis: true,
    order: 6,
  },
  {
    title: i18next.t('state'),
    dataIndex: 'state',
    key: 'state',
    default: true,
    ellipsis: true,
    order: 7,
  },
  {
    title: i18next.t('contactPerson'),
    dataIndex: 'contactPerson',
    key: 'contactPerson',
    render: (_record: string, row: VendorType) =>
      `${row?.contactPersonPrefix || ''} ${row?.contactPerson}`,
    default: true,
    ellipsis: true,
    order: 8,
  },
  {
    title: i18next.t('email'),
    dataIndex: 'email',
    key: 'email',
    default: true,
    ellipsis: true,
    order: 9,
  },
  {
    title: i18next.t('phoneNumber'),
    dataIndex: 'phoneNumber',
    key: 'phoneNumber',
    render: (_record: string, row: VendorType) =>
      `${row?.phoneNumberPrefix || ''} ${row?.phoneNumber}`,
    default: true,
    ellipsis: true,
    order: 10,
  },
  {
    title: i18next.t('action'),
    dataIndex: 'action',
    key: 'action',
    width: 150,
    render: (_: string, row: VendorType) => <VendorsAction id={row?.id} />,
    default: false,
    order: 11,
    fixed: 'right',
  },
];
