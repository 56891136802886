/* eslint-disable react/no-children-prop */
import { Col, Row, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { getTraining, employeesAttendingTraining } from 'api/trainingService';
import CardItem from 'ui-v2/components/Card';
import Content from 'ui-v2/components/Content';
import { useTrainingsData } from 'ui-v2/hooks/useTrainingsData';
import { Item } from 'ui-v2/components/PersonalInfo';
import * as Styled from 'ui-v2/components/PersonalInfo/PersonalInfoStyles';
import { toast } from 'react-toastify';
import { EmployeeType } from 'types/Employee';
import { EmployeeTrainingItem } from 'ui-v2/components/Trainings/EmployeeTrainingItem';
import { useSearchParams } from 'react-router-dom';
import { TrainingType } from 'types/Training';
import NewTrainingForm from 'components/NewForms/NewTrainingForm';
import { useDispatch } from 'react-redux';
import { fetchTraining } from 'redux/trainings/actions';
import { useTranslation } from 'react-i18next';
import { useScreenSize } from 'ui-v2/hooks/useScreenSize';

export default function TrainingDetails() {
  const { trainingId, trainingLoading, training } = useTrainingsData();
  const [employeeTraining, setEmployeeTraining] = useState<EmployeeType[]>([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [modalVisible, setModalVisible] = useState(false);
  const [trainingSelected, setTrainingSelected] = useState<TrainingType>();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { isMobile } = useScreenSize();

  async function fetchEmployeeTraining(trainingId: string | null) {
    if (!trainingId) return;
    employeesAttendingTraining(trainingId)
      .then((response) => {
        if (response.status === 200) {
          setEmployeeTraining(response.data);
        }
      })
      .catch(() => {
        toast.error(
          t("Something went wrong! Can't get employees attending this training")
        );
      });
  }

  const fetchTrainingById = async (id: string | null) => {
    if (!id) return;
    try {
      const response = await getTraining(id);
      if (response.status === 200) {
        setTrainingSelected(response.data);
      }
    } catch (error) {
      toast.error(t('Training failed to fetch by Id'));
    }
  };

  useEffect(() => {
    if (trainingId) {
      dispatch(fetchTraining(trainingId));
      fetchEmployeeTraining(trainingId);
    }
  }, [searchParams, trainingId]);

  useEffect(() => {
    if (searchParams.get('edit-training') !== null) {
      fetchTrainingById(trainingId);
      setModalVisible(true);
      searchParams.delete('edit-training');
      setSearchParams(searchParams);
    }
  }, [searchParams]);

  return (
    <>
      <Content position={isMobile ? 'flexcol' : 'grid'}>
        <>
          <>
            <Spin spinning={trainingLoading}>
              <CardItem title={t('trainingInformation')}>
                {training && (
                  <Row gutter={[0, 16]}>
                    <Styled.RowItem gutter={[0, 10]}>
                      <Col md={{ span: 12 }} sm={{ span: 24 }}>
                        <Item
                          label={t('name')}
                          value={training?.name || ''}
                          children={undefined}
                        />
                      </Col>
                      <Col md={{ span: 12 }} sm={{ span: 24 }}>
                        <Row>
                          <Col span={24}>
                            <Styled.ItemLabel>
                              {t('trainingStatus')}
                            </Styled.ItemLabel>
                          </Col>
                          <Styled.TrainingStatusTag
                            status={training?.status || ''}
                          >
                            <p>{training?.status}</p>
                          </Styled.TrainingStatusTag>
                        </Row>
                      </Col>
                    </Styled.RowItem>
                    <Styled.RowItem gutter={[0, 10]}>
                      <Col md={{ span: 12 }} sm={{ span: 24 }}>
                        <Item
                          label={t('duration')}
                          value={
                            `${training?.valueTime} ${training?.timeType}` || ''
                          }
                          children={undefined}
                        />
                      </Col>
                      <Col md={{ span: 12 }} sm={{ span: 24 }}>
                        <Row>
                          <Item
                            label={t('skills')}
                            value={
                              training?.skills?.map((i: any) => i).join(', ') ||
                              ''
                            }
                            children={undefined}
                          />
                        </Row>
                      </Col>
                    </Styled.RowItem>
                    <Styled.RowItem gutter={[0, 10]}>
                      <Col md={{ span: 12 }} sm={{ span: 24 }}>
                        <Item
                          label={t('trainingCost')}
                          value={
                            training?.currecyTraining?.symbol === 'ALL'
                              ? `${training?.cost
                                  .toString()
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ',')} ${
                                  training?.currecyTraining?.symbol
                                }` || ''
                              : `${training?.cost} ${training?.currecyTraining?.symbol}` ||
                                ''
                          }
                          children={undefined}
                        />
                      </Col>
                      <Col md={{ span: 12 }} sm={{ span: 24 }}>
                        <Row>
                          <Item
                            label={t('type')}
                            value={training?.type || ''}
                            children={undefined}
                          />
                        </Row>
                      </Col>
                    </Styled.RowItem>
                    <Styled.RowItem gutter={[0, 10]} last>
                      <Col md={{ span: 12 }} sm={{ span: 24 }}>
                        <Row>
                          <Item
                            label={t('trainingCertificationName')}
                            value={training?.certificationName || ''}
                            children={undefined}
                          />
                        </Row>
                      </Col>
                      <Col md={{ span: 12 }} sm={{ span: 24 }}>
                        <Item
                          label={t('trainingCertificationCost')}
                          value={
                            `${training?.certificationCost} ${training?.currecyCertification?.symbol}` ||
                            ''
                          }
                          children={undefined}
                        />
                      </Col>
                    </Styled.RowItem>
                  </Row>
                )}
              </CardItem>
            </Spin>
          </>
          <>
            <CardItem title={t('employees')}>
              <Row gutter={[0, 24]}>
                <Styled.RowItem gutter={[0, 10]} last>
                  <Col
                    md={{ span: 24 }}
                    sm={{ span: 24 }}
                    xxl={{ span: 24 }}
                    xl={{ span: 24 }}
                  >
                    <>
                      {!employeeTraining?.length && (
                        <Row>
                          {t('thereAreNoEmployeesRelatedtoThisTraining')}
                        </Row>
                      )}
                    </>

                    <>
                      <Styled.RowItem last>
                        {employeeTraining?.map((item: EmployeeType) => (
                          <EmployeeTrainingItem employee={item} />
                        ))}
                      </Styled.RowItem>
                    </>
                  </Col>
                </Styled.RowItem>
              </Row>
            </CardItem>
          </>
        </>
      </Content>
      {modalVisible && (
        <NewTrainingForm
          open={modalVisible}
          closeModal={() => {
            setModalVisible(false);
            searchParams.delete('edit-training');
            setSearchParams(searchParams);
          }}
          selectedTraining={trainingSelected}
        />
      )}
    </>
  );
}
