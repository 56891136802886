import moment, { Moment } from 'moment';
import {
  CheckCircleOutlined,
  CloseOutlined,
  LineOutlined,
  Loading3QuartersOutlined,
} from '@ant-design/icons';

import {
  ITimeOffRequestDTO,
  TimeOffRecord,
  TimeOffRequest,
  TimeOffStatus,
} from 'types/TimeOff';

export function isSameDay(d1: Moment, d2: Moment) {
  return moment(d1).diff(moment(d2), 'days') === 0;
}

export function isDayInList(days: Array<Moment>, day: Moment) {
  let exists = false;
  days.forEach((item: Moment) => {
    if (isSameDay(item, day)) exists = true;
  });

  return exists;
}

export function findDate(dates: Array<TimeOffRecord>, day: Moment) {
  let match = null;
  dates.forEach((item: TimeOffRecord) => {
    if (moment(item.date).isSame(day, 'day')) {
      match = item;
    }
  });

  return match;
}

export function findDateInTimeOffRequests(
  dates: Array<TimeOffRequest>,
  day: Moment
) {
  let match = null;
  dates.forEach((item: TimeOffRequest) => {
    const dates = JSON.parse(item.dates);

    dates.forEach((i: TimeOffRecord) => {
      if (moment(i.date).isSame(day, 'day')) {
        match = item;
      }
    });
  });

  return match;
}

/* Take (d,m,y) and assign to another moment object */
export function mergeDateHour(d1: Moment, d2: Moment) {
  const cellDateYear = moment(d1).year();
  const cellDateMonth = moment(d1).month();
  const cellDateDay = moment(d1).date();

  return moment(d2)
    .set('year', cellDateYear)
    .set('month', cellDateMonth)
    .set('date', cellDateDay);
}

export function isInRange(a: Moment, b: Moment, date: Moment) {
  const prev = moment(a).isSameOrBefore(b) ? a : b;
  const next = moment(b).isSameOrAfter(a) ? b : a;

  return moment(date).isSameOrAfter(prev) && moment(date).isSameOrBefore(next);
}

export function getDaysBetweenDates(d1: Moment, d2: Moment) {
  const startDate = d1.isSameOrBefore(d2) ? d1 : d2;
  const endDate = d2.isSameOrAfter(d1) ? d2 : d1;

  const dates = [startDate];

  const currDate = moment(startDate).startOf('day');
  const lastDate = moment(endDate).startOf('day');

  while (currDate.add(1, 'days').diff(lastDate) < 0) {
    dates.push(moment(currDate.clone().toDate()));
  }

  dates.push(endDate);

  return dates;
}

export function findRequestsAtDate(
  cellDate: Moment,
  requests: ITimeOffRequestDTO | null
) {
  const matches: TimeOffRequest[] = [];

  requests?.timeOffs?.forEach((r: TimeOffRequest) => {
    const requestDates: Array<Moment> = JSON?.parse(r?.dates)
      ?.map((i: any) => moment(i?.date))
      .flat();

    let found = false;
    requestDates?.forEach((date) => {
      if (date?.isSame(cellDate, 'day')) {
        found = true;
      }
    });

    if (found) {
      matches.push(r);
    }
  });

  return matches;
}

export function isDayIncluded(
  date: any | undefined,
  timeOffList: Array<Moment>
) {
  return timeOffList.find((dateItem: Moment) => dateItem.isSame(date, 'day'));
}

export function getRequestStatusIcon(status: string) {
  if (status === TimeOffStatus.APPROVED) return CheckCircleOutlined;
  if (status === 'Weekend') return LineOutlined;
  if (status === TimeOffStatus.REJECTED) return CloseOutlined;
  return Loading3QuartersOutlined;
}
export const generateYearOptions = () => {
  const currentYear = moment().year();
  const yearOptions = [];
  for (let year = currentYear - 5; year <= currentYear + 5; year += 1) {
    yearOptions.push(moment().year(year).format('YYYY'));
  }
  return yearOptions;
};
