import React, { useState, useEffect } from 'react';
import { Col, Spin, Typography, Popover, Row } from 'antd';
import { HeatMapGrid } from 'react-grid-heatmap';
import { useTranslation } from 'react-i18next';
import CardItem from 'ui-v2/components/Card';
import { StyledButton } from 'components/NewForms/FormStyled';
import { FilterByIcon } from 'Icons/FilterByIcon';
import { useDispatch } from 'react-redux';
import { fetchTrackedHourDetails } from 'redux/trackings/actions';
import moment, { Moment } from 'moment';
import { useDetailedTrackedHours } from 'ui-v2/hooks/useDetailedTrackedHours';
import {
  AttendanceMatrixContainer,
  ColorSquare,
  IconWrapper,
  LegendContainer,
  LegendItem,
  StyledContainer,
  StyledNoDataText,
  StyledScroll,
} from './AttendanceMatrixStyles';
import Filters from './Filters';

const AttendanceMatrix: React.FC = () => {
  const { t } = useTranslation();
  const { list: tracking, loading } = useDetailedTrackedHours();
  const [attendanceData, setAttendanceData] = useState<number[][]>([]);
  const [employees, setEmployees] = useState<any[]>([]);
  const [trackedHours, setTrackedHours] = useState<Array<any>>([]);
  const dispatch = useDispatch();

  const getCellContent = (value: number, employeeId: string, date: Moment) => {
    if (value === 0) return '';

    if (tracking) {
      const trackingEntry = tracking?.find(
        (entry: any) =>
          entry.employee?.id === employeeId &&
          moment(entry.startTime).format('YYYY-MM-DD') ===
            date.format('YYYY-MM-DD')
      );

      if (trackingEntry?.hourType === 'overtime hours') {
        return 'OT';
      }

      return '✓';
    }

    return '';
  };

  const backgroundColors = (value: number) => {
    switch (value) {
      case 0:
        return '#f0f0f0';
      case 1:
        return '#7a95ff';
      default:
        return '#3eb13e';
    }
  };

  useEffect(() => {
    dispatch(
      fetchTrackedHourDetails({
        searchOptions: {
          startDate: moment().startOf('month').format('YYYY-MM-DD'),
          endDate: moment().endOf('month').format('YYYY-MM-DD'),
        },
      })
    );
  }, []);

  useEffect(() => {
    setTrackedHours(tracking);
  }, [tracking]);

  useEffect(() => {
    if (trackedHours) {
      const employeeMap = new Map();
      trackedHours?.forEach((th) => {
        if (th.employee && th.employee.id) {
          employeeMap.set(th.employee.id, th.employee);
        }
      });
      const uniqueEmployees = Array.from(employeeMap.values());
      setEmployees(uniqueEmployees);

      const attendance = uniqueEmployees.map((employee) => {
        const daysInMonth = moment(trackedHours[0]?.startTime).daysInMonth();
        return Array(daysInMonth)
          .fill(0)
          .map((_, dayIndex) => {
            const dayDate = moment(trackedHours[0]?.startTime)
              .date(dayIndex + 1)
              .format('YYYY-MM-DD');
            const matchingHours = trackedHours.find(
              (th) =>
                th.employee.id === employee.id &&
                moment(th.date).format('YYYY-MM-DD') === dayDate
            );

            if (!matchingHours) return 0;
            return matchingHours.isRemote ? 2 : 1;
          });
      });
      setAttendanceData(attendance);
    }
  }, [trackedHours]);

  const renderContent = () => {
    if (loading) {
      return <Spin />;
    }

    if (!trackedHours.length) {
      return (
        <StyledNoDataText justify="center" align="middle">
          <Col>
            <Typography.Text style={{ display: 'block', textAlign: 'center' }}>
              {t('noDataYet.')}
            </Typography.Text>
            <Typography.Link
              style={{ display: 'block', textAlign: 'center' }}
              href="employees/add-employee"
            >
              {t('addSomeEmployeesFirst!')}
            </Typography.Link>
          </Col>
        </StyledNoDataText>
      );
    }

    return (
      <StyledContainer>
        <Row>
          <Col>
            <LegendContainer>
              <LegendItem>
                <ColorSquare color="#7a95ff" />
                <span>Remote attendance</span>
              </LegendItem>
              <LegendItem>
                <ColorSquare color="#3eb13e" />
                <span>Office attendance</span>
              </LegendItem>
              <LegendItem>
                <ColorSquare color="#f0f0f0" />
                <span>No attendance</span>
              </LegendItem>
            </LegendContainer>
          </Col>
        </Row>
        <Row>
          <Col>
            <LegendContainer>
              <LegendItem>
                <span>✓ - Regular hours</span>
              </LegendItem>
              <LegendItem>
                <span>OT - Overtime hours</span>
              </LegendItem>
            </LegendContainer>
          </Col>
        </Row>
        <StyledScroll>
          <HeatMapGrid
            data={attendanceData}
            yLabels={
              employees
                ? employees.map((item) => `${item.firstName} ${item.lastName}`)
                : []
            }
            xLabels={Array.from(
              { length: attendanceData[0]?.length || 0 },
              (_, i) => (i + 1).toString()
            )}
            cellRender={(x, y, value) => (
              <div
                title={`Date: ${y + 1}, Employee: ${employees[x]?.firstName} ${
                  employees[x].lastName
                }`}
              >
                {getCellContent(
                  value,
                  employees[x].id,
                  moment({
                    year: moment(trackedHours[0]?.startTime).year(),
                    month: moment(trackedHours[0]?.startTime).month(),
                    day: y + 1,
                  })
                )}
              </div>
            )}
            yLabelsStyle={() => ({
              fontSize: '0.9rem',
              textTransform: 'uppercase',
              textAlign: 'right',
              color: '#646464',
              marginRight: '10px',
              marginBottom: '1px',
              whiteSpace: 'nowrap',
            })}
            xLabelsStyle={() => ({
              color: '#333',
              fontSize: '0.8rem',
              textAlign: 'center',
              transformOrigin: 'top left',
              marginTop: '0.5rem',
              display: 'inline-block',
              marginRight: '1px',
              width: '1.8rem',
            })}
            cellStyle={(_x, _y, value) => ({
              background: backgroundColors(value),
              fontSize: '1rem',
              color: '#000',
              margin: '0.5px',
            })}
            cellHeight="1.8rem"
            xLabelsPos="bottom"
            yLabelsPos="left"
            square
          />
        </StyledScroll>
      </StyledContainer>
    );
  };

  return (
    <AttendanceMatrixContainer>
      <IconWrapper>
        <Popover placement="leftTop" content={<Filters />} trigger="click">
          <StyledButton id="filter-popover" icon={<FilterByIcon />}>
            {t('filterBy')}
          </StyledButton>
        </Popover>
      </IconWrapper>
      <CardItem title={t('Attendance Matrix')} minHeight={450}>
        {renderContent()}
      </CardItem>
    </AttendanceMatrixContainer>
  );
};

export default AttendanceMatrix;
