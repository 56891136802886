import { Dispatch } from 'redux';
import { getEmployeeRequestsByEntityName } from 'api/request';
import { EmployeeRequestByEntityName } from 'types/Request';
import { Requests } from './types';

export const fetchAllEmployeeRequestsByEntityName =
  (payload: EmployeeRequestByEntityName) => (dispatch: Dispatch) => {
    dispatch({
      type: Requests.FETCH_EMPLOYEE_REQUESTS_START,
    });

    getEmployeeRequestsByEntityName(payload)
      .then((res: any) => {
        dispatch({
          type: Requests.FETCH_EMPLOYEE_REQUESTS_SUCCESS,
          payload: res.data,
        });
      })
      .catch((e: any) => {
        dispatch({
          type: Requests.FETCH_EMPLOYEE_REQUESTS_ERRORED,
          payload: e,
        });
      });
  };
