import { Col, Skeleton, Progress, Button } from 'antd';
import React, { useEffect, useState } from 'react';
import CardItem from 'ui-v2/components/Card';
import { useTranslation } from 'react-i18next';
import { getGoalsByEmployeeId } from 'api/goalService';
import { Goal, GoalsResponse } from 'types/Goal';
import { PlusCircleOutlined } from '@ant-design/icons';
import * as Styled from '../../Task/TaskStyles';
import * as SecondStyled from '../../WorkExperience/WorkExperienceStyles';
import AddGoalModal from './AddGoalModal';
import FeedbackModal from './FeedbackModal';

export default function EmployeeGoals({ id }: { id: string }) {
  const [modalVisible, setModalVisible] = useState(false);
  const [modal2Visible, setModal2Visible] = useState(false);
  const [feedbackGoalId, setFeedbackGoalId] = useState<Goal | null>(null);
  const [goals, setGoals] = useState<Goal[]>([]);
  const [loading, setLoading] = useState(false);

  const { t } = useTranslation();

  const getGoals = async (id: string) => {
    try {
      setLoading(true);
      const response: GoalsResponse = await getGoalsByEmployeeId(id);
      setGoals(response.data);
    } catch {
      console.log('Error');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (id) getGoals(id);
  }, [id]);

  return (
    <CardItem title={t('Goals')}>
      <Skeleton
        loading={loading}
        active
        title={false}
        paragraph={{ rows: 6, width: '100%' }}
      >
        <Styled.RowItem gutter={[0, 24]} justify="center">
          {goals?.length > 0 ? (
            goals?.map((goal: Goal, index) => (
              <Col span={24} key={goal.id}>
                <SecondStyled.RowItem
                  justify="space-between"
                  last={index === goals.length - 1}
                  style={{ padding: '10px 0' }}
                  onClick={() => {
                    setFeedbackGoalId(goal);
                    setModal2Visible(true);
                  }}
                >
                  <Col span={24}>
                    {goal.name}
                    <Progress percent={goal.completed} />
                  </Col>
                </SecondStyled.RowItem>
              </Col>
            ))
          ) : (
            <Col>
              <Styled.Label>{t('No goals yet!')}</Styled.Label>
            </Col>
          )}

          <Col span={24}>
            <Button
              icon={
                <Styled.AddSpaceIcon>
                  <PlusCircleOutlined />
                </Styled.AddSpaceIcon>
              }
              size="large"
              style={{
                borderRadius: '8px',
                width: '100%',
                fontWeight: '600',
                fontSize: '16px',
                color: '344054',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
              onClick={() => setModalVisible(true)}
            >
              {t('Add Goal')}
            </Button>
          </Col>
        </Styled.RowItem>

        {modalVisible && (
          <AddGoalModal
            open={modalVisible}
            closeModal={() => {
              setModalVisible(false);
              getGoals(id);
            }}
            handleModalVisible={() => setModalVisible(false)}
            employeeId={id}
          />
        )}

        {modal2Visible && (
          <FeedbackModal
            open={modal2Visible}
            closeModal={() => {
              setFeedbackGoalId(null);
              setModal2Visible(false);
            }}
            feedbackGoal={feedbackGoalId as Goal}
          />
        )}
      </Skeleton>
    </CardItem>
  );
}
