/* eslint-disable react/no-children-prop */
import { Col, Popconfirm, Row } from 'antd';
import React, { useEffect } from 'react';
import Content from 'ui-v2/components/Content';
import * as Styled from 'ui-v2/components/PersonalInfo/PersonalInfoStyles';
import CardItem from 'ui-v2/components/Card';
import { Item } from 'ui-v2/components/PersonalInfo';
import { useAssetsData } from 'ui-v2/hooks/useAssetsData';
import { convertUTCtoFormatedLocalTime } from 'utils/utilFunctions';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { fetchAsset } from 'redux/assets/actions';
import { Link, useSearchParams } from 'react-router-dom';
import { updateAssetById } from 'api/assetService';
import { useScreenSize } from 'ui-v2/hooks/useScreenSize';
import { toast } from 'react-toastify';
import Barcode from 'react-barcode';
import {
  StyledAddetDepatmentBox,
  StyledUnassignButton,
} from './StyledAssetDetartment';
import EmployeeList from '../DepartmentDetails/EmployeeList';

export default function AssetDetails() {
  const { singleAsset } = useAssetsData();
  const [searchParams, setSearchParams] = useSearchParams();
  const { t } = useTranslation();
  const { isMobile } = useScreenSize();

  const dispatch = useDispatch();

  useEffect(() => {
    if (searchParams.get('id') && !singleAsset.loading && !singleAsset.loaded) {
      dispatch(fetchAsset(searchParams.get('id') as string));
    }
  }, [searchParams, singleAsset]);

  useEffect(() => {
    if (searchParams.get('edit-asset') !== null) {
      searchParams.delete('edit-asset');
      setSearchParams(searchParams);
    }
  }, [searchParams]);

  const handleUnassign = async () => {
    try {
      let updatedValues = { ...singleAsset.data };

      if (singleAsset?.data?.employee?.id) {
        updatedValues = {
          ...updatedValues,
          purchasePrice: Number(updatedValues.purchasePrice),
          employee: null as any,
        };
      } else if (singleAsset?.data?.department?.id) {
        updatedValues = {
          ...updatedValues,
          purchasePrice: Number(updatedValues.purchasePrice),
          department: null as any,
        };
      }

      delete updatedValues.employee;
      delete updatedValues.department;
      const res = await updateAssetById(
        searchParams.get('id') as string,
        updatedValues
      );
      if (res.status === 200) {
        toast.success(t('assetUnassignedSuccessfully'));
        dispatch(fetchAsset(searchParams.get('id') as string));
      } else {
        toast.error(t('falidToUnassignAsset'));
      }
    } catch (error) {
      toast.error(t('falidToUnassignAsset'));
    }
  };

  const purchaseDate = convertUTCtoFormatedLocalTime(
    singleAsset?.data?.purchaseDate
  );

  const warrantyStartDate = convertUTCtoFormatedLocalTime(
    singleAsset?.data?.warrantyStartDate
  );

  const warrantyEndDate = convertUTCtoFormatedLocalTime(
    singleAsset?.data?.warrantyEndDate
  );

  let assignedTo = '';
  if (singleAsset?.data?.employee?.id) {
    assignedTo = `${singleAsset?.data?.employee?.firstName || ''} ${
      singleAsset?.data?.employee?.lastName || ''
    }`;
  } else if (singleAsset?.data?.department?.id) {
    assignedTo = `${singleAsset?.data?.department?.name || ''}`;
  } else {
    assignedTo = '-';
  }

  if (singleAsset.data?.id) {
    return (
      <>
        <Content position={isMobile ? 'flexcol' : 'grid'}>
          <>
            <>
              <CardItem title={t('assetInformation')}>
                <Row gutter={[0, 16]}>
                  <Styled.RowItem gutter={[0, 10]}>
                    <Col md={{ span: 12 }} sm={{ span: 24 }}>
                      <Item
                        label={t('name')}
                        value={singleAsset?.data?.name}
                        children={undefined}
                      />
                    </Col>
                    <Col md={{ span: 12 }} sm={{ span: 24 }}>
                      <Item
                        label={t('assignedTo')}
                        value={assignedTo}
                        children={undefined}
                      />
                    </Col>
                  </Styled.RowItem>
                  <Styled.RowItem gutter={[0, 10]}>
                    <Col md={{ span: 12 }} sm={{ span: 24 }}>
                      <Item
                        label={t('purchaseDate')}
                        value={purchaseDate}
                        children={undefined}
                      />
                    </Col>
                    <Col md={{ span: 12 }} sm={{ span: 24 }}>
                      <Item
                        label={t('purchasePrice')}
                        value={
                          singleAsset?.data?.currency?.symbol === 'ALL'
                            ? `${singleAsset?.data?.purchasePrice
                                .toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ',')} ${
                                singleAsset?.data?.currency?.symbol
                              }`
                            : `${singleAsset?.data?.purchasePrice} ${singleAsset?.data?.currency?.symbol}`
                        }
                        children={undefined}
                      />
                    </Col>
                  </Styled.RowItem>
                  <Styled.RowItem gutter={[0, 10]}>
                    <Col md={{ span: 12 }} sm={{ span: 24 }}>
                      <Item
                        label={t('serialNumber')}
                        value={singleAsset?.data?.serialNumber}
                        children={undefined}
                      />
                    </Col>
                    <Col md={{ span: 12 }} sm={{ span: 24 }}>
                      <Item
                        label={t('type')}
                        value={singleAsset?.data?.type}
                        children={undefined}
                      />
                    </Col>
                  </Styled.RowItem>
                  <Styled.RowItem gutter={[0, 10]}>
                    <Col md={{ span: 12 }} sm={{ span: 24 }}>
                      <Item
                        label={t('description')}
                        value={singleAsset?.data?.description}
                        children={undefined}
                      />
                    </Col>
                    <Col md={{ span: 12 }} sm={{ span: 24 }}>
                      <Item
                        label={t('condition')}
                        value={singleAsset?.data?.condition}
                        children={undefined}
                      />
                    </Col>
                  </Styled.RowItem>
                  <Styled.RowItem gutter={[0, 10]}>
                    <Col md={{ span: 12 }} sm={{ span: 24 }}>
                      <Item
                        label={t('assetLocation')}
                        value={singleAsset?.data?.location}
                        children={undefined}
                      />
                    </Col>
                    <Col md={{ span: 12 }} sm={{ span: 24 }}>
                      <Item
                        label={t('status')}
                        value={singleAsset?.data?.description}
                        children={undefined}
                      />
                    </Col>
                  </Styled.RowItem>
                  <Styled.RowItem gutter={[0, 10]}>
                    <Col md={{ span: 12 }} sm={{ span: 24 }}>
                      <Item
                        label={t('Warranty Start Date')}
                        value={warrantyStartDate}
                        children={undefined}
                      />
                    </Col>
                    <Col md={{ span: 12 }} sm={{ span: 24 }}>
                      <Item
                        label={t('Warranty End Date')}
                        value={warrantyEndDate}
                        children={undefined}
                      />
                    </Col>
                  </Styled.RowItem>
                  <Styled.RowItem gutter={[0, 10]} last>
                    <Col md={{ span: 12 }} sm={{ span: 24 }}>
                      <Item
                        label={t('notes')}
                        value={singleAsset?.data?.notes}
                        children={undefined}
                      />
                    </Col>
                    {singleAsset.data.barcode ? (
                      <Col md={{ span: 12 }} sm={{ span: 24 }}>
                        <Item
                          label={t('barcode')}
                          value={
                            <Barcode
                              width={0.5}
                              height={40}
                              displayValue={false}
                              value={singleAsset?.data?.barcode}
                            />
                          }
                          children={undefined}
                        />
                      </Col>
                    ) : (
                      ''
                    )}
                  </Styled.RowItem>
                  {singleAsset?.data?.assetSubCategory ? (
                    <Styled.RowItem gutter={[0, 10]}>
                      <Col md={{ span: 12 }} sm={{ span: 24 }}>
                        <Item
                          label="Category"
                          value={`${singleAsset?.data?.assetSubCategory?.assetCategory?.categoryName}/${singleAsset?.data?.assetSubCategory?.subCategoryName}`}
                          children={undefined}
                        />
                      </Col>
                    </Styled.RowItem>
                  ) : (
                    ''
                  )}
                </Row>
              </CardItem>
            </>
            <>
              {singleAsset?.data?.isAssigned &&
                (singleAsset?.data?.employee?.id ||
                  singleAsset?.data?.department?.id) && (
                  <>
                    {singleAsset?.data?.employee ? (
                      <EmployeeList
                        list={[singleAsset?.data?.employee]}
                        title={t('employee')}
                        rightButton={
                          <Popconfirm
                            placement="topLeft"
                            title={t('areYouSureYouWantToUnassignThisAsset')}
                            onConfirm={handleUnassign}
                            okText={t('yes')}
                            cancelText={t('no')}
                          >
                            <StyledUnassignButton danger type="text">
                              Unassign
                            </StyledUnassignButton>
                          </Popconfirm>
                        }
                      />
                    ) : (
                      <CardItem
                        title={t('department')}
                        rightButton={
                          <Popconfirm
                            placement="topLeft"
                            title={t('areYouSureYouWantToUnassignThisAsset')}
                            onConfirm={handleUnassign}
                            okText={t('yes')}
                            cancelText={t('no')}
                          >
                            <StyledUnassignButton danger type="text">
                              Unassign
                            </StyledUnassignButton>
                          </Popconfirm>
                        }
                      >
                        <Styled.RowItem last>
                          <Col span={24}>
                            <Link
                              to={`/departments/department-details?id=${singleAsset?.data?.department?.id}`}
                            >
                              <StyledAddetDepatmentBox>
                                <Item
                                  label={singleAsset?.data?.department?.name}
                                />
                              </StyledAddetDepatmentBox>
                            </Link>
                          </Col>
                        </Styled.RowItem>
                      </CardItem>
                    )}
                  </>
                )}
            </>
          </>
        </Content>
      </>
    );
  }
  return null;
}
