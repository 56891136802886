import styled from 'styled-components';

export const StyledParagraph = styled.p`
  margin: 0;
  font-weight: 300;
`;

export const Button = styled.button<{ variant: string }>`
  ${(props) => {
    if (props.variant === 'approve') {
      return `
        background-color: #155EEF;
        color: white;
      `;
    }
    return `
        border: 1px solid #EFF4FF;
        background: #EFF4FF;
        color: #004EEB;

        svg {
          path {
            stroke: #004EEB;
          }
        }

        margin-right: 8px;
      `;
  }}
  width:100%;
  min-height: 40px;
  font-size: 16px;
  border: none;
  border-radius: 7px;
  margin-bottom: 10px;
`;
