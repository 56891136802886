import { CurrencyType } from './Candidates';
import { TeanantLocation } from './TenantLocation';

export enum PayrollRuleType {
  FIXED = 'FIXED',
  PERCENTAGE = 'PERCENTAGE',
  INTERVAL = 'INTERVAL',
}

export enum PayrollIntervalValueType {
  FIXED = 'fixed',
  PERCENTAGE = 'percentage',
}

export interface PayrollRule {
  id: string;
  humanID: string;
  type: PayrollRuleType;
  name: string;
  description: string;
  parameters: {
    value: number;
    from?: number;
    to?: number;
  };
  currency: CurrencyType;
  tenantLocation: TeanantLocation;
  createdAt: string;
  endDate: string;
}

export interface PayrollRuleParameters {
  value: number;
  from?: number;
  to?: number;
  valueType?: PayrollIntervalValueType;
}

export interface CreatePayrollRuleDTO {
  humanID: string;
  type: PayrollRuleType;
  name: string;
  description: string;
  parameters: PayrollRuleParameters | Array<PayrollRuleParameters>;
  currencyId: string;
  tenantLocationId?: string;
}

export interface UpdatePayrollRuleDTO {
  humanID: string;
  type: PayrollRuleType;
  name: string;
  description: string;
  parameters: PayrollRuleParameters | Array<PayrollRuleParameters>;
  currencyId: string;
}
