import { CreateHierarchy } from 'types/Hierarchy';

export enum HierarchyAction {
  FETCH_HIERARCHY_START = 'FETCH_HIERARCHY_START',
  FETCH_HIERARCHY_SUCCESS = 'FETCH_HIERARCHY_SUCCESS',
  FETCH_HIERARCHY_ERRORED = 'FETCH_HIERARCHY_ERRORED',

  CLEAR_HIERARCHY = 'CLEAR_HIERARCHY',
}

export interface HierarchyState {
  data: Array<CreateHierarchy>;
  loading: boolean;
  loaded: boolean;
  errored: boolean;
  error: any;
}
