import React, { useEffect, useMemo, useState } from 'react';
import {
  Chart as ChartJS,
  ArcElement,
  PointElement,
  Filler,
  LineElement,
  Tooltip,
  Legend,
  ChartData,
} from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import CardItem from 'ui-v2/components/Card';

import { Col, Row, Spin, Typography } from 'antd';
import { FAVORITE_PALETTE, PALETE } from 'components/styledComponents/colors';
import { useTranslation } from 'react-i18next';
import { getTrackedHoursPieCHart } from 'api/trackedHoursService';
import moment from 'moment';
import { doughnutOptions } from 'ui-v2/routes/tracking/trackingTabs/trackingTabContent/trackingSummary/utils';

ChartJS.register(
  ArcElement,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend
);

export const PieChartTrackingHours = () => {
  const [dashboardPieStats, setDashboardPieStats] = useState<any>();
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();

  async function getData() {
    setLoading(true);
    let val: any;
    await getTrackedHoursPieCHart({
      searchOptions: {
        startDate: moment().subtract(3, 'months').format('YYYY-MM-DD'),
      },
    })
      .then((response) => {
        val = response;
        setDashboardPieStats(val.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }

  useEffect(() => {
    getData();
  }, []);

  const data: ChartData<'doughnut', number[], string> = useMemo(
    () => ({
      labels: dashboardPieStats
        ? dashboardPieStats.map(
            (item: { projectName: any }) => item.projectName
          )
        : [],
      datasets: [
        {
          label: t('thereAreNoExpensesToReport'),
          data: dashboardPieStats
            ? dashboardPieStats.map(
                (item: { totalCount: any }) => item.totalCount
              )
            : [],
          backgroundColor: dashboardPieStats
            ? dashboardPieStats.map(
                (_: any, index: number) =>
                  FAVORITE_PALETTE[index] ??
                  Object.values(PALETE)[
                    Math.floor(Math.random() * Object.values(PALETE).length)
                  ]
              )
            : [],
          borderWidth: 1,
        },
      ],
    }),
    [dashboardPieStats]
  );

  return (
    <CardItem title={t('Project Hours')} minHeight={600}>
      <Spin spinning={loading}>
        {!loading && dashboardPieStats?.length === 0 ? (
          <Row
            justify="center"
            align="middle"
            style={{
              minHeight: 350,
            }}
          >
            <Col>
              <Typography.Text
                style={{
                  display: 'block',
                  textAlign: 'center',
                }}
              >
                {t('noDataYet.')}
              </Typography.Text>
              <Typography.Link
                style={{
                  display: 'block',
                  textAlign: 'center',
                }}
                href="expenses"
              >
                {t('thereAreNoExpensesToReport')}
              </Typography.Link>
            </Col>
          </Row>
        ) : (
          <Doughnut data={data} options={doughnutOptions} />
        )}
      </Spin>
    </CardItem>
  );
};

export default PieChartTrackingHours;
