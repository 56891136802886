import {
  Background,
  BackgroundVariant,
  ConnectionLineType,
  Controls,
  Edge,
  MiniMap,
  Node,
  ReactFlow,
  ReactFlowProvider,
  useEdgesState,
  useNodesState,
  useReactFlow,
} from '@xyflow/react';
import React, { useEffect, useMemo } from 'react';
import { DataType, EdgeType } from 'types/Hierarchy';
import Dagre from '@dagrejs/dagre';
import { useHierarchyData } from 'ui-v2/hooks/useHierarchyData';
import { fetchHierarchy } from 'redux/hierarchy/action';
import { useDispatch } from 'react-redux';
import GroupNode from '../CustomNodes/GroupNode';

const defaultNodeWidth = 172;
const defaultNodeHeight = 80;

const getLayoutedElements = (nodes: Node[], edges: Edge[]) => {
  const g = new Dagre.graphlib.Graph().setDefaultEdgeLabel(() => ({}));

  g.setGraph({
    rankdir: 'TB',
    nodesep: 100,
    ranksep: 200,
    marginx: 50,
    marginy: 50,
  });

  edges.forEach((edge: Edge) => g.setEdge(edge.source, edge.target));
  nodes.forEach((node: any) =>
    g.setNode(node.id, {
      width: node.measured?.width ?? defaultNodeWidth,
      height: node.measured?.height ?? defaultNodeHeight,
    })
  );

  Dagre.layout(g);

  return {
    nodes: nodes.map((node: any) => {
      const position = g.node(node.id);
      const width = node.measured?.width ?? defaultNodeWidth;
      const height = node.measured?.height ?? defaultNodeHeight;

      return {
        ...node,
        position: {
          x: position.x - width / 2,
          y: position.y - height / 2,
        },
      };
    }),
    edges,
  };
};

const EmployeeHierarchyFlow = () => {
  const { fitView } = useReactFlow();
  const { list } = useHierarchyData();
  const [nodes, setNodes] = useNodesState<Node>([]);
  const [edges, setEdges] = useEdgesState<Edge>([]);
  const dispatch = useDispatch();

  const nodeTypes = useMemo(
    () => ({
      groupEmployee: GroupNode,
    }),
    []
  );

  useEffect(() => {
    dispatch(fetchHierarchy());
  }, []);

  useEffect(() => {
    if (!list?.nodes?.length || !list?.edges?.length) return;

    const formattedNodes = list.nodes.map((el: DataType) => ({
      id: el.id,
      type: 'groupEmployee',
      data: {
        label: el.workPosition.name,
        employees: el.workPosition.employee,
      },
    }));

    const formattedEdges = list.edges.map((edge: EdgeType) => ({
      id: edge.id,
      source: edge.sourceNode.id,
      target: edge.targetNode.id,
      type: ConnectionLineType.SmoothStep,
    }));

    const layouted = getLayoutedElements(formattedNodes, formattedEdges);
    setNodes(layouted.nodes);
    setEdges(layouted.edges);

    setTimeout(() => {
      fitView({ padding: 0.2 });
    }, 100);
  }, [list, fitView]);

  return (
    <div style={{ height: '86vh' }}>
      <ReactFlow
        nodes={nodes}
        edges={edges}
        nodeTypes={nodeTypes}
        fitView
        defaultEdgeOptions={{
          type: ConnectionLineType.SmoothStep,
        }}
      >
        <Background variant={BackgroundVariant.Dots} gap={12} size={1} />
        <Controls />
        <MiniMap nodeStrokeWidth={3} />
      </ReactFlow>
    </div>
  );
};

export default function EmployeeHierarchy() {
  return (
    <ReactFlowProvider>
      <EmployeeHierarchyFlow />
    </ReactFlowProvider>
  );
}
