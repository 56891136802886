import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import { fetchSingleProject } from 'redux/projects/actions';
import { useProjectsData } from 'ui-v2/hooks/useProjectsData';

import ProjectInformation from 'ui-v2/components/Project/ProjectInformation';
import ProjectDocuments from 'ui-v2/components/Project/UploadProjectDocument';
import Content from 'ui-v2/components/Content';
import ProjectModalForm from 'components/NewForms/ProjectForm';
import ProjectEmployees from 'ui-v2/components/Project/ProjectEmployees';
import ProjectTimeOffs from 'ui-v2/components/Project/ProjectTimeOffs/ProjectTimeOffs';
import { useScreenSize } from 'ui-v2/hooks/useScreenSize';

export default function ProjectDetails() {
  const {
    project: { data, loaded },
  } = useProjectsData();
  const dispatch = useDispatch();
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const { isMobile } = useScreenSize();

  const toogleModal = () => setModalVisible((modalVisible) => !modalVisible);

  useEffect(() => {
    if (searchParams.get('edit-project')) {
      setModalVisible(true);
      searchParams.delete('edit-project');
      setSearchParams(searchParams);
    }
    if (searchParams.get('id') && !loaded) {
      dispatch(fetchSingleProject(searchParams.get('id') as string));
    }
  }, [searchParams, loaded]);

  if (!data?.id) {
    return null;
  }

  return (
    <>
      <Content position={isMobile ? 'flexcol' : 'grid'}>
        <>
          <>
            <ProjectInformation />
            <ProjectEmployees />
          </>
          <>
            <ProjectDocuments />
            <ProjectTimeOffs />
          </>
        </>
      </Content>
      {modalVisible && (
        <ProjectModalForm open={modalVisible} closeModal={toogleModal} />
      )}
    </>
  );
}
