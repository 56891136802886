import React from 'react';
import { Row, Col } from 'antd';
import CardItem from 'ui-v2/components/Card';
import { t } from 'i18next';
import { updateExpenseReportStatus } from 'api/expensesService';
import { ExpenseReportStatus, ExpensesReport } from 'types/ExpensesReport';
import { fetchSingleExpenseReport } from 'redux/expenses/action';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button } from './ExpenseStyle';

interface IProps {
  singleExpenseReport: ExpensesReport;
}
export default function ExpenseApproveReject({ singleExpenseReport }: IProps) {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();

  async function handleBtn(type: string) {
    if (type === 'approve') {
      await updateExpenseReportStatus(singleExpenseReport.id, {
        status: ExpenseReportStatus.APPROVED,
      });
      toast.success(t('expenseApprovedSuccesfully'));
    } else {
      await updateExpenseReportStatus(singleExpenseReport.id, {
        status: ExpenseReportStatus.REJECTED,
      });
      toast.success(t('expenseRejectedSuccesfully'));
    }
    dispatch(fetchSingleExpenseReport(searchParams.get('id') as string));
  }

  return (
    <CardItem title={t('approveOrReject')}>
      <>
        <Row>
          <Col span={24}>
            <Button variant="approve" onClick={() => handleBtn('approve')}>
              {t('approve')}
            </Button>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Button variant="reject" onClick={() => handleBtn('reject')}>
              {t('reject')}
            </Button>
          </Col>
        </Row>
      </>
    </CardItem>
  );
}
