import React from 'react';
import { useTranslation } from 'react-i18next';

import { SettingTab } from 'types/settings';
import BaseTabs from 'ui-v2/components/tabs/BaseTabs';
import TenantConfigForm from 'components/NewForms/TenantConfigForm';
import ApiKeysForm from 'components/NewForms/ApiKeysForm';
import CompanyProfileForm from 'components/NewForms/CompanyProfileForm';
import TenantLocationList from 'components/NewForms/TenantLocation/TenantLocationList';
import BaseTab from 'ui-v2/components/tabs/BaseTab';
import TenantDaysOffList from 'components/NewForms/TenantDaysOff/TenantDaysOffList';
import TenantSettingsPageTabLayout from './TenantSettingsPageTabLayout';

interface TenantSettingsPageTabsProps {
  activeTab: SettingTab;
  onTabChange: (tab: SettingTab) => void;
}

const TenantSettingsPageTabs: React.FC<TenantSettingsPageTabsProps> = ({
  activeTab,
  onTabChange,
}) => {
  const { t } = useTranslation();

  const onTabClick = (activeKey: string) => {
    onTabChange(activeKey as SettingTab);
  };

  return (
    <BaseTabs compact defaultActiveKey={activeTab} onTabClick={onTabClick}>
      <BaseTab
        key={SettingTab.CONFIGURATIONS}
        tab={t(SettingTab.CONFIGURATIONS)}
      >
        <TenantSettingsPageTabLayout tab={SettingTab.CONFIGURATIONS}>
          <TenantConfigForm
            tenantOnboardingConfigProps={{
              hideConfigureBtn: true,
            }}
          />
        </TenantSettingsPageTabLayout>
      </BaseTab>

      <BaseTab key={SettingTab.APIKEYS} tab={t(SettingTab.APIKEYS)}>
        <TenantSettingsPageTabLayout tab={SettingTab.APIKEYS}>
          <ApiKeysForm />
        </TenantSettingsPageTabLayout>
      </BaseTab>

      <BaseTab
        key={SettingTab.COMPANYPROFILE}
        tab={t(SettingTab.COMPANYPROFILE)}
      >
        <TenantSettingsPageTabLayout tab={SettingTab.COMPANYPROFILE}>
          <CompanyProfileForm />
        </TenantSettingsPageTabLayout>
      </BaseTab>

      <BaseTab key={SettingTab.LOCATION} tab={t(SettingTab.LOCATION)}>
        <TenantSettingsPageTabLayout tab={SettingTab.LOCATION}>
          <TenantLocationList />
        </TenantSettingsPageTabLayout>
      </BaseTab>
      <BaseTab key={SettingTab.DAYSOFF} tab={t(SettingTab.DAYSOFF)}>
        <TenantSettingsPageTabLayout tab={SettingTab.DAYSOFF}>
          <TenantDaysOffList />
        </TenantSettingsPageTabLayout>
      </BaseTab>
    </BaseTabs>
  );
};

export default TenantSettingsPageTabs;
