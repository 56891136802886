import { UserDataType } from 'redux/authUser/types';
import { MetaPagination } from 'types/GridTable';
import { Image } from 'types/Image';

export interface UsersState {
  data: {
    data: UserDataType[];
    meta: MetaPagination | null;
  };
  loading: boolean;
  loaded: boolean;
  errored: boolean;
  error: any;
  roleId: string | null;
}

export interface UserState {
  data: any;
  loading: boolean;
  loaded: boolean;
  errored: boolean;
  error: any;
}

export enum Users {
  FETCH_USERS_START = 'FETCH_USERS_START',
  FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS',
  FETCH_USERS_ERRORED = 'FETCH_USERS_ERRORED',

  FETCH_USER_START = 'FETCH_USER_START',
  FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS',
  FETCH_USER_ERRORED = 'FETCH_USER_ERRORED',

  CLEAR_USER_DATA = 'CLEAR_USER_DATA',

  FETCH_ROLE_ID = 'FETCH_ROLE_ID',

  DELETE_USER = 'DELETE_USER',
}

export interface SetPassword {
  password: string;
  confirmPassword: string;
}
export interface ForgotPassword {
  email: string;
}
export interface ResendVerificationEmail {
  email: string;
}

export interface CreateUserDto {
  id: string;
  email: string;
  firstName: string;
  password: string;
  phoneNumber: string;
  birthDate: string;
  isActive: boolean;
  avatar: Image;
  roles: string[];
  projects?: string[];
  sendAccountEmail?: boolean;
}
export interface EditUserDto {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  birthDate: string;
  isActive: boolean;
  roleId?: string;
}
