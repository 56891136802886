/* eslint-disable prettier/prettier */
import React, { useEffect } from 'react';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Form, FormInstance, Select, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import { StyledInput, StyledRate } from 'components/NewForms/FormStyled';
import { JobCategoryType } from 'types/JobCategory';

const { Option } = Select;

interface DynamicSkillsFormProps {
  form: FormInstance;
  categoryName: string;
  availableSkills: JobCategoryType[] | undefined;
  defaultSkills?: any[];
  hasYearsOfExperienceField?: boolean;
  hasScoreField?: boolean;
}

const SkillsDynamicForm: React.FC<DynamicSkillsFormProps> = ({
  categoryName,
  availableSkills,
  form,
  defaultSkills,
  hasYearsOfExperienceField = true,
  hasScoreField = true,
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    if (defaultSkills && !form.isFieldsTouched([categoryName])) {
      form.setFieldsValue({
        [categoryName]: defaultSkills
      });
    }
  }, [defaultSkills, form, categoryName]);

  const getSelectedPairs = () => {
    const values = form.getFieldsValue()[categoryName] || [];
    return values?.map((v: any) => ({
      categoryId: v?.categoryId?.value,
      subCategoryId: v?.subCategoryId?.value,
    }));
  };

  const getAvailableCategories = () => {
    const selectedPairs = getSelectedPairs();
    return availableSkills?.map((category) => ({
      ...category,
      skillSubCategory: category.skillSubCategory.filter((sub) =>
        !selectedPairs.some((pair: any) =>
          pair.categoryId === category.id && pair.subCategoryId === sub.id
        )
      )
    }));
  };

  const handleFieldChange = (field: string, index: number, value: string) => {
    const formValues = form.getFieldsValue();
    if (field === 'categoryId') {
      formValues[categoryName][index].subCategoryId = undefined;
    }
    formValues[categoryName][index][field] = value;
    form.setFieldsValue(formValues);
  };

  return (
    <Form.List name={categoryName}>
      {(fields, { add, remove }) => (
        <>
          {fields.map((field, index) => (
            <Space
              key={field.key}
              style={{ display: 'flex', marginBottom: 8 }}
              align="baseline"
            >
              <Form.Item
                {...field}
                name={[field.name, 'categoryId']}
                rules={[{ required: true, message: t('Please select category') }]}
              >
                <Select
                  style={{ width: 200 }}
                  placeholder={t('Select category')}
                  onChange={(value) => { handleFieldChange('categoryId', index, value); console.log(value, 234); }}
                  labelInValue
                >
                  {getAvailableCategories()?.map((category) => (
                    <Option key={category.id} value={category.id}>
                      {category.categoryName}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                {...field}
                name={[field.name, 'subCategoryId']}
                rules={[{ required: true, message: t('Please select subcategory') }]}
              >
                <Select
                  style={{ width: 200 }}
                  placeholder={t('Select subcategory')}
                  onChange={(value) => handleFieldChange('subCategoryId', index, value)}
                  disabled={!form.getFieldValue([categoryName, index, 'categoryId'])}
                  labelInValue
                >
                  {getAvailableCategories()
                    ?.find((c) => c.id === form.getFieldValue([categoryName, index, 'categoryId'])?.value)
                    ?.skillSubCategory.map((sub) => (
                      <Option key={sub.id} value={sub.id} label={sub.subCategoryName}>
                        {sub.subCategoryName}
                      </Option>
                    ))}
                </Select>
              </Form.Item>

              {hasYearsOfExperienceField && (
                <Form.Item
                  name={[field.name, 'yearsOfExperience']}
                  rules={[
                    {
                      required: true,
                      message: t('Please enter years of experience'),
                    },
                    {
                      validator: (_, value) =>
                      (value >= 0.3
                        ? Promise.resolve()
                        : Promise.reject(new Error(t('Minimum of 3 months (0.3) required')))),
                    },
                  ]}
                >
                  <StyledInput
                    type="number"
                    min={0}
                    style={{ width: 200 }}
                    placeholder="Years"
                    onWheel={(e: React.WheelEvent<HTMLInputElement>) =>
                      (e.target as HTMLInputElement).blur()
                    }
                  />
                </Form.Item>
              )}

              {hasScoreField && (
                <Form.Item
                  name={[field.name, 'score']}
                  rules={[
                    { required: true, message: t('Please enter a score!') },
                    {
                      validator: (_, value) =>
                      (value >= 1
                        ? Promise.resolve()
                        : Promise.reject(new Error(t('Minimum score of 1 required')))),
                    },
                  ]}
                >
                  <StyledRate />
                </Form.Item>
              )}

              <MinusCircleOutlined onClick={() => remove(field.name)} />
            </Space>
          ))}
          <Form.Item>
            <Button
              type="dashed"
              onClick={() => add()}
              block
              icon={<PlusOutlined />}
              disabled={getAvailableCategories()?.every((s) => s.skillSubCategory?.length === 0)}
            >
              {t('Add Skill')}
            </Button>
          </Form.Item>
        </>
      )}
    </Form.List>
  );
};

export default SkillsDynamicForm;
