import { useSelector } from 'react-redux';

export function useDetailedTrackedHours() {
  const { data, loading, loaded, errored, error } = useSelector(
    (state: any) => state.trackingHours.trackedHoursDetails
  );

  return {
    list: data,
    loading,
    loaded,
    errored,
    error,
  };
}
