import React, { useState, useCallback, useRef, useEffect } from 'react';
import { InputRef, Tooltip } from 'antd';
import { useSidebarItems } from 'ui-v2/hooks/useSidebarItems';
import { useNavigate } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import NotificationsComponent from 'ui-v2/components/Notifications';
import SearchIcon from 'Icons/SearchIcon';
import { AuthUserState } from 'redux/authUser/types';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { AUTH_ROLES } from 'types/Auth';
import { TENANT_ID } from 'utils/constants';
import { useTenantFeatureData } from 'ui-v2/hooks/useTenantFeatureData';
import { FeatureFlagsTenantType } from 'types/FeatureFlagsTenant';
import { FeatureFlagsList, SidebarItem, SidebarItemGroup } from '../utils';
import {
  StyledSidebarSubMenuItem,
  StyledSiderbarMenu,
  StyledSiderbarMenuGroupItem,
  StyledSidebarMenuItem,
  StyledSidebarMenuText,
} from './StyledSidebarMenu';
import SiderbarSubMenu from './SidebarSubMenu';
import { StyledSiderDivider } from '../StyledAppSidebar';
import SidebarSearch from '../SidebarSearch';

export interface SidebarMenuProps {
  collapsed: boolean;
  setCollapsed: any;
}

const CustomItemComponent: React.FC<{
  wrappedItemName: string;
  filterValue?: string;
}> = ({ wrappedItemName, children, filterValue = '' }) => {
  if (
    wrappedItemName
      .toLocaleLowerCase()
      .startsWith(filterValue.toLocaleLowerCase()) ||
    !filterValue?.length
  ) {
    return <>{children}</>;
  }
  return null;
};

const staticFeatures = [
  FeatureFlagsList.ADMINISTRATIVE,
  FeatureFlagsList.ORG_STRUCT,
];

const SidebarMenu: React.FC<SidebarMenuProps> = ({
  collapsed,
  setCollapsed,
}) => {
  const tenantId = localStorage.getItem(TENANT_ID);
  const [isFeatureAllowed, setIsFeatureAllowed] = useState<any>();
  const { data, getTenantFeatureList } = useTenantFeatureData();
  const featureList: string[] = data
    ?.filter((item: any) => item?.status)
    .map((item: any) => item?.featureFlag?.featureName);
  const [filteredItems, setFilteredItems] = useState<SidebarItemGroup[]>();
  const [filteredCopy, setFilteredCopy] = useState<SidebarItemGroup[]>([]);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [filterValue, setFilterValue] = useState('');
  const { items, groupedItems, isActivePath, onSearch } = useSidebarItems();
  const [isClicked, setIsClicked] = useState(false);

  const { authUserRole }: AuthUserState = useSelector(
    (state: RootState) => state.authUser
  );
  const isSuperAdmin = authUserRole === AUTH_ROLES.SUPER_ADMIN;

  const inputRef = useRef<InputRef>(null);

  const onItemClick = (item: SidebarItem) => {
    navigate(item.path);
  };

  const onItemSearch = (searchedValue: string) => {
    onSearch({
      searchedValue,
      t,
      setFilteredItems,
      filteredCopy,
    });
    setFilterValue(t(searchedValue));
  };

  const onSearchClick = useCallback(() => {
    setCollapsed(!collapsed);
    setIsClicked(!isClicked);
  }, [collapsed]);

  useEffect(() => {
    if (tenantId) {
      getTenantFeatureList(tenantId);
    }
  }, []);

  useEffect(() => {
    if (data.length !== 0) {
      const featureMap = data?.reduce(
        (dict: any, item: FeatureFlagsTenantType) => {
          dict.set(item.featureFlag.featureName, item.status);
          return dict;
        },
        new Map()
      );
      setIsFeatureAllowed(featureMap);
      const filterForMap = groupedItems;
      filterForMap.forEach((item) => {
        const filteredEach = item.items.filter(
          (item) =>
            isFeatureAllowed.get(item.path.slice(1)) ||
            isFeatureAllowed.get(item.path.slice(1)) == null
        );
        item.items = filteredEach;
      });
      setFilteredItems(filterForMap);
      setFilteredCopy(filterForMap);
    } else {
      setFilteredItems(groupedItems);
      setFilteredCopy(groupedItems);
    }
  }, [data]);

  useEffect(() => {
    if (isClicked) {
      inputRef.current?.focus();
    }
  }, [collapsed, inputRef]);

  const canViewNotifications = [
    AUTH_ROLES.AMDIN,
    AUTH_ROLES.HR,
    AUTH_ROLES.EMPLOYEE,
    AUTH_ROLES.PAYROLL_MANAGER,
  ].includes(authUserRole as AUTH_ROLES);

  return (
    <StyledSiderbarMenu>
      {collapsed && (
        <StyledSidebarMenuItem collapsed={collapsed} onClick={onSearchClick}>
          <Tooltip placement="right" title={t('search')}>
            <span>
              <SearchIcon />
            </span>
          </Tooltip>
        </StyledSidebarMenuItem>
      )}
      {!collapsed && (
        <SidebarSearch onSearch={onItemSearch} elementRef={inputRef} />
      )}
      {canViewNotifications && (
        <CustomItemComponent
          filterValue={filterValue}
          wrappedItemName={t('Notifications')}
        >
          <NotificationsComponent collapsed={collapsed} />
        </CustomItemComponent>
      )}

      {items.map((item) => {
        if (
          isSuperAdmin ||
          (item.feature && featureList.includes(item.feature)) ||
          staticFeatures.includes(item.feature as FeatureFlagsList)
        ) {
          return (
            <span key={item.name}>
              <StyledSidebarMenuItem
                collapsed={collapsed}
                onClick={() => onItemClick(item)}
                active={isActivePath({ items: [item] })}
              >
                {collapsed ? (
                  <Tooltip placement="right" title={t('Dashboard')}>
                    <span>{item.icon}</span>
                  </Tooltip>
                ) : (
                  item.icon
                )}
                {!collapsed && (
                  <StyledSidebarMenuText>{t(item.name)}</StyledSidebarMenuText>
                )}
              </StyledSidebarMenuItem>
              <StyledSiderDivider />
            </span>
          );
        }

        return null;
      })}

      {filteredItems?.map((groupedItem) => {
        if (
          isSuperAdmin ||
          (groupedItem.feature && featureList.includes(groupedItem.feature)) ||
          staticFeatures.includes(groupedItem.feature as FeatureFlagsList)
        ) {
          return (
            <StyledSiderbarMenuGroupItem key={t(groupedItem.title)}>
              <SiderbarSubMenu
                active={isActivePath({
                  items: groupedItem.items,
                  grouped: true,
                })}
                title={t(groupedItem.title)}
                collapsed={collapsed}
                setCollapsed={setCollapsed}
              >
                {groupedItem.items.map((item) => {
                  if (
                    isSuperAdmin ||
                    (item.feature && featureList.includes(item.feature)) ||
                    staticFeatures.includes(item.feature as FeatureFlagsList)
                  ) {
                    return (
                      <StyledSidebarSubMenuItem
                        key={item.name}
                        collapsed={collapsed}
                        onClick={() => onItemClick(item)}
                        $active={isActivePath({ items: [item], grouped: true })}
                      >
                        {collapsed ? (
                          <Tooltip placement="right" title={t(item.name)}>
                            <span>{item.icon}</span>
                          </Tooltip>
                        ) : (
                          item.icon
                        )}
                        {!collapsed && (
                          <StyledSidebarMenuText>
                            {t(item.name)}
                          </StyledSidebarMenuText>
                        )}
                      </StyledSidebarSubMenuItem>
                    );
                  }

                  return null;
                })}
              </SiderbarSubMenu>
              <StyledSiderDivider />
            </StyledSiderbarMenuGroupItem>
          );
        }
        return null;
      })}
    </StyledSiderbarMenu>
  );
};

export default SidebarMenu;
