import React from 'react';
import * as Styled from 'ui-v2/components/DataGridComponents';
import i18next from 'i18next';
import PurchaseOrderAction from 'components/Actions/PurchaseOrderAction';
import { PurchaseOrderType } from 'types/PurchaseOrder';

export const PurchaseOrderColumnsMobile = [
  {
    dataIndex: 'purchaseOrder',
    key: 'purchaseOrder',
    render: (_: string, item: PurchaseOrderType) => (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '1rem',
        }}
      >
        <div>
          <strong>{`${i18next.t('item')}: `}</strong>
          {item?.item}
        </div>

        <div>
          <strong>{`${i18next.t('purchaseOrderNumber')}: `}</strong>
          {item?.purchaseOrderNumber}
        </div>

        <div>
          <strong>{`${i18next.t('description')}: `}</strong>
          {item?.description}
        </div>

        <div>
          <strong>{`${i18next.t('quantity')}: `}</strong>
          {item?.quantity}
        </div>

        <div>
          <strong>{`${i18next.t('totalPrice')}: `}</strong>
          {`${item?.currency?.symbol}${item?.totalPrice}`}
        </div>

        <div>
          <strong>{`${i18next.t('status')}: `}</strong>
          {item?.status}
        </div>

        <div>
          <PurchaseOrderAction id={item?.id ?? ''} />
        </div>
      </div>
    ),
    default: true,
    order: 0,
    width: 200,
  },
];

export const PurchaseOrdersColumns = [
  {
    title: i18next.t('item'),
    dataIndex: 'item',
    key: 'item',
    ellipsis: true,
    sorter: (a: any, b: any) => a.item.localeCompare(b.item),
    render: (_: string, item: PurchaseOrderType) => (
      <Styled.TextBold>{item?.item}</Styled.TextBold>
    ),
    default: true,
    order: 0,
  },
  {
    title: i18next.t('purchaseOrderNumber'),
    dataIndex: 'purchaseOrderNumber',
    key: 'purchaseOrderNumber',
    ellipsis: true,
    sorter: (a: any, b: any) => a.item.localeCompare(b.item),
    render: (_: string, item: PurchaseOrderType) => (
      <Styled.TextBold>{item?.purchaseOrderNumber}</Styled.TextBold>
    ),
    default: true,
    order: 0,
  },
  {
    title: i18next.t('description'),
    dataIndex: 'description',
    key: 'description',
    ellipsis: true,
    sorter: (a: any, b: any) => a.item.localeCompare(b.item),
    render: (_: string, item: PurchaseOrderType) => (
      <Styled.TextBold>{item?.description}</Styled.TextBold>
    ),
    default: true,
    order: 1,
  },
  {
    title: i18next.t('quantity'),
    dataIndex: 'quantity',
    key: 'quantity',
    ellipsis: true,
    sorter: (a: any, b: any) => a.item.localeCompare(b.item),
    render: (_: string, item: PurchaseOrderType) => (
      <Styled.TextBold>{item?.quantity}</Styled.TextBold>
    ),
    default: true,
    order: 2,
  },
  {
    title: i18next.t('totalPrice'),
    dataIndex: 'totalPrice',
    key: 'totalPrice',
    render: (_: string, item: PurchaseOrderType) => (
      <Styled.TextBold>{`${item?.currency?.symbol}${item?.totalPrice}`}</Styled.TextBold>
    ),
    default: false,
    order: 3,
  },
  {
    title: i18next.t('status'),
    dataIndex: 'status',
    key: 'status',
    render: (_: string, item: PurchaseOrderType) => (
      <Styled.TextBold>{`${item?.status}`}</Styled.TextBold>
    ),
    default: false,
    order: 4,
  },
  {
    title: i18next.t('action'),
    dataIndex: 'action',
    key: 'action',
    width: 120,
    render: (_: string, row: any) => <PurchaseOrderAction id={row.id} />,
    default: false,
    order: 5,
  },
];
