import DataGrid from 'components/common/DataGrid/DataGrid';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Content from 'ui-v2/components/Content';
import useHeader from 'ui-v2/hooks/useHeader';
import { fetchCheckins } from 'redux/checkin/actions';
import { useCheckinData } from 'ui-v2/hooks/useCheckinData';
import { CheckinColumns } from 'table-configuration/CheckinConfig';

export default function CheckinList({ employeeId }: { employeeId?: string }) {
  const { take } = useHeader();
  const { list, loading, pagination } = useCheckinData();
  const dispatch = useDispatch();

  useEffect(() => {
    const params = {
      pageOptions: { page: 1, take: 10 },
    };
    dispatch(fetchCheckins(params, employeeId));
  }, []);

  const onPageChange = (page: number) => {
    const params = {
      pageOptions: { page, take },
    };
    dispatch(fetchCheckins(params, employeeId));
  };

  return (
    <Content position="stretch">
      <>
        <DataGrid
          loading={loading}
          columns={CheckinColumns}
          data={list?.data ?? []}
          paginationName="Checkin"
          rowSelectionType="checkbox"
          totalItems={pagination?.itemCount}
          currentPage={pagination?.page}
          totalPages={pagination?.pageCount}
          onPageChange={onPageChange}
        />
      </>
    </Content>
  );
}
