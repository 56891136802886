import { Col, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import CardItem from 'ui-v2/components/Card';
import Content from 'ui-v2/components/Content';
import * as Styled from 'ui-v2/components/PersonalInfo/PersonalInfoStyles';
import { useSearchParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useVendorCategoriesData } from 'ui-v2/hooks/useVendorCategoriesData';
import VendorCategoryForm from 'components/NewForms/VendorCategoryForm/VendorCategoryForm';
import { fetchSingleVendorCategory } from 'redux/vendorCategories/actions';
import { Item } from 'ui-v2/components/PersonalInfo';
import { useScreenSize } from 'ui-v2/hooks/useScreenSize';

export default function VendorCategoryDetails() {
  const { vendorCategory } = useVendorCategoriesData();
  const [modalVisible, setModalVisible] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const { t } = useTranslation();
  const { isMobile } = useScreenSize();

  const dispatch = useDispatch();
  const id = searchParams.get('id') as string;

  useEffect(() => {
    dispatch(fetchSingleVendorCategory(id));
  }, [id]);

  useEffect(() => {
    if (searchParams.get('edit-vendor-category')) {
      setModalVisible(true);
      searchParams.delete('edit-vendor-category');
      setSearchParams(searchParams);
    }
  }, [searchParams]);

  if (!vendorCategory) return null;

  return (
    <>
      <Content position={isMobile ? 'flexcol' : 'grid'}>
        <>
          <>
            <CardItem>
              <Row gutter={[0, 16]}>
                <Styled.RowItem gutter={[0, 10]}>
                  <Col md={{ span: 12 }} sm={{ span: 24 }}>
                    <Item
                      label={t('categoryName')}
                      value={vendorCategory?.categoryName}
                    />
                  </Col>
                </Styled.RowItem>
                <Styled.RowItem gutter={[0, 10]}>
                  <Col md={{ span: 12 }} sm={{ span: 24 }}>
                    <Item
                      label={t('description')}
                      value={vendorCategory?.description}
                    />
                  </Col>
                </Styled.RowItem>
              </Row>
            </CardItem>
          </>
          <></>
        </>
      </Content>

      {modalVisible && (
        <VendorCategoryForm
          open={modalVisible}
          closeModal={() => {
            setModalVisible(false);
            setSearchParams(searchParams);
          }}
          selectedVendorCategory={vendorCategory}
        />
      )}
    </>
  );
}
