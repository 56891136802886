import { CreateHierarchy } from 'types/Hierarchy';
import agent from './agent';
import { apiURLs } from './constants';

export const getAllNodes = () => agent.get(`${apiURLs.tenantHierarchy}`);
export const getAllEdges = () => agent.get(`${apiURLs.tenantHierarchy}/edges`);

export const createNewHierarchy = (values: CreateHierarchy) =>
  agent.post(`${apiURLs.tenantHierarchy}`, values);

export const delteHierarchy = () => agent.delete(`${apiURLs.tenantHierarchy}`);
