/* eslint-disable react/no-children-prop */
import {
  getDepartmentEmployees,
  getWorkPositionEmployees,
} from 'api/departmentService';
import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { EmployeeType } from 'types/Employee';
import { WorkPositiontModelType } from 'types/WorkPosition';
import Content from 'ui-v2/components/Content';
import { useScreenSize } from 'ui-v2/hooks/useScreenSize';
import DepartmentForm from 'components/NewForms/DepartmentForm/DepartmentForm';
import { useTranslation } from 'react-i18next';
import DepartmentWorkPositions from './DepartmentWorkPositions';
import EmployeeList from './EmployeeList';
import DepartmentHead from './DepartmentHead';

export default function DepartmentDetails() {
  const [searchParams, setSearchParams] = useSearchParams();
  const departmentId = searchParams.get('id');
  const [employeeList, setEmployeeList] = useState<EmployeeType[]>([]);
  const [selectedWorkPosition, setSelectedWorkPosition] =
    useState<WorkPositiontModelType>();
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const { t } = useTranslation();
  const { isMobile } = useScreenSize();

  async function fetchWorkPositionEmployees(
    departmentId: string,
    workPositionId: string
  ) {
    if (!departmentId || !workPositionId) return;
    getWorkPositionEmployees({ departmentId, workPositionId })
      .then((response) => {
        setEmployeeList(response.data);
      })
      .catch(() => {
        toast.error("Something went wrong! Can't get work position employees!");
      })
      .finally(() => {
        console.log();
      });
  }

  async function fetchDepartmentEmployees(departmentId: string | null) {
    if (!departmentId) return;
    getDepartmentEmployees(departmentId)
      .then((response) => {
        setEmployeeList(response.data);
      })
      .catch(() => {
        toast.error("Something went wrong! Can't get department employees!");
      })
      .finally(() => {
        console.log();
      });
  }

  useEffect(() => {
    if (searchParams.get('edit-department') !== null) {
      setModalVisible(true);
      searchParams.delete('edit-department');
      setSearchParams(searchParams);
    }
  }, [searchParams]);

  useEffect(() => {
    if (departmentId && selectedWorkPosition?.id) {
      fetchWorkPositionEmployees(departmentId, selectedWorkPosition.id);
      return;
    }
    fetchDepartmentEmployees(departmentId);
  }, [selectedWorkPosition, departmentId]);

  return (
    <>
      <Content position="stretch">
        <Content position={isMobile ? 'flexcol' : 'grid'}>
          <>
            <DepartmentWorkPositions
              onRowClick={setSelectedWorkPosition}
              departmentId={departmentId}
            />
            <>
              <DepartmentHead />
              <EmployeeList
                list={employeeList}
                title={`${
                  selectedWorkPosition
                    ? `${t('employeesFor')} ${selectedWorkPosition.name}`
                    : t('employeesForThisDepartment')
                }`}
              />
            </>
          </>
        </Content>
      </Content>
      {modalVisible && (
        <DepartmentForm
          open={modalVisible}
          closeModal={() => {
            setModalVisible(false);
            searchParams.delete('edit-department');
            setSearchParams(searchParams);
          }}
        />
      )}
    </>
  );
}
