import { Col, Form, Row } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import GenericModal from 'ui-v2/components/GenericModal';
import { toastErrorMessages } from 'utils/utilFunctions';
import GenericForm from 'components/NewForms/Form/GenericForm';
import { FormItemStyled, StyledButton } from 'components/NewForms/FormStyled';
import { useTranslation } from 'react-i18next';
import { ScheduleInterviewModalIcon } from 'Icons/ScheduleInterviewModalIcon';
import { InputTypes } from 'types/FormTypes';
import useHeader from 'ui-v2/hooks/useHeader';
import { CreateIdeabox } from 'types/Ideabox';
import useIdeaboxData from 'ui-v2/hooks/useIdeaboxData';
import { createIdeabox, updateIdeabox } from 'api/ideaboxService';
import { fetchIdeabox, fetchSingleIdeabox } from 'redux/ideabox/actions';
import { EmployeeType } from 'types/Employee';
import { AuthUserState } from 'redux/authUser/types';
import { RootState } from 'redux/store';
import { fetchEmployeeOptions } from 'components/NewForms/SelectWithLoad/utils';
import { AUTH_ROLES } from 'types/Auth';

interface IProps {
  open: boolean;
  closeModal: () => void;
}
export default function IdeaboxModalForm({ open, closeModal }: IProps) {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState<boolean>(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const { singleIdeabox } = useIdeaboxData();

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { take } = useHeader();
  const [currentPage, setCurrentPage] = useState<number>(1);

  const { authUserRole, authUser }: AuthUserState = useSelector(
    (state: RootState) => state.authUser
  );

  const canEdit: boolean =
    authUserRole === AUTH_ROLES.AMDIN || authUserRole === AUTH_ROLES.HR
      ? true
      : singleIdeabox?.employee?.id === authUser?.employee?.id;

  useEffect(() => {
    if (searchParams.get('edit-ideabox')) {
      dispatch(fetchSingleIdeabox(searchParams.get('edit-ideabox') as string));
    }
  }, [searchParams]);

  function onFormFinish(values: CreateIdeabox) {
    const valuesToSend: CreateIdeabox = {
      ...values,
      tags: values?.tags?.map((emp: any) => emp?.value),
      private: values?.private || false,
    };
    setLoading(true);

    if (!valuesToSend.tags?.length) {
      delete valuesToSend.tags;
    }

    if (singleIdeabox?.id) {
      updateIdeabox(singleIdeabox?.id, valuesToSend)
        .then((res) => {
          if (res.status === 200) {
            toast.success(t('ideaboxUpdatedSuccessfully'));
            dispatch(
              fetchIdeabox({ pageOptions: { page: currentPage, take } })
            );
            dispatch(fetchSingleIdeabox(searchParams.get('id') as string));

            closeModal();
          }
        })
        .catch((error) => {
          toastErrorMessages(error);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      createIdeabox(valuesToSend)
        .then((res) => {
          if (res.status === 200) {
            toast.success(t('ideaboxCreatedSuccessfully'));
            dispatch(
              fetchIdeabox({ pageOptions: { page: currentPage, take } })
            );
            closeModal();
          }
        })
        .catch(() => {
          toast.error(t('failedToCreateIdeabox'));
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }

  useEffect(() => {
    if (searchParams.get('add-ideabox')) {
      searchParams.delete('add-ideabox');
      setSearchParams(searchParams);
    }

    if (searchParams.get('edit-ideabox')) {
      searchParams.delete('edit-ideabox');
      setSearchParams(searchParams);
    }

    setCurrentPage(
      searchParams.get('page')
        ? parseInt(searchParams.get('page') as string, 10)
        : 1
    );
  }, [searchParams, form, singleIdeabox]);

  const IdeaboxFormConfiguration: any[][] = useMemo(
    () => [
      [
        {
          col: 11,
          offset: 0,
          name: 'ideaText',
          label: t('ideaText'),
          type: InputTypes.INPUT,
          defaultValue: singleIdeabox?.ideaText,
          required: true,
          rules: [
            {
              required: true,
              message: t('ideaTextIsRequired'),
            },
          ],
        },
        {
          col: 7,
          offset: 6,
          name: 'private',
          label: t('private'),
          type: InputTypes.SWITCH,
          defaultChecked: singleIdeabox?.private,
        },
      ],
      [
        {
          col: 24,
          offset: 0,
          name: 'tags',
          label: t('tags'),
          type: InputTypes.SELECT_LOAD,
          mode: 'multiple',
          defaultValue:
            form.getFieldValue('tags') ||
            singleIdeabox?.tags?.map((emp: EmployeeType) => ({
              id: emp?.id,
              label: `${emp?.firstName} ${emp?.lastName}`,
              value: emp?.id,
            })),
          fetchOptions: fetchEmployeeOptions,
        },
      ],
    ],
    [singleIdeabox]
  );

  return (
    <GenericModal
      title={singleIdeabox?.id ? t('editIdeabox') : t('addIdeabox')}
      open={open}
      closeModal={closeModal}
      icon={<ScheduleInterviewModalIcon />}
    >
      <GenericForm
        formConfiguration={IdeaboxFormConfiguration}
        onFinish={onFormFinish}
        form={form}
        loading={loading}
      >
        <FormItemStyled style={{ marginTop: 30, marginBottom: 0 }}>
          <Row>
            <Col span={11}>
              <StyledButton
                onClick={() => {
                  closeModal();
                }}
                htmlType="reset"
                danger
              >
                {t('cancel')}
              </StyledButton>
            </Col>
            {(canEdit || !singleIdeabox?.id) && (
              <Col span={11} offset={2}>
                <StyledButton
                  onClick={() => form.submit()}
                  type="primary"
                  htmlType="submit"
                  loading={loading}
                >
                  {t('submit')}
                </StyledButton>
              </Col>
            )}
          </Row>
        </FormItemStyled>
      </GenericForm>
    </GenericModal>
  );
}
