import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Row, Col } from 'antd';

import { useEmployeesData } from 'ui-v2/hooks/useEmployeesData';
import useHeader from 'ui-v2/hooks/useHeader';
import { useCandidateData } from 'ui-v2/hooks/useCandidateData';
import { fetchCandidate } from 'redux/candidates/actions';
import { useTranslation } from 'react-i18next';
import { useScreenSize } from 'ui-v2/hooks/useScreenSize';
import CustomButton from '../Buttons';
import {
  actionsMap as allActions,
  ActionItem,
  messages,
  getProfileId,
} from './config';
import filterByRole from './roles';
import * as Styled from './HeaderActionsStyles';

export default function HeaderActions() {
  const { data } = useSelector((state: any) => state.authUser);
  const { entity, currentRoute, ID } = useHeader();
  const { candidate, loaded } = useCandidateData();
  const { employee } = useEmployeesData();
  const [searchParams, setSearchParams] = useSearchParams();
  const actionsMap = filterByRole(data?.roles ?? [], allActions);
  const [actions, setActions] = useState(actionsMap[entity as ActionItem]);
  const [message, setMessage] = useState('');
  const { isMobile } = useScreenSize();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    setActions(actionsMap[currentRoute as ActionItem]);
    setMessage('');

    if (currentRoute === 'profile' && loaded && candidate?.employeeId) {
      setActions(actionsMap['is-hired']);
      setMessage('');
    }
    if (currentRoute === 'employee-profile') {
      setActions(
        employee?.candidateId
          ? actionsMap['employee-profile']
          : [actionsMap['employee-profile'][1]]
      );
    }
  }, [currentRoute, employee]);

  useEffect(() => {
    if (searchParams.get('hire') === 'true') {
      searchParams.delete('hire');
      setSearchParams(searchParams);
      setMessage(t(messages.hire));
      setActions(actionsMap.hire);
      navigate(`hire-candidate?id=${searchParams.get('id') || getProfileId()}`);
    }

    if (searchParams.get('edit-profile') === 'true') {
      searchParams.delete('hire');
      setSearchParams(searchParams);
      setMessage(t(messages.hire));
      setActions(actionsMap.hire);
      navigate(`edit-profile?id=${searchParams.get('id') || getProfileId()}`);
    }

    if (searchParams.get('disqualify') === 'true') {
      searchParams.delete('disqualify');
      setSearchParams(searchParams);
      setMessage(t(messages.disqualify));
      setActions(actionsMap.disqualify);
    }

    if (searchParams.get('candidate-rejected') === 'true') {
      searchParams.delete('candidate-rejected');
      setSearchParams(searchParams);
      dispatch(
        fetchCandidate(String(searchParams.get('id') || getProfileId()))
      );
    }
  }, [searchParams]);

  const isCandidateHired = !!candidate?.employeeId;

  return (
    <Row gutter={[24, 0]} justify={!isMobile ? 'end' : 'start'} align="middle">
      <Col>
        <Styled.Message>{message}</Styled.Message>
      </Col>
      <Col>
        <Styled.StyledRow
          gutter={[10, 10]}
          justify={!isMobile ? 'end' : 'start'}
        >
          {actions?.map((action: any) => (
            <Col key={action.label} span={isMobile ? 24 : undefined}>
              <CustomButton
                {...action}
                onClick={() =>
                  dispatch(
                    action.onClick(
                      setSearchParams,
                      setActions,
                      setMessage,
                      ID,
                      employee?.candidateId
                    )
                  )
                }
                disabled={isCandidateHired}
              />
            </Col>
          ))}
        </Styled.StyledRow>
      </Col>
    </Row>
  );
}
