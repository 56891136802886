import { removeCandidate } from 'api/candidateService';
import DataGrid from 'components/common/DataGrid/DataGrid';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { fetchCandidates } from 'redux/candidates/actions';
import { RootState } from 'redux/store';

import Content from 'ui-v2/components/Content';
import { useCandidateData } from 'ui-v2/hooks/useCandidateData';
import useHeader from 'ui-v2/hooks/useHeader';
import { toastErrorMessages } from 'utils/utilFunctions';

export default function CandidatesList() {
  const { columns, take } = useHeader();
  const { list, loading, pagination } = useCandidateData();

  const { loading: candidatesLoading } = useSelector(
    (state: RootState) => state.candidates
  );
  const [searchTerm, setSearchTerm] = useState<string>();

  const dispatch = useDispatch();

  async function deleteCandidateById(id: string | null, showToast = true) {
    if (!id) return;

    try {
      await removeCandidate(id);
      if (showToast) {
        toast.success('Candidate deleted successfully!');
      }
      dispatch(fetchCandidates({ page: pagination?.page, take }));
    } catch (error) {
      toastErrorMessages(error);
    }
  }

  useEffect(() => {
    if (!candidatesLoading) {
      dispatch(fetchCandidates({ page: 1, take }));
    }
  }, [take]);

  const onPageChange = (page: number) => {
    const params = {
      page,
      take,
      ...(searchTerm && { name: searchTerm }),
    };
    dispatch(fetchCandidates(params));
  };

  const onSearch = (searchTerm: string) => {
    const params = {
      page: 1,
      ...(searchTerm && { filterText: searchTerm }),
    };
    dispatch(fetchCandidates(params));
    setSearchTerm(searchTerm);
  };

  async function onDelete(ids: React.Key[]) {
    try {
      await Promise.all(
        ids.map((id) => deleteCandidateById(id?.toString(), false))
      );
      toast.success('Candidates deleted successfully!');
    } catch (error) {
      toastErrorMessages(error);
    }
  }

  return (
    <Content position="stretch">
      <DataGrid
        loading={loading}
        columns={columns}
        data={list}
        paginationName="candidates"
        rowSelectionType="checkbox"
        totalItems={pagination.itemCount}
        currentPage={pagination.page}
        totalPages={pagination.pageCount}
        onSelectedDelete={onDelete}
        onPageChange={onPageChange}
        showSearch
        onSearch={onSearch}
      />
    </Content>
  );
}
