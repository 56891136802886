import React from 'react';
import { Space } from 'antd';
import { CalendarOutlined } from '@ant-design/icons';

import {
  InterviewType,
  InterviewTypeModel,
  InterviewCandidateOpenPositionType,
  InterviewResponseType,
} from 'types/Interview';
import InterviewActions from 'components/Actions/InterviewActions';
import { convertUTCtoFormatedLocalTime } from 'utils/utilFunctions';
import * as Styled from 'ui-v2/components/DataGridComponents';
import { Link } from 'react-router-dom';
import i18next from '../i18n';

export const InterviewsColumnsMobile = [
  {
    dataIndex: 'interview',
    key: 'interview',
    render: (_: string, item: InterviewType & InterviewResponseType) => (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '1rem',
        }}
      >
        <div>
          <strong>{`${i18next.t('date')}: `}</strong>
          {convertUTCtoFormatedLocalTime(item?.date)}
        </div>

        <div>
          <strong>{`${i18next.t('Candidate')}: `}</strong>
          <Link
            to={`/candidates/profile?id=${item?.candidateOpenPositions?.candidate?.id}`}
          >
            <Styled.LinkText>
              {item &&
                `${item?.candidateOpenPositions?.candidate?.firstName ?? '-'} ${
                  item?.candidateOpenPositions?.candidate?.lastName ?? '-'
                }`}
              {console.log(item, 222)}
            </Styled.LinkText>
          </Link>
        </div>
        <div>
          <strong>{`${i18next.t('interviewType')}: `}</strong>
          {item?.interviewType?.name}
        </div>
        <div>
          <strong>{`${i18next.t('notes')}: `}</strong>
          {item?.comments ?? '-'}
        </div>
        <div>
          <InterviewActions
            id={item.id}
            isHired={!item?.candidateOpenPositions?.candidate?.id}
          />
        </div>
      </div>
    ),
    default: true,
    order: 0,
    width: 200,
  },
];

export const InterviewsColumns = [
  {
    title: i18next.t('date'),
    dataIndex: 'date',
    key: 'date',
    sorter: (a: InterviewType, b: InterviewType) =>
      convertUTCtoFormatedLocalTime(a?.date).localeCompare(
        convertUTCtoFormatedLocalTime(b?.date)
      ),
    render: (record: Date) => (
      <Space>
        <CalendarOutlined />
        <Styled.TextBold>
          {convertUTCtoFormatedLocalTime(record)}
        </Styled.TextBold>
      </Space>
    ),
    default: true,
    order: 0,
  },
  {
    title: i18next.t('Candidate'),
    dataIndex: 'candidateOpenPositions',
    key: 'candidateOpenPositions',
    render: (record: InterviewCandidateOpenPositionType) => (
      <Link to={`/candidates/profile?id=${record?.candidate?.id}`}>
        <Styled.LinkText>
          {record &&
            `${record?.candidate?.firstName ?? '-'} ${
              record?.candidate?.lastName ?? '-'
            }`}
        </Styled.LinkText>
      </Link>
    ),
    default: true,
    order: 1,
  },
  {
    title: i18next.t('interviewType'),
    dataIndex: 'interviewType',
    key: 'interviewType',
    render: (record: InterviewTypeModel) => (
      <Styled.Text>{record?.name ?? ''}</Styled.Text>
    ),
    default: true,
    order: 2,
  },
  {
    title: i18next.t('notes'),
    dataIndex: 'comments',
    key: 'comments',
    ellipsis: true,
    render: (record: string) => <Styled.Text>{record ?? ''}</Styled.Text>,
    default: false,
    order: 3,
  },
  {
    title: i18next.t('action'),
    key: 'action',
    width: 120,
    render: (_: string, row: InterviewResponseType) => (
      <InterviewActions
        id={row.id}
        isHired={!row?.candidateOpenPositions?.candidate?.id}
      />
    ),
    order: 4,
  },
];
