import moment from 'moment';

export enum TRACKING_DATE_FORMAT_TYPE {
  SEPARATOR = '~',
  POPOVER_OPEN = 'DD/MM/YYYY',
  POPOVER_CLOSE = 'MMM DD, YYYY',
  URL = 'YYYY-MM-DD',
}

export const TrackingDateFilterPopoverInnerOverlayStyle = {
  width: '500px',
  borderRadius: '8px',
  padding: '0px',
  boxShadow:
    '0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03)',
  border: '1px solid #EAECF0',
};

export const TrackingDateFilterPopoverInnerOverlayStyleMobile = {
  width: '200px',
  borderRadius: '8px',
  padding: '0px',
};

export enum TrackingPopoverLabel {
  TODAY = 'Today',
  YESTERDAY = 'Yesterday',
  THIS_WEEK = 'This Week',
  PAST_TWO_WEEKS = 'Past two week',
  THIS_MONTH = 'This month',
  LAST_MONTH = 'Last month',
  THIS_YEAR = 'This year',
  LAST_YEAR = 'Last year',
}
export const getFormatedDate = (momentDate: moment.Moment) =>
  momentDate.format('YYYY-MM-DD');

export const TRACKING_DATE_POPOVER_DEFAULT_OPTIONS = [
  {
    id: TrackingPopoverLabel.TODAY,
    label: TrackingPopoverLabel.TODAY,
    start: getFormatedDate(moment()),
    end: getFormatedDate(moment()),
  },
  {
    id: TrackingPopoverLabel.YESTERDAY,
    label: TrackingPopoverLabel.YESTERDAY,
    start: getFormatedDate(moment().subtract(1, 'day')),
    end: getFormatedDate(moment().subtract(1, 'day')),
  },
  {
    id: TrackingPopoverLabel.THIS_WEEK,
    label: TrackingPopoverLabel.THIS_WEEK,
    start: getFormatedDate(moment().startOf('isoWeek')),
    end: getFormatedDate(moment().endOf('isoWeek')),
  },
  {
    id: TrackingPopoverLabel.PAST_TWO_WEEKS,
    label: TrackingPopoverLabel.PAST_TWO_WEEKS,
    start: getFormatedDate(moment().subtract(2, 'weeks').startOf('isoWeek')),
    end: getFormatedDate(moment().startOf('isoWeek')),
  },
  {
    id: TrackingPopoverLabel.THIS_MONTH,
    label: TrackingPopoverLabel.THIS_MONTH,
    start: getFormatedDate(moment().startOf('month')),
    end: getFormatedDate(moment().endOf('month')),
  },
  {
    id: TrackingPopoverLabel.LAST_MONTH,
    label: TrackingPopoverLabel.LAST_MONTH,
    start: getFormatedDate(moment().subtract(1, 'month').startOf('month')),
    end: getFormatedDate(moment().subtract(1, 'month').endOf('month')),
  },
  {
    id: TrackingPopoverLabel.THIS_YEAR,
    label: TrackingPopoverLabel.THIS_YEAR,
    start: getFormatedDate(moment().startOf('year')),
    end: getFormatedDate(moment().endOf('year')),
  },
  {
    id: TrackingPopoverLabel.LAST_YEAR,
    label: TrackingPopoverLabel.LAST_YEAR,
    start: getFormatedDate(moment().subtract(1, 'year').startOf('year')),
    end: getFormatedDate(moment().subtract(1, 'year').endOf('year')),
  },
];

export const getTrackingDatePopoverLabel = (start?: string, end?: string) =>
  TRACKING_DATE_POPOVER_DEFAULT_OPTIONS.find(
    (opt) => opt.start === start && opt.end === end
  )?.label as string;

export const getTrackingPopoverFormatedDate = ({
  open,
  urlStart,
  urlEnd,
}: {
  open?: boolean;
  urlStart?: string;
  urlEnd?: string;
}) => {
  if (!urlStart && !urlEnd) {
    return '';
  }
  const label = getTrackingDatePopoverLabel(urlStart, urlEnd);
  if (label && !open) {
    return label;
  }
  if (!open) {
    return `${moment(urlStart)?.format(
      TRACKING_DATE_FORMAT_TYPE.POPOVER_CLOSE
    )} ${TRACKING_DATE_FORMAT_TYPE.SEPARATOR} ${moment(urlEnd)?.format(
      TRACKING_DATE_FORMAT_TYPE.POPOVER_CLOSE
    )}`;
  }
  return `${moment(urlStart)?.format(TRACKING_DATE_FORMAT_TYPE.POPOVER_OPEN)} ${
    TRACKING_DATE_FORMAT_TYPE.SEPARATOR
  } ${moment(urlEnd)?.format(TRACKING_DATE_FORMAT_TYPE.POPOVER_OPEN)}`;
};

export const compareTrackingDates = (date1: string, date2: string) => {
  if (
    moment(date1).format(TRACKING_DATE_FORMAT_TYPE.POPOVER_CLOSE) ===
    moment(date2).format(TRACKING_DATE_FORMAT_TYPE.POPOVER_CLOSE)
  ) {
    return true;
  }
  return false;
};

export const getTrackingDatePopoverDefaultRangeOptions = () =>
  TRACKING_DATE_POPOVER_DEFAULT_OPTIONS;
