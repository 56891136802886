import styled from 'styled-components';
import { Col, Row, Typography } from 'antd';

export const StyledTrackingDateFilterRow = styled(Row)(({ theme }) => ({
  borderRadius: '4px',
  border: `1px solid ${theme.colors.palette.primary.gray['300']}`,
  display: 'grid',
  gridTemplateColumns: '80% 20%',
}));

export const StyledTrackingDateFilterDateLabel = styled(Typography.Text)(
  ({ theme }) => ({
    ...theme.typography.body.medium.sm,
    color: theme.colors.palette.primary.gray[600],
  })
);

export const StyledTrackingDateFilterPopoverDefaultRangeWrapper = styled(Col)<{
  isPhoneScreen?: boolean;
}>(({ theme, isPhoneScreen }) => ({
  borderRight: !isPhoneScreen
    ? `1px solid ${theme.colors.palette.primary.gray['300']}`
    : 'none',
}));

export const StyledTrackingDateFilterPopoverLabel = styled(Col)<{
  active?: boolean;
}>(({ active, theme }) => ({
  padding: '0.625rem',
  cursor: 'pointer',
  borderTopLeftRadius: '4px',
  borderBottomLeftRadius: '4px',
  ':hover': {
    color: theme.colors.palette.primary.blue['500'],
  },
  ...(active && {
    backgroundColor: theme.colors.palette.primary.blue['800'],
    color: theme.colors.palette.base.white,
    ':hover': {
      color: theme.colors.palette.base.white,
    },
  }),
}));

export const StyledTrackingDateFilterIcon = styled.span(() => ({
  opacity: '0.6',
}));

export const StyledTrackingDateFilterDateLabelCol = styled(Col)(
  ({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    gap: '.5rem',
    ':hover': {
      backgroundColor: theme.colors.palette.primary.gray['200'],
    },
    cursor: 'pointer',
    padding: '0.625rem',
    borderTopLeftRadius: '4px',
    borderBottomLeftRadius: '4px',
    borderRight: `1px solid ${theme.colors.palette.primary.gray['300']}`,
  })
);

export const StyledTrackingDateFilterDateActionWrapper = styled(Col)(() => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(2, 1fr)',
  height: '100%',
}));

export const StyledTrackingDateFilterDateActionItem = styled.div<{
  bordered?: boolean;
  dividedRight?: boolean;
}>(({ dividedRight, bordered, theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  ...(dividedRight && {
    borderRight: `1px solid ${theme.colors.palette.primary.gray['300']}`,
  }),
  ...(bordered && {
    borderTopRightRadius: '4px',
    borderBottomRightRadius: '4px',
  }),
  cursor: 'pointer',
  ':hover': {
    backgroundColor: theme.colors.palette.primary.gray['200'],
  },
}));
