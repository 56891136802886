/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable prettier/prettier */
import React, { useState } from 'react';
import { Avatar, Button, Col, List, Popconfirm, Row, Skeleton } from 'antd';

import { EmployeeRequest, RespondToRequest } from 'types/Request';
import CardItem from 'ui-v2/components/Card';
import GenericModal from 'ui-v2/components/GenericModal';
import { StyledButton } from 'components/NewForms/FormStyled';
import { respondToRequest } from 'api/request';
import { toastErrorMessages } from 'utils/utilFunctions';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAllRequests } from 'redux/requests/actions';
import { useTranslation } from 'react-i18next';
import { AuthUserState } from 'redux/authUser/types';
import { RootState } from 'redux/store';
import { AssetRequestStatus } from 'types/Asset';
import * as Styled from './EmployeeRequestsListStyles';
import EmployeeRequestModal from './EmployeeRequestModal';
import { getEntityName, RequestTab } from '../Requests/Requests';

interface IProps {
  requests: EmployeeRequest[];
  loading: boolean;
  children?: any;
  activeTab: RequestTab;
}

export default function EmployeeRequestList({
  requests,
  loading,
  children,
  activeTab,
}: IProps) {
  const auth = useSelector((state: RootState) => state.authUser);

  const [selectedRequest, setSelectedRequest] = useState<EmployeeRequest | null>(null);
  const [modalOpen, setModalOpen] = useState(false);
  const dispatch = useDispatch();

  const employeeId = (auth as AuthUserState)?.authUser?.employee?.id as string;

  const handleRequestClick = (request: EmployeeRequest) => {
    setSelectedRequest(request);
  };

  const handleModalClose = () => {
    setSelectedRequest(null);
  };

  const onSubmit = async (vote: boolean) => {
    if (selectedRequest) {
      const payload: RespondToRequest = {
        requestId: selectedRequest?.id,
        approved: vote,
      };
      try {
        const response = await respondToRequest(payload);
        if (response.status === 200) {
          if (response.data.approved === 'true') {
            toast.success('Succesfully rejected request');
          } else toast.success('Succesfully approved request');
          setSelectedRequest(null);
          dispatch(fetchAllRequests());
        }
      } catch (error) {
        toastErrorMessages(error);
      }
    }
  };

  const onApproveRequest = () => {
    onSubmit(true);
  };

  const onCancelRequest = () => {
    onSubmit(false);
  };

  const { t } = useTranslation();

  return (
    <>
      {children || (
        <CardItem title={t('requests')} rightButtonContent={3} rightButton={<Button onClick={() => setModalOpen(true)}>Make a request</Button>} wrapContentHeight>
          <List
            loading={loading}
            itemLayout="horizontal"
            style={{ width: '100%' }}
            dataSource={requests}
            renderItem={(request: EmployeeRequest) => (
              <Styled.Item
                onClick={() => handleRequestClick(request)}
                actions={[
                  <Row justify="center">
                    <Styled.FixedWidthContainer>
                      <Styled.Reason ellipsis>
                        {t(request?.description).toUpperCase()}
                      </Styled.Reason>
                    </Styled.FixedWidthContainer>
                  </Row>,
                ]}
              >
                <Skeleton avatar title={false} loading={loading} active>
                  <List.Item.Meta
                    avatar={
                      <Avatar>
                        {request?.requestedBy?.firstName?.trim().charAt(0)}
                      </Avatar>
                    }
                    description={
                      <Row justify="end">
                        <Col span={12}>
                          <Styled.Description ellipsis>
                            {`${request?.requestedBy?.firstName} ${request?.requestedBy?.lastName}`}
                          </Styled.Description>
                        </Col>
                        <Col span={12}>
                          <Row justify="center">
                            <Styled.Status status={request?.asset?.requestStatus}>
                              {request?.asset?.requestStatus === AssetRequestStatus.ACCEPTED
                                ? t('approved')
                                : request?.asset?.requestStatus === AssetRequestStatus.REJECTED
                                  ? t('rejected')
                                  : t('pending')}
                            </Styled.Status>
                          </Row>
                        </Col>
                      </Row>
                    }
                  />
                </Skeleton>
              </Styled.Item>
            )}
          />
        </CardItem>
      )}
      {selectedRequest && (
        <GenericModal
          title={t('requestDetails')}
          closeModal={handleModalClose}
          open
        >
          <Row justify="space-between">
            <Col>
              <p>
                {t('requestFrom')}:{' '}
                {selectedRequest?.requestedBy?.firstName?.toUpperCase()}{' '}
                {selectedRequest?.requestedBy?.lastName?.toUpperCase()}
              </p>
            </Col>
            <Col>
              <Styled.Status status={selectedRequest?.asset?.requestStatus}>
                {selectedRequest.asset?.requestStatus === AssetRequestStatus.ACCEPTED
                  ? t('approved')
                  : selectedRequest?.asset?.requestStatus === AssetRequestStatus.REJECTED
                    ? t('rejected')
                    : t('pending')}
              </Styled.Status>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <p style={{ fontSize: 14 }}>
                {t('title')}: {t(selectedRequest?.description).toUpperCase()}
              </p>
            </Col>
            <Col span={12}>
              <p style={{ fontSize: 14 }}>
                {t('type')}: {t(selectedRequest?.entityName).toUpperCase()}
              </p>
            </Col>
          </Row>
          {selectedRequest?.asset?.requestStatus === AssetRequestStatus.PENDING && (
            <Row gutter={[16, 16]} style={{ marginTop: 20 }}>
              <Col span={5} offset={12} style={{ left: 20 }}>
                <Popconfirm
                  title={t('rejectThisRequest')}
                  okText={t('yes')}
                  cancelText={t('no')}
                  onConfirm={onCancelRequest}
                >
                  <StyledButton type="link">{t('reject')}</StyledButton>
                </Popconfirm>
              </Col>
              <Col span={6} offset={1}>
                <Popconfirm
                  title={t('approveThisRequest')}
                  okText={t('yes')}
                  cancelText={t('no')}
                  onConfirm={onApproveRequest}
                >
                  <StyledButton type="primary">{t('Approve')}</StyledButton>
                </Popconfirm>
              </Col>
            </Row>
          )}
        </GenericModal>

      )}
      <EmployeeRequestModal
        open={modalOpen}
        closeModal={() => setModalOpen(false)}
        employeeId={employeeId}
        entityName={getEntityName(activeTab)}
      />
    </>
  );
}
