import React, { useCallback, useEffect, useState } from 'react';
import { Form, Spin } from 'antd';
import { Image as ImageType } from 'types/Image';
import { useSearchParams } from 'react-router-dom';
import {
  TenantConfigDescriptionDto,
  TenantConfigUpdateDTO,
} from 'types/TenantConfig';
import { useTenantConfigData } from 'ui-v2/hooks/useTenantConfigData';
import { SettingTab } from 'types/settings';
import { useTenantsData } from 'ui-v2/hooks/useTenantsData';
import { UploadTenantDocumentsDto } from 'types/UploadTenantDocuments';
import { uploadTenantDocuments } from 'api/tenantDocuments';
import { TenantDocuments } from 'types/TenantDocumentsDto';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import TenantConfigFormContentLayout from './components/TenantConfigFormContentLayout';
import { getTenantConfigDto } from './utils';
import TenantMissionConfig from './components/TenantMissionConfig';
import TenantGeneralConfig from './components/TenantGeneralConfig';

export interface TenantOnboardingConfigProps {
  validating?: boolean;
  formValues?: (values: any) => void;
  toggleValidation?: () => void;
  hideConfigureBtn?: boolean;
}

interface TenantConfigFormContentProps {
  loading: boolean;
  onSubmit: (values: any) => void;
  tenantOnboardingConfigProps?: TenantOnboardingConfigProps;
}

export type Document = {
  name: string;
  content: string;
  type: string;
};

export type DocumentsState = {
  nda: Document | null;
  jobContract: Document | null;
  additionalDocs: Document[];
};

const TenantConfigFormContent: React.FC<TenantConfigFormContentProps> = ({
  loading,
  onSubmit,
  tenantOnboardingConfigProps,
}) => {
  const [generalConfigForm] = Form.useForm();
  const [companyDescriptionPanelForm] = Form.useForm();
  const [companyLocationPanelForm] = Form.useForm();
  const { t } = useTranslation();

  const [searchParams, setSearchParams] = useSearchParams();
  const [fileToUpload, setFileToUpload] = useState<ImageType>();
  const [documents, setDocuments] = useState<DocumentsState>({
    nda: null,
    jobContract: null,
    additionalDocs: [],
  });

  const handleDocumentChange = (data: any) => {
    setDocuments(data);
  };

  const { tenantPersisted: tenant } = useTenantsData();

  const { data: tenantConfig, loading: tenantConfLoading } =
    useTenantConfigData();

  useEffect(() => {
    if (tenantConfig?.videoUrl) {
      generalConfigForm.setFieldsValue({
        videoUrl: tenantConfig.videoUrl,
      });
    }
  }, [tenantConfig]);

  useEffect(() => {
    if (searchParams.get('settings-save') === SettingTab.CONFIGURATIONS) {
      searchParams.delete('settings-save');
      setSearchParams(searchParams);
      companyDescriptionPanelForm.submit();
      companyLocationPanelForm.submit();
      generalConfigForm.submit();
    }
  }, [searchParams]);

  useEffect(() => {
    if (tenantOnboardingConfigProps?.validating) {
      companyDescriptionPanelForm.submit();
      generalConfigForm.submit();
      tenantOnboardingConfigProps?.toggleValidation?.();
    }
  }, [tenantOnboardingConfigProps?.validating]);

  const onFinish = useCallback(
    async (formValues: any) => {
      const companyDescriptionPanelFormErrors =
        companyDescriptionPanelForm.getFieldsError();

      const hasErrors = companyDescriptionPanelFormErrors.some(
        (fieldError) => !!fieldError.errors.length
      );

      if (!tenantConfig || hasErrors) {
        return;
      }
      let valuesToSubmit: TenantConfigUpdateDTO = getTenantConfigDto({
        formValues,
        tenantConfig,
        fileToUpload,
      });

      const missionVisionValues: TenantConfigDescriptionDto =
        companyDescriptionPanelForm.getFieldsValue();

      valuesToSubmit = {
        ...valuesToSubmit,
        tenant: tenant?.id as string,
        ...(Object.values(missionVisionValues)?.length && {
          missionVisionValues: JSON.stringify([missionVisionValues]),
        }),
        videoUrl: generalConfigForm.getFieldValue('videoUrl'),
      };

      if (documents.nda) {
        documents.nda.type = 'nda';
      } else if (documents.jobContract) {
        documents.jobContract.type = 'jobContract';
      }

      const docToUpload = [
        documents.nda as Document,
        documents.jobContract as Document,

        ...(documents.additionalDocs || []),
      ];

      const tenantDocs: UploadTenantDocumentsDto = {
        tenantId: tenant?.id as string,
        documents: docToUpload as unknown as TenantDocuments,
      };

      if (documents.nda && documents.jobContract) {
        await uploadTenantDocuments(tenantDocs);
      } else {
        toast.warning(t('uploadNdaAndJobContract'));
        return;
      }

      onSubmit(valuesToSubmit);
    },
    [tenant, tenantConfig, companyDescriptionPanelForm, fileToUpload, documents]
  );

  return (
    <Spin spinning={loading || tenantConfLoading}>
      <TenantConfigFormContentLayout
        companyDescriptionPanelContent={
          <TenantMissionConfig formInstance={companyDescriptionPanelForm} />
        }
      >
        <TenantGeneralConfig
          generalConfigForm={generalConfigForm}
          companyDescriptionPanelForm={companyDescriptionPanelForm}
          documents={documents}
          handleDocumentChange={handleDocumentChange}
          onFinish={onFinish}
          setFileToUpload={setFileToUpload}
        />
      </TenantConfigFormContentLayout>
    </Spin>
  );
};

export default TenantConfigFormContent;
