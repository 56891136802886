import { Col, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import CardItem from 'ui-v2/components/Card';
import * as Styled from 'ui-v2/components/PersonalInfo/PersonalInfoStyles';
import RightSection from 'ui-v2/components/RightSection';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getAllDaysOffRules } from 'api/tenantDaysOffService';
import { LegalDaysOff } from 'types/LegalDaysOff';
import TenantDaysOffForm from './TenantDaysOffForm';
import {
  HeaderLocation,
  RowItemLocation,
} from '../TenantLocation/TenantLocationStyles';
import { TenantDaysOffItem } from './TenantDaysOffItem';

export default function TenantDaysOffList() {
  const [modalVisible, setModalVisible] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedDayOffRule, setSelectedDayOffRule] =
    useState<LegalDaysOff | null>();
  const onToggleModal = () => {
    setModalVisible((state) => !state);
    setSelectedDayOffRule(null);
  };
  const { t } = useTranslation();

  const onOpenModal = () => {
    onToggleModal();
  };
  const [daysOff, setDaysOff] = useState<LegalDaysOff[]>([]);
  const fetchDaysOff = () => {
    getAllDaysOffRules()
      .then((response) => {
        if (response.status === 200) {
          setDaysOff(response.data || []);
        }
      })
      .catch(() => {
        toast.error(
          t("Something went wrong! Can't get days off for this tenant")
        );
      });
  };

  useEffect(() => {
    fetchDaysOff();
  }, []);

  useEffect(() => {
    if (
      searchParams.get('added-days-off-rule') === 'true' ||
      searchParams.get('edited-days-off-rule') === 'true'
    ) {
      fetchDaysOff();
      searchParams.delete('added-days-off-rule');
      searchParams.delete('edited-days-off-rule');
      setSearchParams(searchParams);
    }
  }, [searchParams]);

  return (
    <>
      <>
        <CardItem
          title={t('daysOff')}
          rightButton={
            <RightSection
              buttonCallBack={() => onOpenModal()}
              buttonText={t('addDaysOffRule')}
            />
          }
        >
          <Row gutter={[0, 24]} justify="center">
            <Styled.RowItem gutter={[0, 10]} last>
              <Col
                md={{ span: 24 }}
                sm={{ span: 24 }}
                xxl={{ span: 24 }}
                xl={{ span: 24 }}
              >
                <>
                  {!daysOff?.length && (
                    <Row>{t('thereAreNoLocationsForThisTenant')}</Row>
                  )}
                </>
              </Col>
            </Styled.RowItem>

            {daysOff?.length && (
              <>
                <RowItemLocation>
                  <Col span={4}>
                    <HeaderLocation>{t('Number of days')}</HeaderLocation>
                  </Col>
                  <Col span={4}>
                    <HeaderLocation>{t('Valid From')}</HeaderLocation>
                  </Col>
                  <Col span={5}>
                    <HeaderLocation>{t('Valid To')}</HeaderLocation>
                  </Col>
                  <Col span={4}>
                    <HeaderLocation>{t('Location')}</HeaderLocation>
                  </Col>
                </RowItemLocation>

                {daysOff?.map((day: LegalDaysOff) => (
                  <TenantDaysOffItem key={day?.id} data={day} />
                ))}
              </>
            )}
            {modalVisible && (
              <TenantDaysOffForm
                open={modalVisible}
                closeModal={onToggleModal}
                selectedDayOffRule={selectedDayOffRule}
                fetchDaysOff={fetchDaysOff}
              />
            )}
          </Row>
        </CardItem>
      </>
    </>
  );
}
