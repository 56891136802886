import React from 'react';
import { EmployeeAttendance } from 'types/EmployeeAttendance';
import moment from 'moment';

export const CheckinColumns = [
  {
    title: 'Employee Name',
    dataIndex: 'name',
    key: 'name',
    default: true,
    order: 1,
    render: (_: string, row: EmployeeAttendance) => (
      <p>{`${row?.employee?.firstName} ${row?.employee?.lastName}`}</p>
    ),
  },
  {
    title: 'Date',
    dataIndex: 'Date',
    key: 'Date',
    default: true,
    order: 1,
    render: (_: string, row: EmployeeAttendance) => (
      <p>{`${moment(row?.checkin).format('YYYY-MM-DD')}`}</p>
    ),
  },
  {
    title: 'Checkin Start',
    dataIndex: 'Start',
    key: 'Start',
    default: true,
    render: (_: string, row: EmployeeAttendance) => (
      <p>{`${moment(row?.checkin).format('hh:mm a')}`}</p>
    ),
    order: 4,
  },
  {
    title: 'Checkin End',
    dataIndex: 'End',
    key: 'End',
    default: true,
    render: (_: string, row: EmployeeAttendance) => (
      <p>{`${moment(row?.checkout).format('hh:mm a')}`}</p>
    ),
    order: 4,
  },
];
