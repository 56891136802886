import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { fetchTimeOff, fetchTimeOffToApprove } from 'api/timeOffService';
import {
  mapTimeOffRequests,
  sortByStatus,
} from 'ui-v2/routes/ProtectedRoutes/Employee/TimeOff/utils';
import {
  GetTimeOffToApproveDTO,
  ITimeOffRequestDTO,
  TimeOffRequest,
} from 'types/TimeOff';
import {
  getUserEmployeeId,
  isAdmin,
  isEmployee,
  isHR,
} from 'utils/utilFunctions';
import { AuthUserState } from 'redux/authUser/types';
import { EMPLOYEE_ID } from 'utils/constants';
import { useSearchParams } from 'react-router-dom';
import useHeader from './useHeader';

const PAGE_SIZE = 5;

export default function useTimeOffRequestsData() {
  const authUserData: AuthUserState = useSelector(
    (state: any) => state.authUser
  );
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<ITimeOffRequestDTO | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [searchParams] = useSearchParams();
  const [employeeTimeOffList, setEmployeeTimeOffList] = useState<
    TimeOffRequest[]
  >([]);

  const isAdminUser = isAdmin(authUserData);
  const isEmployeeUser = isEmployee(authUserData);
  const isHrUser = isHR(authUserData);

  const { entity, currentRoute } = useHeader();

  const fetchTimeOffEmployee = (page: number, take: number) => {
    const employeeId =
      searchParams.get('id') || (localStorage.getItem(EMPLOYEE_ID) as string);

    const pageOptionsDto = {
      pageOptionsDto: {
        page,
        take,
        skip: (page - 1) * take,
      },
      employeeId,
    };

    fetchTimeOff(pageOptionsDto)
      .then((response) => {
        if (response.data) {
          const timeOffRequests: TimeOffRequest[] = mapTimeOffRequests(
            response.data?.data
          );
          setTotal(response?.data?.meta?.itemCount);
          setEmployeeTimeOffList(sortByStatus(timeOffRequests));
          setLoading(false);
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const fetchDataApprover = async (
    payload: GetTimeOffToApproveDTO = {
      employees: [],
      projects: [],
      departments: [],
    }
  ) => {
    const approverEmployeeId = getUserEmployeeId(authUserData);

    if (!approverEmployeeId) return;

    const response = await fetchTimeOffToApprove(approverEmployeeId, payload);
    if (response.status === 200 && response.data) {
      const timeOffs: ITimeOffRequestDTO = response?.data;
      setData(timeOffs);
    }
    setLoading(false);
  };

  const onPageChange = (page: number) => {
    setLoading(true);
    setCurrentPage(page);
  };

  useEffect(() => {
    if (currentRoute === 'requests') {
      fetchDataApprover();
      return;
    }
    if ((isAdminUser || isEmployeeUser || isHrUser) && entity !== 'time-off') {
      fetchTimeOffEmployee(currentPage, PAGE_SIZE);
    }
    if (entity === 'time-off') {
      fetchDataApprover();
    }
  }, [authUserData?.authUser?.id, currentPage, PAGE_SIZE]);

  return {
    requests: data,
    setData,
    loading,
    fetchTimeOffEmployee,
    fetchDataApprover,
    employeeTimeOffList,
    onPageChange,
    currentPage,
    PAGE_SIZE,
    total,
  };
}
