/* eslint-disable no-confusing-arrow */
import React from 'react';
import { ProjectType } from 'types/Project';
import ProjectActions from 'components/Actions/ProjectActions';
import { convertUTCtoFormatedLocalTime } from 'utils/utilFunctions';
import i18next from '../i18n';

export const ProjectsColumnsMobile = [
  {
    dataIndex: 'project',
    key: 'project',
    render: (_: string, item: ProjectType) => (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '1rem',
        }}
      >
        <div>
          <strong>{`${i18next.t('name')}: `}</strong>
          {item?.name}
        </div>

        <div>
          <strong>{`${i18next.t('description')}: `}</strong>
          {item?.description}
        </div>
        <div>
          <strong>{`${i18next.t('startDate')}: `}</strong>
          {item?.startDate}
        </div>
        <div>
          <strong>{`${i18next.t('endDate')}: `}</strong>
          {item?.endDate}
        </div>
        <div>
          <ProjectActions id={item.id} />
        </div>
      </div>
    ),
    default: true,
    order: 0,
    width: 200,
  },
];

export const ProjectsColumns = [
  {
    title: i18next.t('name'),
    dataIndex: 'name',
    key: 'name',
    sorter: (a: ProjectType, b: ProjectType) => a.name.localeCompare(b.name),
    default: true,
    order: 0,
  },
  {
    title: i18next.t('description'),
    dataIndex: 'description',
    key: 'description',
    sorter: (a: ProjectType, b: ProjectType) =>
      a.description.localeCompare(b.description),
    render: (text: string) =>
      text.length > 150 ? `${text.substring(0, 150)}...` : text,
    default: true,
    order: 0,
  },
  {
    title: i18next.t('startDate'),
    dataIndex: 'startDate',
    key: 'startDate',
    sorter: (a: ProjectType, b: ProjectType) =>
      a.description.localeCompare(b.description),
    render: (record: string) => convertUTCtoFormatedLocalTime(record),
    default: false,
  },
  {
    title: i18next.t('endDate'),
    dataIndex: 'endDate',
    key: 'endDate',
    sorter: (a: ProjectType, b: ProjectType) =>
      a.description.localeCompare(b.description),
    render: (record: string) => convertUTCtoFormatedLocalTime(record),
    default: false,
  },
  {
    title: i18next.t('action'),
    dataIndex: 'action',
    key: 'action',
    width: 120,
    render: (_: string, row: ProjectType) => <ProjectActions id={row.id} />,
    default: false,
    order: 4,
    fixed: 'right',
  },
];
