import React, { useEffect, useState } from 'react';
import { Col, Row, Spin } from 'antd';

import { usePayrollRuleData } from 'ui-v2/hooks/usePayrollRule';
import Content from 'ui-v2/components/Content';
import CardItem from 'ui-v2/components/Card';
import * as Styled from 'ui-v2/components/PersonalInfo/PersonalInfoStyles';
import { Item } from 'ui-v2/components/PersonalInfo';
import { useTranslation } from 'react-i18next';
import {
  PayrollIntervalValueType,
  PayrollRule,
  PayrollRuleParameters,
  PayrollRuleType,
} from 'types/PayrollRule';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useScreenSize } from 'ui-v2/hooks/useScreenSize';
import { getPayrollRuleByHumanId } from 'api/payrollRulesService';
import { formatDate } from 'utils/utilFunctions';
import AddPayrollRuleForm from './PayrollRuleForm';

export default function PayrollRuleDetail() {
  const { singlePayrollRule, singlePayrollRuleLoading } = usePayrollRuleData();
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [modalOpen, setModalOpen] = useState(false);
  const [historicalPayrollRuleData, setHistoricalPayrollRuleData] =
    useState<PayrollRule[]>();
  const { isMobile } = useScreenSize();

  useEffect(() => {
    if (searchParams.get('edit-rule') === 'true') {
      searchParams.delete('edit-rule');
      setSearchParams(searchParams);
      setModalOpen(true);
    }
  }, [searchParams]);

  useEffect(() => {
    const fetchPayrollRuleData = async () => {
      if (singlePayrollRule?.humanID) {
        try {
          const res = await getPayrollRuleByHumanId(singlePayrollRule?.humanID);
          if (res.status === 200) {
            setHistoricalPayrollRuleData(res.data);
          }
        } catch (error) {
          toast.error(t('failedToGetPayrollRuleData'));
        }
      }
    };

    fetchPayrollRuleData();
  }, [singlePayrollRule, setHistoricalPayrollRuleData, t]);

  return (
    <>
      <Content position={isMobile ? 'flexcol' : 'grid'}>
        <>
          <CardItem title={t('payrollRuleInformation')}>
            <Spin spinning={singlePayrollRuleLoading || !singlePayrollRule}>
              <Row gutter={[0, 16]}>
                <Styled.RowItem gutter={[0, 10]}>
                  <Col md={{ span: 12 }} sm={{ span: 24 }}>
                    <Item
                      label={t('Rule ID')}
                      value={singlePayrollRule?.humanID ?? ''}
                    />
                  </Col>
                  <Col md={{ span: 12 }} sm={{ span: 24 }}>
                    <Item
                      label={t('Rule Type')}
                      value={singlePayrollRule?.type ?? ''}
                    />
                  </Col>
                </Styled.RowItem>
                <Styled.RowItem gutter={[0, 10]}>
                  <Col md={{ span: 12 }} sm={{ span: 24 }}>
                    <Item
                      label={t('name')}
                      value={singlePayrollRule?.name ?? ''}
                    />
                  </Col>
                  <Col md={{ span: 12 }} sm={{ span: 24 }}>
                    <Item
                      label={t('Rule Description')}
                      value={`${singlePayrollRule?.description ?? ''}`}
                    />
                  </Col>
                </Styled.RowItem>
                {singlePayrollRule?.type === PayrollRuleType.FIXED && (
                  <Styled.RowItem gutter={[0, 10]}>
                    <Col md={{ span: 12 }} sm={{ span: 24 }}>
                      <Item
                        label={t('Rule Value')}
                        value={`${singlePayrollRule?.parameters?.value ?? ''}${
                          singlePayrollRule?.currency?.symbol ?? ''
                        }`}
                      />
                    </Col>
                  </Styled.RowItem>
                )}
                {singlePayrollRule?.type === PayrollRuleType.PERCENTAGE && (
                  <Styled.RowItem gutter={[0, 10]}>
                    <Col md={{ span: 12 }} sm={{ span: 24 }}>
                      <Item
                        label={t('Rule Value')}
                        value={singlePayrollRule?.parameters?.value}
                      />
                    </Col>
                  </Styled.RowItem>
                )}
                {singlePayrollRule?.type === PayrollRuleType.INTERVAL && (
                  <Styled.RowItem gutter={[0, 10]}>
                    <Col md={{ span: 12 }} sm={{ span: 24 }}>
                      <Item
                        label={t('valueType')}
                        value={
                          singlePayrollRule?.parameters[0]?.valueType?.toUpperCase() ??
                          ''
                        }
                      />
                    </Col>
                    {singlePayrollRule?.currency?.name && (
                      <Col md={{ span: 12 }} sm={{ span: 24 }}>
                        <Item
                          label={t('currency')}
                          value={singlePayrollRule?.currency?.name ?? ''}
                        />
                      </Col>
                    )}
                  </Styled.RowItem>
                )}
                {singlePayrollRule?.type === PayrollRuleType.INTERVAL &&
                  singlePayrollRule.parameters.map(
                    ({ to, from, value, valueType }: PayrollRuleParameters) => (
                      <Styled.RowItem gutter={[0, 10]}>
                        <Col md={{ span: 12 }} sm={{ span: 24 }}>
                          <Item
                            label={t('Rule From')}
                            value={`${from} ${
                              valueType === PayrollIntervalValueType.PERCENTAGE
                                ? '%'
                                : singlePayrollRule?.currency?.symbol
                            }`}
                          />
                        </Col>
                        <Col md={{ span: 12 }} sm={{ span: 24 }}>
                          <Item
                            label={t('Rule To')}
                            value={`${to} ${
                              valueType === PayrollIntervalValueType.PERCENTAGE
                                ? '%'
                                : singlePayrollRule?.currency?.symbol
                            }`}
                          />
                        </Col>
                        <Col md={{ span: 12 }} sm={{ span: 24 }}>
                          <Item
                            label={t('Rule Value')}
                            value={`${value} ${
                              valueType === PayrollIntervalValueType.PERCENTAGE
                                ? '%'
                                : singlePayrollRule?.currency?.symbol
                            }`}
                          />
                        </Col>
                      </Styled.RowItem>
                    )
                  )}
              </Row>
              <Styled.RowItem>
                <Col md={{ span: 12 }} sm={{ span: 24 }}>
                  <Item
                    label={t('ruleLocation')}
                    value={`${singlePayrollRule?.tenantLocation?.city ?? ''}, ${
                      singlePayrollRule?.tenantLocation?.country ?? ''
                    }`}
                  />
                </Col>
              </Styled.RowItem>
            </Spin>
          </CardItem>
          <CardItem title={t('payrollRuleHistoricalData')}>
            <Spin spinning={!historicalPayrollRuleData}>
              <Row gutter={[0, 16]}>
                {historicalPayrollRuleData &&
                  historicalPayrollRuleData.map((rule: any) => (
                    <Styled.RowItem gutter={[0, 10]}>
                      <Col md={{ span: 5 }} sm={{ span: 12 }}>
                        <Item label={t('Rule Type')} value={rule.type} />
                      </Col>
                      {rule.type === PayrollRuleType.FIXED && (
                        <Col md={{ span: 5 }} sm={{ span: 12 }}>
                          <Item
                            label={t('Value')}
                            value={`${rule.parameters?.value ?? ''}${
                              rule.currency?.symbol ?? ''
                            }`}
                          />
                        </Col>
                      )}
                      {rule.type === PayrollRuleType.PERCENTAGE && (
                        <Col md={{ span: 5 }} sm={{ span: 12 }}>
                          <Item
                            label={t('Value')}
                            value={rule.parameters?.value}
                          />
                        </Col>
                      )}
                      {rule.type === PayrollRuleType.INTERVAL &&
                        rule.parameters.map(
                          ({
                            to,
                            from,
                            value,
                            valueType,
                          }: PayrollRuleParameters) => (
                            <>
                              <Col md={{ span: 6 }} sm={{ span: 12 }}>
                                <Item
                                  label={t('From')}
                                  value={`${from} ${
                                    valueType ===
                                    PayrollIntervalValueType.PERCENTAGE
                                      ? '%'
                                      : rule.currency?.symbol
                                  }`}
                                />
                              </Col>
                              <Col md={{ span: 5 }} sm={{ span: 12 }}>
                                <Item
                                  label={t('To')}
                                  value={`${to} ${
                                    valueType ===
                                    PayrollIntervalValueType.PERCENTAGE
                                      ? '%'
                                      : rule.currency?.symbol
                                  }`}
                                />
                              </Col>
                              <Col md={{ span: 5 }} sm={{ span: 12 }}>
                                <Item
                                  label={t('Value')}
                                  value={`${value} ${
                                    valueType ===
                                    PayrollIntervalValueType.PERCENTAGE
                                      ? '%'
                                      : rule.currency?.symbol
                                  }`}
                                />
                              </Col>
                            </>
                          )
                        )}
                      <Col md={{ span: 7 }} sm={{ span: 12 }}>
                        <Item
                          label={t('startDate')}
                          value={formatDate(rule.createdAt)}
                        />
                      </Col>
                      <Col md={{ span: 7 }} sm={{ span: 12 }}>
                        <Item
                          label={t('endDate')}
                          value={formatDate(rule.updatedAt)}
                        />
                      </Col>
                    </Styled.RowItem>
                  ))}
              </Row>
            </Spin>
          </CardItem>
        </>
      </Content>
      {modalOpen && (
        <AddPayrollRuleForm
          open={modalOpen}
          closeModal={() => {
            searchParams.delete('edit-rule');
            setSearchParams(searchParams);
            setModalOpen(false);
          }}
        />
      )}
    </>
  );
}
