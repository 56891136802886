import React from 'react';
import { Row, Col } from 'antd';
import {
  NodataLabel,
  NodataLabelBold,
  StyledCard,
} from 'ui-v2/components/Assets/AssetItem/AssetItemStyled';
import { t } from 'i18next';

interface IProps {
  fields: { label: string; value: string }[];
}

export default function EmployeeProfileMobileTabs({ fields }: IProps) {
  return (
    <StyledCard>
      <Row gutter={[8, 8]}>
        {fields.map(({ label, value }) => (
          <Col span={24} key={label}>
            <NodataLabelBold>{t(label)}</NodataLabelBold>
            <NodataLabel>{`: ${value}`}</NodataLabel>
          </Col>
        ))}
      </Row>
    </StyledCard>
  );
}
