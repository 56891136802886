import { AssetType } from './Asset';
import { EmployeeType } from './Employee';

export enum RequestType {
  TIMEOFF = 'timeoff',
  INFORMATION = 'information',
  ASSETS = 'assets',
  COMPENSATION = 'compensation',
  JOB = 'job',
}

export enum EmployeeRequestEntity {
  TIMEOFF = 'timeoff',
  INFORMATION = 'information',
  ASSETS = 'asset',
  BENEFITS = 'benefits',
  COMPENSATION = 'compensation',
  QUESTIONNAIRES = 'questionnaires',
  OTHER = 'other',
}

export interface Request {
  firstName: string;
  lastName: string;
  title: string;
  from: EmployeeType;
  to: EmployeeType[];
  notes: string;
  type: RequestType;
  statusColor: string;
  id: string;
  message: string;
  createdAt: Date | string;
  requestCC: EmployeeType[];
  approved: boolean;
}

export interface EmployeeRequest {
  id: string;
  description: string;
  entityName: string;
  entityId: string;
  requestedBy: EmployeeType;
  asset: AssetType;
  approved: boolean;
}

export interface CreateEmployeeRequest {
  description: string;
  entityName: string;
  entityId: string;
  employeeId: string;
}

export interface EmployeeRequestByEntityName {
  entityName: EmployeeRequestEntity;
  employeeId: string;
}

export interface RespondToRequest {
  requestId: string;
  approved: boolean;
}

export interface updateRequest {
  requestId: string;
  approved: boolean;
  message?: string;
}
