import React from 'react';
import { Col, Row, Spin } from 'antd';
import { Item } from 'ui-v2/components/PersonalInfo';
import CardItem from 'ui-v2/components/Card';
import * as Styled from 'ui-v2/components/PersonalInfo/PersonalInfoStyles';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { CreateExpenseLines, ExpensesReport } from 'types/ExpensesReport';
import { StyledParagraph } from './ExpenseStyle';

interface IProps {
  loading: boolean;
  singleExpenseReport: ExpensesReport;
}

export default function ExpenseInformation({
  loading,
  singleExpenseReport,
}: IProps) {
  const { t } = useTranslation();

  if (!singleExpenseReport?.id) return null;
  return (
    <CardItem title={t('expenseReportInformation')}>
      <Spin spinning={loading}>
        <Row gutter={[0, 16]}>
          <Styled.RowItem gutter={[0, 10]}>
            <Col md={{ span: 8 }} sm={{ span: 24 }}>
              <Item
                label={t('employee')}
                value={`${singleExpenseReport?.employee?.firstName} ${singleExpenseReport?.employee?.lastName}`}
              />
            </Col>
            <Col md={{ span: 8 }} sm={{ span: 24 }}>
              <Item
                label={t('project')}
                value={singleExpenseReport?.project?.name}
              />
            </Col>
            <Col md={{ span: 8 }} sm={{ span: 24 }}>
              <Styled.ItemLabel>{t('status')}</Styled.ItemLabel>
              <Styled.ExpenseStatusTag
                status={singleExpenseReport?.status || ''}
              >
                <p>{singleExpenseReport?.status}</p>
              </Styled.ExpenseStatusTag>
            </Col>
          </Styled.RowItem>
          <Styled.RowItem gutter={[0, 10]}>
            <Col md={{ span: 8 }} sm={{ span: 24 }}>
              <Item
                label={t('expenseItems')}
                value={singleExpenseReport?.expenseLines?.length}
              />
            </Col>
            <Col md={{ span: 12 }} sm={{ span: 24 }}>
              <Item
                label={t('totalAmount')}
                value={`${singleExpenseReport.expenseLines
                  .reduce(
                    (total, expenseLine) =>
                      total + parseFloat(expenseLine.amount),
                    0
                  )
                  .toFixed(2)} ${
                  singleExpenseReport?.expenseLines &&
                  singleExpenseReport?.expenseLines[0]?.currency?.symbol
                    ? singleExpenseReport?.expenseLines[0]?.currency?.symbol
                    : ''
                }`}
              />
            </Col>
          </Styled.RowItem>
          <Styled.RowItem gutter={[0, 10]}>
            <Col md={{ span: 28 }} sm={{ span: 24 }}>
              <Item
                label={t('expenseLines')}
                value={
                  <ul>
                    {singleExpenseReport?.expenseLines.map(
                      (line: CreateExpenseLines) => (
                        <li key={line.id}>
                          {line.category.charAt(0).toUpperCase() +
                            line.category.slice(1).toLowerCase()}
                          <StyledParagraph>
                            {`${t('amount')}: ${line.amount} ${
                              line?.currency?.symbol
                            }`}
                          </StyledParagraph>
                          <StyledParagraph>
                            {`${t('expenseDate')}: ${moment(
                              line.expenseDate
                            ).format('DD/MM/YYYY')}`}
                          </StyledParagraph>
                          {line.description ? (
                            <StyledParagraph>
                              {`${t('description')}: ${line.description}`}
                            </StyledParagraph>
                          ) : null}
                        </li>
                      )
                    )}
                  </ul>
                }
              />
            </Col>
          </Styled.RowItem>
        </Row>
      </Spin>
    </CardItem>
  );
}
