import { Dispatch } from 'redux';
import { QueryParamsDTO } from 'types/QueryParams';
import { getCandidatesByOpenPositionId } from 'api/candidateService';
import { getPeopleSuggestionBasedOnOpenPosition } from 'api/skillsetMatchingService';
import { CandidateSkillsetAction, SkillsetMatchingActions } from './types';

export const fetchSkillsetMatchingByOpenPositionId =
  (
    id: string,
    queryParams: QueryParamsDTO,
    yearsOfExperienceFilter?: boolean,
    applied?: boolean
  ) =>
  (dispatch: Dispatch) => {
    dispatch({
      type: SkillsetMatchingActions.FETCH_SKILLSETMATCHING_START,
    });
    getPeopleSuggestionBasedOnOpenPosition(
      id,
      queryParams,
      yearsOfExperienceFilter,
      applied
    )
      .then((res) => {
        dispatch({
          type: SkillsetMatchingActions.FETCH_SKILLSETMATCHING_SUCCESS,
          payload: res.data,
        });
      })
      .catch((e) => {
        dispatch({
          type: SkillsetMatchingActions.FETCH_SKILLSETMATCHING_ERRORED,
          payload: e,
        });
      });
  };

export const fetchCandidatesSkillsetMatchingByOpenPositionId =
  (id: string, queryParams: QueryParamsDTO) => (dispatch: Dispatch) => {
    dispatch({
      type: CandidateSkillsetAction.FETCH_CANDIDATESSKILLSET_START,
    });
    getCandidatesByOpenPositionId(id, queryParams)
      .then((res) => {
        dispatch({
          type: CandidateSkillsetAction.FETCH_CANDIDATESSKILLSET_SUCCESS,
          payload: res.data,
        });
      })
      .catch((e) => {
        dispatch({
          type: CandidateSkillsetAction.FETCH_CANDIDATESSKILLSET_ERRORED,
          payload: e,
        });
      });
  };

export const clearSkillsetMatching = () => (dispatch: Dispatch) => {
  dispatch({
    type: SkillsetMatchingActions.CLEAR_SKILLSETMATCHING,
  });
};
