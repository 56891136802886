import { EmployeeAttendance } from 'types/EmployeeAttendance';

export enum Checkin {
  FETCH_CHECKIN_START = 'FETCH_CHECKIN_START',
  FETCH_CHECKIN_SUCCESS = 'FETCH_CHECKIN_SUCCESS',
  FETCH_CHECKIN_ERRORED = 'FETCH_CHECKIN_ERRORED',
}

export interface CheckinState {
  data: Array<EmployeeAttendance>;
  loading: boolean;
  loaded: boolean;
  errored: boolean;
  error: any;
}
