/* eslint-disable no-confusing-arrow */
/* eslint-disable indent */
/* eslint-disable prettier/prettier */
import styled from 'styled-components';
import { Typography, Tag } from 'antd';
import { StageBg, StageBgKey, StageColor, StageColorKey } from 'types/Stages';

export const Container = styled.div`
  margin-bottom: 10px;
  width: 100%;
  // margin-top: -20px;
  @media (min-width: 1320px) {
    max-height: 90px;
  }
`;
export const HireDateStyle = styled(Tag)`
  align-items: center;
  border-radius: 5px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
`;
export const Title = styled(Typography.Text)`
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  line-height: 38px;
  color: #101828;
  display: block;
  white-space: nowrap;
  width: 100%;
`;

export const Role = styled(Typography.Text)<{
  hidden: boolean;
}>`
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  color: #475467;
  ${({ hidden }) => hidden && 'display: none'};
`;

export const StatusTagLabel = styled.div<{
  status: string;
  hidden?: boolean;
}>`
  border-radius: 16px;
  background: #edeff3;
  padding: 3px 10px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #344054;
  border: none;
  margin-left: 0px;

  ${({ hidden }) => hidden && 'display: none'};

  ${({ status }) => status === 'new' && 'background-color:#edeff3 '};
  ${({ status }) => status === 'verify' && 'background-color:#ECFDF3 '};
  ${({ status }) => status === 'hired' && 'background-color:#ECFDF3 '};
  ${({ status }) => status === 'rejected' && 'background-color:#FEF3F2 '};
`;

export const TagLabel = styled(Tag)<{
  hidden?: boolean;
  stage: string;
}>`
  border-radius: 16px;
  background: #edeff3;
  padding: 3px 10px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #344054;
  border: none;
  margin-left: 0px;

  ${({ hidden }) => hidden && 'display: none'};

  ${({ stage }) => `color: ${StageColor[stage as StageColorKey]} `};
  ${({ stage }) => `background-color: ${StageBg[stage as StageBgKey]} `};
`;

// export const RowBanner = styled(Row)<{
//   isHrDutiesCompleted: boolean;
// }>`
//   ${({ isHrDutiesCompleted }) =>
//     isHrDutiesCompleted === true ? 'margin-top: 30px' : ''};
// `;
