import React from 'react';
import { DashboardIcon } from 'Icons/DashboardIcon';
import { UserIcon } from 'Icons/UserIcon';
import { CalendarIcon } from 'Icons/CalendarIcon';
import { TimeEntryIcon } from 'Icons/TimeEntryIcon';
import DatabaseIcon from 'Icons/DatabaseIcon';
import { CertificationsIcon } from 'Icons/CertificationsIcon';
import { EmployeesIcon } from 'Icons/EmployeesIcon';
import { SettingsIcon } from 'Icons/SettingsIcon';
import { WorkPositionsIcon } from 'Icons/WorkPositionsIcon';
import { FilesIcon } from 'Icons/FilesIcon';
import { AssetSidebarIcon } from 'Icons/AssetSidebarIcon';
import { ProjectsIcon } from 'Icons/ProjectsIcon';
import { DepartmentsIcon } from 'Icons/DepartmentsIcon';
import { TrainingsIcon } from 'Icons/TrainingsIcon';
import { InterviewsIcon } from 'Icons/InterviewsIcon';
import { OpenPositionsIcon } from 'Icons/OpenPositionsIcon';
import { DollarOutlined, OrderedListOutlined } from '@ant-design/icons';
import { CandidatesIcon } from 'Icons/CandidatesIcon';
import { PlusIcon } from 'Icons/PlusIcon';
import { ReviewsIcon } from 'Icons/ReviewsIcon';
import { EvaluationPeriodsIcon } from 'Icons/EvaluationPeriodsIcon';
import { UsersIcon } from '../../../Icons/UsersIcon';
import { TimeOffIcon } from '../../../Icons/TimeOffIcon';
import { BenefitsIcon } from '../../../Icons/BenefitsIcon';
import { PayrollRulesIcon } from '../../../Icons/PayrollRulesIcon';
import { PayrollReportIcon } from '../../../Icons/PayrollReportIcon';
import { DataEntryIcon } from '../../../Icons/DataEntryIcon';
import { OnboardingIcon } from '../../../Icons/OnboardingIcon';

export enum FeatureFlagsList {
  RECRUITING = 'recruting',
  MANAGING = 'managing',
  VENDORS = 'vendors',
  PURCHASES = 'purchases',
  ORG_STRUCT = 'org-struct',
  ADMINISTRATIVE = 'administrative',
}

export type SidebarItem = {
  icon: React.ReactNode;
  name: string;
  path: string;
  feature?: FeatureFlagsList;
};

export type SidebarItemGroup = {
  items: Array<SidebarItem>;
  title: string;
  feature?: FeatureFlagsList;
};

export enum SCREEN_SIZE {
  SMALL = 'sm',
  MEDIUM = 'md',
}

export type ScreenSizeKey = keyof typeof SCREEN_SIZE;

export function getWindowSize() {
  const { innerWidth } = window;
  if (innerWidth < 1194) {
    return SCREEN_SIZE.SMALL;
  }
  return SCREEN_SIZE.MEDIUM;
}

export const getAdminHrSidebarItems = (): Array<SidebarItem> => [
  {
    icon: <DashboardIcon />,
    name: 'Dashboard',
    path: '/dashboard',
  },
  {
    icon: <DashboardIcon />,
    name: 'Feed',
    path: '/feed',
  },
];
export const getEmployeeSidebarItems = (): Array<SidebarItem> => [
  {
    icon: <DashboardIcon />,
    name: 'Dashboard',
    path: '/employee-dashboard',
  },
  {
    icon: <DashboardIcon />,
    name: 'Feed',
    path: '/feed',
  },
  {
    icon: <UserIcon color="#ffff" />,
    path: '/employee-details',
    name: 'Profile',
    feature: FeatureFlagsList.RECRUITING,
  },
  {
    icon: <TimeEntryIcon />,
    path: '/requests',
    name: 'All Requests',
  },
  {
    icon: <CalendarIcon />,
    path: '/employee-details/time-off',
    name: 'Calendar',
  },
  {
    icon: <CalendarIcon />,
    path: '/ideabox',
    name: 'Ideabox',
  },
  {
    icon: <PlusIcon />,
    path: '/employee-details/tracker',
    name: 'Tracker',
    feature: FeatureFlagsList.RECRUITING,
  },
  {
    icon: <EvaluationPeriodsIcon />,
    path: '/goals',
    name: 'Goals',
  },
  {
    icon: <DollarOutlined />,
    path: '/expenses',
    name: 'Expenses',
  },
];

export const getPayrollManagerSidebarItems = (): Array<SidebarItem> => [
  {
    icon: <DashboardIcon />,
    name: 'Dashboard',
    path: '/employee-dashboard',
  },
  {
    icon: <DashboardIcon />,
    name: 'Feed',
    path: '/feed',
  },
  {
    icon: <UserIcon color="#ffff" />,
    path: '/employee-details',
    name: 'Profile',
    feature: FeatureFlagsList.RECRUITING,
  },
  {
    icon: <PayrollReportIcon />,
    path: '/payroll-report',
    name: 'Payroll Report',
  },
  {
    icon: <PayrollRulesIcon />,
    path: '/payroll-rules',
    name: 'Payroll Rules',
  },
  {
    icon: <TimeEntryIcon />,
    path: '/requests',
    name: 'All Requests',
  },
  {
    icon: <CalendarIcon />,
    path: '/employee-details/time-off',
    name: 'Calendar',
  },
  {
    icon: <CalendarIcon />,
    path: '/ideabox',
    name: 'Ideabox',
  },
  {
    icon: <PlusIcon />,
    path: '/employee-details/tracker',
    name: 'Tracker',
  },
  {
    icon: <EvaluationPeriodsIcon />,
    path: '/goals',
    name: 'Goals',
  },
  {
    icon: <DollarOutlined />,
    path: '/expenses',
    name: 'Expenses',
  },
];

export const getHeadOfDepartmentSidebarItems = (): Array<SidebarItem> => [
  {
    icon: <UserIcon color="#ffff" />,
    path: '/employee-details',
    name: 'Profile',
  },
  {
    icon: <TimeEntryIcon />,
    path: '/requests',
    name: 'All Requests',
  },
  {
    icon: <CalendarIcon />,
    path: '/employee-details/time-off',
    name: 'Calendar',
  },
  {
    icon: <CalendarIcon />,
    path: '/ideabox',
    name: 'Ideabox',
  },
  {
    icon: <ReviewsIcon />,
    name: 'Questionnaires',
    path: '/questionnaires',
  },
  {
    icon: <PlusIcon />,
    path: '/employee-details/tracker',
    name: 'Tracker',
  },
];
export const getGuestSidebarItems = (): Array<SidebarItem> => [
  {
    icon: <TimeEntryIcon />,
    path: '/tracking',
    name: 'Tracking Hours',
  },
];
export const getGuestTrackingHoursSidebarItems = (): Array<SidebarItem> => [
  {
    icon: <TimeEntryIcon />,
    path: '/tracking',
    name: 'Tracking Hours',
  },
];
export const getEditorSidebarItems = (): Array<SidebarItem> => [
  {
    icon: <TimeEntryIcon />,
    path: '/tracking',
    name: 'Tracking Hours',
  },
];
export const getSuperAdminSidebarItems = (): Array<SidebarItem> => [
  {
    icon: <CertificationsIcon />,
    path: '/ip-whitelisting',
    name: 'IP Whitelisting',
  },
  {
    icon: <DatabaseIcon />,
    path: '/tenants',
    name: 'Tenants',
  },
  {
    icon: <EmployeesIcon />,
    path: '/public-super-admins',
    name: 'Users List',
  },
  {
    icon: <DatabaseIcon />,
    path: '/feature-flags',
    name: 'Feature Flags',
  },
];

export const getAdminHrGroupedSidebarItems = (): Array<SidebarItemGroup> => [
  {
    title: 'Recruitment',
    feature: FeatureFlagsList.RECRUITING,
    items: [
      {
        icon: <CandidatesIcon />,
        path: '/candidates',
        name: 'Candidates',
        feature: FeatureFlagsList.RECRUITING,
      },
      {
        icon: <InterviewsIcon />,
        path: '/interviews',
        name: 'Interviews',
        feature: FeatureFlagsList.RECRUITING,
      },
      {
        icon: <OnboardingIcon />,
        path: '/onboardings',
        name: 'Onboardings',
        feature: FeatureFlagsList.RECRUITING,
      },
      {
        icon: <OpenPositionsIcon />,
        path: '/open-positions',
        name: 'Open Positions',
        feature: FeatureFlagsList.RECRUITING,
      },
    ],
  },
  {
    title: 'Managing',
    feature: FeatureFlagsList.MANAGING,
    items: [
      {
        icon: <BenefitsIcon />,
        path: '/benefits',
        name: 'Benefits',
        feature: FeatureFlagsList.MANAGING,
      },
      {
        icon: <EmployeesIcon />,
        path: '/employees',
        name: 'Employees',
        feature: FeatureFlagsList.MANAGING,
      },
      {
        icon: <EvaluationPeriodsIcon />,
        path: '/goals',
        name: 'Goals',
        feature: FeatureFlagsList.MANAGING,
      },
      {
        icon: <DollarOutlined />,
        path: '/expenses',
        name: 'Expenses',
        feature: FeatureFlagsList.MANAGING,
      },
      {
        icon: <PayrollReportIcon />,
        path: '/payroll-report',
        name: 'Payroll Report',
        feature: FeatureFlagsList.MANAGING,
      },
      {
        icon: <PayrollRulesIcon />,
        path: '/payroll-rules',
        name: 'Payroll Rules',
        feature: FeatureFlagsList.MANAGING,
      },
      {
        icon: <ReviewsIcon />,
        name: 'Questionnaires',
        path: '/questionnaires',
        feature: FeatureFlagsList.MANAGING,
      },
      {
        icon: <CalendarIcon />,
        path: '/ideabox',
        name: 'Ideabox',
        feature: FeatureFlagsList.MANAGING,
      },
      {
        icon: <TimeOffIcon />,
        path: '/calendar',
        name: 'Calendar',
        feature: FeatureFlagsList.MANAGING,
      },
      {
        icon: <TimeOffIcon />,
        path: '/time-off',
        name: 'Time Off',
        feature: FeatureFlagsList.MANAGING,
      },
      {
        icon: <TimeEntryIcon />,
        path: '/tracking',
        name: 'Tracking Hours',
        feature: FeatureFlagsList.MANAGING,
      },
      {
        icon: <TrainingsIcon />,
        path: '/trainings',
        name: 'Training',
        feature: FeatureFlagsList.MANAGING,
      },
      {
        icon: <UsersIcon />,
        path: '/users',
        name: 'Users List',
        feature: FeatureFlagsList.MANAGING,
      },
      {
        icon: <OrderedListOutlined />,
        path: '/hierarchy',
        name: 'Hierarchy',
        feature: FeatureFlagsList.MANAGING,
      },
      {
        icon: <TimeOffIcon />,
        path: '/attendance',
        name: 'Employee Attendance',
        feature: FeatureFlagsList.MANAGING,
      },
      {
        icon: <TimeEntryIcon />,
        path: '/checkin',
        name: 'Checkin List',
      },
    ],
  },
  {
    title: 'Vendors',
    feature: FeatureFlagsList.VENDORS,
    items: [
      {
        icon: <OpenPositionsIcon />,
        path: '/vendor-management',
        name: 'Vendor Management',
        feature: FeatureFlagsList.VENDORS,
      },
      {
        icon: <FilesIcon />,
        path: '/vendor-questionnaire',
        name: 'Vendor Questionnaire',
        feature: FeatureFlagsList.VENDORS,
      },
      {
        icon: <OpenPositionsIcon />,
        path: '/vendors',
        name: 'Vendor',
        feature: FeatureFlagsList.VENDORS,
      },
      {
        icon: <TimeOffIcon />,
        path: '/vendor-categories',
        name: 'Vendor Categories',
        feature: FeatureFlagsList.VENDORS,
      },
    ],
  },
  {
    title: 'Purchases',
    feature: FeatureFlagsList.PURCHASES,
    items: [
      {
        icon: <TimeEntryIcon />,
        path: '/purchase-orders',
        name: 'Purchase Order',
        feature: FeatureFlagsList.PURCHASES,
      },
      {
        icon: <FilesIcon />,
        path: '/invoicing',
        name: 'Invoicing',
        feature: FeatureFlagsList.PURCHASES,
      },
      {
        icon: <InterviewsIcon />,
        path: '/receiving',
        name: 'Receiving',
        feature: FeatureFlagsList.PURCHASES,
      },
      {
        icon: <InterviewsIcon />,
        path: '/purchase-requests',
        name: 'Purchase Request',
        feature: FeatureFlagsList.PURCHASES,
      },
    ],
  },
  {
    title: 'Organizational Structure',
    feature: FeatureFlagsList.ORG_STRUCT,
    items: [
      {
        icon: <AssetSidebarIcon />,
        path: '/assets',
        name: 'Assets',
        feature: FeatureFlagsList.ORG_STRUCT,
      },
      {
        icon: <CertificationsIcon />,
        path: '/certifications',
        name: 'Certifications',
        feature: FeatureFlagsList.ORG_STRUCT,
      },
      {
        icon: <DataEntryIcon />,
        path: '/data-entry',
        name: 'Data Entry',
        feature: FeatureFlagsList.ORG_STRUCT,
      },
      {
        icon: <WorkPositionsIcon />,
        path: '/work-positions',
        name: 'Work Positions',
        feature: FeatureFlagsList.ORG_STRUCT,
      },
      {
        icon: <DepartmentsIcon />,
        path: '/departments',
        name: 'Departments',
        feature: FeatureFlagsList.RECRUITING,
      },
      {
        icon: <FilesIcon />,
        path: '/files',
        name: 'Files',
        feature: FeatureFlagsList.ORG_STRUCT,
      },
      {
        icon: <ProjectsIcon />,
        path: '/projects',
        name: 'Projects',
        feature: FeatureFlagsList.ORG_STRUCT,
      },
      {
        icon: <TimeEntryIcon />,
        path: '/requests',
        name: 'Requests',
        feature: FeatureFlagsList.ORG_STRUCT,
      },
    ],
  },
  {
    title: 'Administrative',
    feature: FeatureFlagsList.ADMINISTRATIVE,
    items: [
      {
        icon: <SettingsIcon />,
        path: '/settings',
        name: 'Settings',
        feature: FeatureFlagsList.ADMINISTRATIVE,
      },
      {
        icon: <WorkPositionsIcon />,
        path: '/skills',
        name: 'Skill Categories',
        feature: FeatureFlagsList.ADMINISTRATIVE,
      },
      {
        icon: <AssetSidebarIcon />,
        path: '/asset-categories',
        name: 'Asset Categories',
        feature: FeatureFlagsList.ADMINISTRATIVE,
      },
    ],
  },
];
export const getEmployeeGroupedSidebarItems = (): Array<SidebarItemGroup> => [];
export const getGuestGroupedSidebarItems = (): Array<SidebarItemGroup> => [];
export const getGuestTrackingHoursGroupedSidebarItems =
  (): Array<SidebarItemGroup> => [];
export const getEditorGroupedSidebarItems = (): Array<SidebarItemGroup> => [];
export const getSuperAdminGroupedSidebarItems =
  (): Array<SidebarItemGroup> => [];
