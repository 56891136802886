import { Col, Form, Row } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { InputTypes } from 'types/FormTypes';
import { SettingTab } from 'types/settings';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import {
  convertDateToUTC,
  convertUTCtoLocalTime,
  getUserDateFormat,
  toastErrorMessages,
} from 'utils/utilFunctions';
import GenericModal from 'ui-v2/components/GenericModal';
import { createDaysOffRule, updateDaysOffRule } from 'api/tenantDaysOffService';
import { CreateLegalDaysOffDTO, LegalDaysOff } from 'types/LegalDaysOff';
import moment from 'moment';
import GenericForm from '../Form/GenericForm';
import { FormItemStyled, StyledButton } from '../FormStyled';
import { fetchTenantLocationsOptions } from '../SelectWithLoad/utils';

interface IProps {
  open: boolean;
  closeModal: () => void;
  selectedDayOffRule?: LegalDaysOff | null;
  fetchDaysOff: () => void;
}

export default function TenantDaysOffForm({
  open,
  closeModal,
  selectedDayOffRule,
  fetchDaysOff,
}: IProps) {
  const [form] = Form.useForm();
  const [searchParams, setSearchParams] = useSearchParams();
  const [loading, setLoading] = useState<boolean>(false);
  const { t } = useTranslation();
  useEffect(() => {
    if (selectedDayOffRule) {
      form.setFieldsValue({
        streetName: selectedDayOffRule.daysCount,
        validFrom: convertUTCtoLocalTime(selectedDayOffRule?.validTo),
        ...(selectedDayOffRule?.validTo && {
          validTo: convertUTCtoLocalTime(selectedDayOffRule?.validTo),
        }),
      });
    }
  }, [selectedDayOffRule]);

  useEffect(() => {
    if (searchParams.get('settings-save') === SettingTab.APIKEYS) {
      searchParams.delete('settings-save');
      setSearchParams(searchParams);
      form.submit();
    }
  }, [searchParams]);

  const userDateFormat = getUserDateFormat();
  const today = moment().startOf('day');

  function onFormFinish(values: any) {
    const valuesToSend: CreateLegalDaysOffDTO = {
      daysCount: Number(values?.daysCount),
      validFrom: convertDateToUTC(values?.validFrom),
      validTo: values?.validTo ? convertDateToUTC(values?.validTo) : undefined,
      tenantLocationId: values?.tenantLocation?.value,
    };

    setLoading(true);

    if (selectedDayOffRule?.id) {
      updateDaysOffRule(selectedDayOffRule?.id, valuesToSend)
        .then((res) => {
          if (res.status === 200) {
            toast.success(t('tenantLocationUpdatedSuccessfully'));
            searchParams.append('edited-days-off-rule', 'true');
            closeModal();
            fetchDaysOff();
          }
        })
        .catch((error) => {
          toastErrorMessages(error);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      createDaysOffRule(valuesToSend)
        .then((res) => {
          if (res.status === 201) {
            toast.success(t('tenantLocationCreatedSuccessfully'));
            searchParams.append('added-days-off-rule', 'true');
            closeModal();
            fetchDaysOff();
          }
        })
        .catch((error) => {
          toastErrorMessages(error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }

  const TenantDaysOffFormConfigurations: any[][] = useMemo(
    () => [
      [
        {
          col: 11,
          offset: 0,
          name: 'daysCount',
          label: t('numberOfDays'),
          type: InputTypes.INPUT,
          defaultValue: selectedDayOffRule?.daysCount,
          inputProps: {
            type: 'number',
            min: 0,
          },
          rules: [
            {
              required: true,
              message: t('fieldRequired'),
            },
          ],
        },
        {
          col: 11,
          offset: 2,
          name: 'tenantLocation',
          label: t('location'),
          type: InputTypes.SELECT_LOAD,
          fetchOptions: fetchTenantLocationsOptions,
          rules: [
            {
              required: true,
              message: t('fieldRequired'),
            },
          ],
        },
      ],
      [
        {
          col: 11,
          offset: 0,
          name: 'validFrom',
          label: t('validFrom'),
          type: InputTypes.DATEPICKER,
          dateProps: {
            format: userDateFormat,
            showTime: false,
            disabledDate: (current: moment.Moment) => current.isBefore(today),
          },
          rules: [
            {
              required: true,
              message: t('fieldRequired'),
            },
          ],
        },
        {
          col: 11,
          offset: 2,
          name: 'validTo',
          label: t('validTo'),
          type: InputTypes.DATEPICKER,
          dateProps: {
            format: userDateFormat,
            showTime: false,
          },
        },
      ],
    ],

    [selectedDayOffRule]
  );

  return (
    <GenericModal
      title={
        selectedDayOffRule?.id ? t('editDaysOffRule') : t('addDaysOffRule')
      }
      open={open}
      closeModal={closeModal}
    >
      <GenericForm
        formConfiguration={TenantDaysOffFormConfigurations}
        form={form}
        loading={loading}
        onFinish={onFormFinish}
      >
        <FormItemStyled style={{ marginTop: 30, marginBottom: 0 }}>
          <Row>
            <Col span={11}>
              <StyledButton
                onClick={() => {
                  closeModal();
                }}
                htmlType="reset"
                danger
              >
                {t('cancel')}
              </StyledButton>
            </Col>
            <Col span={11} offset={2}>
              <StyledButton
                onClick={() => form.submit()}
                type="primary"
                htmlType="submit"
                loading={loading}
              >
                {t('submit')}
              </StyledButton>
            </Col>
          </Row>
        </FormItemStyled>
      </GenericForm>
    </GenericModal>
  );
}
