import React from 'react';
import CandidateActions from 'components/Actions/CandidateActions';
import { CandidatesType } from 'types/Candidates';
import * as Styled from 'ui-v2/components/DataGridComponents';
import moment from 'moment';
import { convertUTCtoLocalTime } from 'utils/utilFunctions';
import { getCandidateStatusIcon } from 'ui-v2/components/Header/helpers';
import { Row } from 'antd';
import i18next from '../i18n';

export const CandidatesColumsMobile = [
  {
    dataIndex: 'candidate',
    key: 'candidate',
    render: (_: string, item: CandidatesType) => (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '1rem',
        }}
      >
        <div>
          <strong>{`${i18next.t('Candidate')}: `}</strong>
          {`${item.firstName} ${item.lastName}, ${moment().diff(
            convertUTCtoLocalTime(item.birthDate),
            'years',
            false
          )}`}
        </div>
        <div>
          <strong>{`${i18next.t('jobTitle')}: `}</strong>
          {item.currentJobTitle}
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            flexWrap: 'nowrap',
            gap: '0.5rem',
          }}
        >
          <strong>{`${i18next.t('status')}: `}</strong>
          <Styled.StatusTag status={item?.status || ''}>
            <p>{getCandidateStatusIcon(item?.status)}</p>
            {i18next.t(
              (item.status?.charAt(0)?.toUpperCase() || '') +
                (item.status?.slice(1) || '')
            )}
          </Styled.StatusTag>
        </div>
        <div>
          <strong>{`${i18next.t('openPosition')}: `}</strong>
          {item?.candidateOpenPositions
            ?.map((el: any) => el.openPositions.name)
            ?.join(', ')}
        </div>
        <div>
          <strong>{`${i18next.t('expectedSalary')}: `}</strong>
          {item?.currency?.symbol === 'ALL'
            ? `${item?.expectedSalary
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ',')} ${
                item?.currency?.symbol
              }`
            : `${item?.expectedSalary} ${item?.currency?.symbol}`}
        </div>
        <div>
          <strong>{`${i18next.t('refferedBy')}: `}</strong>
          {item?.referalType}
        </div>
        <div>
          <CandidateActions id={item?.id} />
        </div>
      </div>
    ),
    default: true,
    order: 0,
    width: 200,
  },
];

export const CandidatesColums = [
  {
    title: i18next.t('Candidate'),
    dataIndex: 'firstName',
    key: 'firstName',
    ellipsis: true,
    sorter: (a: CandidatesType, b: CandidatesType) =>
      a.firstName.localeCompare(b.firstName),
    render: (_: string, item: CandidatesType) => (
      <Styled.TextBold>
        {`${item.firstName} ${item.lastName}, ${moment().diff(
          convertUTCtoLocalTime(item.birthDate),
          'years',
          false
        )}`}
      </Styled.TextBold>
    ),
    default: true,
    order: 0,
  },
  {
    title: i18next.t('jobTitle'),
    dataIndex: 'currentJobTitle',
    key: 'currentJobTitle',
    ellipsis: true,
    render: (_: string, item: any) => (
      <Styled.Text>{item.currentJobTitle}</Styled.Text>
    ),
    default: true,
    order: 1,
  },
  {
    title: i18next.t('status'),
    dataIndex: 'status',
    key: 'status',
    render: (_: string, row: CandidatesType) => (
      <Row justify="start">
        <Styled.StatusTag status={row?.status || ''}>
          <p>{getCandidateStatusIcon(row?.status)}</p>
          {i18next.t(
            (row.status?.charAt(0)?.toUpperCase() || '') +
              (row.status?.slice(1) || '')
          )}
        </Styled.StatusTag>
      </Row>
    ),
    default: true,
    order: 2,
  },
  {
    title: i18next.t('openPosition'),
    dataIndex: 'candidateOpenPositions',
    key: 'openPosition',
    ellipsis: true,
    // sorter: (a: CandidatesType, b: CandidatesType) =>
    //   a.candidateOpenPositions
    //     ?.map((el: any) => el.openPositions.name)
    //     .localeCompare(
    //       b.candidateOpenPositions?.map((el: any) => el.openPositions.name)
    //     ),
    render: (_: string, item: any) => {
      const positions: string[] = item?.candidateOpenPositions?.map(
        (el: any) => el.openPositions.name
      );
      const positionValid = positions?.length ? positions.join(', ') : '';

      return <Styled.Text>{positionValid}</Styled.Text>;
    },
    default: false,
    order: 3,
  },
  {
    title: i18next.t('expectedSalary'),
    dataIndex: 'expectedSalary',
    key: 'expectedSalary',
    render: (_: string, item: any) => (
      <Styled.Text>
        {item?.currency?.symbol === 'ALL'
          ? `${item?.expectedSalary
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ',')} ${item?.currency?.symbol}`
          : `${item?.expectedSalary} ${item?.currency?.symbol}`}
      </Styled.Text>
    ),
    default: false,
    order: 4,
  },
  {
    title: i18next.t('refferedBy'),
    dataIndex: 'referalType',
    key: 'referalType',
    // sorter: (a: CandidatesType, b: CandidatesType) => {
    //   if (a.employeeReferral == null && b.employeeReferral == null) {
    //     return 0;
    //   }
    //   if (a.employeeReferral == null) {
    //     return 1;
    //   }
    //   if (b.employeeReferral == null) {
    //     return -1;
    //   }
    //   return a.employeeReferral.localeCompare(b.employeeReferral);
    // },
    render: (record: string, item: CandidatesType) => (
      <Styled.Text>{item.referalType}</Styled.Text>
    ),
    default: false,
    order: 5,
  },
  {
    title: i18next.t('action'),
    dataIndex: 'action',
    key: 'action',
    width: 120,
    render: (_: string, row: CandidatesType) => (
      <CandidateActions id={row.id} />
    ),
    default: false,
    order: 6,
    fixed: 'right',
  },
];
