import React from 'react';
import {
  CandidateOpenPositionObj,
  CandidateType,
  Gender,
} from 'types/Candidates';
import { FormConfigurationType, InputTypes } from 'types/FormTypes';
import {
  convertUTCtoLocalTime,
  isValidEmail,
  isValidPhoneNumber,
} from 'utils/utilFunctions';
import { OptionType } from 'types/OptionTypes';
import { RcFile } from 'antd/lib/upload';
import { FileOutlinedIcon } from 'Icons/FileOutlinedIcon';
import i18next from '../../../i18n';
import {
  fetchCertifications,
  fetchOpenPositionOptions,
} from '../SelectWithLoad/utils';
import { prefixSelector } from '../EmployeeForm/components/utils';

const transformToUpperCase = (value: string | undefined) => {
  if (!value) return null;
  return value.charAt(0).toUpperCase() + value.slice(1);
};

export const getCandidateFormConfigurations = ({
  candidate,
  currencyOptions,
  uploadAction,
  currencyLoading,
  prefixDefault,
}: {
  candidate?: CandidateType;
  currencyOptions: OptionType[];
  uploadAction: (file: RcFile) => void;
  currencyLoading: boolean;
  prefixDefault?: string;
}): FormConfigurationType[][] => [
  [
    {
      col: 11,
      offset: 0,
      name: 'firstName',
      label: i18next.t('firstName'),
      type: InputTypes.INPUT,
      defaultValue: candidate?.firstName,
      rules: [
        {
          required: true,
          message: i18next.t('fieldRequired'),
        },
      ],
    },
    {
      col: 11,
      offset: 2,
      name: 'lastName',
      label: i18next.t('lastName'),
      type: InputTypes.INPUT,
      defaultValue: candidate?.lastName,
      rules: [
        {
          required: true,
          message: i18next.t('fieldRequired'),
        },
      ],
    },
  ],
  [
    {
      col: 11,
      offset: 0,
      name: 'birthDate',
      label: i18next.t('birthday'),
      type: InputTypes.DATEPICKER,
      birthday: true,
      defaultValue: convertUTCtoLocalTime(candidate?.birthDate),
      rules: [
        {
          required: true,
          message: i18next.t('fieldRequired'),
        },
      ],
    },
    {
      col: 11,
      offset: 2,
      name: 'gender',
      label: i18next.t('gender'),
      type: InputTypes.SELECT,
      defaultValue:
        candidate?.gender &&
        Object.values(Gender).at(
          Object.keys(Gender)?.indexOf(candidate?.gender)
        ),
      selectOptions: Object.values(Gender).map((item: Gender) => ({
        id: item,
        value: item,
        label: item,
      })),
      rules: [
        {
          required: true,
          message: i18next.t('fieldRequired'),
        },
      ],
    },
  ],
  [
    {
      col: 11,
      offset: 0,
      name: 'email',
      label: i18next.t('emailAddress'),
      type: InputTypes.INPUT,
      defaultValue: candidate?.email,
      rules: [
        {
          required: true,
          message: i18next.t('requiredEmail'),
        },
        {
          validator(_: any, value: any): Promise<void> {
            if (!value) {
              return Promise.reject();
            }
            if (isValidEmail(value)) {
              return Promise.resolve();
            }
            return Promise.reject(new Error(i18next.t('Email is not valid')));
          },
        } as any,
      ],
    },
    {
      col: 11,
      offset: 2,
      name: 'phoneNumber',
      label: i18next.t('phoneNumber'),
      type: InputTypes.SELECTPREFIX,
      defaultValue: candidate?.phoneNumber,
      rules: [
        {
          required: true,
          message: i18next.t('Phone number is required!'),
        },
        {
          validator(_: any, value: string | undefined): Promise<void> {
            if (typeof value === 'string' && isValidPhoneNumber(value)) {
              if (value.length <= 15) {
                return Promise.resolve();
              }
              Promise.reject(
                new Error(
                  i18next.t('Phone number cannot be more than 15 digits')
                )
              );
            }
            if (typeof value === 'string' && value.length) {
              return Promise.reject(
                new Error(i18next.t('Phone number is not valid'))
              );
            }
            return Promise.reject();
          },
        } as any,
      ],
      prefix: {
        name: 'phoneNumberPrefix',
        selectOptions: prefixSelector,
        defaultValue: candidate?.phoneNumberPrefix || prefixDefault,
        placeholder: '',
      },
    },
  ],
  [
    {
      col: 11,
      offset: 0,
      name: 'referalType',
      label: i18next.t('source'),
      type: InputTypes.SELECT_PREFIX_REFERAL,
      defaultValue: transformToUpperCase(candidate?.referalType),
    },
    {
      col: 11,
      offset: 2,
      name: 'referedBy',
      label: 'Refered by',
      type: InputTypes.INPUT,
      defaultValue: candidate?.referedBy,
    },
  ],
  [
    {
      col: 11,
      offset: 0,
      name: 'currentJobTitle',
      label: i18next.t('currentjobTitle'),
      type: InputTypes.INPUT,
      defaultValue: candidate?.currentJobTitle,
      rules: [
        {
          required: true,
          message: i18next.t('fieldRequired'),
        },
      ],
    },
    {
      col: 11,
      offset: 2,
      name: 'expectedSalary',
      label: i18next.t('expectedSalary'),
      type: InputTypes.SELECTPREFIX,
      defaultValue: candidate?.expectedSalary,
      rules: [
        {
          required: true,
          message: i18next.t('fieldRequired'),
        },
      ],
      inputProps: {
        type: 'number',
        rows: 1,
        min: 0,
      } as any,
      prefix: {
        name: 'currencyId',
        selectOptions: currencyOptions,
        placeholder: '',
        defaultValue: candidate?.currency?.id || currencyOptions[1]?.id,
        rules: [
          {
            required: true,
            message: i18next.t('fieldRequired'),
          },
        ],
      },
      loading: currencyLoading,
    },
  ],
  [
    {
      col: 11,
      offset: 0,
      name: 'certifications',
      label: i18next.t('certifications'),
      type: InputTypes.SELECT_LOAD,
      fetchOptions: fetchCertifications,
      mode: 'multiple',
      showSearch: true,
      defaultValue:
        candidate?.certificationsCandidates?.map((cop: any) => ({
          id: cop?.certification?.id,
          label: cop?.certification?.name,
          value: cop?.certification?.name,
        })) || [],
    },
    {
      col: 11,
      offset: 2,
      name: 'candidateOpenPositions',
      label: i18next.t('openPosition'),
      type: InputTypes.SELECT_LOAD,
      fetchOptions: fetchOpenPositionOptions,
      mode: 'multiple',
      showSearch: true,
      defaultValue:
        candidate?.candidateOpenPositions?.map(
          (cop: CandidateOpenPositionObj) => ({
            id: cop?.openPositions?.id,
            label: cop?.openPositions?.name,
            value: cop?.openPositions?.id,
          })
        ) || [],
      rules: [
        {
          required: true,
          message: i18next.t('fieldRequired'),
        },
      ],
    },
  ],
  [
    {
      col: 24,
      offset: 0,
      name: 'notes',
      label: i18next.t('notes'),
      type: InputTypes.TEXTAREA,
      defaultValue: candidate?.notes,
    },
  ],
  [
    {
      col: 24,
      offset: 0,
      name: 'resume',
      type: InputTypes.UPLOAD,
      label: i18next.t('uploadCV'),
      rules: [
        {
          required: !candidate?.id,
          message: i18next.t('fieldRequired'),
        },
      ],
      uploadProps: {
        accept: ".pdf,'data:application/pdf;base64,'",
        beforeUpload: (file: RcFile) => uploadAction(file),
        maxCount: 1,
        listType: 'picture',
        iconRender: () => <FileOutlinedIcon />,
        defaultFileList:
          candidate?.resume &&
          ([
            {
              uid: '1',
              name: candidate?.resume,
            },
          ] as any),
      },
    },
  ],
];
