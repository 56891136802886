import { Dispatch } from 'redux';
import { QueryParamsDTO } from 'types/QueryParams';
import { getAllCheckins } from 'api/employeeAttendanceService';
import { Checkin } from './types';

export const fetchCheckins =
  (queryParams: QueryParamsDTO, employeeId?: string) =>
  (dispatch: Dispatch) => {
    dispatch({
      type: Checkin.FETCH_CHECKIN_START,
    });
    getAllCheckins(queryParams, employeeId)
      .then((res) => {
        dispatch({
          type: Checkin.FETCH_CHECKIN_SUCCESS,
          payload: res.data,
        });
      })
      .catch((e) => {
        dispatch({
          type: Checkin.FETCH_CHECKIN_ERRORED,
          payload: e,
        });
      });
  };
