import { useEffect, useState } from 'react';
import { PHONE_SCREEN_BREAKPOINT } from 'utils/constants';

export const useScreenSize = () => {
  const [isMobile, setIsMobile] = useState<boolean>(
    window.innerWidth <= PHONE_SCREEN_BREAKPOINT
  );

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= PHONE_SCREEN_BREAKPOINT);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return { isMobile };
};
