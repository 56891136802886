import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Col, Form, Row, Collapse, Input, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';

import {
  createNewWorkPosition,
  updateWorkPosition,
} from 'api/workPositionService';
import { fetchSingleWorkPosition } from 'redux/workPositions/actions';
import { InputTypes } from 'types/FormTypes';
import { WorkPositiontModelType } from 'types/WorkPosition';
import { useWorkPositionsData } from 'ui-v2/hooks/useWorkPositionsData';
import { isAdmin, isHR, toastErrorMessages } from 'utils/utilFunctions';
import GenericModal from 'ui-v2/components/GenericModal';
import { checkHrDutiesCompletion } from 'redux/hrDuties/actions';
import { RootState } from 'redux/store';
import { getAllJobCategoriesWithSubCategories } from 'api/jobCategoryService';
import SkillsDynamicForm from 'components/Form/SkillsDynamicForm';
import { fetchDepartmentsOptions } from '../SelectWithLoad/utils';
import GenericForm from '../Form/GenericForm';
import { FormItemStyled, RequirementText, StyledButton } from '../FormStyled';
import { isFormEdited } from '../Form/utils';

interface IProps {
  open: boolean;
  closeModal: () => void;
}

type Requirement = {
  key: number;
  requirement: string;
};

function WorkPositionForm({ open, closeModal }: IProps) {
  const [form] = Form.useForm();
  const [loading, isLoading] = useState<boolean>(false);
  const [skillsList, setSkillsList] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [requirements, setRequirements] = useState<Requirement[]>([]);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const auth = useSelector((state: RootState) => state.authUser);
  const { t } = useTranslation();

  const {
    workPosition: { data },
  } = useWorkPositionsData();

  useEffect(
    () => () => {
      closeModal();
    },
    [searchParams]
  );

  const fetchSkills = useCallback(async () => {
    getAllJobCategoriesWithSubCategories()
      .then((response: any) => {
        if (response.status === 200 && response.data) {
          setSkillsList(response.data);
        }
      })
      .catch(() => {
        console.log('error');
      });
  }, []);

  useEffect(() => {
    fetchSkills();
  }, []);

  const allSkillsModified = skillsList?.map(
    ({ subCategories, ...rest }: any) => ({
      ...rest,
      skillSubCategory: subCategories,
    })
  );

  const modifiedDeafultSkills = data?.skills?.map((c: any) => ({
    ...c,
    categoryId: {
      value: c.skillCategory.id,
      label: c.skillCategory.categoryName,
    },
    subCategoryId: {
      value: c.id,
      label: c.subCategoryName,
    },
  }));

  useEffect(() => {
    if (data?.requirements) {
      const loadedRequirements = data.requirements
        .split('; ')
        .map((req: string, index: number) => ({
          key: index,
          requirement: req,
        }));
      setRequirements(loadedRequirements);
    }
  }, [data]);

  async function onFormFinish() {
    const formValues = form.getFieldsValue(true);

    if (formValues['skills-matrix'] === undefined || skillsList.length === 0) {
      toast.warning(t('pleaseAddSkills!'));
      isLoading(false);
      return;
    }

    const skills = formValues['skills-matrix']?.map(
      (skill: any) => skill.subCategoryId.value
    );

    const valuesToSend: WorkPositiontModelType = {
      ...formValues,
      departmentId:
        typeof formValues?.departmentId === 'string'
          ? formValues?.departmentId
          : formValues?.departmentId?.key,
      isHeadDepartment:
        typeof formValues?.isHeadDepartment === 'boolean'
          ? formValues?.isHeadDepartment
          : data?.isHeadDepartment || false,
      requirements: requirements.map((req) => req.requirement).join('; '),
      skill: skills,
    };

    isLoading(true);

    if (data?.id) {
      if (
        isFormEdited({
          formValues: valuesToSend,
          valuesToCheck: data,
          entity: 'workPosition',
        })
      ) {
        isLoading(false);
        closeModal();
        return;
      }
      updateWorkPosition(valuesToSend, data?.id)
        .then((response) => {
          if (response.status === 200) {
            closeModal();
            toast.success(t('Successfully updated work position!'));
            if (response.data?.id) {
              dispatch(fetchSingleWorkPosition(response.data.id));
              navigate(
                `/work-positions/work-position-details?id=${response.data.id}`
              );
            } else {
              navigate('/work-positions');
            }
          }
        })
        .catch((error) => {
          toastErrorMessages(error);
        })
        .finally(() => {
          isLoading(false);
        });

      return;
    }

    createNewWorkPosition(valuesToSend)
      .then((response) => {
        toast.success(t('Successfully created work position!'));
        if (isAdmin(auth) || isHR(auth)) {
          dispatch(checkHrDutiesCompletion());
        }
        if (response.data?.id) {
          closeModal();
          navigate(
            `/work-positions/work-position-details?id=${response.data.id}`
          );
        } else {
          navigate('/work-positions');
        }
      })
      .catch((error) => {
        toastErrorMessages(error);
      })
      .finally(() => {
        isLoading(false);
      });
  }

  const onAddRequirement = () => {
    const newRequirementKey = requirements.length;
    const newRequirement: Requirement = {
      key: newRequirementKey,
      requirement: '',
    };

    setRequirements([...requirements, newRequirement]);
  };

  const onRemoveRequirement = (requirementKey: number) => {
    const filteredRequirements = requirements.filter(
      (req) => req.key !== requirementKey
    );
    setRequirements(filteredRequirements);
  };

  const onRequirementChanged = (
    currentRequirement: Requirement,
    value: string
  ) => {
    const updatedRequirements = requirements.map((req) => {
      if (req.key === currentRequirement.key) {
        return {
          ...req,
          requirement: value,
        };
      }
      return req;
    });
    setRequirements(updatedRequirements);
  };

  useEffect(() => {
    if (searchParams.get('add-save-work-position') === 'true') {
      searchParams.delete('add-save-work-position');
      form.submit();
      setSearchParams(searchParams);
    }

    if (searchParams.get('edit-save-work-position') === 'true') {
      searchParams.delete('edit-save-work-position');
      form.submit();
      setSearchParams(searchParams);
    }
  }, [searchParams]);

  const WorkPositionFormConfiguration: any[][] = useMemo(
    () => [
      [
        {
          col: 24,
          offset: 0,
          name: 'name',
          defaultValue: data?.name,
          label: t('workpositionName'),
          type: InputTypes.INPUT,
          rules: [
            {
              required: true,
              message: t('fieldRequired'),
            },
          ],
        },
        {
          col: 24,
          offset: 0,
          name: 'isHeadDepartment',
          label: t('isheadDepartment'),
          type: InputTypes.SWITCH,
          defaultChecked: data?.isHeadDepartment,
        },
      ],
      [
        {
          col: 24,
          offset: 0,
          name: 'departmentId',
          label: t('department'),
          type: InputTypes.SELECT_LOAD,
          fetchOptions: fetchDepartmentsOptions,
          defaultValue: data?.department?.id,
          rules: [
            {
              required: true,
              message: t('fieldRequired'),
            },
          ],
        },
      ],
    ],
    [data, skillsList]
  );

  return (
    <GenericModal
      title={data?.id ? t('editWorkPosition') : t('addworkPosition')}
      open={open}
      closeModal={closeModal}
    >
      <GenericForm
        formConfiguration={WorkPositionFormConfiguration}
        form={form}
        loading={loading}
        onFinish={onFormFinish}
      >
        <SkillsDynamicForm
          availableSkills={allSkillsModified}
          defaultSkills={modifiedDeafultSkills}
          categoryName="skills-matrix"
          hasYearsOfExperienceField={false}
          hasScoreField={false}
          form={form}
        />
        <FormItemStyled style={{ marginTop: 30 }}>
          <Row gutter={[0, 30]}>
            <RequirementText>{t('requirements')}</RequirementText>
            <Col span={24}>
              <Collapse style={{ marginTop: '-30px' }}>
                {requirements.map((requirement, index) => (
                  <Collapse.Panel
                    header={`${t('requirement')} ${index + 1}`}
                    key={requirement.key}
                    extra={
                      <MinusCircleOutlined
                        onClick={() => onRemoveRequirement(requirement.key)}
                      />
                    }
                  >
                    <Row>
                      <Col span={24}>
                        <Form.Item
                          label={t('requirement')}
                          name={`requirement${requirement.key}`}
                          initialValue={requirement.requirement}
                          rules={[
                            {
                              required: true,
                              message: t('pleaseEnterRequirement'),
                            },
                          ]}
                        >
                          <Input
                            placeholder={t('addRequirement')}
                            onChange={(e) =>
                              onRequirementChanged(requirement, e.target.value)
                            }
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Collapse.Panel>
                ))}
              </Collapse>
            </Col>
            <Col span={24} style={{ textAlign: 'right' }}>
              <Form.Item>
                <Button
                  type="dashed"
                  onClick={onAddRequirement}
                  icon={<PlusOutlined />}
                >
                  {t('addRequirement')}
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </FormItemStyled>

        <FormItemStyled style={{ marginTop: 30, marginBottom: 0 }}>
          <Row>
            <Col span={11}>
              <StyledButton onClick={closeModal} htmlType="reset" danger>
                {t('cancel')}
              </StyledButton>
            </Col>
            <Col span={11} offset={2}>
              <StyledButton
                onClick={() => form.submit()}
                type="primary"
                htmlType="submit"
                loading={loading}
              >
                {t('submit')}
              </StyledButton>
            </Col>
          </Row>
        </FormItemStyled>
      </GenericForm>
    </GenericModal>
  );
}

export default WorkPositionForm;
