import { Col, Form, Row } from 'antd';
import GenericForm from 'components/NewForms/Form';
import { FormItemStyled, StyledButton } from 'components/NewForms/FormStyled';
import { WorkExperienceIcon } from 'Icons/WorkExperienceIcon';
import React, { useMemo, useState } from 'react';
import moment, { Moment } from 'moment';
import { toast } from 'react-toastify';
import { FormConfigurationType } from 'types/FormTypes';
import GenericModal from 'ui-v2/components/GenericModal';
import {
  convertDateToUTC,
  convertUTCtoLocalTime,
  getUserDateFormat,
  toastErrorMessages,
} from 'utils/utilFunctions';
import { useTranslation } from 'react-i18next';
import { Goal } from 'types/Goal';
import { createNewGoal, updateGoal } from 'api/goalService';

interface IProps {
  open: boolean;
  closeModal: () => void;
  goalSelected?: Goal;
  employeeId: string;
  handleModalVisible: () => void;
}

export default function AddGoalModal({
  open,
  closeModal,
  goalSelected,
  employeeId,
  handleModalVisible,
}: IProps) {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const userDateFormat = getUserDateFormat();

  const AddWorkExperienceFormConfiguration: FormConfigurationType[][] = useMemo(
    () => [
      [
        {
          col: 24,
          offset: 0,
          name: 'name',
          label: t('name'),
          type: 'input',
          defaultValue: goalSelected?.name,
          rules: [
            {
              required: true,
              message: t('Name is required'),
            },
          ],
        },
        {
          col: 24,
          offset: 0,
          name: 'description',
          label: t('description'),
          type: 'input',
          defaultValue: goalSelected?.description,
          rules: [
            {
              required: true,
              message: t('Description is required'),
            },
          ],
        },
      ],
      [
        {
          col: 11,
          offset: 0,
          name: 'startDate',
          label: t('startDate'),
          type: 'datepicker',
          defaultValue: convertUTCtoLocalTime(goalSelected?.startDate),
          rules: [
            {
              required: true,
              message: t('Start date is required'),
            },
          ],
        },
        {
          col: 11,
          offset: 2,
          name: 'endDate',
          label: t('end'),
          type: 'datepicker',
          defaultValue: convertUTCtoLocalTime(goalSelected?.endDate),
          dateProps: {
            format: userDateFormat,
            showTime: false,
            disabledDate: (current: Moment) =>
              moment(current).isSameOrBefore(form.getFieldValue('startDate')),
          },
          rules: [
            {
              required: true,
              message: t('End date is required'),
            },
          ],
        },
      ],
      [],
    ],
    [goalSelected]
  );

  const onSubmit = async () => {
    if (loading) return;
    setLoading(true);
    const formValues: any = form.getFieldsValue(true);

    const valuesToSend = {
      employeeId,
      name: formValues.name,
      description: formValues.description,
      //   certificate: formValues.certificate?.id,
      startDate:
        formValues?.startDate && convertDateToUTC(formValues?.startDate),
      endDate: formValues?.endDate && convertDateToUTC(formValues?.endDate),
    };

    if (goalSelected?.id) {
      await updateGoal(valuesToSend, goalSelected?.id)
        .then((response) => {
          if (response.status === 200 && response.data) {
            toast.success(t('Succesfully created goal!'));
            closeModal();
          }
        })
        .catch((error) => {
          toastErrorMessages(error);
        });
    } else {
      await createNewGoal(valuesToSend)
        .then((response) => {
          if (response.status === 200 && response.data) {
            toast.success(t('Succesfully added goal!'));
            closeModal();
          }
        })
        .catch((error) => {
          toastErrorMessages(error);
        });
    }
  };

  return (
    <GenericModal
      title={goalSelected?.id ? t('Edit Goal') : t('Add Goal')}
      open={open}
      closeModal={handleModalVisible}
      icon={<WorkExperienceIcon />}
    >
      <GenericForm
        formConfiguration={AddWorkExperienceFormConfiguration}
        onFinish={onSubmit}
        form={form}
        loading={loading}
      >
        <FormItemStyled style={{ marginTop: 30, marginBottom: 0 }}>
          <Row>
            <Col span={11}>
              <StyledButton
                onClick={handleModalVisible}
                htmlType="reset"
                danger
              >
                {t('cancel')}
              </StyledButton>
            </Col>
            <Col span={11} offset={2}>
              <StyledButton loading={loading} type="primary" htmlType="submit">
                {t('confirm')}
              </StyledButton>
            </Col>
          </Row>
        </FormItemStyled>
      </GenericForm>
    </GenericModal>
  );
}
