import React, { useEffect, useState } from 'react';
import { Col, Row, Skeleton } from 'antd';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { getCandidatesByOpenPositionId } from 'api/candidateService';
import { useNavigate } from 'react-router-dom';
import {
  createCandidateEmployeeRank,
  getPositionCandidateEmployeeRanks,
} from 'api/candidateEmployeeRankService';
import { CandidateOpenPositionType } from 'types/Candidates';
import CardItem from '../Card';
import { CadnidateItem } from './CandidateItem';

interface IProps {
  openPositionId: string;
  loading: boolean;
}

export default function CandidateSkills({ openPositionId, loading }: IProps) {
  const navigate = useNavigate();
  const [candidateList, setCandidateList] = useState<any[]>([]);
  const { t } = useTranslation();

  const handleDrop = async (result: any) => {
    const { source, destination, type } = result;

    if (!destination) return;

    if (
      source.droppableId === destination.droppableId &&
      source.index === destination.index
    ) {
      return;
    }

    if (type === 'group') {
      const reorderedCandidates = [...candidateList];

      const sourceIndex = source.index;
      const destinationIndex = destination.index;

      const [removedCandidates] = reorderedCandidates.splice(sourceIndex, 1);
      reorderedCandidates.splice(destinationIndex, 0, removedCandidates);

      setCandidateList(reorderedCandidates);
      const formattedData = reorderedCandidates.map((item, index) => ({
        candidateId: item.candidate ? item.candidate.id : item.id,
        openPositionId,
        rank: index + 1,
      }));

      try {
        await createCandidateEmployeeRank(formattedData);
      } catch (error) {
        toast.error('Error updating candidate rank');
      }
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (openPositionId) {
        try {
          const candRank = await getPositionCandidateEmployeeRanks(
            openPositionId,
            {
              pageOptions: { page: 1, take: 10 },
            }
          );
          const response = await getCandidatesByOpenPositionId(openPositionId);
          if (
            candRank?.data?.data?.length > 0 &&
            candRank?.data?.data !== undefined
          ) {
            const mergedCandidates = [...candRank.data.data];

            response.data.data.forEach((candidate: any) => {
              const isDuplicate = mergedCandidates.some(
                (existingCandidate) =>
                  existingCandidate.candidate.id === candidate.id
              );

              if (!isDuplicate) {
                mergedCandidates.push(candidate);
              }
            });

            setCandidateList(mergedCandidates);
          } else if (response.data.data !== undefined) {
            setCandidateList(response.data.data);
          }
        } catch (error) {
          toast.error('Error fetching candidates');
        }
      }
    };

    fetchData();
  }, []);

  return (
    <DragDropContext onDragEnd={handleDrop}>
      <CardItem title={t('Candidates List')}>
        <Skeleton
          loading={loading}
          active
          title={false}
          paragraph={{ rows: 6, width: '100%' }}
        >
          <Droppable droppableId="ROOT" type="group">
            {(provided) => (
              <Row
                gutter={[10, 24]}
                justify="center"
                style={{
                  width: '100%',
                  height: '100%',
                }}
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {candidateList.map(
                  (store: CandidateOpenPositionType, index: number) => (
                    <Draggable
                      draggableId={store.id}
                      key={store.id}
                      index={index}
                    >
                      {(provided) => (
                        <Col
                          span={24}
                          {...provided.dragHandleProps}
                          {...provided.draggableProps}
                          ref={provided.innerRef}
                        >
                          <CadnidateItem
                            key={store.id}
                            item={store.candidate ? store.candidate : store}
                            isLast={index === candidateList.length - 1}
                            onItemClick={() => {
                              navigate(
                                `/candidates/profile?id=${
                                  store.candidate
                                    ? store.candidate.id
                                    : store.id
                                }`
                              );
                            }}
                          />
                        </Col>
                      )}
                    </Draggable>
                  )
                )}
                {provided.placeholder}
              </Row>
            )}
          </Droppable>
        </Skeleton>
      </CardItem>
    </DragDropContext>
  );
}
