/* eslint-disable import/no-duplicates */
/* eslint-disable react/no-danger */
/* eslint-disable react/no-children-prop */
import { Col, Row, Image } from 'antd';
import React, { useEffect, useState } from 'react';
import Content from 'ui-v2/components/Content';
import * as Styled from 'ui-v2/components/PersonalInfo/PersonalInfoStyles';
import CardItem from 'ui-v2/components/Card';
import { Item } from 'ui-v2/components/PersonalInfo';
import { convertUTCtoFormatedLocalTime } from 'utils/utilFunctions';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useScreenSize } from 'ui-v2/hooks/useScreenSize';
import { OpenPositionType } from 'interfaces/OpenPosition';
import { getOpenPositionById } from 'api/openPositions';
import { FallBackImage } from 'ui-v2/components/Tenant/utils';
import CandidateSkills from 'ui-v2/components/CandidateSkills';
import * as Styledd from '../../components/PersonalInfo/PersonalInfoStyles';

export default function OpenPositionDetails() {
  const [openPositionData, setOpenPositionData] = useState<OpenPositionType>();
  const [searchParams] = useSearchParams();
  const id = searchParams.get('id');
  const { isMobile } = useScreenSize();
  const { t } = useTranslation();

  const getOpenPositionData = async (id: string) => {
    if (id) {
      getOpenPositionById(id)
        .then((response) => {
          const { data } = response;
          setOpenPositionData(data);
        })
        .catch(() => {
          toast.error(t('errorFetchingOpenPosition'));
        });
    }
  };

  useEffect(() => {
    if (id) {
      getOpenPositionData(id);
    }
  }, [id]);

  const validFrom = convertUTCtoFormatedLocalTime(openPositionData?.validFrom);
  const validTo =
    convertUTCtoFormatedLocalTime(openPositionData?.validTo) || '';

  if (openPositionData?.id) {
    return (
      <>
        <Content position={isMobile ? 'flexcol' : 'grid'}>
          <>
            <CardItem title={t('openPositionInformation')}>
              <Row gutter={[0, 16]}>
                <Styled.RowItem gutter={[0, 10]}>
                  <Col md={{ span: 12 }} sm={{ span: 24 }}>
                    <Item
                      label={t('name')}
                      value={openPositionData?.name}
                      children={undefined}
                    />
                  </Col>
                  <Col md={{ span: 12 }} sm={{ span: 24 }}>
                    <Item
                      label={t('employmentType')}
                      value={openPositionData?.employeeType?.name}
                      children={undefined}
                    />
                  </Col>
                </Styled.RowItem>
                <Styled.RowItem gutter={[0, 10]}>
                  <Col md={{ span: 12 }} sm={{ span: 24 }}>
                    <Item
                      label={t('defaultopenPosition')}
                      value={
                        openPositionData?.isOpenPosDefault ? t('yes') : t('no')
                      }
                      children={undefined}
                    />
                  </Col>
                  <Col md={{ span: 12 }} sm={{ span: 24 }}>
                    <Item
                      label={t('Visibility')}
                      value={
                        openPositionData?.isPublic ? t('public') : t('private')
                      }
                      children={undefined}
                    />
                  </Col>
                </Styled.RowItem>
                <Styled.RowItem gutter={[0, 10]}>
                  <Col md={{ span: 12 }} sm={{ span: 24 }}>
                    <Item
                      label={t('validFrom')}
                      value={validFrom}
                      children={undefined}
                    />
                  </Col>
                  <Col md={{ span: 12 }} sm={{ span: 24 }}>
                    <Item
                      label={t('validTo')}
                      value={validTo}
                      children={undefined}
                    />
                  </Col>
                </Styled.RowItem>
                {openPositionData?.image && (
                  <Styled.RowItem gutter={[0, 10]}>
                    <Col md={{ span: 12 }} sm={{ span: 24 }}>
                      <Item label="" value={t('image')} children={undefined} />
                    </Col>
                    <Col md={{ span: 12 }} sm={{ span: 24 }}>
                      <Image
                        width={50}
                        height={50}
                        src={openPositionData?.image as unknown as string}
                        fallback={FallBackImage}
                      />
                    </Col>
                  </Styled.RowItem>
                )}
                <Styled.RowItem gutter={[0, 10]}>
                  <Col md={{ span: 12 }} sm={{ span: 24 }}>
                    <Styledd.ItemLabel>Description</Styledd.ItemLabel>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: openPositionData.description,
                      }}
                    />
                  </Col>
                  <Col md={{ span: 12 }} sm={{ span: 24 }}>
                    <Item
                      label={t('workPosition')}
                      value={openPositionData?.workPosition?.name}
                      children={undefined}
                    />
                  </Col>
                </Styled.RowItem>
              </Row>
            </CardItem>
            <>
              <CandidateSkills openPositionId={id ?? ''} loading={false} />
            </>
          </>
        </Content>
      </>
    );
  }
  return null;
}
