import React from 'react';
import { HeadcountType } from 'types/Headcount';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import i18next from '../i18n';

const HoverableSpan = styled.span`
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;
export const HeadcountColumns = [
  {
    title: i18next.t('name'),
    dataIndex: 'name',
    key: 'name',
    default: true,
    render: (_: string, item: HeadcountType) => (
      <Link
        to={{
          pathname: '/employees/employee-profile',
          search: `?id=${item?.id}`,
        }}
      >
        <HoverableSpan>{`${item?.firstName} ${item?.lastName}`}</HoverableSpan>
      </Link>
    ),
    order: 0,
  },
  {
    title: i18next.t('department'),
    dataIndex: 'department',
    key: 'department',
    default: true,
    render: (_: string, item: HeadcountType) => (
      <>{`${item?.workPosition?.department?.name}`}</>
    ),
    order: 1,
  },
  {
    title: i18next.t('workPosition'),
    dataIndex: 'workposition',
    key: 'workposition',
    default: true,
    render: (_: string, item: HeadcountType) => (
      <>{`${item?.workPosition?.name}`}</>
    ),
    order: 2,
  },
];
