import type { SwitchProps, UploadProps } from 'antd';
import { Moment } from 'moment';
import { CSSProperties } from 'styled-components';
import { OptionType } from 'types/OptionTypes';

export type InputType =
  | 'input'
  | 'select'
  | 'selectPrefix'
  | 'upload'
  | 'datepicker'
  | 'date-range'
  | 'switch'
  | 'button'
  | 'switch'
  | 'popconfirmSwitch'
  | 'textarea'
  | 'checkbox'
  | 'rate'
  | 'timepicker'
  | 'select-load'
  | 'checkbox-group'
  | 'input-button'
  | 'select-prefix-referal'
  | 'label'
  | 'rich-text';

export interface RulesType {
  required: boolean;
  message?: string;
}
export interface PrefixType {
  placeholder: string;
  name: string;
  selectOptions?: OptionType[];
  defaultValue?: any;
  rules?: RulesType[];
  prefixWidth?: number;
}

export interface FormConfigurationType {
  onClick?: (() => void) | undefined;
  checkboxOptions?: Array<OptionType>;
  checkedOptions?: Array<OptionType>;
  buttonType?: string | undefined;
  col: number;
  offset: number;
  name: string;
  defaultValue?: any;
  label: string;
  type: InputType;
  selectOptions?: OptionType[];
  prefix?: PrefixType;
  rules?: RulesType[];
  isMultiSelect?: boolean;
  uploadProps?: UploadProps;
  inputProps?: {
    type: 'text' | 'number' | 'email' | 'password' | 'decimal';
    min?: number;
    placeHolder?: string;
  };
  dateProps?: {
    format: any;
    showTime: boolean;
    disabledDate?: (current: Moment) => boolean;
  };
  timeProps?: {
    disabledHours?: () => number[];
    disabledMinutes?: () => number[];
    onSelect?: (time: any) => void;
  };
  textAreaProps?: {
    rows: number;
  };
  disabled?: boolean;
  birthday?: boolean;
  timepicker?: boolean;
  validateStatus?: 'error' | 'validating';
  help?: string;
  showSearch?: boolean;
  fetchOptions?: any;
  checked?: boolean;
  mode?: 'multiple' | 'tags' | undefined;
  disableEmployeeReferal?: boolean;
  styles?: CSSProperties;
  onSelect?: (id: string, fieldName?: string) => void;
  onDeselect?: (id: string) => void;
  onSelectChange?: (values: string) => void;
  onChange?: (date: Moment | null, dateString: string) => void;
  onChecked?: (checked: boolean) => void;
  onClear?: () => void;
  onPressEnter?: any;
  direction?: 'horizontal' | 'vertical';
  defaultChecked?: boolean | undefined;
  hideLabel?: boolean | undefined;
  allowClear?: boolean;
  switchProps?: SwitchProps;
  hint?: React.ReactNode | string;
  loading?: boolean;
  buttonText?: string | undefined;
  disabledInput?: boolean;
  onButtonClick?: () => void;
  popconfirmMessage?: string | undefined;
  setFieldValue?: any;
  component?: any;
}

export enum InputTypes {
  INPUT = 'input',
  SELECT = 'select',
  SELECTPREFIX = 'selectPrefix',
  SELECT_LOAD = 'select-load',
  TIMEPICKER = 'timepicker',
  DATEPICKER = 'datepicker',
  SWITCH = 'switch',
  POPCONFIRM_SWITCH = 'popconfirmSwitch',
  UPLOAD = 'upload',
  BUTTON = 'button',
  TEXTAREA = 'textarea',
  CHECKBOX = 'checkbox',
  INPUT_BUTTON = 'input-button',
  CHECKBOX_GROUP = 'checkbox-group',
  RATE = 'rate',
  SELECT_PREFIX_REFERAL = 'select-prefix-referal',
  DATE_RANGE = 'date-range',
  LABEL = 'label',
  RICH_TEXT = 'rich-text',
}
