import React from 'react';
import { GetTimeOffToApproveDTO, ITimeOffRequestDTO } from 'types/TimeOff';

import CardItem from 'ui-v2/components/Card';
import TimeOffCalendarAdmin from 'ui-v2/components/TimeOffCalendarAdmin';
import { EmployeeType } from 'types/Employee';
import Filters from '../Filters';

interface IProps {
  requests: ITimeOffRequestDTO | null;
  employees: EmployeeType[];
  setRequests: (requests: ITimeOffRequestDTO | null) => void;
  fetchDataApprover: (data: GetTimeOffToApproveDTO) => void;
}

export default function TimeOffCalendarAdminCard({
  requests,
  employees,
  setRequests,
  fetchDataApprover,
}: IProps) {
  return (
    <CardItem
      wrapContentHeight
      rightButton={<Filters fetchDataApprover={fetchDataApprover} />}
      rightButtonContent={10}
    >
      <TimeOffCalendarAdmin
        employees={employees}
        requests={requests}
        setRequests={setRequests}
      />
    </CardItem>
  );
}
