import { PayrollDatesActions } from 'components/Actions/PayrollActions';
import i18next from 'i18next';
import React from 'react';
import { PayrollDates } from 'types/PayrollReport';

export const PayrollDateColumnsMobile = [
  {
    dataIndex: 'payroll',
    key: 'payroll',
    render: (_: string, item: PayrollDates) => (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '1rem',
        }}
      >
        <div>
          <strong>{`${i18next.t('date')}: `}</strong>
          {item?.unique_date}
        </div>
        <div>
          <strong>{`${i18next.t('count')}: `}</strong>
          {item?.count}
        </div>
        <div>
          <strong>{`${i18next.t('location')}: `}</strong>
          {`${item?.tenantLocation?.city}, ${item?.tenantLocation?.country}`}
        </div>
        <div>
          <PayrollDatesActions
            id={item.unique_date}
            tenantLocationId={item?.tenantLocation?.id}
            reportId={item.payrollReportId}
          />
        </div>
      </div>
    ),
    default: true,
    order: 0,
    width: 200,
  },
];

export const PayrollDateColumns = [
  {
    title: i18next.t('date'),
    dataIndex: 'unique_date',
    key: 'unique_date',
    order: 1,
  },
  {
    title: i18next.t('count'),
    dataIndex: 'count',
    key: 'count',
    order: 1,
  },
  {
    title: i18next.t('location'),
    dataIndex: 'location',
    key: 'location',
    order: 3,
    render: (_: string, row: PayrollDates) => (
      <p>{`${row?.tenantLocation?.city}, ${row?.tenantLocation?.country}`}</p>
    ),
  },
  {
    title: i18next.t('action'),
    dataIndex: 'action',
    key: 'action',
    width: 120,
    render: (_: string, row: PayrollDates) => (
      <PayrollDatesActions
        id={row.unique_date}
        tenantLocationId={row?.tenantLocation?.id}
        reportId={row.payrollReportId}
      />
    ),
    default: false,
    order: 5,
  },
];
