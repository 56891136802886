import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  addEdge,
  Background,
  BackgroundVariant,
  ConnectionLineType,
  Controls,
  Edge,
  MiniMap,
  Node,
  Panel,
  ReactFlow,
  ReactFlowProvider,
  reconnectEdge,
  useEdgesState,
  useNodesState,
} from '@xyflow/react';
import '@xyflow/react/dist/style.css';
import { useSearchParams } from 'react-router-dom';
import { useHierarchyData } from 'ui-v2/hooks/useHierarchyData';
import { DataType, EdgeType } from 'types/Hierarchy';
import { useDispatch } from 'react-redux';
import { fetchHierarchy } from 'redux/hierarchy/action';
import HierarchyModal from './HierarchyModal';
import CustomNode from './CustomNodes/CustomNode';
import HierarchyButtons from './HierarchyButtons';

export default function FlowComponent() {
  const [nodes, setNodes, onNodesChange] = useNodesState<Node>([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState<Edge>([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const { list } = useHierarchyData();
  const dispatch = useDispatch();
  const nodeTypes = useMemo(() => ({ customNode: CustomNode }), []);

  useEffect(() => {
    if (searchParams.get('new-node') === 'true') {
      searchParams.delete('new-node');
      setSearchParams(searchParams);
      setModalVisible(true);
    }
  }, [searchParams, setSearchParams]);

  useEffect(() => {
    dispatch(fetchHierarchy());
  }, []);

  const handleDelete = useCallback(
    (node: Node) => {
      setNodes((prevNodes) => prevNodes.filter((n) => n.id !== node.id));
      setEdges((prevEdges) =>
        prevEdges.filter(
          (edge) => edge.source !== node.id && edge.target !== node.id
        )
      );
    },
    [setNodes, setEdges]
  );

  useEffect(() => {
    if (list.nodes?.length && list.edges?.length) {
      const formattedNodes = list.nodes.map((el: DataType) => ({
        id: el.id,
        type: el.type,
        position: {
          x: el.x || 0,
          y: el.y || 0,
        },
        data: {
          label: el.workPosition.name,
          workpositionId: el.workPosition.id,
          employee: el.employee,
          onDelete: handleDelete,
        },
      }));

      const formattedEdges = list.edges.map((edge: EdgeType) => ({
        id: edge.id,
        source: edge.sourceNode.id,
        target: edge.targetNode.id,
        type: ConnectionLineType.SmoothStep,
      }));

      setNodes(formattedNodes);
      setEdges(formattedEdges);
    }
  }, [list, setNodes, setEdges]);

  const edgeReconnectSuccessful = useRef(true);

  const onConnect = useCallback(
    (params) =>
      setEdges((eds) =>
        addEdge({ ...params, type: ConnectionLineType.SmoothStep }, eds)
      ),
    [setEdges]
  );
  const onReconnectStart = useCallback(() => {
    edgeReconnectSuccessful.current = false;
  }, []);

  const onReconnect = useCallback(
    (oldEdge, newConnection) => {
      edgeReconnectSuccessful.current = true;
      setEdges((els) => reconnectEdge(oldEdge, newConnection, els));
    },
    [setEdges]
  );

  const onReconnectEnd = useCallback(
    (_, edge) => {
      if (!edgeReconnectSuccessful.current) {
        setEdges((eds) => eds.filter((e) => e.id !== edge.id));
      }
      edgeReconnectSuccessful.current = true;
    },
    [setEdges]
  );

  const onRestore = useCallback(() => {
    if (list.nodes?.length && list.edges?.length) {
      const formattedNodes = list.nodes.map((el: DataType) => ({
        id: el.id,
        type: el.type,
        position: {
          x: el.x || 0,
          y: el.y || 0,
        },
        data: {
          label: el.workPosition.name,
          workpositionId: el.workPosition.id,
          employee: el.employee,
          onDelete: handleDelete,
        },
      }));

      const formattedEdges = list.edges.map((edge: EdgeType) => ({
        id: edge.id,
        source: edge.sourceNode.id,
        target: edge.targetNode.id,
        type: ConnectionLineType.SmoothStep,
      }));

      setNodes(formattedNodes);
      setEdges(formattedEdges);
    }
  }, [list]);

  const handleAddNode = useCallback(
    ({ id, name }) => {
      const newId = (nodes.length + 1).toString();
      const newNode = {
        id: newId,
        type: 'customNode',
        position: {
          x: Math.random() * 10,
          y: Math.random() * 10,
        },
        data: { label: name, workpositionId: id, onDelete: handleDelete },
      };

      setNodes((prevNodes) => [...prevNodes, newNode]);
      setModalVisible(false);
    },
    [nodes, setNodes]
  );

  return (
    <>
      <ReactFlowProvider>
        <div style={{ height: '86vh' }}>
          <ReactFlow
            nodes={nodes}
            edges={edges}
            nodeTypes={nodeTypes}
            onNodesChange={onNodesChange}
            onEdgesChange={onEdgesChange}
            onConnect={onConnect}
            onReconnectStart={onReconnectStart}
            onReconnect={onReconnect}
            onReconnectEnd={onReconnectEnd}
            fitView
          >
            <Background
              variant={'dots' as BackgroundVariant}
              gap={12}
              size={1}
            />
            <Controls />
            <MiniMap nodeStrokeWidth={3} />
            <Panel position="top-right">
              <HierarchyButtons
                onRestore={onRestore}
                data={{ edge: edges, data: nodes }}
              />
            </Panel>
          </ReactFlow>
        </div>
      </ReactFlowProvider>
      {modalVisible && (
        <HierarchyModal
          open={modalVisible}
          closeModal={() => {
            setModalVisible(false);
            searchParams.delete('edit-expense-details');
            setSearchParams(searchParams);
          }}
          handleAddNode={handleAddNode}
        />
      )}
    </>
  );
}
