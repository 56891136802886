import { PlusCircleOutlined } from '@ant-design/icons';
import { Button, Col, Row, Skeleton } from 'antd';
import { removeEmployeeAssets } from 'api/employeeService';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { fetchEmployeesDetail } from 'redux/employees/actions';
import { EmployeeAssetType } from 'types/Asset';
import { RemoveEmployeeAsset } from 'types/Employee';
import { AssetItem } from 'ui-v2/components/Assets/AssetItem/Index';
import CardItem from 'ui-v2/components/Card';
import RightSection from 'ui-v2/components/RightSection';
import { formatDate, toastErrorMessages } from 'utils/utilFunctions';
import { BUTTONS } from 'ui-v2/components/HeaderActions/config';
import RoleBasedButton from 'ui-v2/components/RoleBasedButton';
import { Link } from 'react-router-dom';
import { AuthUserState } from 'redux/authUser/types';
import { RootState } from 'redux/store';
import { useScreenSize } from 'ui-v2/hooks/useScreenSize';
import { useTranslation } from 'react-i18next';
import { AUTH_ROLES } from 'types/Auth';
import * as Styled from '../../WorkExperience/WorkExperienceStyles';
import AddAssetsModal from './AddAssetsModal';
import EmployeeProfileMobileTabs from '../Onboarding/EmployeeProfileMobileTabs';

interface IProps {
  assets: EmployeeAssetType[];
  employeeId: string;
  visibility: boolean;
  onAssetDeleted?: () => void;
}

export default function EmployeeAssets({
  assets,
  employeeId,
  visibility,
  onAssetDeleted,
}: IProps) {
  const dispatch = useDispatch();
  const [modalVisible, setModalVisible] = useState(false);
  const [assetSelected, setassetSelected] = useState<EmployeeAssetType>();
  const [loading, isLoading] = useState<boolean>(false);
  const { isMobile } = useScreenSize();

  const { authUserRole }: AuthUserState = useSelector(
    (state: RootState) => state.authUser
  );
  const { t } = useTranslation();

  const onToggleModal = () => setModalVisible((state) => !state);

  const onViewCallBack = (asset: EmployeeAssetType) => {
    setassetSelected(asset);
    onToggleModal();
  };

  const onButtonClick = () => {
    setassetSelected(undefined);
    onToggleModal();
  };

  const onDelete = (assetId: string) => {
    isLoading(true);
    const payload: RemoveEmployeeAsset = {
      employeeId,
      assetId,
    };
    removeEmployeeAssets(payload)
      .then(() => {
        toast.success(t('assetUnassignedSuccessfully'));
        dispatch(fetchEmployeesDetail(employeeId));
        onAssetDeleted?.();
      })
      .catch((e) => {
        toastErrorMessages(e);
      })
      .finally(() => {
        isLoading(false);
      });
  };

  return (
    <>
      {loading ? (
        <Skeleton loading={loading} paragraph={{ rows: 1 }} active />
      ) : (
        <CardItem
          title={t('employeeAssets')}
          rightButton={
            assets?.length ? (
              <RoleBasedButton btn={BUTTONS.ADD_ASSET}>
                <RightSection
                  buttonCallBack={() => onButtonClick()}
                  buttonText={t('addAssets')}
                />
              </RoleBasedButton>
            ) : undefined
          }
        >
          <>
            <Row gutter={[0, 24]}>
              {!assets?.length && (
                <>
                  <Styled.RowItem justify="center" last>
                    <Col>
                      <Styled.NodataLabel>
                        {authUserRole === AUTH_ROLES.EMPLOYEE
                          ? 'There are no assets related to you'
                          : t('There are no assets related to this employee')}
                      </Styled.NodataLabel>
                    </Col>
                  </Styled.RowItem>
                  {visibility && (
                    <Styled.RowItem last justify="center">
                      <RoleBasedButton btn={BUTTONS.ADD_ASSET}>
                        <Col>
                          <Button
                            icon={<PlusCircleOutlined />}
                            size="large"
                            style={{
                              borderRadius: '8px',
                              fontWeight: '600',
                              fontSize: '16px',
                              color: '344054',
                            }}
                            onClick={onButtonClick}
                          >
                            {t('assignAsset')}
                          </Button>
                        </Col>
                      </RoleBasedButton>
                    </Styled.RowItem>
                  )}
                  {visibility && assets?.length === 0 && (
                    <Styled.RowItem justify="center" last>
                      <Col>
                        <Styled.Link>
                          {t('You can add an asset from this link')}
                          <Link to="/assets?add-asset=true">
                            {t('addAsset')}
                          </Link>
                        </Styled.Link>
                      </Col>
                    </Styled.RowItem>
                  )}
                </>
              )}

              {!!assets?.length &&
                (!isMobile ? (
                  <>
                    <Styled.RowItem last style={{ paddingLeft: 10 }}>
                      <Col span={3}>
                        <Styled.NodataLabel>{t('asset')}</Styled.NodataLabel>
                      </Col>
                      <Col span={4}>
                        <Styled.NodataLabel>
                          {t('serialNumber')}
                        </Styled.NodataLabel>
                      </Col>
                      <Col span={3}>
                        <Styled.NodataLabel>{t('note')}</Styled.NodataLabel>
                      </Col>
                      <Col span={3}>
                        <Styled.NodataLabel>
                          {t('dateLoaned')}
                        </Styled.NodataLabel>
                      </Col>
                      <Col span={3}>
                        <Styled.NodataLabel>
                          {t('dateReturned')}
                        </Styled.NodataLabel>
                      </Col>
                      <Col span={3}>
                        <Styled.NodataLabel>
                          {t('assetCost')}
                        </Styled.NodataLabel>
                      </Col>
                    </Styled.RowItem>

                    {assets.map((a: EmployeeAssetType) => (
                      <AssetItem
                        key={a?.id}
                        name={a?.asset?.name}
                        notes={a?.notes}
                        dateLoaded={formatDate(a?.startDate)}
                        dateReturned={formatDate(a?.endDate)}
                        assetCost={a?.asset?.purchasePrice?.toString()}
                        serialNumber={a?.asset?.serialNumber}
                        onDelete={() => a?.asset?.id && onDelete(a.asset.id)}
                        onView={() => onViewCallBack(a)}
                        currency={a?.asset?.currency?.symbol}
                      />
                    ))}
                  </>
                ) : (
                  <>
                    {assets.map((asset: EmployeeAssetType) => (
                      <EmployeeProfileMobileTabs
                        key={asset.id}
                        fields={[
                          { label: 'asset', value: asset?.asset?.name },
                          {
                            label: 'serialNumber',
                            value: asset?.asset?.serialNumber,
                          },
                          { label: 'note', value: asset?.notes },
                          {
                            label: 'dateLoaned',
                            value: formatDate(asset?.startDate),
                          },
                          {
                            label: 'dateReturned',
                            value: formatDate(asset?.endDate),
                          },
                          {
                            label: 'assetCost',
                            value: `${asset?.asset?.purchasePrice?.toString()} ${
                              asset?.asset?.currency?.symbol
                            } `,
                          },
                        ]}
                      />
                    ))}
                  </>
                ))}
            </Row>
            {modalVisible && (
              <AddAssetsModal
                open={modalVisible}
                closeModal={() => setModalVisible(false)}
                assetSelected={assetSelected}
                employeeId={employeeId}
              />
            )}
          </>
        </CardItem>
      )}
    </>
  );
}
