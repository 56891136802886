import React from 'react';
import { StyledButton } from 'components/NewForms/FormStyled';
import { Popover } from 'antd';
import { useTranslation } from 'react-i18next';
import {
  PopoverOverlayInnerStyles,
  PopoverOverlayStyles,
} from '../TableFilterPopover/TableFilterPopoverStyles';
import TenantFeatureComponent from './TenantFeatureComponent';

export default function TableTenantFeaturePopover() {
  const { t } = useTranslation();

  return (
    <Popover
      overlayClassName="popover-content"
      overlayStyle={PopoverOverlayStyles}
      overlayInnerStyle={PopoverOverlayInnerStyles}
      content={<TenantFeatureComponent />}
      trigger="click"
    >
      <StyledButton type="primary" id="configure-popover">
        {t('Add Feature')}
      </StyledButton>
    </Popover>
  );
}
