import React from 'react';
import { Handle, NodeToolbar, Position } from '@xyflow/react';
import { CloseOutlined } from '@ant-design/icons';
import { CircularButton } from '../HierarchyStyles';

export default function CustomNode({ selected, id, data }: any) {
  const handleDeleteClick = () => {
    if (data.onDelete) {
      data.onDelete({ id });
    } else {
      console.error('onDelete function is not defined in data');
    }
  };

  return (
    <>
      <NodeToolbar
        isVisible={data.forceToolbarVisible || undefined}
        position={Position.Right}
      >
        <CircularButton onClick={handleDeleteClick}>
          <CloseOutlined />
        </CircularButton>
      </NodeToolbar>
      <Handle
        type="target"
        position={Position.Top}
        style={{
          width: '10px',
          height: '10px',
          background: '#004EEB',
        }}
      />
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '50px',
          padding: '10px',
          backgroundColor: '#EFF4FF',
          border: '1px solid #004EEB',
          boxShadow: selected ? '0 0 7px #004EEB' : 'none',
          minWidth: '100px',
          borderRadius: '7px',
        }}
      >
        {data.label}
      </div>

      <Handle
        type="source"
        position={Position.Bottom}
        style={{
          width: '10px',
          height: '10px',
          background: 'blue',
        }}
      />
    </>
  );
}
