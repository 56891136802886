import React from 'react';
import { Ideabox } from 'types/Ideabox';
import IdeaboxActions from 'components/Actions/IdeaboxActions';
import i18next from '../i18n';

export const IdeaboxColumnsMobile = [
  {
    dataIndex: 'ideabox',
    key: 'ideabox',
    default: true,
    render: (_: string, item: Ideabox) => (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '1rem',
        }}
      >
        <div>
          <strong>{`${i18next.t('visibility')}: `}</strong>
          {item?.private ? i18next.t('private') : i18next.t('public')}
        </div>

        <div>
          <strong>{`${i18next.t('ideaText')}: `}</strong>
          {item.ideaText}
        </div>
        <div>
          <strong>{`${i18next.t('creator')}: `}</strong>
          {`${item?.employee?.firstName} ${item?.employee?.lastName}`}
        </div>
        <div>
          <strong>{`${i18next.t('tags')}: `}</strong>
          {item?.tags?.length > 0
            ? item.tags
                .map((emp) => `${emp?.firstName} ${emp?.lastName}`)
                .join(', ')
            : i18next.t('noTags')}
        </div>
        <div>
          <IdeaboxActions id={item?.id} data={item} />
        </div>
      </div>
    ),
    width: 100,
  },
];

export const IdeaboxColumns = [
  {
    title: i18next.t('visibility'),
    dataIndex: 'private',
    key: 'private',
    default: true,
    render: (_: string, item: Ideabox) => (
      <>{item?.private ? i18next.t('private') : i18next.t('public')}</>
    ),
    width: 100,

    order: 0,
  },
  {
    title: i18next.t('ideaText'),
    dataIndex: 'ideaText',
    key: 'ideaText',
    default: true,
    width: 140,

    order: 1,
  },
  {
    title: i18next.t('creator'),
    dataIndex: 'employee',
    key: 'employee',
    default: true,
    render: (_: string, item: Ideabox) => (
      <>{`${item?.employee?.firstName} ${item?.employee?.lastName}`}</>
    ),
    width: 140,

    order: 2,
  },
  {
    title: i18next.t('tags'),
    dataIndex: 'tags',
    key: 'tags',
    default: true,
    render: (_: string, item: Ideabox) => (
      <>
        {item?.tags?.length > 0
          ? item.tags
              .map((emp) => `${emp?.firstName} ${emp?.lastName}`)
              .join(', ')
          : i18next.t('noTags')}
      </>
    ),
    width: 140,
    order: 3,
  },
  {
    title: i18next.t('action'),
    dataIndex: 'action',
    key: 'action',
    render: (_: string, row: Ideabox) => (
      <IdeaboxActions id={row?.id} data={row} />
    ),
    default: false,
    width: 130,
    order: 4,
    fixed: 'middle',
  },
];
