import React from 'react';
import CandidatesSkillSetMatching from './CandidatesSkillSetMatching';
import SkillsetMatching from './SkillsetMatching';

export default function GeneralSkillSet() {
  return (
    <>
      <SkillsetMatching />
      <CandidatesSkillSetMatching />
    </>
  );
}
