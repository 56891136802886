import { Col, Form, Row } from 'antd';
import React, { useMemo, useState } from 'react';
import GenericModal from 'ui-v2/components/GenericModal';
import GenericForm from 'components/NewForms/Form/GenericForm';
import { FormItemStyled, StyledButton } from 'components/NewForms/FormStyled';
import { useTranslation } from 'react-i18next';
import { ScheduleInterviewModalIcon } from 'Icons/ScheduleInterviewModalIcon';
import { InputTypes } from 'types/FormTypes';
import { useWorkPositionsSkimData } from 'ui-v2/hooks/useWorkPositionsSkimData';

interface IProps {
  open: boolean;
  closeModal: () => void;
  handleAddNode: (nodeData: { name: string; id: string }) => void;
}

export default function HierarchyModal({
  open,
  closeModal,
  handleAddNode,
}: IProps) {
  const [form] = Form.useForm();
  const [loading] = useState<boolean>(false);
  const { t } = useTranslation();
  const { filteredWorkPositions } = useWorkPositionsSkimData();

  function onFormFinish(values: any) {
    const workPositionName = filteredWorkPositions.find(
      (el) => el.id === values.workPositionId
    )?.label;

    const { workPositionId } = values;

    handleAddNode({ id: workPositionId, name: workPositionName as string });
    form.resetFields();
    closeModal();
  }

  const FormConfiguration: any[][] = useMemo(
    () => [
      [
        {
          col: 24,
          offset: 0,
          name: 'workPositionId',
          label: t('workPosition'),
          type: InputTypes.SELECT,
          showSearch: true,
          selectOptions: filteredWorkPositions,
          rules: [
            {
              required: true,
              message: t('fieldRequired'),
            },
          ],
        },
      ],
    ],
    [filteredWorkPositions]
  );

  return (
    <GenericModal
      title={t('addANewNode')}
      open={open}
      closeModal={closeModal}
      icon={<ScheduleInterviewModalIcon />}
    >
      <GenericForm
        formConfiguration={FormConfiguration}
        onFinish={onFormFinish}
        form={form}
        loading={loading}
      >
        <FormItemStyled style={{ marginTop: 30, marginBottom: 0 }}>
          <Row>
            <Col span={11}>
              <StyledButton
                onClick={() => {
                  form.resetFields();
                  closeModal();
                }}
                htmlType="reset"
                danger
              >
                {t('cancel')}
              </StyledButton>
            </Col>

            <Col span={11} offset={2}>
              <StyledButton
                onClick={() => form.submit()}
                type="primary"
                htmlType="submit"
                loading={loading}
              >
                {t('submit')}
              </StyledButton>
            </Col>
          </Row>
        </FormItemStyled>
      </GenericForm>
    </GenericModal>
  );
}
