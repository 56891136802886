import React from 'react';
import TrainingActions from 'components/Actions/TrainingActions';
import { TrainingType } from 'types/Training';
import * as Styled from 'ui-v2/components/PersonalInfo/PersonalInfoStyles';
import { Row } from 'antd';
import i18next from '../i18n';

export const TrainingsColumnsMobile = [
  {
    dataIndex: 'trainings',
    key: 'trainings',
    render: (_: string, item: TrainingType) => (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '1rem',
        }}
      >
        <div>
          <strong>{`${i18next.t('name')}: `}</strong>
          {item?.name}
        </div>

        <div>
          <strong>{`${i18next.t('type')}: `}</strong>
          {item?.type}
        </div>
        <div>
          <strong>{`${i18next.t('duration')}: `}</strong>
          {`${item?.valueTime} ${item?.timeType}`}
        </div>
        <div>
          <strong>{`${i18next.t('trainingSkills')}: `}</strong>
          {`${item?.skills?.map((i: any) => i).join(', ') || ''}`}
        </div>
        <div>
          <strong>{`${i18next.t('trainingSkills')}: `}</strong>
          {item?.currecyTraining?.symbol === 'ALL'
            ? `${item?.cost.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} ${
                item?.currecyTraining?.symbol
              }`
            : `${item?.cost} ${item?.currecyTraining?.symbol}`}
        </div>
        <div>
          <Row>
            <strong>{`${i18next.t('trainingStatus')}: `}</strong>

            <Styled.TrainingStatusTag status={item?.status || ''}>
              <p>{item?.status}</p>
            </Styled.TrainingStatusTag>
          </Row>
        </div>
        <div>
          <TrainingActions id={item.id} />
        </div>
      </div>
    ),
    default: true,
    order: 0,
    width: 200,
  },
];

export const TrainingsColumns = [
  {
    title: i18next.t('name'),
    dataIndex: 'name',
    key: 'name',
    sorter: (a: TrainingType, b: TrainingType) => a.name.localeCompare(b.name),
    default: true,
    order: 0,
  },
  {
    title: i18next.t('type'),
    dataIndex: 'type',
    key: 'type',
    default: true,
    order: 1,
  },
  {
    title: i18next.t('duration'),
    dataIndex: 'valueTime',
    key: 'duration',
    render: (_: string, item: TrainingType) => (
      <>{`${item?.valueTime} ${item?.timeType}`}</>
    ),
    default: true,
    order: 2,
  },
  {
    title: i18next.t('trainingSkills'),
    dataIndex: 'skills',
    key: 'skills',
    default: true,
    render: (_: string, item: TrainingType) => (
      <>{`${item?.skills?.map((i: any) => i).join(', ') || ''}`}</>
    ),
    order: 3,
  },
  {
    title: i18next.t('trainingCost'),
    dataIndex: 'cost',
    key: 'cost',
    default: true,
    render: (_: string, item: TrainingType) => (
      <>
        {item?.currecyTraining?.symbol === 'ALL'
          ? `${item?.cost.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} ${
              item?.currecyTraining?.symbol
            }`
          : `${item?.cost} ${item?.currecyTraining?.symbol}`}
      </>
    ),
    order: 4,
  },
  {
    title: i18next.t('trainingStatus'),
    dataIndex: 'status',
    key: 'status',
    default: true,
    render: (_: string, item: TrainingType) => (
      <Row justify="start">
        <Styled.TrainingStatusTag status={item?.status || ''}>
          <p>{item?.status}</p>
        </Styled.TrainingStatusTag>
      </Row>
    ),
    order: 5,
  },
  {
    title: i18next.t('action'),
    dataIndex: 'action',
    key: 'action',
    width: 150,
    render: (record: string, row: TrainingType) => (
      <TrainingActions id={row.id} />
    ),
    default: false,
    order: 6,
    fixed: 'right',
  },
];
