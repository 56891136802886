import React, { useMemo, useState } from 'react';
import { Col, Form, Row } from 'antd';

import GenericModal from 'ui-v2/components/GenericModal';
import GenericForm from 'components/NewForms/Form/GenericForm';
import { FormItemStyled, StyledButton } from 'components/NewForms/FormStyled';
import { SkillSubCategory, SkillSubCategoryInputType } from 'types/Skills';
import { OptionType } from 'types/OptionTypes';
import { InputTypes } from 'types/FormTypes';
import { AssignTaskIcon } from 'Icons/AssignTaskIcon';
import { useTranslation } from 'react-i18next';
import { fetchCategory } from 'components/NewForms/SelectWithLoad/utils';
import { SelectLoadPayload } from 'components/NewForms/SelectWithLoad/SelectWithLoad';
import { toastErrorMessages } from 'utils/utilFunctions';
import { getSkillsForCategories } from 'api/skillService';
import { SkillModalRateStyles } from '../SkillsStyles';

interface IProps {
  open: boolean;
  skillOptions: Array<OptionType>;
  closeModal: () => void;
  loading: boolean;
  onSubmit: (formValues: SkillSubCategoryInputType) => void;
}

const AddSkill: React.FC<IProps> = ({
  open,
  skillOptions,
  closeModal,
  onSubmit,
  loading,
}) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const [subCategory, setSubCategory] = useState();
  const [subCategoryOptions, setSubCategoryOptions] =
    useState<SelectLoadPayload>();

  const handleCategoryChange = async () => {
    setSubCategory(form.getFieldValue('skillCategoryId')?.value);
    const skillCategoryId = form.getFieldValue('skillCategoryId')?.value;
    if (skillCategoryId) {
      try {
        const response = await getSkillsForCategories(skillCategoryId);
        if (response) {
          setSubCategoryOptions(
            response.data.subCategories.map((item: SkillSubCategory) => ({
              id: item.id,
              value: item.subCategoryName,
              label: item.subCategoryName,
            }))
          );
        }
      } catch (error) {
        toastErrorMessages(error);
      }
    }
  };

  const AddSkillFormConfiguration: any[][] = useMemo(
    () => [
      [
        {
          col: 24,
          offset: 0,
          name: 'skillCategoryId',
          label: t('skillCategories'),
          type: InputTypes.SELECT_LOAD,
          fetchOptions: fetchCategory,
          onSelect: handleCategoryChange,
          styles: { textTransform: 'capitalize' },
          rules: [
            {
              required: true,
              message: t('Category name is required'),
            },
          ],
        },
      ],
      [
        {
          col: 14,
          offset: 0,
          name: 'skillSubCategoryId',
          label: t('skillName'),
          type: InputTypes.SELECT,
          disabled: !subCategory,
          selectOptions: subCategoryOptions,
          styles: { textTransform: 'capitalize' },
          rules: [
            {
              required: true,
              message: t('Skill name is required'),
            },
          ],
        },
        {
          col: 8,
          offset: 2,
          name: 'yearsOfExperience',
          label: t('exp(Years)'),
          type: InputTypes.INPUT,
          inputProps: {
            type: 'number',
            min: 0,
            step: 0.1,
          },
          rules: [
            {
              required: true,
              message: t('Experience is required'),
            },
          ],
        },
      ],
      [
        {
          col: 24,
          offset: 0,
          name: 'score',
          label: t('skillScore'),
          styles: SkillModalRateStyles,
          type: InputTypes.RATE,
          rules: [
            {
              required: true,
              message: t('Skill score is required!'),
            },
          ],
        },
      ],
    ],
    [skillOptions, subCategoryOptions, subCategory]
  );

  return (
    <GenericModal
      title={t('addSkill')}
      open={open}
      closeModal={closeModal}
      icon={<AssignTaskIcon />}
    >
      <GenericForm
        formConfiguration={AddSkillFormConfiguration}
        onFinish={onSubmit}
        form={form}
        loading={loading}
      >
        <FormItemStyled style={{ marginTop: 30, marginBottom: 0 }}>
          <Row>
            <Col span={11}>
              <StyledButton onClick={closeModal} htmlType="reset" danger>
                {t('cancel')}
              </StyledButton>
            </Col>
            <Col span={11} offset={2}>
              <StyledButton type="primary" htmlType="submit">
                {t('confirm')}
              </StyledButton>
            </Col>
          </Row>
        </FormItemStyled>
      </GenericForm>
    </GenericModal>
  );
};

export default AddSkill;
