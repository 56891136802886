import React from 'react';
import { ExpensesReport } from 'types/ExpensesReport';
import { Row } from 'antd';
import * as Styled from 'ui-v2/components/PersonalInfo/PersonalInfoStyles';
import { PHONE_SCREEN_BREAKPOINT } from 'utils/constants';
import ExpenseReportActions from 'components/Actions/ExpenseReportActions';
import i18next from '../i18n';

const isPhoneScreen = window.innerWidth < PHONE_SCREEN_BREAKPOINT;

export const ExpenseReportColumnsMobile = [
  {
    dataIndex: 'expenses',
    key: 'expenses',
    render: (_: string, item: ExpensesReport) => (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '1rem',
        }}
      >
        <div>
          <strong>{`${i18next.t('employee')}: `}</strong>
          {`${item?.employee?.firstName} ${item?.employee?.lastName}`}
        </div>

        <div>
          <strong>{`${i18next.t('expenseItems')}: `}</strong>
          {item.expenseLines.length}
        </div>
        <div>
          <strong>{`${i18next.t('totalAmount')}: `}</strong>
          {`${item.expenseLines
            .reduce((total, line) => total + Number(line.amount), 0)
            .toFixed(2)} ${item?.expenseLines[0]?.currency?.symbol}`}
        </div>
        <div>
          <strong>{`${i18next.t('expenseStatus')}: `}</strong>
          <Styled.ExpenseStatusTag
            status={item?.status || ''}
            isPhoneScreen={isPhoneScreen}
          >
            <p>{item?.status}</p>
          </Styled.ExpenseStatusTag>
        </div>
        <div>
          <ExpenseReportActions id={item?.id} />
        </div>
      </div>
    ),
    default: true,
    order: 0,
    width: 200,
  },
];

export const ExpenseReportColumns = [
  {
    title: i18next.t('employee'),
    dataIndex: 'employee',
    key: 'employee',
    default: false,
    render: (_: string, item: ExpensesReport) => (
      <>{`${item?.employee?.firstName} ${item?.employee?.lastName}`}</>
    ),
    order: 0,
    width: 150,
  },
  {
    title: i18next.t('expenseStatus'),
    dataIndex: 'status',
    key: 'status',
    default: false,
    render: (_: string, item: ExpensesReport) => (
      <Row justify="start">
        <Styled.ExpenseStatusTag status={item?.status || ''}>
          <p>{item?.status}</p>
        </Styled.ExpenseStatusTag>
      </Row>
    ),
    order: 1,
    width: 150,
  },
  {
    title: i18next.t('expenseItems'),
    dataIndex: 'expenseItems',
    key: 'expenseItems',
    render: (_: string, item: ExpensesReport) => (
      <>{item.expenseLines.length}</>
    ),
    default: false,
    order: 2,
    width: 150,
  },
  {
    title: i18next.t('totalAmount'),
    dataIndex: 'total',
    key: 'total',
    render: (_: string, item: ExpensesReport) => {
      const totalAmount = item.expenseLines.reduce(
        (total, line) => total + Number(line.amount),
        0
      );
      return `${totalAmount.toFixed(2)} ${
        item?.expenseLines[0]?.currency?.symbol
      }`;
    },
    default: false,
    order: 3,
    width: 150,
  },
  {
    title: i18next.t('action'),
    dataIndex: 'action',
    key: 'action',
    width: 130,
    render: (record: string, row: ExpensesReport) => (
      <ExpenseReportActions id={row?.id} />
    ),
    default: false,
    order: 4,
    fixed: 'middle',
  },
];
