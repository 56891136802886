import { Moment } from 'moment';
import { EmployeeType } from './Employee';

export enum ReasonType {
  Vacation = 'Vacation',
  Health = 'Health/Disability',
  Break = 'Break',
  Maternity = 'Maternity',
  Absence = 'Absence',
}

export enum TimeOffStatus {
  PENDING = 'PENDING',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
}

export interface IApprover {
  id: string;
  action: TimeOffStatus;
  actionDate: Date;
  employee: EmployeeType;
  color?: string;
  approverid: string;
}

export interface TimeOff {
  id: string;
  dates?: any;
  timeOff?: string[];
  status: TimeOffStatus;
  aprovalStatus: TimeOffStatus;
  statusColor: string;
  approvedBy?: string;
  reason?: ReasonType;
  description?: string;
  employeeId: string;
  approver: IApprover[];
  employee?: EmployeeType;
}

export interface DateType {
  date: Date;
  timeOffStart: Date;
  timeOffEnd: Date;
}

interface Substitutes {
  employeeId: string;
  substitutes: EmployeeType[];
}

export interface TimeOffRequest {
  id: string;
  employeeId: string;
  firstName: string;
  lastName: string;
  action: 'PENDING' | string;
  actionDate: string;
  dates: string;
  reason: string;
  approvers: {
    id: string;
    action: TimeOffStatus;
    actionDate: string;
    employeeId: string;
    firstName: string;
    lastName: string;
  }[];
  substitutes: {
    id: string;
    email: string;
    firstName: string;
    lastName: string;
    length: number;
  }[];
  timeOffId: string;
  aprovalStatus: TimeOffStatus;
}

export interface EmployeeTimeOffRequest {
  id: string;
  approver: Array<{
    id: string;
    action: TimeOffStatus;
    actionDate: string;
    employee: EmployeeType;
  }>;
  aprovalStatus: TimeOffStatus;
  createdAt: string;
  dates: string;
  description: string;
  reason: string;
  updatedAt: string;
}

export interface ITimeOffRequestDTO {
  timeOffs: TimeOffRequest[];
  employees: EmployeeType[];
  substitutes: Substitutes[];
}

export interface TimeOffCreateDTO {
  dates: string;
  reason: ReasonType;
  approvers: string[];
  description: string;
  employeeId: string;
}
export interface TimeOffRecord {
  date?: Moment;
  timeOffStart?: Moment;
  timeOffEnd?: Moment;
}

export interface IApproverUpdateDTO {
  timeOffId: string;
  employeeId: string;
  action: TimeOffStatus;
  admin?: boolean;
}

export interface GetTimeOffToApproveDTO {
  employees?: string[];
  projects?: string[];
  departments?: string[];
}
