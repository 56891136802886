import agent from 'api/agent';
import { CreateCandidateEmployeeRank } from 'types/CandidateEmployeeRank';
import { QueryParamsDTO } from 'types/QueryParams';
import { apiURLs } from './constants';

export const createCandidateEmployeeRank = (
  values: Array<CreateCandidateEmployeeRank>
) => agent.post(apiURLs.candidateEmployeeRank, values);

export const getPositionCandidateEmployeeRanks = (
  openPositionId: string,
  pageOptionsDto: QueryParamsDTO
) =>
  agent.post(
    `${apiURLs.candidateEmployeeRank}/openPosition/${openPositionId}`,
    pageOptionsDto
  );

export const deleteCandidateEmployeeRank = (documentId: string) =>
  agent.delete(`${apiURLs.candidateEmployeeRank}/${documentId}`);
