import {
  CreateLegalDaysOffDTO,
  UpdateLegalDaysOffDTO,
} from 'types/LegalDaysOff';
import agent from './agent';
import { apiURLs } from './constants';

export const getAllDaysOffRules = (tenantLocationId?: string) =>
  agent.get(apiURLs.legalDaysOff, { params: { tenantLocationId } });

export const getCurrentDaysOffRule = (tenantLocationId: string, date?: Date) =>
  agent.get(`${apiURLs.legalDaysOff}/current`, {
    params: { tenantLocationId, date },
  });

export const createDaysOffRule = (payload: CreateLegalDaysOffDTO) =>
  agent.post(apiURLs.legalDaysOff, payload);

export const updateDaysOffRule = (id: string, payload: UpdateLegalDaysOffDTO) =>
  agent.patch(`${apiURLs.legalDaysOff}/${id}`, payload);
