import { Col, Form, Row } from 'antd';
import React, { useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import {
  convertBase64,
  convertDateToUTC,
  convertUTCtoLocalTime,
  getValidFileContent,
  toastErrorMessages,
} from 'utils/utilFunctions';
import { AuthUserState } from 'redux/authUser/types';
import { updateUserProfile } from 'api/userProfileService';
import { useDispatch } from 'react-redux';
import { useUsersData } from 'ui-v2/hooks/useUsersData';
import GenericModal from 'ui-v2/components/GenericModal';

import { FormConfigurationType, InputTypes } from 'types/FormTypes';
import { UserUpdateType } from 'types/User';
import { RcFile } from 'antd/lib/upload';
import { Image } from 'types/Image';
import useGetSelectOptions from 'api/hooks/useGeSelectOptions';
import { AUTH_ROLES } from 'types/Auth';
import { CreateUserDto } from 'redux/users/types';
import { addNewUser } from 'api/userService';
import { StatusType } from 'types/Status';
import { fetchUsers } from 'redux/users/actions';
import { getAuthUser } from 'redux/authUser/actions';
import useHeader from 'ui-v2/hooks/useHeader';
import { useTranslation } from 'react-i18next';
import GenericForm from '../Form/GenericForm';
import { FormItemStyled, StyledButton } from '../FormStyled';
import { prefixSelector } from '../EmployeeForm/components/utils';
import { fetchProjectOptions } from '../SelectWithLoad/utils';
import { ValueType } from '../SelectWithLoad/SelectWithLoad';
import { isFormEdited } from '../Form/utils';

interface IProps {
  open: boolean;
  closeModal: () => void;
  authUserData?: AuthUserState;
}
function UserForm({ open, closeModal, authUserData }: IProps) {
  const [form] = Form.useForm();
  const { entity } = useHeader();
  const [loading, isLoading] = useState<boolean>(false);
  const [fileToUpload, setFileToUpload] = useState<Image>();
  const [guestTH, isGuestTh] = useState(false);
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const authUser = authUserData?.authUser;

  const { user, roleId } = useUsersData();
  const [twoFac, seTwoFac] = useState(user?.twoFactorEnabled);
  const [isActive, setIsActive] = useState(user?.isActive);
  const { optionsType: roles } = useGetSelectOptions({
    type: 'roles',
    transform: (item) => ({
      id: item.name,
      value: item.name,
      label: item.name,
    }),
  });
  async function onFormFinish() {
    const formValues = form.getFieldsValue(true);
    delete formValues?.avatar;
    isLoading(true);
    if (user?.id || authUser?.id) {
      const payload: UserUpdateType = {
        ...formValues,
        birthDate: convertDateToUTC(formValues.birthDate),
        isActive,
        twoFactorEnabled: twoFac,
        ...(fileToUpload && {
          avatar: {
            ...fileToUpload,
            content: fileToUpload?.content,
          },
        }),
      };
      if (
        isFormEdited({
          formValues: payload,
          valuesToCheck: user,
          entity: 'users',
        })
      ) {
        isLoading(false);
        closeModal();
        return;
      }
      updateUserProfile(user?.id || authUser?.id, payload)
        .then((response) => {
          if (response.status === 200 && response.data) {
            if (entity === 'my-profile') {
              dispatch(getAuthUser());
            } else if (roleId !== null) {
              dispatch(fetchUsers({ roleId }));
            } else {
              dispatch(fetchUsers());
            }
            toast.success(t('Successfully updated user!'));
            closeModal();
          }
        })
        .catch((error) => toastErrorMessages(error))
        .finally(() => isLoading(false));
    } else {
      const payload: CreateUserDto = {
        ...formValues,
        avatar: {
          ...fileToUpload,
          content: fileToUpload?.content,
        },
        roles: [`${formValues.roles}`],
        birthDate: convertDateToUTC(formValues.birthDate),
        projects: formValues?.projects?.map((item: ValueType) => item?.value),
        sendAccountEmail: true,
      };
      if (!guestTH) {
        delete payload.projects;
      }
      addNewUser(payload)
        .then((res) => {
          if (res.status === 200 && res.data?.id) {
            isLoading(false);
            toast.success(t('User is added successfully!'));
            closeModal();
            dispatch(fetchUsers());
          }
        })
        .catch((error) => {
          toastErrorMessages(error);
        })
        .finally(() => {
          isLoading(false);
        });
    }
  }

  async function uploadAction(file: RcFile) {
    const base64 = (await convertBase64(file)) as string;
    const validBase64 = getValidFileContent(base64);
    setFileToUpload({ name: file.name, content: validBase64, type: file.type });
    return false;
  }

  const UserFormConfiguration: FormConfigurationType[][] = useMemo(() => {
    const userForm: FormConfigurationType[][] = [
      user?.id
        ? [
            {
              col: 11,
              offset: 0,
              name: 'twoFactorEnabled',
              label: t('twoFactorAuthentication'),
              type: InputTypes.POPCONFIRM_SWITCH,
              popconfirmMessage: t('doYouWantToDisableTwoFactorAuthentication'),
              switchProps: {
                checkedChildren: StatusType.ENABLED,
                unCheckedChildren: StatusType.Disabled,
              },
              setFieldValue: seTwoFac,
              defaultChecked: !!user?.twoFactorEnabled,
            },
            {
              col: 11,
              offset: 2,
              name: 'isActive',
              label: t('accountStatus'),
              type: InputTypes.POPCONFIRM_SWITCH,
              popconfirmMessage: t('doYouWantToInactivateThisAccount?'),
              switchProps: {
                checkedChildren: StatusType.ACTIVE,
                unCheckedChildren: StatusType.INACTIVE,
              },
              setFieldValue: setIsActive,
              defaultChecked: !!user?.isActive,
            },
          ]
        : [],
      [
        {
          col: 11,
          offset: 0,
          name: 'firstName',
          label: t('firstName'),
          type: InputTypes.INPUT,
          defaultValue: user?.firstName || authUser?.firstName,
          rules: [
            {
              required: true,
              message: t('fieldRequired'),
            },
          ],
        },
        {
          col: 11,
          offset: 2,
          name: 'lastName',
          label: t('lastName'),
          type: InputTypes.INPUT,
          defaultValue: user?.lastName || authUser?.lastName,
          rules: [
            {
              required: true,
              message: t('fieldRequired'),
            },
          ],
        },
      ],
      [
        {
          col: 24,
          offset: 0,
          name: 'birthDate',
          label: t('birthdate'),
          type: InputTypes.DATEPICKER,
          birthday: true,
          defaultValue:
            convertUTCtoLocalTime(user?.birthDate || authUser?.birthDate) || '',
          rules: [
            {
              required: true,
              message: t('fieldRequired'),
            },
          ],
        },
      ],
      user?.id
        ? [
            {
              col: 24,
              offset: 0,
              name: 'roles',
              label: t('role'),
              type: InputTypes.SELECT,
              showSearch: true,
              styles: { textTransform: 'capitalize' },
              defaultValue: user?.roles?.at(0)?.name,
              selectOptions:
                roles
                  ?.filter((i) => i?.label !== AUTH_ROLES.SUPER_ADMIN)
                  .map((i) => ({
                    ...i,
                    label: `${i.label}`.replaceAll('_', ' '),
                  })) || [],
              onSelect: (role) =>
                isGuestTh(AUTH_ROLES.GUEST_TRACKING_HOURS === role),
              rules: [
                {
                  required: true,
                  message: t('Role is required'),
                },
              ],
            },
          ]
        : [],
      user?.id
        ? [
            {
              col: 24,
              offset: 0,
              name: 'phoneNumber',
              label: t('phoneNumber'),
              type: InputTypes.SELECTPREFIX,
              defaultValue: user?.phoneNumber || authUser?.phoneNumber,
              rules: [
                {
                  required: true,
                  message: t('Phone number is required!'),
                },
              ],
              prefix: {
                name: 'phoneNumberPrefix',
                selectOptions: prefixSelector,
                defaultValue:
                  user?.phoneNumberPrefix || authUser?.phoneNumberPrefix,
                placeholder: '',
              },
            },
          ]
        : [
            {
              col: 24,
              offset: 0,
              name: 'email',
              label: t('email'),
              type: InputTypes.INPUT,
              defaultValue: user?.email || authUser?.email,
              rules: [
                {
                  required: true,
                  message: t('requiredEmail'),
                },
              ],
            },
          ],
      [
        {
          col: 24,
          offset: 0,
          name: 'phoneNumber',
          label: t('phoneNumber'),
          type: InputTypes.SELECTPREFIX,
          defaultValue: user?.phoneNumber || authUser?.phoneNumber,
          rules: [
            {
              required: true,
              message: t('Phone number is required!'),
            },
          ],
          prefix: {
            name: 'phoneNumberPrefix',
            selectOptions: prefixSelector,
            defaultValue: authUser?.phoneNumberPrefix,
            placeholder: '',
          },
        },
      ],
      user?.projects
        ? [
            {
              col: 24,
              offset: 0,
              name: 'projects',
              label: t('projects'),
              type: InputTypes.SELECT_LOAD,
              defaultValue: user?.projects?.map((el: any) => el?.id),
              fetchOptions: fetchProjectOptions,
              mode: 'multiple',
              rules: [
                {
                  required: true,
                  message: t('Project is required!'),
                },
              ],
            },
          ]
        : [],
      entity === 'my-profile'
        ? [
            {
              col: 24,
              offset: 0,
              name: 'avatar',
              label: t('avatar'),
              type: 'upload',
              uploadProps: {
                accept: 'image/png, image/jpeg',
                beforeUpload: (fileToUpload: RcFile) =>
                  uploadAction(fileToUpload),
                maxCount: 1,
                listType: 'picture',
                defaultFileList: [
                  {
                    uid: '1',
                    name: 'Profile Photo',
                    url: authUser?.avatar,
                  },
                ],
              },
            },
          ]
        : [],
    ];

    const userExtraFields: FormConfigurationType[][] = [
      [
        {
          col: 24,
          offset: 0,
          name: 'roles',
          label: t('role'),
          type: InputTypes.SELECT,
          showSearch: true,
          styles: { textTransform: 'capitalize' },
          selectOptions:
            roles
              ?.filter((i) => i?.label !== 'super_admin')
              .map((i) => ({
                ...i,
                label: `${i.label}`.replaceAll('_', ' '),
              })) || [],
          onSelect: (role) =>
            isGuestTh(AUTH_ROLES.GUEST_TRACKING_HOURS === role),
          rules: [
            {
              required: true,
              message: t('Role is required'),
            },
          ],
        },
      ],
      guestTH
        ? [
            {
              col: 24,
              offset: 0,
              name: 'projects',
              label: t('projects'),
              type: InputTypes.SELECT_LOAD,
              fetchOptions: fetchProjectOptions,
              mode: 'multiple',
              rules: [
                {
                  required: true,
                  message: t('Project is required!'),
                },
              ],
            },
          ]
        : [],
      [
        {
          col: 24,
          offset: 0,
          name: 'avatar',
          label: t('avatar'),
          type: 'upload',
          uploadProps: {
            accept: 'image/png, image/jpeg',
            beforeUpload: (fileToUpload: RcFile) => uploadAction(fileToUpload),
            maxCount: 1,
            listType: 'picture',
          },
        },
      ],
    ];

    if (user?.id || authUser?.id) {
      return userForm;
    }

    return userForm.concat(userExtraFields);
  }, [user, roles, guestTH, authUser]);

  return (
    <GenericModal
      title={
        // eslint-disable-next-line no-nested-ternary
        user?.id
          ? t('Edit User')
          : authUser?.id
          ? t('editProfile')
          : t('Add User')
      }
      open={open}
      closeModal={closeModal}
    >
      <GenericForm
        formConfiguration={UserFormConfiguration}
        form={form}
        loading={loading}
        onFinish={onFormFinish}
      >
        <FormItemStyled style={{ marginTop: 30, marginBottom: 0 }}>
          <Row>
            <Col span={11}>
              <StyledButton
                onClick={() => {
                  closeModal();
                }}
                htmlType="reset"
                danger
              >
                {t('cancel')}
              </StyledButton>
            </Col>
            <Col span={11} offset={2}>
              <StyledButton type="primary" htmlType="submit" loading={loading}>
                {t('submit')}
              </StyledButton>
            </Col>
          </Row>
        </FormItemStyled>
      </GenericForm>
    </GenericModal>
  );
}

export default UserForm;
