import React, { useEffect, useState } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import CardItem from 'ui-v2/components/Card';
import { PALETE } from 'components/styledComponents/colors';
import { Col, Row, Spin, Typography } from 'antd';
import moment, { Moment } from 'moment';
import { useTranslation } from 'react-i18next';
import { getTrackedHoursBarChart } from 'api/trackedHoursService';
import { getEmployeeCount } from 'api/employeeService';
import { barChartOptions } from 'ui-v2/routes/tracking/trackingTabs/trackingTabContent/trackingSummary/utils';
import { StyledTrackingSummaryBarChartDashboard } from 'ui-v2/routes/tracking/trackingTabs/trackingTabContent/trackingSummary/StyledTrackingSummary';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const getPast10Days = () => {
  const daysArray = [];
  for (let i = 0; i < 10; i += 1) {
    const day = moment().subtract(i, 'days');
    daysArray.push(day);
  }
  return daysArray;
};

export const BarChartTrackingHours = () => {
  const [loading, setLoading] = useState(true);
  const past10Days: Moment[] = getPast10Days().reverse();
  const [data, setData] = useState(false);
  const { t } = useTranslation();
  const [barData, setBarData] = useState<any>({
    past10Days,
    datasets: [
      {
        label: 'Average hours',
        data: [],
        backgroundColor: PALETE['rose-400'],
      },
    ],
  });

  const getData = async () => {
    let count: number;
    await getEmployeeCount().then((response) => {
      count = response.data;
    });
    await getTrackedHoursBarChart({
      searchOptions: {
        startDate: moment().subtract(10, 'days').format('YYYY-MM-DD'),
      },
    })
      .then((response) => {
        if (response.status === 200 && response.data) {
          const values = response.data.map((item: any) => ({
            ...item,
            sum: item.sum / count,
          }));

          const dateSet = new Set(
            past10Days.map((date) => `${date.date()}/${date.month() + 1}`)
          );

          setBarData({
            labels: Array.from(dateSet),
            datasets: [
              {
                label: 'Avg hours',
                data: past10Days.map(
                  (day: Moment) =>
                    values.find(
                      (item: any) =>
                        day.date() === item.days && day.month() === item.months
                    )?.sum
                ),
                backgroundColor: PALETE['blue-400'],
              },
            ],
          });
          setData(response.data.length);
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        console.log('error');
      });
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <CardItem title={t('Average Working Hours')} minHeight={0}>
      <Spin spinning={loading}>
        {!loading && !data ? (
          <Row
            justify="center"
            align="middle"
            style={{
              minHeight: 400,
            }}
          >
            <Col>
              <Typography.Text
                style={{
                  display: 'block',
                  textAlign: 'center',
                }}
              >
                {t('noDataYet.')}
              </Typography.Text>
              <Typography.Link
                style={{
                  display: 'block',
                  textAlign: 'center',
                }}
                href="candidates/add-candidate"
              >
                {t('addSomeCandidates&EmployeesFirst!')}
              </Typography.Link>
            </Col>
          </Row>
        ) : (
          <StyledTrackingSummaryBarChartDashboard
            data={barData}
            options={barChartOptions}
          />
        )}
      </Spin>
    </CardItem>
  );
};
export default BarChartTrackingHours;
