import React, { useState, useEffect } from 'react';
import { Col, Row, Spin, Typography, Select } from 'antd';
import { HeatMapGrid } from 'react-grid-heatmap';
import { useTranslation } from 'react-i18next';
import CardItem from 'ui-v2/components/Card';
import { toastErrorMessages } from 'utils/utilFunctions';
import { getAttendanceMatrixForEmployee } from 'api/employeeDashobard';
import {
  StyledContainer,
  StyledNoDataText,
  StyledSelectWrapper,
} from './EmployeeAttendanceStyles';

const { Option } = Select;

interface AttendanceMatrixData {
  xLabels: string[];
  yLabels: string[];
  data: number[][];
}

const EmployeeAttendance: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();
  const [matrixData, setMatrixData] = useState<AttendanceMatrixData>({
    xLabels: [],
    yLabels: [],
    data: [],
  });
  const [selectedMonth, setSelectedMonth] = useState<string>('2024-09');
  const employeeId = localStorage.getItem('EMPLOYEE_ID') as string;

  const months = Array.from({ length: 12 }, (_, i) => {
    const date = new Date(2024, i, 1);
    return {
      value: `${date.getFullYear()}-${(i + 1).toString().padStart(2, '0')}`,
      label: date.toLocaleString('default', { month: 'long' }),
    };
  });

  async function fetchMatrixData(month: string) {
    setLoading(true);
    try {
      const { data } = await getAttendanceMatrixForEmployee(month, employeeId);
      setMatrixData(data);
    } catch (error) {
      toastErrorMessages(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchMatrixData(selectedMonth);
  }, [selectedMonth]);

  const renderContent = () => {
    if (loading) {
      return <Spin />;
    }

    if (
      matrixData.data.length === 0 ||
      !Array.isArray(matrixData.xLabels) ||
      !Array.isArray(matrixData.yLabels) ||
      !Array.isArray(matrixData.data)
    ) {
      return (
        <StyledNoDataText justify="center" align="middle">
          <Col>
            <Typography.Text style={{ display: 'block', textAlign: 'center' }}>
              {t('noDataYet.')}
            </Typography.Text>
            <Typography.Link
              style={{ display: 'block', textAlign: 'center' }}
              href="employees/add-employee"
            >
              {t('addSomeEmployeesFirst!')}
            </Typography.Link>
          </Col>
        </StyledNoDataText>
      );
    }

    const visibleData = matrixData.data[0];

    return (
      <StyledContainer>
        <HeatMapGrid
          data={[visibleData]}
          xLabels={matrixData.xLabels}
          cellRender={(x, y, value) => (
            <div
              title={`Date: ${matrixData.xLabels[x]} = ${
                value === 1 ? 'Checked In' : 'Not Checked In'
              }`}
            >
              {value === 1 ? '✔' : ''}
            </div>
          )}
          yLabelsStyle={() => ({
            fontSize: '0.9rem',
            textTransform: 'uppercase',
            color: '#333',
            transform: 'rotate(-45deg)',
            fontWeight: 'bold',
          })}
          xLabelsStyle={() => ({
            color: '#333',
            fontSize: '0.8rem',
            textAlign: 'center',
            transform: 'rotate(-45deg)',
            transformOrigin: 'top left',
            marginTop: '1rem',
            display: 'inline-block',
            width: '1.8rem',
          })}
          cellStyle={(_x, _y, ratio) => ({
            background: ratio === 1 ? '#4caf50' : '#e0e0e0',
            fontSize: '1rem',
            color: '#000',
          })}
          cellHeight="1.8rem"
          xLabelsPos="bottom"
          yLabelsPos="left"
          square
        />
      </StyledContainer>
    );
  };

  return (
    <CardItem
      title={t('Attendance Matrix')}
      minHeight={450}
      rightButton={
        <Row justify="end">
          <StyledSelectWrapper xs={24} lg={10} md={16}>
            <Select
              value={selectedMonth}
              onChange={setSelectedMonth}
              style={{ width: '100%' }}
            >
              {months.map((month) => (
                <Option key={month.value} value={month.value}>
                  {month.label}
                </Option>
              ))}
            </Select>
          </StyledSelectWrapper>
        </Row>
      }
    >
      <>{renderContent()}</>
    </CardItem>
  );
};

export default EmployeeAttendance;
