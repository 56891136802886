import { Reducer } from 'react';
import { Action } from 'redux';
import {
  CandidateSkillsetAction,
  CandidatesSkillsetState,
  SkillsetMatchingActions,
  SkillsetMatchingState,
} from './types';

const initialState: SkillsetMatchingState = {
  data: {
    data: [],
    meta: [],
  },
  loading: false,
  loaded: false,
  errored: false,
  error: null,
};

const canidateInitialState: CandidatesSkillsetState = {
  data: {
    data: [],
    meta: [],
  },
  loading: false,
  loaded: false,
  errored: false,
  error: null,
};
const skillsetMatchingReducer: Reducer<SkillsetMatchingState, Action> = (
  state = initialState,
  action: any
) => {
  switch (action.type) {
    case SkillsetMatchingActions.FETCH_SKILLSETMATCHING_START:
      state = { ...state, loading: true };
      break;
    case SkillsetMatchingActions.FETCH_SKILLSETMATCHING_SUCCESS:
      state = { ...state, loading: false, loaded: true, data: action.payload };
      break;
    case SkillsetMatchingActions.FETCH_SKILLSETMATCHING_ERRORED:
      state = {
        ...state,
        loading: false,
        loaded: false,
        errored: true,
        error: action.payload,
      };
      break;
    case SkillsetMatchingActions.CLEAR_SKILLSETMATCHING:
      state = { ...initialState };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

const canidadatesInitialState: CandidatesSkillsetState = {
  data: {
    data: [],
    meta: [],
  },
  loading: false,
  loaded: false,
  errored: false,
  error: null,
};

const candidatesSkillsetReducer: Reducer<CandidatesSkillsetState, Action> = (
  state = canidadatesInitialState,
  action: any
) => {
  switch (action.type) {
    case CandidateSkillsetAction.FETCH_CANDIDATESSKILLSET_START:
      state = { ...state, loading: true };
      break;
    case CandidateSkillsetAction.FETCH_CANDIDATESSKILLSET_SUCCESS:
      state = { ...state, loading: false, loaded: true, data: action.payload };
      break;
    case CandidateSkillsetAction.FETCH_CANDIDATESSKILLSET_ERRORED:
      state = {
        ...state,
        loading: false,
        loaded: false,
        errored: true,
        error: action.payload,
      };
      break;
    case CandidateSkillsetAction.CLEAR_CANDIDATESSKILLSET:
      state = { ...canidateInitialState };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export {
  skillsetMatchingReducer as SkillsetMatchingReducer,
  candidatesSkillsetReducer as CandidatesSkillsetReducer,
};
