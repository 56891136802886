import React, { useMemo } from 'react';
import { FormInstance } from 'antd';

import { Image as ImageType } from 'types/Image';
import { FormConfigurationType } from 'types/FormTypes';
import { useTenantsData } from 'ui-v2/hooks/useTenantsData';
import { useTenantConfigData } from 'ui-v2/hooks/useTenantConfigData';
import GenericForm from 'components/NewForms/Form';
import TenantConfigVideoField from './TenantConfigVideoField';
import {
  getTenantOnboardingFormConfig,
  uploadTenantConfigAction,
} from '../utils';
import DocumentUpload from '../TenantDocumentUpload';
import { DocumentsState } from '../TenantConfigFormContent';

interface TenantGeneralConfigProps {
  generalConfigForm: FormInstance;
  companyDescriptionPanelForm: FormInstance;
  onFinish: (values: any) => void;
  setFileToUpload: (file: ImageType) => void;
  handleDocumentChange: (docs: any) => void;
  documents: DocumentsState;
}

const TenantGeneralConfig: React.FC<TenantGeneralConfigProps> = ({
  generalConfigForm,
  companyDescriptionPanelForm,
  setFileToUpload,
  onFinish,
  handleDocumentChange,
  documents,
}) => {
  const { tenantPersisted: tenant } = useTenantsData();
  const { data: tenantConfig } = useTenantConfigData();

  const videoUrl = generalConfigForm.getFieldValue('videoUrl');

  const generalFormConfiguration: FormConfigurationType[][] = useMemo(() => {
    const formConfig: FormConfigurationType[][] = [
      ...getTenantOnboardingFormConfig({
        tenantConfig,
        tenant,
        uploadAction: (file) => uploadTenantConfigAction(file, setFileToUpload),
      }),
    ];
    return formConfig;
  }, [tenantConfig, tenant, videoUrl, companyDescriptionPanelForm]);

  return (
    <GenericForm
      form={generalConfigForm}
      formConfiguration={generalFormConfiguration}
      onFinish={onFinish}
    >
      <TenantConfigVideoField videoUrl={videoUrl} />
      {tenant?.id && (
        <DocumentUpload
          tenantId={tenant.id}
          isOnBoardingCompleted={tenant?.isOnBoardingCompleted || false}
          documents={documents}
          handleDocumentChange={handleDocumentChange}
        />
      )}
    </GenericForm>
  );
};

export default TenantGeneralConfig;
