export enum Requests {
  FETCH_EMPLOYEE_REQUESTS_START = 'FETCH_EMPLOYEE_REQUESTS_START',
  FETCH_EMPLOYEE_REQUESTS_SUCCESS = 'FETCH_EMPLOYEE_REQUESTS_SUCCESS',
  FETCH_EMPLOYEE_REQUESTS_ERRORED = 'FETCH_EMPLOYEE_REQUESTS_ERRORED',
}

export interface EmployeeRequestsState {
  data: EmployeeRequestsState[];
  loading: boolean;
  loaded: boolean;
  errored: boolean;
  error: any;
}
