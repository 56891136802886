import React from 'react';
import * as Styled from 'ui-v2/components/DataGridComponents';
import SkillActions from 'components/Actions/SkillActions';
import i18next from 'i18next';

export const SkillsColumnsMobile = [
  {
    dataIndex: 'skillcategories',
    key: 'skillcategories',
    render: (_: string, item: any) => (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '1rem',
        }}
      >
        <div>
          <strong>{`${i18next.t('categoryName')}: `}</strong>
          {item?.skills_category_categoryName}
        </div>

        <div>
          <strong>{`${i18next.t('numberofSkills')}: `}</strong>
          {item?.skillSubCategoryCount}
        </div>

        <div>
          <SkillActions id={item.skills_category_id} />
        </div>
      </div>
    ),
    default: true,
    order: 0,
    width: 200,
  },
];

export const SkillsColumns = [
  {
    title: i18next.t('categoryName'),
    dataIndex: 'skills_category_categoryName',
    key: 'skills_category_categoryName',
    ellipsis: true,
    sorter: (a: any, b: any) =>
      a.skills_category_categoryName.localeCompare(
        b.skills_category_categoryName
      ),
    render: (_: string, item: any) => (
      <Styled.TextBold>{item?.skills_category_categoryName}</Styled.TextBold>
    ),
    default: true,
    order: 0,
  },
  {
    title: i18next.t('numberofSkills'),
    dataIndex: 'skillSubCategoryCount',
    key: 'skillSubCategoryCount',
    ellipsis: true,
    sorter: (a: any, b: any) =>
      a.skillSubCategoryCount.localeCompare(b.skillSubCategoryCount),
    render: (_: string, item: any) => (
      <Styled.TextBold>{item?.skillSubCategoryCount}</Styled.TextBold>
    ),
    default: true,
    order: 0,
  },
  {
    title: i18next.t('action'),
    dataIndex: 'action',
    key: 'action',
    width: 120,
    render: (_: string, row: any) => (
      <SkillActions id={row.skills_category_id} />
    ),
    default: false,
    order: 2,
  },
];
