import React, { useEffect, useState } from 'react';
import Content from 'ui-v2/components/Content';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import DataGrid from 'components/common/DataGrid/DataGrid';
import useHeader from 'ui-v2/hooks/useHeader';
import { useAssetLogsData } from 'ui-v2/hooks/useAssetLogsData';
import { fetchAssetLogsById } from 'redux/assetLogs/actions';
import { useScreenSize } from 'ui-v2/hooks/useScreenSize';
import {
  AssetLogsColumns,
  AssetLogsColumnsMobile,
} from 'table-configuration/AssetLogsConfig';
import moment from 'moment';
import DateModal from './DateModal';

export default function AssetLogs() {
  const { list, pagination, loading } = useAssetLogsData();
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchTerm, setSearchTerm] = useState<string>('');
  const { take } = useHeader();
  const dispatch = useDispatch();
  const [modal, setModal] = useState(false);
  const { isMobile } = useScreenSize();

  useEffect(() => {
    if (searchParams.get('startDate') === 'true') {
      setModal(true);
      searchParams.delete('startDate');
      setSearchParams(searchParams);
    }
    if (searchParams.get('endDate') === 'true') {
      setModal(true);
      searchParams.delete('endDate');
      setSearchParams(searchParams);
    }

    if (searchParams.get('startDate') && searchParams.get('endDate')) {
      const id: string = searchParams.get('id') as string;
      const params = {
        pageOptions: {
          page: 1,
          take,
        },
        filterOptions: {
          filterFields: ['description'],
          filterText: searchTerm,
        },
        searchOptions: {
          startDate: searchParams.get('startDate'),
          endDate: searchParams.get('endDate'),
        },
      };
      dispatch(fetchAssetLogsById(id, params));
    }
  }, [searchParams]);

  const onPageChange = (page: number) => {
    const id: string = searchParams.get('id') as string;
    const params = {
      pageOptions: {
        page,
        take,
      },
      filterOptions: {
        filterFields: ['description'],
        filterText: searchTerm,
      },
    };
    dispatch(fetchAssetLogsById(id, params));
  };

  const onSearch = (searchTerm: string) => {
    const id: string = searchParams.get('id') as string;
    const params = {
      pageOptions: {
        page: 1,
        take,
      },
      filterOptions: {
        filterFields: ['description'],
        filterText: searchTerm,
      },
    };
    dispatch(fetchAssetLogsById(id, params));
    setSearchTerm(searchTerm);
  };

  const onFinish = (date: Date) => {
    if (!searchParams.get('startDate')) {
      searchParams.append('startDate', moment(date).format('YYYY-MM-DD'));
      setSearchParams(searchParams);
    } else if (!searchParams.get('endDate')) {
      searchParams.append('endDate', moment(date).format('YYYY-MM-DD'));
      setSearchParams(searchParams);
    }

    setModal(false);
  };

  return (
    <Content position="stretch">
      <>
        <DataGrid
          loading={loading}
          columns={isMobile ? AssetLogsColumnsMobile : AssetLogsColumns}
          data={list}
          paginationName="AssetLogs"
          rowSelectionType="checkbox"
          totalItems={pagination.itemCount}
          currentPage={pagination.page}
          totalPages={pagination.pageCount}
          onPageChange={onPageChange}
          onSearch={onSearch}
          showSearch
          multipleSelect={false}
          entityProps="asset-logs"
        />
        <DateModal
          open={modal}
          closeModal={() => setModal(false)}
          onFinish={onFinish}
        />
      </>
    </Content>
  );
}
