/* eslint-disable react/no-children-prop */
import { Col, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import Content from 'ui-v2/components/Content';
import * as Styled from 'ui-v2/components/PersonalInfo/PersonalInfoStyles';
import CardItem from 'ui-v2/components/Card';
import { useCertificationsData } from 'ui-v2/hooks/useCertificationsData';
import { convertUTCtoFormatedLocalTime } from 'utils/utilFunctions';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import CertificationModalForm from 'components/NewForms/NewCertificationForm';
import { useScreenSize } from 'ui-v2/hooks/useScreenSize';

function Item({
  label,
  value,
  children,
}: {
  label: string;
  value: string;
  children: React.ReactNode;
}) {
  return (
    <Row gutter={[0, children ? 8 : 0]}>
      <Col span={24}>
        <Styled.ItemLabel>{label}</Styled.ItemLabel>
      </Col>
      <Col span={24}>
        <Styled.ItemValue>{value}</Styled.ItemValue>
        {children}
      </Col>
    </Row>
  );
}

export default function CertificationDetails() {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [modalVisible, setModalVisible] = useState(false);
  const { certification } = useCertificationsData();
  const { isMobile } = useScreenSize();

  useEffect(() => {
    if (searchParams.get('edit-certification')) {
      setModalVisible(true);
      searchParams.delete('edit-certification');
      setSearchParams(searchParams);
    }
  }, [searchParams]);

  if (!certification) return null;

  const formatedExpireDate = convertUTCtoFormatedLocalTime(
    certification.validation
  );

  return (
    <>
      <Content position={isMobile ? 'flexcol' : 'grid'}>
        <>
          <>
            <CardItem title={t('certification')}>
              <Row gutter={[0, 16]}>
                <Styled.RowItem gutter={[0, 10]}>
                  <Col md={{ span: 12 }} sm={{ span: 24 }}>
                    <Item
                      label={t('name')}
                      value={certification.name}
                      children={undefined}
                    />
                  </Col>
                  <Col md={{ span: 12 }} sm={{ span: 24 }}>
                    <Item
                      label={t('expireDate')}
                      value={formatedExpireDate}
                      children={undefined}
                    />
                  </Col>
                </Styled.RowItem>
                <Styled.RowItem gutter={[0, 10]}>
                  <Col md={{ span: 12 }} sm={{ span: 24 }}>
                    <Item
                      label={t('companyName')}
                      value={certification.companyName}
                      children={undefined}
                    />
                  </Col>
                </Styled.RowItem>
                <Styled.RowItem gutter={[0, 10]}>
                  <Col md={{ span: 12 }} sm={{ span: 24 }}>
                    <Item
                      label={t('cost')}
                      value={
                        certification.currency?.symbol === 'ALL'
                          ? `${certification.cost
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ',')} ${
                              certification.currency?.symbol
                            }`
                          : `${certification.cost} ${certification.currency?.symbol}`
                      }
                      children={undefined}
                    />
                  </Col>
                  <Col md={{ span: 12 }} sm={{ span: 24 }}>
                    <Item
                      label={t('trainingDuration')}
                      value={certification.trainingDuration}
                      children={undefined}
                    />
                  </Col>
                </Styled.RowItem>
                <Styled.RowItem gutter={[0, 10]} last>
                  <Col md={{ span: 12 }} sm={{ span: 24 }}>
                    <Item
                      label={t('skills')}
                      value={certification?.certificationSkills
                        .map(
                          (item: { subCategoryName: string }) =>
                            item?.subCategoryName
                        )
                        .join(', ')}
                      children={undefined}
                    />
                  </Col>
                </Styled.RowItem>
              </Row>
            </CardItem>
          </>
          <></>
        </>
      </Content>
      {modalVisible && (
        <CertificationModalForm
          open={modalVisible}
          closeModal={() => {
            setModalVisible(false);
            searchParams.delete('edit-certification');
            setSearchParams(searchParams);
          }}
        />
      )}
    </>
  );
}
