import React from 'react';
import Row from 'antd/lib/row';
import Col from 'antd/lib/col';
import Stats from './EmployeeStats';
import EmployeeAttendance from './EmployeeAttendance';

const EmployeeDashboardPage = () => (
  <>
    <h2
      style={{
        fontWeight: '1000px',
        marginTop: '15px',
        marginBottom: '30px',
        fontSize: '24px',
      }}
    >
      Dashboard
    </h2>
    <Row gutter={[16, 16]}>
      <Col span={24}>
        <Stats />
      </Col>
    </Row>

    <div style={{ marginTop: '30px' }}>
      <Col span={24}>
        <Row>
          <Col xxl={24} md={24} style={{ height: '350px' }}>
            <EmployeeAttendance />
          </Col>
        </Row>
      </Col>
    </div>
  </>
);

export default EmployeeDashboardPage;
