import React from 'react';
import * as Styled from 'ui-v2/components/DataGridComponents';
import i18next from 'i18next';
import { InvoicingType } from 'types/Invoicing';
import { convertUTCtoFormatedLocalTime } from 'utils/utilFunctions';
import { CalendarOutlined } from '@ant-design/icons';
import { Space } from 'antd';
import InvoicingAction from 'components/Actions/InvoicingAction';

export const InvoicingColumnsMobile = [
  {
    dataIndex: 'invoice',
    key: 'invoice',
    render: (_: string, item: InvoicingType) => (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '1rem',
        }}
      >
        <div>
          <strong>{`${i18next.t('number')}: `}</strong>
          {item?.invoiceNumber}
        </div>

        <div>
          <strong>{`${i18next.t('date')}: `}</strong>
          <Space>
            <CalendarOutlined />
            {convertUTCtoFormatedLocalTime(item.invoiceDate)}
          </Space>
        </div>

        <div>
          <strong>{`${i18next.t('dueDate')}: `}</strong>
          <Space>
            <CalendarOutlined />
            {convertUTCtoFormatedLocalTime(item.dueDate)}
          </Space>
        </div>

        <div>
          <strong>{`${i18next.t('paymentTerms')}: `}</strong>
          {item?.paymentTerms}
        </div>

        <div>
          <InvoicingAction id={item?.id ?? ''} />
        </div>
      </div>
    ),
    default: true,
    order: 0,
    width: 200,
  },
];

export const InvoicingColumns = [
  {
    title: i18next.t('number'),
    dataIndex: 'invoiceNumber',
    key: 'invoiceNumber',
    ellipsis: true,
    sorter: (a: any, b: any) => a.item.localeCompare(b.item),
    render: (_: string, item: InvoicingType) => (
      <Styled.TextBold>{item?.invoiceNumber}</Styled.TextBold>
    ),
    width: 100,
    default: true,
    order: 0,
  },
  {
    title: i18next.t('date'),
    dataIndex: 'invoiceDate',
    key: 'invoiceDate',
    ellipsis: true,
    sorter: (a: any, b: any) => a.item.localeCompare(b.item),
    render: (record: Date) => (
      <Space>
        <CalendarOutlined />
        <Styled.TextBold>
          {convertUTCtoFormatedLocalTime(record)}
        </Styled.TextBold>
      </Space>
    ),
    default: true,
    order: 0,
  },
  {
    title: i18next.t('dueDate'),
    dataIndex: 'dueDate',
    key: 'dueDate',
    ellipsis: true,
    sorter: (a: any, b: any) => a.item.localeCompare(b.item),
    render: (record: Date) => (
      <Space>
        <CalendarOutlined />
        <Styled.TextBold>
          {convertUTCtoFormatedLocalTime(record)}
        </Styled.TextBold>
      </Space>
    ),
    default: true,
    order: 1,
  },
  {
    title: i18next.t('paymentTerms'),
    dataIndex: 'paymentTerms',
    key: 'paymentTerms',
    ellipsis: true,
    sorter: (a: any, b: any) => a.item.localeCompare(b.item),
    render: (_: string, item: InvoicingType) => (
      <Styled.TextBold>{item?.paymentTerms}</Styled.TextBold>
    ),
    default: true,
    order: 2,
  },
  {
    title: i18next.t('action'),
    dataIndex: 'action',
    key: 'action',
    width: 120,
    render: (_: string, row: any) => <InvoicingAction id={row.id} />,
    default: false,
    order: 4,
  },
];
