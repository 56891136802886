import React, { useEffect, useState } from 'react';
import Content from 'ui-v2/components/Content';
import DataGrid from 'components/common/DataGrid/DataGrid';
import { PayrollReport } from 'types/PayrollReport';
import moment from 'moment';
import { useSearchParams } from 'react-router-dom';
import {
  PayrollReportColumns,
  PayrollReportColumnsMobile,
} from 'table-configuration/PayrollReportConfig';
import { convertDateToUTC, toastErrorMessages } from 'utils/utilFunctions';
import { useScreenSize } from 'ui-v2/hooks/useScreenSize';
import { getPayrollReportByDate } from 'api/payrollReportService';
import ExpandedRow from './ExpandedTable';
import { GenerateReportDate } from './ExpandedTable/ExpandedTableStyles';

export default function PayrollGenerateReport() {
  const [searchParams] = useSearchParams();
  const [payrollDate, setPayrollDate] = useState<Date | null>(new Date());
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<PayrollReport[]>([]);
  const { isMobile } = useScreenSize();

  useEffect(() => {
    const date = searchParams.get('date');
    const tenantLocationId = searchParams.get('tenantLocationId');

    if (date && tenantLocationId) {
      const reportDate = new Date(date);

      setLoading(true);
      getPayrollReportByDate(convertDateToUTC(reportDate), tenantLocationId)
        .then((res) => {
          setPayrollDate(reportDate);
          setData(res?.data);
        })
        .catch((error) => {
          toastErrorMessages(error.response.data.error);
        })
        .finally(() => {
          setLoading(false);
        });

      setPayrollDate(reportDate);
    }
  }, [searchParams]);

  return (
    <Content position="stretch">
      <>
        <GenerateReportDate>
          Date:
          {moment(payrollDate).format('DD/MM/YYYY')}
        </GenerateReportDate>
        <DataGrid
          loading={loading}
          columns={isMobile ? PayrollReportColumnsMobile : PayrollReportColumns}
          data={data}
          rowSelectionType="checkbox"
          totalPages={data.length}
          showSearch
          paginationName="payrollReport"
          rowKey={(record: PayrollReport) => record.employee?.id}
          expandedRowRender={
            !isMobile && {
              expandedRowRender: (record: PayrollReport) => (
                <ExpandedRow report={record} />
              ),
            }
          }
        />
      </>
    </Content>
  );
}
