import React, { useCallback, useEffect, useState } from 'react';
import { Popover } from 'antd';
import { CalendarOutlined } from '@ant-design/icons';
import moment from 'moment';

import { useTrackedHoursData } from 'ui-v2/hooks/useTrackedHoursData';
import { useScreenSize } from 'ui-v2/hooks/useScreenSize';
import {
  StyledTrackingDateFilterRow,
  StyledTrackingDateFilterDateLabelCol,
  StyledTrackingDateFilterDateActionWrapper,
  StyledTrackingDateFilterIcon,
} from './StyledTrackingDateFilter';
import {
  TrackingDateFilterPopoverInnerOverlayStyle,
  TrackingDateFilterPopoverInnerOverlayStyleMobile,
} from './utils';
import TrackingDateFilterLabel from './TrackingDateFilterLabel';
import TrackingDatePopoverContent from './TrackingDatePopoverContent';
import TrackingDateAction from './TrackingDateAction';

const TrackingDateFilter: React.FC = () => {
  const [popoverOpen, setPopoverOpen] = useState(false);
  const { isMobile } = useScreenSize();

  const {
    urlState: { startDate, endDate },
    setUrlState,
  } = useTrackedHoursData();

  useEffect(() => {
    if (!startDate && !endDate) {
      setUrlState({
        startDate: moment().startOf('isoWeek').format('YYYY-MM-DD'),
        endDate: moment().endOf('isoWeek').format('YYYY-MM-DD'),
      });
    }
  }, [startDate, endDate]);

  const tooglePopoverVisibility = useCallback(() => {
    setPopoverOpen(!popoverOpen);
  }, [popoverOpen]);

  return (
    <StyledTrackingDateFilterRow align="middle">
      <Popover
        trigger="click"
        visible={popoverOpen}
        placement="bottomRight"
        overlayInnerStyle={
          isMobile
            ? TrackingDateFilterPopoverInnerOverlayStyleMobile
            : TrackingDateFilterPopoverInnerOverlayStyle
        }
        onVisibleChange={tooglePopoverVisibility}
        style={{ width: '350px' }}
        content={
          <TrackingDatePopoverContent
            tooglePopoverVisibility={tooglePopoverVisibility}
          />
        }
      >
        <StyledTrackingDateFilterDateLabelCol onClick={tooglePopoverVisibility}>
          <StyledTrackingDateFilterIcon>
            <CalendarOutlined />
          </StyledTrackingDateFilterIcon>

          <TrackingDateFilterLabel isPopoverOpen={popoverOpen} />
        </StyledTrackingDateFilterDateLabelCol>
      </Popover>
      <StyledTrackingDateFilterDateActionWrapper>
        <TrackingDateAction arrowDirection="left" />
        <TrackingDateAction arrowDirection="right" />
      </StyledTrackingDateFilterDateActionWrapper>
    </StyledTrackingDateFilterRow>
  );
};

export default TrackingDateFilter;
