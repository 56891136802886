import { useSelector } from 'react-redux';

export function useHierarchyData() {
  const {
    data = [],
    loading,
    loaded,
    errored,
    error,
  } = useSelector((state: any) => state.hierarchy);

  return {
    list: data || [],
    loading,
    loaded,
    errored,
    error,
  };
}
