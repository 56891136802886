/* eslint-disable @typescript-eslint/no-empty-function */
import { PlusCircleOutlined } from '@ant-design/icons';
import { Col, Row, Skeleton } from 'antd';
import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import CardItem from 'ui-v2/components/Card';
import RightSection from 'ui-v2/components/RightSection';
import { useSelector } from 'react-redux';
import { BUTTONS } from 'ui-v2/components/HeaderActions/config';
import RoleBasedButton from 'ui-v2/components/RoleBasedButton';
import {
  getBankDetailsForOneEmployee,
  getBankDetailsById,
  deleteBankDetailsById,
} from 'api/bankDetails';
import { BankDetail } from 'types/BankDetails';
import { AuthUserState } from 'redux/authUser/types';
import { RootState } from 'redux/store';
import { BankItem } from 'ui-v2/components/BankDetails/BankItem';
import { useTranslation } from 'react-i18next';
import { useScreenSize } from 'ui-v2/hooks/useScreenSize';
import * as Styled from '../../WorkExperience/WorkExperienceStyles';
import AddBankDetails from './AddBankDetails';
import EmployeeProfileMobileTabs from '../Onboarding/EmployeeProfileMobileTabs';

interface IProps {
  employeeId: string;
}

export default function BankDetails({ employeeId }: IProps) {
  const [modalVisible, setModalVisible] = useState(false);
  const [bankDetails, setBankDetails] = useState<BankDetail[]>([]);
  const [selectedBankDetails, setSelectedBankDetails] = useState<BankDetail>();
  const [loading, setLoading] = useState(false);
  const { isMobile } = useScreenSize();

  const [searchParams, setSearchParams] = useSearchParams();
  const onToggleModal = () => setModalVisible((state) => !state);

  const { authUserRole }: AuthUserState = useSelector(
    (state: RootState) => state.authUser
  );
  const { t } = useTranslation();

  const onButtonClick = () => {
    setSelectedBankDetails(undefined);
    onToggleModal();
  };

  const getEmployeeBankDetails = async (id: string) => {
    setLoading(true);

    try {
      setLoading(true);
      const response = await getBankDetailsForOneEmployee(id);
      if (response.status === 200 && response.data) {
        setBankDetails(response.data);
        setLoading(false);
      }
    } catch (error) {
      toast.error(t('Something went wrong'));
    }
    setLoading(false);
  };

  const getEmployeeBankDetailsById = async (id: string) => {
    setLoading(true);

    try {
      setLoading(true);
      const response = await getBankDetailsById(id);
      if (response.status === 200 && response.data) {
        setSelectedBankDetails(response.data);
        setLoading(false);
      }
    } catch (error) {
      toast.error(t('Something went wrong'));
    }
    setLoading(false);
  };

  const deleteEmployeeBankDetailsById = async (id: string | null) => {
    if (!id) return;
    try {
      const response = await deleteBankDetailsById(id);
      if (response.status === 200) {
        toast.success(t('bankDetailsDeletedSuccessfully'));
        await getEmployeeBankDetails(employeeId);
      }
    } catch (error) {
      toast.error(t('bankDetailsFailedtoDelete'));
    }
  };

  useEffect(() => {
    if (searchParams.get('edit-bankItem')) {
      setModalVisible(true);
      getEmployeeBankDetailsById(searchParams.get('edit-bankItem') ?? '');
      setSearchParams(searchParams);
    }
    if (searchParams.get('delete-bankItem')) {
      deleteEmployeeBankDetailsById(searchParams.get('delete-bankItem'));
      searchParams.delete('delete-bankItem');
      setSearchParams(searchParams);
    }
  }, [searchParams]);

  useEffect(() => {
    getEmployeeBankDetails(employeeId);
  }, [employeeId]);

  return (
    <>
      {loading ? (
        <Skeleton loading={loading} paragraph={{ rows: 2 }} active />
      ) : (
        <CardItem
          title={t('employeebankDetails')}
          rightButton={
            bankDetails?.length ? (
              <RoleBasedButton btn={BUTTONS.ADD_BUTTON}>
                <RightSection
                  buttonCallBack={() => onButtonClick()}
                  buttonText={t('add bank details')}
                />
              </RoleBasedButton>
            ) : undefined
          }
        >
          <>
            <Row gutter={[0, 24]}>
              {!bankDetails?.length && (
                <>
                  <Styled.RowItem justify="center" last>
                    <Col>
                      <Styled.NodataLabel>
                        {authUserRole === 'employee'
                          ? 'There are no bank details related to you'
                          : t('therearenobankdetailsrelatedtothisEmployee')}
                      </Styled.NodataLabel>
                    </Col>
                  </Styled.RowItem>
                  {!bankDetails?.length && (
                    <Styled.RowItem last justify="center">
                      <RoleBasedButton btn={BUTTONS.ADD_BUTTON}>
                        <Col>
                          <Styled.ButtonStyle
                            icon={<PlusCircleOutlined />}
                            size="large"
                            onClick={onButtonClick}
                          >
                            {t('add bank details')}
                          </Styled.ButtonStyle>
                        </Col>
                      </RoleBasedButton>
                    </Styled.RowItem>
                  )}
                </>
              )}

              {!!bankDetails?.length &&
                (!isMobile ? (
                  <>
                    <Styled.RowItem last style={{ paddingLeft: 10 }}>
                      <Col span={4}>
                        <Styled.NodataLabel>{t('bankName')}</Styled.NodataLabel>
                      </Col>
                      <Col span={4}>
                        <Styled.NodataLabel>{t('IBAN')}</Styled.NodataLabel>
                      </Col>
                      <Col span={4}>
                        <Styled.NodataLabel>{t('SWIFT')}</Styled.NodataLabel>
                      </Col>
                      <Col span={4}>
                        <Styled.NodataLabel>{t('currency')}</Styled.NodataLabel>
                      </Col>
                      <Col span={4}>
                        <Styled.NodataLabel>
                          {t('bankAddress')}
                        </Styled.NodataLabel>
                      </Col>
                      <Col span={4}>
                        <Styled.NodataLabel>{t('action')}</Styled.NodataLabel>
                      </Col>
                    </Styled.RowItem>

                    {!loading &&
                      bankDetails?.map((bank: BankDetail) => (
                        <BankItem
                          key={bank.id}
                          bankName={bank?.bankName}
                          swift={bank.swift}
                          iban={bank?.iban}
                          currency={bank?.currency}
                          bankAddress={bank?.bankAddress}
                          id={bank?.id}
                        />
                      ))}
                  </>
                ) : (
                  !loading &&
                  bankDetails?.map((bank: BankDetail) => (
                    <EmployeeProfileMobileTabs
                      key={bank.id}
                      fields={[
                        { label: 'bankName', value: bank?.bankName },
                        {
                          label: 'IBAN',
                          value: bank?.iban,
                        },
                        {
                          label: 'SWIFT',
                          value: bank.swift,
                        },
                        {
                          label: 'currency',
                          value: bank?.currency?.name,
                        },
                      ]}
                    />
                  ))
                ))}
            </Row>
            {modalVisible && (
              <AddBankDetails
                selectedBankDetails={selectedBankDetails}
                fetchBankDetails={getEmployeeBankDetails}
                open={modalVisible}
                closeModal={() => {
                  setModalVisible(false);
                  searchParams.delete('edit-bankItem');
                  setSearchParams(searchParams);
                }}
                employeeId={employeeId}
              />
            )}
          </>
        </CardItem>
      )}
    </>
  );
}
