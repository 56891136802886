import { Dispatch } from 'redux';
import { getAllEdges, getAllNodes } from 'api/hierarchyService';
import { HierarchyAction } from './types';

export const fetchHierarchy = () => (dispatch: Dispatch) => {
  dispatch({
    type: HierarchyAction.FETCH_HIERARCHY_START,
  });

  Promise.all([getAllNodes(), getAllEdges()])
    .then(([nodesRes, edgesRes]) => {
      dispatch({
        type: HierarchyAction.FETCH_HIERARCHY_SUCCESS,
        payload: {
          nodes: nodesRes.data,
          edges: edgesRes.data,
        },
      });
    })
    .catch((e) => {
      dispatch({
        type: HierarchyAction.FETCH_HIERARCHY_ERRORED,
        payload: e,
      });
    });
};

export const clearHierarchy = () => (dispatch: Dispatch) => {
  dispatch({
    type: HierarchyAction.CLEAR_HIERARCHY,
  });
};
