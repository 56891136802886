import React, { useMemo } from 'react';
import { useForm } from 'antd/lib/form/Form';
import { useDispatch } from 'react-redux';
import { InputTypes } from 'types/FormTypes';
import GenericForm from 'components/NewForms/Form';
import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { useEmployeeSkimData } from 'ui-v2/hooks/useEmployeeSkimData';
import { fetchCheckins } from 'redux/checkin/actions';
import useHeader from 'ui-v2/hooks/useHeader';
import { StyledPopoverContentButton } from '../TableFilterPopoverStyles';

export default function TrainingsFilter() {
  const { take } = useHeader();
  const [form] = useForm();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { getEmployeeSkimSelectWithLoadOptions } = useEmployeeSkimData();

  async function onFormChange() {
    const employee = form.getFieldValue('employee')?.key;

    const params = {
      pageOptions: { page: 1, take },
    };
    if (employee) dispatch(fetchCheckins(params, employee));
  }

  const EmployeesConfig: any[][] = useMemo(
    () => [
      [
        {
          col: 24,
          offset: 0,
          name: 'employee',
          label: t('employee'),
          type: InputTypes.SELECT_LOAD,
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          onSelect: () => {},
          fetchOptions: getEmployeeSkimSelectWithLoadOptions,
        },
      ],
    ],
    []
  );
  return (
    <>
      <GenericForm
        formConfiguration={EmployeesConfig}
        onFieldsChange={onFormChange}
        form={form}
      />
      <Row justify="center">
        <Col>
          <StyledPopoverContentButton
            type="link"
            onClick={() => {
              form.resetFields();
              dispatch(fetchCheckins({ pageOptions: { page: 1, take } }));
            }}
          >
            {t('clearAll')}
          </StyledPopoverContentButton>
        </Col>
      </Row>
    </>
  );
}
