import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import Content from 'ui-v2/components/Content';
import { fetchSingleExpenseReport } from 'redux/expenses/action';
import useExpenseReport from 'ui-v2/hooks/useExpenseReportData';
import { useScreenSize } from 'ui-v2/hooks/useScreenSize';
import { RootState } from 'redux/store';
import ExpenseReportModalForm from 'pages/Expenses';
import { AuthUserState } from 'redux/authUser/types';
import { AUTH_ROLES } from 'types/Auth';
import { ExpenseReportStatus } from 'types/ExpensesReport';
import ExpenseInformation from './ExpenseInformation';
import ExpenseApproveReject from './ExpenseApproveReject';

export default function ExpenseDetails() {
  const { singleExpenseReport, singleExpenseReportLoading } =
    useExpenseReport();
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const { isMobile } = useScreenSize();

  const { authUserRole }: AuthUserState = useSelector(
    (state: RootState) => state.authUser
  );
  const canApprove = !!(
    authUserRole === AUTH_ROLES.AMDIN ||
    authUserRole === AUTH_ROLES.HR ||
    authUserRole === AUTH_ROLES.PAYROLL_MANAGER
  );
  useEffect(() => {
    if (searchParams.get('edit-expense-details')) {
      setModalVisible(true);
      searchParams.delete('edit-expense-details');
      setSearchParams(searchParams);
    }
  }, [searchParams]);

  useEffect(() => {
    if (searchParams.get('id')) {
      dispatch(fetchSingleExpenseReport(searchParams.get('id') as string));
    }
  }, [searchParams]);

  if (!singleExpenseReport?.id) {
    return null;
  }

  return (
    <>
      <Content position={isMobile ? 'flexcol' : 'grid'}>
        <>
          <ExpenseInformation
            loading={singleExpenseReportLoading}
            singleExpenseReport={singleExpenseReport}
          />
          <>
            {canApprove &&
              singleExpenseReport?.status === ExpenseReportStatus.PENDING && (
                <ExpenseApproveReject
                  singleExpenseReport={singleExpenseReport}
                />
              )}
          </>
        </>
      </Content>
      {modalVisible && (
        <ExpenseReportModalForm
          open={modalVisible}
          closeModal={() => {
            setModalVisible(false);
            searchParams.delete('edit-expense-details');
            setSearchParams(searchParams);
          }}
        />
      )}
    </>
  );
}
