import { Col, Row, Tag } from 'antd';
import { DragOutlined } from '@ant-design/icons';
import React from 'react';
import {
  EmergencyContactContainer,
  Name,
  AvatarStyle,
  StyledCol,
  StyledRow,
} from './CandidateItemStyled';

interface IProps {
  item: any;
  isLast: boolean;
  onItemClick: (id: string) => void;
}

export function CadnidateItem({ item, isLast, onItemClick }: IProps) {
  const handleItemClick = () => {
    onItemClick(item.id);
  };

  return (
    <EmergencyContactContainer isLast={isLast}>
      <StyledRow onClick={handleItemClick} isLast={isLast}>
        <Col span={3}>
          <AvatarStyle size={40} shape="circle">
            {`${item.firstName?.toUpperCase().charAt(0)}${item.lastName
              ?.toUpperCase()
              .charAt(0)}`}
          </AvatarStyle>
        </Col>
        <StyledCol span={19}>
          <Name style={{ marginBottom: '8px' }}>
            {`${item.firstName} ${item.lastName}`}
          </Name>
          <Row gutter={[0, 8]}>
            {item.skillSubCategoriesCandidates?.length <= 3 ? (
              item.skillSubCategoriesCandidates?.map((skill: any) => (
                <Tag color="success" style={{ borderRadius: '8px' }}>
                  {skill.skillsSubCategory?.subCategoryName}
                </Tag>
              ))
            ) : (
              <>
                {item.skillSubCategoriesCandidates
                  ?.slice(0, 3)
                  .map((skill: any) => (
                    <Tag color="success" style={{ borderRadius: '8px' }}>
                      {skill.skillsSubCategory?.subCategoryName}
                    </Tag>
                  ))}
                <Tag color="processing" style={{ borderRadius: '8px' }}>
                  {`+${item.skillSubCategoriesCandidates.length - 3} skills`}
                </Tag>
              </>
            )}
          </Row>
        </StyledCol>
        <Col span={1} style={{ display: 'flex', alignItems: 'center' }}>
          <DragOutlined style={{ cursor: 'grab' }} />
        </Col>
      </StyledRow>
    </EmergencyContactContainer>
  );
}
