import React, { useCallback, useEffect, useState } from 'react';
import { Tabs } from 'antd';
import { useRequestData } from 'ui-v2/hooks/useRequestData';
import Content from 'ui-v2/components/Content';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAllRequests } from 'redux/requests/actions';
import { TabsStyled } from 'ui-v2/components/TabsStyled';
import { useTranslation } from 'react-i18next';
import { SettingTab } from 'types/settings';
import { useSearchParams } from 'react-router-dom';
import { useEmployeeRequestData } from 'ui-v2/hooks/useEmployeeRequestData';
import { fetchAllEmployeeRequestsByEntityName } from 'redux/employeeRequests/action';
import { RootState } from 'redux/store';
import { AuthUserState } from 'redux/authUser/types';
import { EmployeeRequestEntity } from 'types/Request';
import RequestList from './RequestList';
import TimeOffRequests from '../TimeOffRequests';
import QuestionnaireFeedback from '../QuestionnaireFeedback/QuestionnaireFeedback';
import EmployeeRequestList from '../EmployeeRequests';

export enum RequestTab {
  ALL = '1',
  TIME_OFF = '2',
  BENEFITS = '3',
  INFORMATION = '4',
  ASSETS = '5',
  COMPENSATION = '6',
  REQUESTS = '7',
  QUESTIONNAIRES = '8',
  OTHER = '9',
}

export const getEntityName = (tab: RequestTab): EmployeeRequestEntity => {
  switch (tab) {
    case RequestTab.BENEFITS:
      return EmployeeRequestEntity.BENEFITS;
    case RequestTab.ASSETS:
      return EmployeeRequestEntity.ASSETS;
    default:
      return EmployeeRequestEntity.BENEFITS;
  }
};

export default function Requests() {
  const auth = useSelector((state: RootState) => state.authUser);
  const { requests, loading } = useRequestData();
  const { employeeRequests } = useEmployeeRequestData();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [activeTab, setActiveTab] = useState<string>(
    searchParams?.get('tab') ?? `${SettingTab.CONFIGURATIONS}`
  );

  const employeeId = (auth as AuthUserState)?.authUser?.employee?.id as string;

  const onTabClick = useCallback(
    (activeKey: string) => {
      setActiveTab(activeKey);
      searchParams.set('tab', activeKey);
      setSearchParams(searchParams);
    },
    [searchParams, activeTab]
  );

  const { TabPane } = Tabs;

  useEffect(() => {
    if (loading) {
      return;
    }
    dispatch(fetchAllRequests());
  }, []);

  useEffect(() => {
    if (activeTab === RequestTab.BENEFITS || activeTab === RequestTab.ASSETS) {
      dispatch(
        fetchAllEmployeeRequestsByEntityName({
          entityName: getEntityName(activeTab),
          employeeId,
        })
      );
    }
  }, [activeTab]);

  return (
    <Content position="strech">
      <TabsStyled defaultActiveKey={activeTab} onTabClick={onTabClick}>
        <TabPane tab={t('all')} key={RequestTab.ALL}>
          <RequestList requests={requests} loading={loading} />
        </TabPane>
        <TabPane tab={t('Time Off')} key={RequestTab.TIME_OFF}>
          <RequestList
            requests={requests}
            loading={loading}
            filterByType="timeoff"
          >
            <TimeOffRequests />
          </RequestList>
        </TabPane>
        <TabPane tab={t('benefits')} key={RequestTab.BENEFITS}>
          <EmployeeRequestList
            requests={[]}
            loading={loading}
            activeTab={RequestTab.BENEFITS}
          />
        </TabPane>
        <TabPane tab={t('information')} key={RequestTab.INFORMATION}>
          <RequestList
            requests={requests}
            loading={loading}
            filterByType="information"
          />
        </TabPane>
        <TabPane tab={t('assets')} key={RequestTab.ASSETS}>
          <EmployeeRequestList
            requests={employeeRequests}
            loading={loading}
            activeTab={RequestTab.ASSETS}
          />
        </TabPane>
        <TabPane tab={t('compensation')} key={RequestTab.COMPENSATION}>
          <RequestList
            requests={requests}
            loading={loading}
            filterByType="compensation"
          />
        </TabPane>
        <TabPane tab={t('Requests')} key={RequestTab.REQUESTS}>
          <TimeOffRequests />
        </TabPane>
        <TabPane tab={t('questionnaires')} key={RequestTab.QUESTIONNAIRES}>
          <QuestionnaireFeedback />
        </TabPane>
        <TabPane tab={t('other')} key={RequestTab.OTHER}>
          <RequestList
            requests={requests}
            loading={loading}
            filterByType="other"
          />
        </TabPane>
      </TabsStyled>
    </Content>
  );
}
