/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useEffect, useState } from 'react';
import { Form, Upload, Button, Input, Modal, Row, Col, Spin } from 'antd';
import { UploadOutlined, DeleteOutlined, EyeOutlined } from '@ant-design/icons';
import type { RcFile } from 'antd/lib/upload';
import { getTenantDocumentsById } from 'api/tenantDocuments';
import { toast } from 'react-toastify';
import { convertBase64, validateFileSize } from 'utils/utilFunctions';
import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';
import { DocumentsState } from './TenantConfigFormContent';

interface IProps {
  tenantId: string;
  isOnBoardingCompleted: boolean;
  documents: DocumentsState;
  handleDocumentChange: (e: any) => void;
}

export enum DocumentType {
  NDA = 'nda',
  JOB_CONTRACT = 'jobContract',
  ADDITIONAL = 'additional',
}

const DocumentUpload = ({
  tenantId,
  isOnBoardingCompleted,
  documents,
  handleDocumentChange,
}: IProps) => {
  const [previewContent, setPreviewContent] = useState<string | null>(null);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();

  const getTenantDocuments = () => {
    getTenantDocumentsById(tenantId)
      .then((res) => {
        if (res?.status === 201) {
          const doc: DocumentsState = {
            nda: null,
            jobContract: null,
            additionalDocs: [],
          };

          res?.data?.forEach((el: { type: string; docUrl: string }) => {
            if (el.type === DocumentType.NDA) {
              doc.nda = {
                name: el.type,
                content: el.docUrl,
                type: DocumentType.NDA,
              };
            } else if (el.type === DocumentType.JOB_CONTRACT) {
              doc.jobContract = {
                name: el.type,
                content: el.docUrl,
                type: DocumentType.JOB_CONTRACT,
              };
            } else {
              doc.additionalDocs = [
                ...doc.additionalDocs,
                {
                  name: el.type,
                  content: el.docUrl,
                  type: el?.type?.split('.')[0],
                },
              ];
            }
          });

          handleDocumentChange(doc);
        }
      })
      .catch((err) => {
        if (isOnBoardingCompleted) {
          toast.error(err?.messsage || t('couldntRetrieveTheTenantsDocuments'));
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (tenantId) {
      getTenantDocuments();
    }
  }, []);

  async function uploadAction(file: RcFile) {
    if (!validateFileSize(file)) return false;
    return (await convertBase64(file)) as string;
  }

  const handleUpload = async (
    file: RcFile,
    type: DocumentType,
    index: number | null = null
  ): Promise<boolean> => {
    let base64: string = (await uploadAction(file)) as string;

    if (!base64 || typeof base64 !== 'string') {
      toast.error(t('somethingWrongWithTheFile'));
    }

    base64 = base64.split(',').pop() as string;

    if (type === DocumentType.ADDITIONAL) {
      const updatedDocs = [...documents.additionalDocs];

      if (index === null && updatedDocs.some((doc) => doc.type === '')) {
        toast.error(t('pleaseSpecifyATypeForExistingAdditionalDocumentsFirst'));
        return false;
      }

      if (index !== null) {
        updatedDocs[index] = {
          ...updatedDocs[index],
          content: base64 as string,
          name: file.name,
        };
      } else {
        updatedDocs.push({
          name: file.name,
          content: base64 as string,
          type: '',
        });
      }
      handleDocumentChange({ ...documents, additionalDocs: updatedDocs });
    } else {
      if (documents[type]) {
        toast.warning(
          `You cannot upload multiple ${type.toUpperCase()} documents.`
        );
        return false;
      }
      handleDocumentChange({
        ...documents,
        [type]: { name: file.name, content: base64, type },
      });
    }
    return false;
  };

  const handleRemove = (type: DocumentType, index: number | null = null) => {
    if (type === 'additional' && index !== null) {
      const updatedDocs = [...documents.additionalDocs];
      updatedDocs.splice(index, 1);
      handleDocumentChange({ ...documents, additionalDocs: updatedDocs });
    } else {
      handleDocumentChange({ ...documents, [type]: null });
    }
  };

  const updateAdditionalDocType = (index: number, value: string) => {
    const updatedDocs = [...documents.additionalDocs];

    if (updatedDocs.some((doc, i) => doc.type === value && i !== index)) {
      toast.error(t('youCannotEnterTheSameDocumentTypeMoreThanOnce'));
      return;
    }

    updatedDocs[index] = {
      ...updatedDocs[index],
      type: value,
    };
    handleDocumentChange({ ...documents, additionalDocs: updatedDocs });
  };

  const handlePreview = (content: string) => {
    const previewContent = content.startsWith('https')
      ? content
      : `data:application/pdf;base64,${content}`;

    setPreviewContent(previewContent);
    setPreviewVisible(true);
  };

  return (
    <>
      <Spin spinning={loading}>
        <Form layout="vertical">
          <Form.Item label="NDA Document">
            <Row gutter={[8, 0]} justify="space-between">
              <Row align="middle">
                <Col>
                  <Upload
                    beforeUpload={(file) =>
                      handleUpload(file, DocumentType.NDA)
                    }
                    accept=".pdf"
                    maxCount={1}
                    onRemove={() => handleRemove(DocumentType.NDA)}
                    fileList={
                      documents.nda
                        ? [
                            {
                              uid: uuid(),
                              name: documents.nda.name,
                              status: 'done',
                            },
                          ]
                        : []
                    }
                  >
                    <Button icon={<UploadOutlined />}>{t('uploadNDA')}</Button>
                  </Upload>
                </Col>
              </Row>
              {documents.nda && (
                <Col>
                  <Button
                    icon={<EyeOutlined />}
                    onClick={() => handlePreview(documents.nda!.content)}
                  >
                    {t('preview')}
                  </Button>
                </Col>
              )}
            </Row>
          </Form.Item>

          <Form.Item label="Job Contract Document">
            <Row gutter={[8, 0]} justify="space-between">
              <Row align="middle" gutter={[8, 0]}>
                <Col>
                  <Upload
                    beforeUpload={(file) =>
                      handleUpload(file, DocumentType.JOB_CONTRACT)
                    }
                    accept=".pdf"
                    maxCount={1}
                    onRemove={() => handleRemove(DocumentType.JOB_CONTRACT)}
                    fileList={
                      documents.jobContract
                        ? [
                            {
                              uid: uuid(),
                              name: documents.jobContract.name,
                              status: 'done',
                            },
                          ]
                        : []
                    }
                  >
                    <Button icon={<UploadOutlined />}>
                      {t('uploadJobContract')}
                    </Button>
                  </Upload>
                </Col>
              </Row>
              {documents.jobContract && (
                <Col>
                  <Button
                    icon={<EyeOutlined />}
                    onClick={() =>
                      handlePreview(documents.jobContract!.content)
                    }
                  >
                    Preview
                  </Button>
                </Col>
              )}
            </Row>
          </Form.Item>

          {documents.additionalDocs.map((doc, index) => (
            <div
              style={{
                justifyContent: 'space-between',
                display: 'flex',
                marginBottom: 35,
              }}
            >
              <Col>
                <Input
                  placeholder="Document Type"
                  value={doc.type || doc.name || ''}
                  onChange={(e) =>
                    updateAdditionalDocType(index, e.target.value)
                  }
                  style={{ marginBottom: 8 }}
                />
              </Col>
              <Col>
                <Upload
                  beforeUpload={(file) =>
                    handleUpload(file, DocumentType.ADDITIONAL, index)
                  }
                  accept=".pdf"
                  maxCount={1}
                  onRemove={() => handleRemove(DocumentType.ADDITIONAL, index)}
                  fileList={
                    doc.content
                      ? [
                          {
                            uid: uuid(),
                            name: doc.name,
                            status: 'done',
                          },
                        ]
                      : []
                  }
                >
                  <Button icon={<UploadOutlined />}>
                    {t('uploadDocument')}
                  </Button>
                </Upload>
              </Col>
              {doc.content && (
                <Col>
                  <Button
                    icon={<EyeOutlined />}
                    onClick={() => handlePreview(doc.content)}
                  >
                    Preview
                  </Button>
                </Col>
              )}
              <Col>
                <Button
                  type="text"
                  icon={<DeleteOutlined />}
                  onClick={() => handleRemove(DocumentType.ADDITIONAL, index)}
                  danger
                >
                  Remove
                </Button>
              </Col>
            </div>
          ))}

          <Button
            type="dashed"
            onClick={() =>
              handleDocumentChange({
                ...documents,
                additionalDocs: [
                  ...documents.additionalDocs,
                  { name: '', content: '', type: '' },
                ],
              })
            }
            style={{ width: '100%' }}
          >
            Add Another Document
          </Button>
        </Form>

        <Modal
          visible={previewVisible}
          footer={null}
          onCancel={() => setPreviewVisible(false)}
          width={800}
        >
          {previewContent ? (
            <embed
              src={previewContent}
              type="application/pdf"
              width="100%"
              height="700px"
            />
          ) : (
            <p>No content to preview</p>
          )}
        </Modal>
      </Spin>
    </>
  );
};

export default DocumentUpload;
