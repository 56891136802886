import { Col, Form, Row } from 'antd';
import { createEmployeeRequest } from 'api/request';
import GenericForm from 'components/NewForms/Form';
import { FormItemStyled, StyledButton } from 'components/NewForms/FormStyled';
import { AssignTaskIcon } from 'Icons/AssignTaskIcon';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { CreateEmployeeRequest, EmployeeRequestEntity } from 'types/Request';
import { FormConfigurationType, InputTypes } from 'types/FormTypes';
import GenericModal from 'ui-v2/components/GenericModal';
import { toastErrorMessages } from 'utils/utilFunctions';
import { fetchAssetOptions } from 'components/NewForms/SelectWithLoad/utils';
import { fetchAllEmployeeRequestsByEntityName } from 'redux/employeeRequests/action';
import { useDispatch } from 'react-redux';
import { TFunction } from 'i18next';

export const getEntityFieldConfig = (
  entityName: EmployeeRequestEntity,
  t: TFunction
) => {
  switch (entityName) {
    case EmployeeRequestEntity.ASSETS:
      return {
        name: 'assetId',
        label: t('assetsList'),
        fetchOptions: fetchAssetOptions,
      };
    case EmployeeRequestEntity.TIMEOFF:
      return {
        name: 'timeoffId',
        label: t('timeoffList'),
        fetchOptions: undefined,
      };
    case EmployeeRequestEntity.BENEFITS:
      return {
        name: 'benefitId',
        label: t('benefitsList'),
        fetchOptions: undefined,
      };
    case EmployeeRequestEntity.COMPENSATION:
      return {
        name: 'compensationId',
        label: t('compensationList'),
        fetchOptions: undefined,
      };
    case EmployeeRequestEntity.QUESTIONNAIRES:
      return {
        name: 'questionnaireId',
        label: t('questionnairesList'),
        fetchOptions: undefined,
      };
    default:
      return undefined;
  }
};

interface IProps {
  open: boolean;
  closeModal: () => void;
  employeeId: string;
  entityName: EmployeeRequestEntity;
}

export default function EmployeeRequestModal({
  open,
  closeModal,
  employeeId,
  entityName,
}: IProps) {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const fieldConfig = getEntityFieldConfig(entityName, t);

  const RequestFormConfiguration: FormConfigurationType[][] = useMemo(
    () => [
      [
        {
          col: 24,
          offset: 0,
          name: fieldConfig ? fieldConfig.name : '',
          label: fieldConfig ? fieldConfig.label : '',
          type: InputTypes.SELECT_LOAD,
          fetchOptions: fieldConfig?.fetchOptions,
          rules: [
            {
              required: true,
              message: t('assetisRequired'),
            },
          ],
        },
      ],
      [
        {
          col: 24,
          offset: 0,
          name: 'description',
          label: t('description'),
          type: InputTypes.TEXTAREA,
          rules: [
            {
              required: true,
              message: t('descriptionRequired'),
            },
          ],
        },
      ],
    ],
    []
  );

  const onSubmit = async () => {
    if (loading || !employeeId) return;

    setLoading(true);
    const formValues = form.getFieldsValue(true);

    const requestPayload: CreateEmployeeRequest = {
      employeeId,
      description: formValues.description,
      entityName,
      entityId: formValues?.assetId?.value,
    };

    try {
      const res = await createEmployeeRequest(requestPayload);
      if (res.status === 200) {
        toast.success(t('requestSubmittedSuccessfully'));
        dispatch(
          fetchAllEmployeeRequestsByEntityName({ employeeId, entityName })
        );
        closeModal();
      }
    } catch (error) {
      toastErrorMessages(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <GenericModal
      title={t('newRequest')}
      open={open}
      closeModal={closeModal}
      icon={<AssignTaskIcon />}
    >
      <GenericForm
        formConfiguration={RequestFormConfiguration}
        onFinish={onSubmit}
        form={form}
        loading={loading}
      >
        <FormItemStyled style={{ marginTop: 30, marginBottom: 0 }}>
          <Row>
            <Col span={11}>
              <StyledButton onClick={closeModal} htmlType="reset" danger>
                {t('cancel')}
              </StyledButton>
            </Col>
            <Col span={11} offset={2}>
              <StyledButton loading={loading} type="primary" htmlType="submit">
                {t('submit')}
              </StyledButton>
            </Col>
          </Row>
        </FormItemStyled>
      </GenericForm>
    </GenericModal>
  );
}
