import React from 'react';
import { Col, Row } from 'antd';
import { useScreenSize } from 'ui-v2/hooks/useScreenSize';
import * as Styled from './CardStyles';

interface IProps {
  title?: string | React.ReactNode;
  minHeight?: number;
  children: JSX.Element;
  rightButton?: JSX.Element | undefined;
  disabled?: boolean;
  wrapContentHeight?: boolean;
  rightButtonContent?: number;
}

export default function CardItem({
  title,
  minHeight = 170,
  children,
  rightButton,
  disabled,
  wrapContentHeight,
  rightButtonContent = 8,
}: IProps) {
  const { isMobile } = useScreenSize();

  return (
    <Styled.Container
      direction="vertical"
      size="middle"
      disabled={disabled}
      wrapContentHeight={wrapContentHeight}
    >
      <Styled.RowCardItem gutter={[16, 16]}>
        {title &&
          (isMobile ? (
            <Col span={24}>
              <Styled.Title>{title}</Styled.Title>
            </Col>
          ) : (
            <Col span={rightButton ? 24 - rightButtonContent : 24}>
              <Styled.Title>{title}</Styled.Title>
            </Col>
          ))}
        {rightButton &&
          (isMobile ? (
            <Row
              style={{
                display: 'flex',
                width: '100%',
                justifySelf: isMobile ? 'flex-end' : 'center',
              }}
            >
              <Col span={24}>{rightButton}</Col>
            </Row>
          ) : (
            <Col span={rightButtonContent}>{rightButton}</Col>
          ))}
      </Styled.RowCardItem>
      <Styled.Children minHeight={minHeight}>{children}</Styled.Children>
    </Styled.Container>
  );
}

CardItem.defaultProps = {
  title: '',
  minHeight: 0,
};
