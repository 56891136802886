import React, { useMemo, useState } from 'react';
import { Form } from 'antd';
import GenericForm from 'components/NewForms/Form/GenericForm';
import { InputTypes } from 'types/FormTypes';
import { OptionType } from 'types/OptionTypes';
import { useTranslation } from 'react-i18next';
import { getEmployeesByProjectOrDepartmentIds } from 'api/timeOffService';
import { useEmployeeSkimData } from 'ui-v2/hooks/useEmployeeSkimData';
import { useDispatch } from 'react-redux';
import { fetchTrackedHourDetails } from 'redux/trackings/actions';
import moment from 'moment';
import { fetchDepartmentsList } from '../ProtectedRoutes/Employee/TimeOff/Filters/utils';

export default function Filters() {
  const [form] = Form.useForm();
  const [dep, setDep] = useState({ value: null });
  const [employeeOptions, setEmployeeOptions] = useState<OptionType[]>([]);
  const { data: allEmployees } = useEmployeeSkimData();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const months = [
    { id: '01', value: '01', label: 'January' },
    { id: '02', value: '02', label: 'February' },
    { id: '03', value: '03', label: 'March' },
    { id: '04', value: '04', label: 'April' },
    { id: '05', value: '05', label: 'May' },
    { id: '06', value: '06', label: 'June' },
    { id: '07', value: '07', label: 'July' },
    { id: '08', value: '08', label: 'August' },
    { id: '09', value: '09', label: 'September' },
    { id: '10', value: '10', label: 'October' },
    { id: '11', value: '11', label: 'November' },
    { id: '12', value: '12', label: 'December' },
  ];

  const yearsArray = Array.from({ length: 10 }, (_, i) => {
    const year = new Date().getFullYear() - i;
    return {
      id: year.toString(),
      label: year.toString(),
      value: year.toString(),
    };
  });

  const currentMonthValue = new Date().toISOString().slice(5, 7);

  const currentYear = yearsArray.find(
    (date) => +date.value === new Date().getFullYear()
  );
  const currentMonth = months.find(
    (month) => month.value === currentMonthValue
  );
  const [selectedMonth] = useState<{
    id: string;
    value: string;
    label: string;
  }>(currentMonth as { id: string; value: string; label: string });

  const [selectedYear] = useState<any>(currentYear);

  const transformedAllEmployees = useMemo(
    () =>
      allEmployees.map((item) => ({
        id: item.id,
        label: `${item.firstName} ${item.lastName}`,
        value: item.id,
      })),
    [allEmployees]
  );

  const onFieldsChange = async (changedFields: any) => {
    if (changedFields.some((field: any) => field.name[0] === 'departments')) {
      form.setFieldsValue({ employees: null });
    }

    const departmentsFieldValue = form?.getFieldValue('departments');
    setDep({ value: departmentsFieldValue || null });

    if (departmentsFieldValue) {
      const result = await getEmployeesByProjectOrDepartmentIds({
        departments: [departmentsFieldValue.value],
      });

      setEmployeeOptions(
        result?.data?.map((emp: any) => ({
          id: emp.id,
          label: `${emp.firstname} ${emp.lastname}`,
          value: emp.id,
        })) || []
      );
    }

    const getSelectedValue = (field: any) =>
      form.getFieldValue(field)?.value || form.getFieldValue(field);

    const selectedMonth = getSelectedValue('month');
    const selectedYear = getSelectedValue('year');
    const selectedDepartment = getSelectedValue('departments');
    const selectedEmployee = getSelectedValue('employees');

    if (selectedEmployee && selectedMonth && selectedYear) {
      dispatch(
        fetchTrackedHourDetails({
          searchOptions: {
            startDate: moment(`${selectedYear}-${selectedMonth}-01`).format(
              'YYYY-MM-DD'
            ),
            endDate: moment(`${selectedYear}-${selectedMonth}-01`)
              .endOf('month')
              .format('YYYY-MM-DD'),
            employeeIds: [selectedEmployee],
          },
        })
      );
    } else if (selectedDepartment && selectedMonth && selectedYear) {
      dispatch(
        fetchTrackedHourDetails({
          searchOptions: {
            startDate: moment(`${selectedYear}-${selectedMonth}-01`).format(
              'YYYY-MM-DD'
            ),
            endDate: moment(`${selectedYear}-${selectedMonth}-01`)
              .endOf('month')
              .format('YYYY-MM-DD'),
            departmentId: selectedDepartment,
          },
        })
      );
    } else if (selectedMonth && selectedYear) {
      dispatch(
        fetchTrackedHourDetails({
          searchOptions: {
            startDate: moment(`${selectedYear}-${selectedMonth}-01`).format(
              'YYYY-MM-DD'
            ),
            endDate: moment(`${selectedYear}-${selectedMonth}-01`)
              .endOf('month')
              .format('YYYY-MM-DD'),
          },
        })
      );
    }
  };

  const FilterFormConfig: any[][] = useMemo(
    () => [
      [
        {
          col: 24,
          offset: 0,
          name: 'departments',
          label: t('departments'),
          type: InputTypes.SELECT_LOAD,
          fetchOptions: fetchDepartmentsList,
        },
      ],
      [
        {
          col: 24,
          offset: 0,
          name: 'employees',
          label: t('employee'),
          node: 'tags',
          selectOptions:
            dep.value == null ? transformedAllEmployees : employeeOptions,
          type: InputTypes.SELECT,
          showSearch: true,
        },
      ],
      [
        {
          col: 24,
          offset: 0,
          name: 'month',
          label: t('month'),
          type: InputTypes.SELECT,
          defaultValue: form.getFieldValue('month') || selectedMonth,
          selectOptions: months,
        },
      ],
      [
        {
          col: 24,
          offset: 0,
          name: 'year',
          label: t('year'),
          type: InputTypes.SELECT,
          defaultValue: form.getFieldValue('year') || selectedYear,

          selectOptions: yearsArray,
        },
      ],
    ],
    [employeeOptions, dep, transformedAllEmployees, selectedMonth, selectedYear]
  );

  return (
    <GenericForm
      formConfiguration={FilterFormConfig}
      form={form}
      onFieldsChange={onFieldsChange}
    />
  );
}
