import React from 'react';
import { PHONE_SCREEN_BREAKPOINT } from 'utils/constants';
import TrackingHoursActions from 'components/Actions/TrackingHoursActions';
import moment from 'moment';
import { formatMinutesToHoursAndMinutes } from 'ui-v2/routes/ProtectedRoutes/Employee/TimeOff/utils';
import { TrackingHoursDetailedTableUtils } from './utils';
import i18next from '../../i18n';

type ColumnType = TrackingHoursDetailedTableUtils.TrackingHoursColumnsType;
const isPhone = window.innerWidth <= PHONE_SCREEN_BREAKPOINT;

export const TrackingHoursColumnsMobile: ColumnType[] = [
  {
    title: i18next.t('date'),
    dataIndex: 'date',
    key: 'date',
    render: (_, item) => {
      const breakStart = moment(item.breakStartTime);
      const breakEnd = moment(item.breakEndTime);

      const formattedBreakStart = breakStart.format('HH:mm');
      const formattedBreakEnd = breakEnd.format('HH:mm');
      return (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '1rem',
          }}
        >
          <div>
            <strong>{`${i18next.t('descriptionProject')}: `}</strong>
            {item.description}
          </div>

          <div>
            <strong>{`${i18next.t('employee')}: `}</strong>
            {`${item.employee.firstName} ${item.employee.lastName}`}
          </div>
          <div>
            <strong>{`${i18next.t('date')}: `}</strong>
            {item.date}
          </div>
          <div>
            <strong>{`${i18next.t('break')}: `}</strong>
            {`${formattedBreakStart}-${formattedBreakEnd}`}
          </div>
          <div>
            <strong>{`${i18next.t('duration')}: `}</strong>
            {formatMinutesToHoursAndMinutes(item.duration)}
          </div>
          <div>
            <strong>{`${i18next.t('type')}: `}</strong>
            {item.hourType}
          </div>
          <div>
            <TrackingHoursActions id={item?.id} />
          </div>
        </div>
      );
    },
    default: true,
    width: 150,
  },
];

export const TrackingHoursColumns: ColumnType[] = [
  {
    title: i18next.t('descriptionProject'),
    dataIndex: 'assignment',
    key: 'assignment',
    sorter: TrackingHoursDetailedTableUtils.SortTimeEntryData,
    render: TrackingHoursDetailedTableUtils.TimeEntryCellData,
    ellipsis: true,
    default: true,
    width: isPhone ? 250 : 200,
  },
  {
    title: i18next.t('employee'),
    dataIndex: 'employee',
    key: 'employee',
    sorter: TrackingHoursDetailedTableUtils.SortEmployeeData,
    render: TrackingHoursDetailedTableUtils.EmployeeCellData,
    default: true,
    width: 150,
  },
  {
    title: i18next.t('date'),
    dataIndex: 'date',
    key: 'date',
    render: TrackingHoursDetailedTableUtils.DateCellData,
    default: true,
    width: 150,
  },
  {
    title: i18next.t('break'),
    dataIndex: 'break',
    key: 'break',
    render: TrackingHoursDetailedTableUtils.BreakCellData,
    default: true,
    width: isPhone ? 100 : 90,
  },
  {
    title: i18next.t('duration'),
    dataIndex: 'duration',
    key: 'duration',
    render: TrackingHoursDetailedTableUtils.DurationCellData,
    default: true,
    width: isPhone ? 130 : 90,
  },
  {
    title: i18next.t('type'),
    dataIndex: 'hourType',
    key: 'hourType',
    render: TrackingHoursDetailedTableUtils.HourTypeCellData,
    default: true,
    width: 150,
  },
  {
    width: 150,
    render: (_, row) => <TrackingHoursActions id={row?.id} />,
    default: false,
  },
];
