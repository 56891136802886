import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import Content from 'ui-v2/components/Content';
import useIdeaboxData from 'ui-v2/hooks/useIdeaboxData';
import { fetchSingleIdeabox } from 'redux/ideabox/actions';
import IdeaboxModalForm from 'components/NewForms/IdeaBoxForm';
import IdeaboxInformation from './IdeaboxInformation';

export default function IdeaboxDetails() {
  const { singleIdeabox, singleIdeaboxLoading } = useIdeaboxData();
  const dispatch = useDispatch();
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams.get('edit-ideabox-details')) {
      setModalVisible(true);
      searchParams.delete('edit-ideabox-details');
      setSearchParams(searchParams);
    }
  }, [searchParams]);

  useEffect(() => {
    if (searchParams.get('id')) {
      dispatch(fetchSingleIdeabox(searchParams.get('id') as string));
    }
  }, [searchParams]);

  if (!singleIdeabox?.id) {
    return null;
  }

  return (
    <>
      <Content position="flexcol">
        <>
          <IdeaboxInformation loading={singleIdeaboxLoading} />
        </>
      </Content>
      {modalVisible && (
        <IdeaboxModalForm
          open={modalVisible}
          closeModal={() => {
            setModalVisible(false);
            searchParams.delete('edit-ideabox-details');
            setSearchParams(searchParams);
          }}
        />
      )}
    </>
  );
}
