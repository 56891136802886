import { Col, Row } from 'antd';
import styled from 'styled-components';

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 500px;
  position: relative;
`;

export const StyledScroll = styled.div`
  max-height: 450px;
  overflow: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

export const StyledNoDataText = styled(Row)`
  min-height: 350px;
`;

export const StyledSelectWrapper = styled(Col)`
  display: flex;
  justify-content: end;
  margin-bottom: 1rem;
  width: 100%;
  gap: 5px;
`;

export const AttendanceMatrixContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export const IconWrapper = styled.div`
  margin-bottom: 10px;
`;

export const LegendContainer = styled(Row)`
  margin-bottom: 16px;
  display: flex;
  gap: 24px;
`;

export const LegendItem = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const ColorSquare = styled.div<{ color: string }>`
  width: 16px;
  height: 16px;
  ${({ color }) => `background-color: ${color};`}
`;
