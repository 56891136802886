import styled from 'styled-components';

export const MonthCell = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #e6f7ff;
  height: 100%;
`;

export const TimeOffDetails = styled.div<{ isPhoneScreen: boolean }>`
  width: ${(props) => (props.isPhoneScreen ? '100%' : '90%')};
  margin-top: ${(props) => (props.isPhoneScreen ? '20px' : '0px')};
  margin-bottom: 50px;
`;
