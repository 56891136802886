import React, { useEffect, useState } from 'react';
import { PlusCircleOutlined } from '@ant-design/icons';
import { Button, Col, Row, Skeleton } from 'antd';
import { useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { EmployeeType } from 'types/Employee';
import { useSelector } from 'react-redux';
import { EmployeeTaskModelType } from 'types/EmployeeTask';
import { deleteEmployeeTask, getEmployeeTasks } from 'api/employeeTaskService';
import CardItem from 'ui-v2/components/Card';
import RightSection from 'ui-v2/components/RightSection';
import { BUTTONS } from 'ui-v2/components/HeaderActions/config';
import RoleBasedButton from 'ui-v2/components/RoleBasedButton';
import { AuthUserState } from 'redux/authUser/types';
import { RootState } from 'redux/store';
import { useTranslation } from 'react-i18next';
import { useScreenSize } from 'ui-v2/hooks/useScreenSize';
import {
  convertUTCtoFormatedLocalTime,
  formatMentorName,
} from 'utils/utilFunctions';
import * as Styled from '../../WorkExperience/WorkExperienceStyles';
import EmployeeOnboardingModal from './EmployeeOnboardingModal';
import { EmployeeOnboardingTaskItem } from './EmployeeOnboardingTaskItem/EmployeeOnboardingTaskItem';
import EmployeeOnboardingTaskViewModal from './EmployeeOnboardingTaskViewModal';
import EmployeeOnboardingMobile from './EmployeeProfileMobileTabs';

type IProps = {
  employee: EmployeeType;
  visiblity: boolean;
};

export default function EmployeeOnboarding({ employee, visiblity }: IProps) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [loading, isLoading] = useState<boolean>(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [viewModal, setViewModal] = useState(false);
  const [viewTaskDetails, setViewTaskDetails] =
    useState<EmployeeTaskModelType>();
  const [task, setTask] = useState<EmployeeTaskModelType | null>();
  const [tasks, setTasks] = useState<EmployeeTaskModelType[]>([]);
  const onToggleModal = () => setModalVisible((state) => !state);
  const onToggleModalView = () => setViewModal((state) => !state);
  const { isMobile } = useScreenSize();

  const { authUserRole }: AuthUserState = useSelector(
    (state: RootState) => state.authUser
  );
  const { t } = useTranslation();

  async function fetchEmployeeTasks(id: string) {
    isLoading(true);
    getEmployeeTasks(id)
      .then((response) => {
        setTasks(response.data ?? []);
      })
      .catch(() => {
        toast.error(t("Something went wrong, can't get employee tasks"));
      })
      .finally(() => {
        isLoading(false);
      });
  }

  useEffect(() => {
    if (employee?.id) {
      fetchEmployeeTasks(employee.id);
    }
  }, [employee?.id]);

  async function onDelete(taskId: string) {
    isLoading(true);
    deleteEmployeeTask(taskId)
      .then((response) => {
        if (response.status === 200) {
          fetchEmployeeTasks(employee.id);
          toast.success(t('taskDeletedSsuccessfully'));
        }
      })
      .catch(() => {
        toast.error(t('taskFailedtoDelete'));
      })
      .finally(() => {
        isLoading(false);
      });
  }

  const onButtonClick = () => {
    onToggleModal();
  };

  const onModalClose = () => {
    setModalVisible(false);
    setTask(null);
  };

  const onEditCallBack = (index: number) => {
    setTask(tasks[index]);
    onToggleModal();
  };

  const onViewCallBack = (index: number) => {
    setViewTaskDetails(tasks[index]);
    onToggleModalView();
  };

  useEffect(() => {
    if (searchParams.get('delete-employee-onboarding')) {
      onDelete(searchParams.get('delete-employee-onboarding') as string);
      searchParams.delete('delete-employee-onboarding');
      setSearchParams(searchParams);
    }
  }, [searchParams]);

  return (
    <>
      {loading ? (
        <Skeleton loading={loading} paragraph={{ rows: 1 }} active />
      ) : (
        <CardItem
          title={t('employeeonboardingTasks')}
          rightButton={
            tasks.length !== 0 && visiblity ? (
              <RoleBasedButton btn={BUTTONS.ADD_BUTTON}>
                <RightSection
                  buttonCallBack={() => {
                    onButtonClick();
                  }}
                  buttonText={t('assignTask')}
                />
              </RoleBasedButton>
            ) : undefined
          }
        >
          <>
            <Row gutter={[0, 24]}>
              {!tasks?.length && (
                <>
                  <Styled.RowItem justify="center" last>
                    <Col>
                      <Styled.NodataLabel>
                        {authUserRole === 'employee'
                          ? 'There is no onboarding process related to you'
                          : t(
                              'Currently there is no onboarding process related to this employee'
                            )}
                      </Styled.NodataLabel>
                    </Col>
                  </Styled.RowItem>
                  {visiblity && (
                    <Styled.RowItem last justify="center">
                      <RoleBasedButton btn={BUTTONS.ADD_BUTTON}>
                        <Col>
                          <Button
                            icon={<PlusCircleOutlined />}
                            size="large"
                            style={{
                              borderRadius: '8px',
                              fontWeight: '600',
                              fontSize: '16px',
                              color: '344054',
                            }}
                            onClick={() => {
                              onButtonClick();
                            }}
                          >
                            {t('addtask')}
                          </Button>
                        </Col>
                      </RoleBasedButton>
                    </Styled.RowItem>
                  )}
                </>
              )}
              {tasks?.length > 0 &&
                !loading &&
                (!isMobile ? (
                  <>
                    <Styled.RowItem last style={{ paddingLeft: 10 }}>
                      <Col span={4}>
                        <Styled.NodataLabel>
                          {t('task name')}
                        </Styled.NodataLabel>
                      </Col>
                      <Col span={3}>
                        <Styled.NodataLabel>
                          {t('assignDate')}
                        </Styled.NodataLabel>
                      </Col>
                      <Col span={4}>
                        <Styled.NodataLabel>{t('type')}</Styled.NodataLabel>
                      </Col>
                      <Col span={3}>
                        <Styled.NodataLabel>{t('dueDate')}</Styled.NodataLabel>
                      </Col>
                      <Col span={3}>
                        <Styled.NodataLabel>{t('status')}</Styled.NodataLabel>
                      </Col>
                      <Col span={4}>
                        <Styled.NodataLabel>{t('mentor')}</Styled.NodataLabel>
                      </Col>
                    </Styled.RowItem>
                    {tasks.map((task: EmployeeTaskModelType, index: number) => (
                      <EmployeeOnboardingTaskItem
                        key={task.id}
                        name={task.name}
                        assignedDate={task.assignedDate}
                        dueDate={task.dueDate}
                        status={task.status}
                        type={task.type}
                        employee={task.mentor}
                        onDelete={() => onDelete(task.id)}
                        onEdit={() => onEditCallBack(index)}
                        onView={() => onViewCallBack(index)}
                        visibility={visiblity}
                      />
                    ))}
                  </>
                ) : (
                  tasks.map((task: EmployeeTaskModelType) => (
                    <EmployeeOnboardingMobile
                      key={task.id}
                      fields={[
                        { label: 'task name', value: task.name },
                        {
                          label: 'assignDate',
                          value: convertUTCtoFormatedLocalTime(
                            task.assignedDate
                          ),
                        },
                        { label: 'type', value: task.type },
                        {
                          label: 'dueDate',
                          value: convertUTCtoFormatedLocalTime(task.dueDate),
                        },
                        { label: 'status', value: task.status },
                        { label: 'mentor', value: formatMentorName(employee) },
                      ]}
                    />
                  ))
                ))}
            </Row>

            {modalVisible && (
              <EmployeeOnboardingModal
                open={modalVisible}
                closeModal={onModalClose}
                employeeId={employee.id}
                task={task || undefined}
                fetch={() => fetchEmployeeTasks(employee?.id)}
              />
            )}

            {viewModal && (
              <EmployeeOnboardingTaskViewModal
                task={viewTaskDetails}
                open={viewModal}
                closeModal={() => {
                  setViewModal(false);
                  setSearchParams(searchParams);
                }}
              />
            )}
          </>
        </CardItem>
      )}
    </>
  );
}
